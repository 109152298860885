export enum QUERY_BUILDER_OPERATOR {
  LESS_THAN = "less than",
  LESS_THAN_OR_EQUAL = "less than or equal",
  EQUAL = "equal",
  NOT_EQUAL = "not equal",
  GREATER_THAN_OR_EQUAL = "greater than or equal",
  GREATER_THAN = "greater than",
  IS_NULL = "is null",
  IN = "in",
  BETWEEN = "between",
}

export enum VALUE_EDITOR {
  TEXT = "text",
  DATE = "date",
  SELECT = "select",
  MULTISELECT = "multiselect",
}
