import React, { FC, PropsWithChildren } from "react";
import classnames from "classnames";

export type SidebarProps = PropsWithChildren<{
  className?: string;
}>;

export const Sidebar: FC<SidebarProps> = ({ children, className }) => {
  return <aside className={classnames("page-sidebar", className)}>{children}</aside>;
};
