export enum AccountActionTypes {
  GO_TO_CHECKOUT = "@@ACCOUNT/GO_TO_CHECKOUT",

  CHECK_PAYMENT_METHOD_EXISTENCE = "@@ACCOUNT/CHECK_PAYMENT_METHOD_EXISTENCE",
  CHECK_PAYMENT_METHOD_EXISTENCE_SUCCESS = "@@ACCOUNT/CHECK_PAYMENT_METHOD_EXISTENCE_SUCCESS",
  CHECK_PAYMENT_METHOD_EXISTENCE_FAILURE = "@@ACCOUNT/CHECK_PAYMENT_METHOD_EXISTENCE_FAILURE",

  GO_TO_BILLING_PORTAL = "@@ACCOUNT/GO_TO_BILLING_PORTAL",

  GET_INVOICES = "@@ACCOUNT/GET_INVOICES",
  GET_INVOICES_SUCCESS = "@@ACCOUNT/GET_INVOICES_SUCCESS",
  GET_INVOICES_FAILURE = "@@ACCOUNT/GET_INVOICES_FAILURE",

  PAY_INVOICE = "@@ACCOUNT/PAY_INVOICE",
  PAY_INVOICE_SUCCESS = "@@ACCOUNT/PAY_INVOICE_SUCCESS",
  PAY_INVOICE_FAILURE = "@@ACCOUNT/PAY_INVOICE_FAILURE",

  GET_ACCOUNTS = "@@CLIENT/GET_ACCOUNTS",
  GET_ACCOUNTS_SUCCESS = "@@CLIENT/GET_ACCOUNTS_SUCCESS",
  GET_ACCOUNTS_FAILURE = "@@CLIENT/GET_ACCOUNTS_FAILURE",

  GET_ACCOUNT = "@@CLIENT/GET_ACCOUNT",
  GET_ACCOUNT_SUCCESS = "@@CLIENT/GET_ACCOUNT_SUCCESS",
  GET_ACCOUNT_FAILURE = "@@CLIENT/GET_ACCOUNT_FAILURE",

  GET_ACCOUNT_PROPERTY_LISTS = "@@CLIENT/GET_ACCOUNT_PROPERTY_LISTS",
  GET_ACCOUNT_PROPERTY_LISTS_SUCCESS = "@@CLIENT/GET_ACCOUNT_PROPERTY_LISTS_SUCCESS",
  GET_ACCOUNT_PROPERTY_LISTS_FAILURE = "@@CLIENT/GET_ACCOUNT_PROPERTY_LISTS_FAILURE",

  CREATE_ACCOUNT_PROPERTY_LIST = "@@CLIENT/CREATE_ACCOUNT_PROPERTY_LIST",
  CREATE_ACCOUNT_PROPERTY_LIST_SUCCESS = "@@CLIENT/CREATE_ACCOUNT_PROPERTY_LIST_SUCCESS",
  CREATE_ACCOUNT_PROPERTY_LIST_FAILURE = "@@CLIENT/CREATE_ACCOUNT_PROPERTY_LIST_FAILURE",

  GET_ACCOUNT_NOTES = "@@CLIENT/GET_ACCOUNT_NOTES",
  GET_ACCOUNT_NOTES_SUCCESS = "@@CLIENT/GET_ACCOUNT_NOTES_SUCCESS",
  GET_ACCOUNT_NOTES_FAILURE = "@@CLIENT/GET_ACCOUNT_NOTES_FAILURE",

  CREATE_ACCOUNT_NOTE = "@@CLIENT/CREATE_ACCOUNT_NOTE",
  CREATE_ACCOUNT_NOTE_SUCCESS = "@@CLIENT/CREATE_ACCOUNT_NOTE_SUCCESS",
  CREATE_ACCOUNT_NOTE_FAILURE = "@@CLIENT/CREATE_ACCOUNT_NOTE_FAILURE",

  GET_ACCOUNT_ATTEMPTS = "@@CLIENT/GET_ACCOUNT_ATTEMPTS",
  GET_ACCOUNT_ATTEMPTS_SUCCESS = "@@CLIENT/GET_ACCOUNT_ATTEMPTS_SUCCESS",
  GET_ACCOUNT_ATTEMPTS_FAILURE = "@@CLIENT/GET_ACCOUNT_ATTEMPTS_FAILURE",

  SAVE_ACCOUNT_ATTEMPTS = "@@CLIENT/SAVE_ACCOUNT_ATTEMPTS",
  SAVE_ACCOUNT_ATTEMPTS_SUCCESS = "@@CLIENT/SAVE_ACCOUNT_ATTEMPTS_SUCCESS",
  SAVE_ACCOUNT_ATTEMPTS_FAILURE = "@@CLIENT/SAVE_ACCOUNT_ATTEMPTS_FAILURE",

  LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE = "@@CLIENT/LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE",
  LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE_SUCCESS = "@@CLIENT/LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE_SUCCESS",
  LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE_FAILURE = "@@CLIENT/LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE_FAILURE",

  LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTY = "@@CLIENT/LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTY",
  LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTY_SUCCESS = "@@CLIENT/LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTY_SUCCESS",
  LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTY_FAILURE = "@@CLIENT/LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTY_FAILURE",

  GET_ACCOUNTS_LIST = "@@CLIENT/GET_ACCOUNTS_LIST",
  GET_ACCOUNTS_LIST_SUCCESS = "@@CLIENT/GET_ACCOUNTS_LIST_SUCCESS",
  GET_ACCOUNTS_LIST_FAILURE = "@@CLIENT/GET_ACCOUNTS_LIST_FAILURE",

  SET_ACCOUNTS_LIST_FILTER = "@@CLIENT/SET_ACCOUNTS_LIST_FILTER",

  CLEAR_ACCOUNTS_LIST = "@@CLIENT/CLEAR_ACCOUNTS_LIST",
  CLEAR_ACCOUNT = "@@CLIENT/CLEAR_ACCOUNT",
  CLEAR_ACCOUNT_PROPERTY_LIST = "@@CLIENT/CLEAR_ACCOUNT_PROPERTY_LIST",
  CLEAR_ALL_ACCOUNT_PROPERTY_LISTS = "@@CLIENT/CLEAR_ALL_ACCOUNT_PROPERTY_LISTS",

  CLEAR_ERRORS = "@@ACCOUNT/CLEAR_ERRORS",

  CREATE_ACCOUNT = "@@ACCOUNT/CREATE_ACCOUNT",
  CREATE_ACCOUNT_SUCCESS = "@@ACCOUNT/CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_FAILURE = "@@ACCOUNT/CREATE_ACCOUNT_FAILURE",

  UPDATE_ACCOUNT = "@@ACCOUNT/UPDATE_ACCOUNT",
  UPDATE_ACCOUNT_SUCCESS = "@@ACCOUNT/UPDATE_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_FAILURE = "@@ACCOUNT/UPDATE_ACCOUNT_FAILURE",

  UPDATE_SELF_ACCOUNT = "@@ACCOUNT/UPDATE_SELF_ACCOUNT",
  UPDATE_SELF_ACCOUNT_SUCCESS = "@@ACCOUNT/UPDATE_SELF_ACCOUNT_SUCCESS",
  UPDATE_SELF_ACCOUNT_FAILURE = "@@ACCOUNT/UPDATE_SELF_ACCOUNT_FAILURE",

  CHECK_ACCOUNT = "@@ACCOUNT/CHECK_ACCOUNT",
  CHECK_ACCOUNT_SUCCESS = "@@ACCOUNT/CHECK_ACCOUNT_SUCCESS",
  CHECK_ACCOUNT_FAILURE = "@@ACCOUNT/CHECK_ACCOUNT_FAILURE",

  GET_SUBSCRIPTION = "@@ACCOUNT/GET_SUBSCRIPTION",
  GET_SUBSCRIPTION_SUCCESS = "@@ACCOUNT/GET_SUBSCRIPTION_SUCCESS",
  GET_SUBSCRIPTION_FAILURE = "@@ACCOUNT/GET_SUBSCRIPTION_FAILURE",

  UPDATE_SUBSCRIPTION = "@@ACCOUNT/UPDATE_SUBSCRIPTION",
  UPDATE_SUBSCRIPTION_SUCCESS = "@@ACCOUNT/UPDATE_SUBSCRIPTION_SUCCESS",
  UPDATE_SUBSCRIPTION_FAILURE = "@@ACCOUNT/UPDATE_SUBSCRIPTION_FAILURE",

  SET_ACTIVE_ACCOUNT = "@@ACCOUNT/SET_ACTIVE_ACCOUNT",
  SET_ACTIVE_ACCOUNT_SUCCESS = "@@ACCOUNT/SET_ACTIVE_ACCOUNT_SUCCESS",
  SET_ACTIVE_ACCOUNT_FAILURE = "@@ACCOUNT/SET_ACTIVE_ACCOUNT_FAILURE",

  SET_ENABLED_FEATURES = "@@ACCOUNT/SET_ENABLED_FEATURES",

  CREATE_ACCOUNT_PAYMENT = "@@ACCOUNT/CREATE_ACCOUNT_PAYMENT",
  CREATE_ACCOUNT_PAYMENT_SUCCESS = "@@ACCOUNT/CREATE_ACCOUNT_PAYMENT_SUCCESS",
  CREATE_ACCOUNT_PAYMENT_FAILURE = "@@ACCOUNT/CREATE_ACCOUNT_PAYMENT_FAILURE",

  GET_ACCOUNT_PAYMENT_STATUS = "@@ACCOUNT/GET_ACCOUNT_PAYMENT_STATUS",
  GET_ACCOUNT_PAYMENT_STATUS_SUCCESS = "@@ACCOUNT/GET_ACCOUNT_PAYMENT_STATUS_SUCCESS",
  GET_ACCOUNT_PAYMENT_STATUS_FAILURE = "@@ACCOUNT/GET_ACCOUNT_PAYMENT_STATUS_FAILURE",

  CLEAR_CHECK_ACCOUNT = "@@ACCOUNT/CLEAR_CHECK_ACCOUNT",

  CLEAR_ACCOUNT_PAYMENT = "@@ACCOUNT/CLEAR_ACCOUNT_PAYMENT",

  ACTIVATE_ACCOUNT = "@@ACCOUNT/ACTIVATE_ACCOUNT",

  DEACTIVATE_ACCOUNT = "@@ACCOUNT/DEACTIVATE_ACCOUNT",
}
