import React, { Dispatch, FC, SetStateAction } from "react";
import { Pagination as _Pagination } from "rsuite";
import { Pagination as PaginationInterface } from "@shared/interfaces";
import { pageSizeOptions } from "@shared/constants";
import "./index.scss";
import { SelectPickerInput } from "@shared/components";

export interface PaginationProps {
  handlePagination: (pageNumber: number) => void;
  pagination: PaginationInterface;
  setPagination: Dispatch<SetStateAction<PaginationInterface>>;
  totalPages: number;
  totalData: number;
}

const Pagination: FC<PaginationProps> = (props) => {
  const { handlePagination, pagination, totalData, setPagination } = props;
  const { currentPage, resultsPerPage } = pagination;

  const firstRowOfPage = (currentPage - 1) * resultsPerPage + 1;
  let lastRowOfPage = currentPage * resultsPerPage;
  lastRowOfPage = lastRowOfPage > totalData ? totalData : lastRowOfPage;

  const changePageSize = (value: string | null) => {
    if (value) {
      setPagination((prevState) => {
        return {
          ...prevState,
          currentPage: 1,
          resultsPerPage: +value,
        };
      });
    }
  };
  return (
    <div className="pagination-panel">
      <div className="current-pagination-page">
        Showing {firstRowOfPage}-{lastRowOfPage} of {totalData}
      </div>
      <div className="pagination-control">
        {totalData > resultsPerPage && (
          <_Pagination
            total={totalData}
            next={true}
            prev={true}
            first={false}
            last={false}
            ellipsis
            limit={resultsPerPage}
            activePage={currentPage}
            onChangePage={handlePagination}
            maxButtons={3}
            size="lg"
            className="pagination"
          />
        )}
      </div>
      <div className="pagination-page-size">
        <p className="results-per-page">Results per page</p>
        <SelectPickerInput
          name="resultsPerPage"
          placeholder={"Results per page"}
          placement="topStart"
          searchable={false}
          dropdownHeight={200}
          cleanable={false}
          useContainer={true}
          disableContainerStyles={true}
          data={pageSizeOptions}
          className="pagination-select-picker"
          value={resultsPerPage + ""}
          onChange={(value) => value && changePageSize(value)}
        />
      </div>
    </div>
  );
};

export default Pagination;
