import React, { FC, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "@containers/PayPerLead/store";

import { useCache } from "./CacheProvider";

export interface CountyPriceProviderProps {
  stateId: number;
  countyId: number;
  children?: ReactNode;
}

export const CountyPriceProvider: FC<CountyPriceProviderProps> = ({ stateId, countyId, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchCountyPrice.request({ stateId, countyId }));
  }, [dispatch, stateId, countyId]);

  return <>{children}</>;
};

export const useCountyPrice = (countyId?: number) => {
  const cache = useCache();

  if (countyId) {
    return cache.leadPricesByCounty[countyId];
  }
};
