import { Role } from "./Role";
import { Account } from "./Account";
import { County } from "./County";
import { State } from "./State";

export enum UserStatus {
  active = "active",
  invited = "invited",
  deleted = "deleted",
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
  phone_country_code?: string;
  status: UserStatus;
  counties?: DetailedCounty[];
  created_at: Date;
  stripe_customer_id: string | null;
  claritas_id: string | null;
  claritas_nickname: string | null;
  is_active_in_account?: boolean;
  sms_on_lead_creation?: boolean;
  email_on_lead_creation?: boolean;

  roles: Role[];
  accounts: Account[];
  account_users?: { account_id: number; sms_on_lead_creation: boolean; email_on_lead_creation: boolean }[];
}

export interface DetailedCounty extends County {
  state: State;
}
