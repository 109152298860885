export const NamesOfParentRoutes = {
  AUTH: "/auth",
  DASHBOARD: "/",
};

export const NameOfRoutes = {
  SIGN_IN: "/",
  SIGN_UP: "/signup",
  AUTH: "/auth/",
  AUTH_LOGIN: "/auth/login",
  CHECK_USER: "/auth/user",
  AUTH_REGISTRATION: "/auth/registration",
  AUTH_FORGOT_PASSWORD: "/auth/restore",
  AUTH_CHANGE_PASSWORD: "/auth/change",
  FILE: "files",
  CLARITAS: "flikid",
  DATA_DELIVERY_UPDATES: "data-delivery",
  PROPERTIES: "properties",
  PAY_PER_LEAD: "pay-per-lead",
  TICKETS: "tickets",
};

export const NameOfChildRoutes = {
  AUTH: {
    LOGIN: "/login",
    CHECK_USER: "/user",
    FORGOT_PASSWORD: "/restore",
    RESET_PASSWORD: "/recovery/:hash",
    SET_PASSWORD: "/signup/:hash",
    REGISTRATION: "/registration",
  },
  SKIP_TRACING: {
    ROOT: "/skip-tracings",
    CREATE: "/new",
    DETAILS: "/:id",
    LIST: "/",
  },
  CONTACT: {
    ROOT: "/contacts",
    CREATE: "/new",
    DETAILS: "/:id",
    LIST: "/",
    PRESET: {
      ROOT: "/presets",
      CREATE: "/new",
      DETAILS: "/:presetId",
      LIST: "/",
    },
  },
  INVESTOR: {
    ROOT: "/monthly-files",
    LIST: "/",
    UPLOAD: "/upload",
  },
  MARKET_MAP: {
    ROOT: "/market-map",
  },
  PRESET_BUILDER: {
    ROOT: "/preset-builder",
  },
  FILE: {
    DOWNLOAD_MULTIPLE: "/download",
    DOWNLOAD: "/:id/download",
  },
  CLARITAS: {
    DASHBOARD: "",
  },
  DATA_DELIVERY_UPDATES: {
    DASHBOARD: "",
  },
  PRESET_SUCCESS: {
    ROOT: "/preset-success",
  },
  PRESET_ERROR: {
    ROOT: "/preset-error",
  },
  PRESET_NOT_FOUND: {
    ROOT: "/preset-not-found",
  },
  PRESET_NORMAL: {
    ROOT: "/preset-normal",
  },
  PRESET_LOADING: {
    ROOT: "/preset-loading",
  },
  RECORD_MANAGEMENT: {
    ROOT: "/record-management",
    PROPERTIES: "properties",
    PROPERTY: "properties/:propertyId",
    LISTS: "lists",
    LISTS_DETAIL: "lists/:listId",
    OWNERS: "owners",
    OWNER_DETAILS: "owners/:name",
  },
  PAY_PER_LEAD: {
    ROOT: "/pay-per-lead",
    DASHBOARD: "",
    ITEM: ":id",
    SUBSCRIPTIONS: "subscriptions",
    CONFIRM: "confirm",
    ACTIVATE: "activate",
    DEACTIVATE: "deactivate",
    ADD: "add",
    DISPUTES: "disputes",
    CUSTOMERS: "customers",
    LEADS: "leads",
  },
  TICKETS: {
    ROOT: "/tickets",
  },
};
