import React, { FC, PropsWithChildren } from "react";
import classnames from "classnames";

import "./index.scss";

export type ContainerProps = PropsWithChildren<{
  layout?: "vertical" | "horizontal";
  className?: string;
}>;

export const Container: FC<ContainerProps> = ({ children, layout = "horizontal", className }) => {
  return (
    <section
      className={classnames("container", className, {
        "vertical-layout": layout === "vertical",
        "horizontal-layout": layout === "horizontal",
      })}
    >
      {children}
    </section>
  );
};
