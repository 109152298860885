import { BaseFilter, PaginatedResponse } from "@shared/interfaces";
import { PayPerLeadDispute } from "@shared/models/PayPerLead";

export interface GetPayPerLeadShape extends BaseFilter {
  is_active?: boolean;
  account_id?: number;
  state_id?: number;
  county_id?: number;
  from?: Date;
  to?: Date;
  show_county?: boolean;
  show_state?: boolean;
  show_account?: boolean;
  show_dispute?: boolean;
  show_detail?: boolean;
}

export interface DisputePayPerLead {
  lead_id: number;
  message: string;
}

export interface GetDisputesRequest extends BaseFilter {
  account_id?: number;
  is_active?: boolean;
  lead_id?: number;
  resolution?: string;
}

export interface CheckPurchaseResponse {
  paid: boolean;
  hasPendingInvoice: boolean;
}

export type GetDisputesSuccess = PaginatedResponse<PayPerLeadDispute>;

export interface UpdateDisputeRequest {
  disputeId: number;
  is_approved: boolean;
}

export interface UpdateDisputeResponse {
  resolved_by: string;
  resolved_date: Date | string;
}

export interface UpdateDisputeSuccess extends UpdateDisputeResponse {
  disputeId: number;
  is_approved: boolean;
}

export interface DownloadLeadsActionPayload {
  ids: number[];
  active_account: boolean;
}

export interface DownloadLeadsRequestPayload {
  ids: number[];
  account_id?: number;
  order?: string;
  direction?: string;
}

export interface QualifyLeadsPayloadBase {
  ids: number[];
  is_qualified: boolean | null;
}

export interface QualifyLeadsRequest extends QualifyLeadsPayloadBase {
  account_id: number;
}

export interface QualifyLeadsActionPayload extends QualifyLeadsPayloadBase {
  onError?: (error?: Error) => void;
  notify?: boolean;
}

export enum LeadBulkAction {
  ADMIN_LEADS_LIST = "ADMIN_LEADS_LIST",
  LEADS_DASHBOARD = "LEADS_DASHBOARD",
}
