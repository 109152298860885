export enum SharedActionTypes {
  NAVIGATE = "@@SHARED/NAVIGATE",

  START_LOADING = "@@SHARED/START_LOADING",
  STOP_LOADING = "@@SHARED/STOP_LOADING",
  ADD_LOADING_TYPE = "@@SHARED/ADD_LOADING_TYPE",
  REMOVE_LOADING_TYPE = "@@SHARED/REMOVE_LOADING_TYPE",

  ADD_LOADING_SECTION = "@@SHARED/ADD_LOADING_SECTION",
  REMOVE_LOADING_SECTION = "@@SHARED/REMOVE_LOADING_SECTION",
  SHOW_NOTIFICATION = "@@SHARED/SHOW_NOTIFICATION",

  GET_USER_DETAIL = "@@SHARED/GET_USER_DETAIL",
  GET_USER_DETAIL_SUCCESS = "@@SHARED/GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILURE = "@@SHARED/GET_USER_DETAIL_FAILURE",

  GET_ROLES = "@@SHARED/GET_ROLES",
  GET_ROLES_SUCCESS = "@@SHARED/GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE = "@@SHARED/GET_ROLES_FAILURE",

  SHOW_OVERLAY = "@@SHARED/SHOW_OVERLAY",
  HIDE_OVERLAY = "@@SHARED/HIDE_OVERLAY",
  UPDATE_OVERLAY = "@@SHARED/UPDATE_OVERLAY",

  SHOW_MODAL = "@@SHARED/SHOW_MODAL",
  HIDE_MODAL = "@@SHARED/HIDE_MODAL",

  SHOW_PROGRESS_SCREEN = "@@SHARED/SHOW_PROGRESS_SCREEN",
  HIDE_PROGRESS_SCREEN = "@@SHARED/HIDE_PROGRESS_SCREEN",

  UPLOAD_TO_S3 = "@@SHARED/UPLOAD_TO_S3",
  UPLOAD_TO_S3_SUCCESS = "@@SHARED/UPLOAD_TO_S3_SUCCESS",
  UPLOAD_TO_S3_FAILURE = "@@SHARED/UPLOAD_TO_S3_FAILURE",

  UPLOAD_TO_S3_MULTIPLE = "@@SHARED/UPLOAD_TO_S3_MULTIPLE",
  UPLOAD_TO_S3_MULTIPLE_SUCCESS = "@@SHARED/UPLOAD_TO_S3_MULTIPLE_SUCCESS",
  UPLOAD_TO_S3_MULTIPLE_FAILURE = "@@SHARED/UPLOAD_TO_S3_MULTIPLE_FAILURE",
  UPLOAD_TO_S3_MULTIPLE_CANCEL = "@@SHARED/UPLOAD_TO_S3_MULTIPLE_CANCEL",

  UPLOAD_TO_S3_MULTIPLE_ITEM = "@@SHARED/UPLOAD_TO_S3_MULTIPLE_ITEM_SUCCESS",
  UPLOAD_TO_S3_MULTIPLE_ITEM_SUCCESS = "@@SHARED/UPLOAD_TO_S3_MULTIPLE_ITEM_SUCCESS",
  UPLOAD_TO_S3_MULTIPLE_ITEM_FAILURE = "@@SHARED/UPLOAD_TO_S3_MULTIPLE_ITEM_FAILURE",
  UPLOAD_TO_S3_MULTIPLE_ITEM_CANCEL = "@@SHARED/UPLOAD_TO_S3_MULTIPLE_ITEM_CANCEL",

  GET_COUNTIES_BY_STATE = "@@SHARED/GET_COUNTIES_BY_STATE",
  GET_COUNTIES_BY_STATE_SUCCESS = "@@SHARED/GET_COUNTIES_BY_STATE_SUCCESS",
  GET_COUNTIES_BY_STATE_FAILURE = "@@SHARED/GET_COUNTIES_BY_STATE_FAILURE",

  GET_STATES = "@@SHARED/GET_STATES",
  GET_STATES_SUCCESS = "@@SHARED/GET_STATES_SUCCESS",
  GET_STATES_FAILURE = "@@SHARED/GET_STATES_FAILURE",

  GET_PROPERTY_TYPES = "@@SHARED/GET_PROPERTY_TYPES",
  GET_PROPERTY_TYPES_SUCCESS = "@@SHARED/GET_PROPERTY_TYPES_SUCCESS",
  GET_PROPERTY_TYPES_FAILURE = "@@SHARED/GET_PROPERTY_TYPES_FAILURE",

  GET_ZIPS = "@@SHARED/GET_ZIPS",
  GET_ZIPS_SUCCESS = "@@SHARED/GET_ZIPS_SUCCESS",
  GET_ZIPS_FAILURE = "@@SHARED/GET_ZIPS_FAILURE",

  GET_NEIGHBORHOODS = "@@SHARED/GET_NEIGHBORHOODS",
  GET_NEIGHBORHOODS_SUCCESS = "@@SHARED/GET_NEIGHBORHOODS_SUCCESS",
  GET_NEIGHBORHOODS_FAILURE = "@@SHARED/GET_NEIGHBORHOODS_FAILURE",

  CLEAR_FILE = "@@SHARED/CLEAR_FILE",
  CLEAR_MULTIPLE_UPLOADS = "@@SHARED/CLEAR_MULTIPLE_UPLOADS",
  SHOW_BACKGROUND_UPLOAD_WIDGET = "@@SHARED/SHOW_BACKGROUND_UPLOAD_WIDGET",
  HIDE_BACKGROUND_UPLOAD_WIDGET = "@@SHARED/HIDE_BACKGROUND_UPLOAD_WIDGET",

  HANDLE_FORBIDDEN_ERROR = "@@SHARED/HANDLE_FORBIDDEN_ERROR",
}
