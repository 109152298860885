import { FileToMapAccount, UpdateFileMappingType } from "@shared/models";

import { request } from "../../../shared/utils";
import { API, METHODS } from "../../../shared/constants";
import { CreateFileShape } from "../interface";

export default {
  getTempAWSCreds: () => request(METHODS.GET, API.FILE.GET_TEMP_AWS_CREDS)(),
  createFile: (payload: CreateFileShape) => request(METHODS.POST, API.FILE.CREATE)(payload),
  updateFileMapping: (payload: UpdateFileMappingType & { id: number }) => {
    const { id, ...rest } = payload;
    return request(METHODS.PATCH, API.FILE.UPDATE_FILE_MAPPING(String(id)))(rest);
  },
  getFileSignedUrl: (fileId: number) => request(METHODS.GET, API.FILE.GET_FILE_SIGNED_URL(fileId))(),
  mapFileToAccount: (payload: FileToMapAccount[]) => request(METHODS.POST, API.FILE.MAP_FILE_TO_ACCOUNT)(payload),
};
