import { createAction, createAsyncAction } from "typesafe-actions";
import { BaseFilter, PaginatedResponse, ResponseError } from "@shared/interfaces";
import {
  ClaritasError,
  ClaritasStatisticsDataPoint,
  GetClaritasStatisticsShape,
  GetClaritasVisitorsFileShape,
  GetClaritasVisitorsShape,
} from "@containers/Claritas/interface";
import { ClaritasVisitor } from "@shared/models";

import { ClaritasActionTypes } from "./constants";

export const getVisitors = createAsyncAction(
  ClaritasActionTypes.GET_VISITORS,
  ClaritasActionTypes.GET_VISITORS_SUCCESS,
  ClaritasActionTypes.GET_VISITORS_FAILURE,
)<GetClaritasVisitorsShape, PaginatedResponse<ClaritasVisitor> | null, ResponseError>();

export const getStatistics = createAsyncAction(
  ClaritasActionTypes.GET_STATISTICS,
  ClaritasActionTypes.GET_STATISTICS_SUCCESS,
  ClaritasActionTypes.GET_STATISTICS_FAILURE,
)<GetClaritasStatisticsShape, ClaritasStatisticsDataPoint[] | null, ResponseError>();

export const getVisitorsFile = createAsyncAction(
  ClaritasActionTypes.GET_VISITORS_FILE,
  ClaritasActionTypes.GET_VISITORS_FILE_SUCCESS,
  ClaritasActionTypes.GET_VISITORS_FILE_FAILURE,
)<GetClaritasVisitorsFileShape, void, ResponseError>();

export const setFilter = createAction(ClaritasActionTypes.SET_FILTER)<BaseFilter | null>();
export const clearErrors = createAction(ClaritasActionTypes.CLEAR_ERRORS)<ClaritasError[] | void>();
