import React, { Suspense, ExoticComponent } from "react";
import { LoadingIndicator } from "@shared/components";

const Loadable = (Component: ExoticComponent) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<LoadingIndicator />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
