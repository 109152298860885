import React, { useCallback, useMemo } from "react";
import classnames from "classnames";
import { TableItemType } from "@shared/components/Table/interfaces";
import { useDebouncedFunction } from "@shared/hooks";
import { SortTable } from "@shared/interfaces";

import { TableProperty } from "./interface";
import { Head } from "./Head";
import { Row } from "./Row";
import { CheckboxInput } from "..";
import "./index.scss";

export interface TableProps<T> extends SortTable {
  items: TableItemType<T>[];
  properties: TableProperty<T>[];
  onFinishScroll?: () => void;
  onRowClick?: (item: TableItemType<T>) => void;
  selectableRows?: boolean;
  keyForSelect?: keyof TableItemType<T>;
  selectedItems?: TableItemType<T>[];
  onChangeSelectedItems?: (items: TableItemType<T>[]) => void;
  tableRef?: React.RefObject<HTMLDivElement>;
  isHeadBoxIsChecked?: boolean;
  headerless?: boolean;
  bordered?: boolean;
  hover?: boolean;
  className?: string;
}

function Table<T>(props: TableProps<T>) {
  const {
    sortOrder,
    sortBy,
    onChangeSorting,
    items,
    properties,
    onFinishScroll,
    onRowClick,
    selectedItems,
    onChangeSelectedItems,
    selectableRows,
    keyForSelect,
    tableRef,
    isHeadBoxIsChecked,
    headerless,
    bordered,
    hover,
    className,
  } = props;

  const debouncedFinishScroll = useDebouncedFunction(onFinishScroll);
  const key = useMemo(() => keyForSelect || properties[0].key, [keyForSelect, properties]);

  const onScroll = useCallback(
    (event: React.SyntheticEvent<Element>) => {
      const maxScroll = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
      if (event.currentTarget.scrollTop >= maxScroll - 1 && debouncedFinishScroll) {
        debouncedFinishScroll();
      }
    },
    [debouncedFinishScroll],
  );

  const onSelectUnselectItem = useCallback(
    (item: TableItemType<T>) => {
      if (!selectedItems?.find((selectedItem) => selectedItem[key] === item[key])) {
        onChangeSelectedItems?.([...(selectedItems || []), item]);
      } else {
        onChangeSelectedItems?.(selectedItems?.filter((selectedItem) => selectedItem[key] !== item[key]));
      }
    },
    [selectedItems, onChangeSelectedItems, key],
  );

  const handleSelectAll = useCallback(
    (isAllSelected: boolean) => {
      if (isAllSelected) {
        onChangeSelectedItems?.(items);
      } else {
        onChangeSelectedItems?.([]);
      }
    },
    [items, onChangeSelectedItems],
  );

  const selectColumn = useCallback<(key: keyof TableItemType<T>) => TableProperty<T>>(
    (key) => ({
      title: "",
      key,
      appearClickBlock: (item: TableItemType<T>) => {
        return (
          <CheckboxInput
            value={selectedItems?.find((selectedItem) => selectedItem[key] === item[key]) ? "1" : ""}
            onChange={() => true}
          />
        );
      },
      cellClick: (item: TableItemType<T>) => {
        onSelectUnselectItem?.(item);
      },
      cellClassName: "checkbox-cell",
      headerClassName: "checkbox-cell-header",
    }),
    [onSelectUnselectItem, selectedItems],
  );

  const tableProperties = useMemo(() => {
    return selectableRows ? [selectColumn(key), ...properties] : properties;
  }, [properties, selectableRows, selectColumn, key]);

  return (
    <div className={classnames("table-wrapper", className, { bordered, hover })} ref={tableRef} onScroll={onScroll}>
      {!headerless && (
        <Head<T>
          properties={tableProperties}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onChangeSorting={onChangeSorting}
          isChecked={isHeadBoxIsChecked}
          selectableRows={selectableRows}
          handleSelectAll={handleSelectAll}
        />
      )}
      <div className={classnames("table-content", { "table-clickable": onRowClick })}>
        {items?.map((item, index) => (
          <Row<T> key={index} item={item} properties={tableProperties} onClick={() => onRowClick?.(item)} />
        ))}
      </div>
    </div>
  );
}

export default Table;
