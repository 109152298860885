import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";

export default {
  getUserDetails: () => request(METHODS.GET, API.USER.GET_USER_DETAILS)(),
  getStates: () => request(METHODS.GET, API.STATE.GET_STATES_LIST)({ params: { order: "name", direction: "asc" } }),
  getPropertyTypes: () => request(METHODS.GET, API.RECORD_MANAGEMENT.GET_PROPERTY_TYPES)(),
  getCounties: (state_id?: number) =>
    request(METHODS.GET, API.COUNTY.GET_COUNTIES_LIST)({ params: { state_id, order: "name", direction: "asc" } }),
  getZips: (county_id?: number) => request(METHODS.GET, API.COUNTY.GET_COUNTY_ZIPS)({ params: { county_id } }),
  getNeighborhoods: (countyId: number) => request(METHODS.GET, API.COUNTY.GET_COUNTY_NEIGHBORHOOD_LIST(countyId))(),
};
