import { RoleName, User } from "../models";
import { tokenHandler } from "../utils";

export const getUserPermissions = (userParam?: User): string[] => {
  const user: User = userParam || tokenHandler.getUser();

  if (!user || !user.roles) {
    return [];
  }

  const permissions: string[] = [];
  user.roles.forEach((r) => {
    permissions.push(...(r.permissions ? r.permissions.map((p) => p.name) : []));
  });

  return permissions;
};

export const checkPermission = (permission: string, user?: User): boolean => {
  const userPermissions = getUserPermissions(user);
  return !!userPermissions.find((up) => up === permission);
};

export const checkPermissions = (permissions: string[], user?: User): boolean => {
  const userPermissions = getUserPermissions(user);
  return permissions.some((p) => userPermissions.find((up) => up === p));
};

export const checkIfAdmin = (user?: User) => {
  const currentUser: User = user || tokenHandler.getUser();
  const adminRoles = [RoleName.admin, RoleName.super_admin];

  return currentUser.roles.some((r) => adminRoles.includes(r.name));
};
