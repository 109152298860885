import { createAction, createAsyncAction } from "typesafe-actions";
import {
  BuyBoxMetrics,
  CountyGeoInterface,
  FilterChanged,
  FilterShape,
  GetMetricsPayload,
  ResponseError,
} from "@shared/interfaces";
import { Account, County, State } from "@shared/models";
import { NeighborhoodMetrics } from "@shared/interfaces/NeighborhoodMetrics.interface";

import { MARKET_MAP_ACTIONS } from "./constants";

export const getMetrics = createAsyncAction(
  MARKET_MAP_ACTIONS.GET_METRICS,
  MARKET_MAP_ACTIONS.GET_METRICS_SUCCESS,
  MARKET_MAP_ACTIONS.GET_METRICS_FAILURE,
)<Account, GetMetricsPayload, ResponseError>();

export const filterChange = createAsyncAction(
  MARKET_MAP_ACTIONS.FILTER_CHANGE,
  MARKET_MAP_ACTIONS.FILTER_CHANGE_SUCCESS,
  MARKET_MAP_ACTIONS.FILTER_CHANGE_FAILURE,
)<FilterShape, FilterChanged>();

export const getBuyBoxData = createAsyncAction(
  MARKET_MAP_ACTIONS.BUY_BOX,
  MARKET_MAP_ACTIONS.BUY_BOX_SUCCESS,
  MARKET_MAP_ACTIONS.BUY_BOX_FAILURE,
)<Account, BuyBoxMetrics, ResponseError>();

export const resetBuyBoxFilters = createAsyncAction(
  MARKET_MAP_ACTIONS.RESET_BUY_BOX_FILTERS,
  MARKET_MAP_ACTIONS.RESET_BUY_BOX_FILTERS_SUCCESS,
  MARKET_MAP_ACTIONS.RESET_BUY_BOX_FILTERS_FAILURE,
)<null, null, ResponseError>();

export const getZipsCSVFile = createAsyncAction(
  MARKET_MAP_ACTIONS.GET_ZIPS_CSV,
  MARKET_MAP_ACTIONS.GET_ZIPS_CSV_SUCCESS,
  MARKET_MAP_ACTIONS.GET_ZIPS_CSV_FAILURE,
)<Account, null, ResponseError>();

export const getNeighborhoodsCSVFile = createAsyncAction(
  MARKET_MAP_ACTIONS.GET_NEIGHBORHOODS_CSV,
  MARKET_MAP_ACTIONS.GET_NEIGHBORHOODS_CSV_SUCCESS,
  MARKET_MAP_ACTIONS.GET_NEIGHBORHOODS_CSV_FAILURE,
)<Account, null, ResponseError>();

export const startDownloadingZipsCSV = createAction(MARKET_MAP_ACTIONS.START_DOWNLOADING_ZIPS_CSV)();
export const stopDownloadingZipsCSV = createAction(MARKET_MAP_ACTIONS.STOP_DOWNLOADING_ZIPS_CSV)();
export const resetMarketMapState = createAsyncAction(
  MARKET_MAP_ACTIONS.RESET_STATE_REQUEST,
  MARKET_MAP_ACTIONS.RESET_STATE_SUCCESS,
  MARKET_MAP_ACTIONS.RESET_STATE_FAILURE,
)<null, null, ResponseError>();

export const getCountyNeighborhoodMetrics = createAsyncAction(
  MARKET_MAP_ACTIONS.GET_COUNTY_NEIGHBORHOOD_METRICS,
  MARKET_MAP_ACTIONS.GET_COUNTY_NEIGHBORHOOD_METRICS_SUCCESS,
  MARKET_MAP_ACTIONS.GET_COUNTY_NEIGHBORHOOD_METRICS_FAILURE,
)<string, NeighborhoodMetrics, ResponseError>();

export const getCountyGeoData = createAsyncAction(
  MARKET_MAP_ACTIONS.GET_COUNTY_GEO_DATA,
  MARKET_MAP_ACTIONS.GET_COUNTY_GEO_DATA_SUCCESS,
  MARKET_MAP_ACTIONS.GET_COUNTY_GEO_DATA_FAILURE,
)<string, Omit<CountyGeoInterface, "neighborhoodsGeo">, ResponseError>();

export const getCountyNeighborhoodsGeoData = createAsyncAction(
  MARKET_MAP_ACTIONS.GET_COUNTY_NEIGHBORHOODS_GEO_DATA,
  MARKET_MAP_ACTIONS.GET_COUNTY_NEIGHBORHOODS_GEO_DATA_SUCCESS,
  MARKET_MAP_ACTIONS.GET_COUNTY_NEIGHBORHOODS_GEO_DATA_FAILURE,
)<string, Omit<CountyGeoInterface, "geometry">, ResponseError>();

export const getInitData = createAsyncAction(
  MARKET_MAP_ACTIONS.GET_INIT_DATA,
  MARKET_MAP_ACTIONS.GET_INIT_DATA_SUCCESS,
  MARKET_MAP_ACTIONS.GET_INIT_DATA_FAILURE,
)<undefined, { state: State; county: County }, ResponseError>();

export const clearCountyGeoData = createAction(MARKET_MAP_ACTIONS.CLEAR_COUNTY_GEO_DATA)();
