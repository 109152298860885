import {
  PayPerLeadSubscription,
  SubscriptionItemServerModel,
  SubscriptionServerModel,
  SubscriptionStateItem,
} from "@containers/PayPerLead/interface";

const toServerModel = (subscription: PayPerLeadSubscription, accountId?: number): SubscriptionServerModel => {
  const serverModelItems: SubscriptionItemServerModel[] = [];

  for (const stateItem of subscription.items) {
    if (stateItem.all_counties) {
      serverModelItems.push({
        state_id: stateItem.state_id as number,
        leads_total: stateItem.leads_total as number,
        all_counties: true,
        counties: [],
      });
    } else {
      if (stateItem.force_total_per_county) {
        for (const countyItem of stateItem.counties) {
          serverModelItems.push({
            state_id: stateItem.state_id as number,
            leads_total: countyItem.total_leads as number,
            all_counties: false,
            counties: [
              {
                id: countyItem.county_id,
              },
            ],
          });
        }
      } else {
        serverModelItems.push({
          state_id: stateItem.state_id as number,
          leads_total: stateItem.leads_total as number,
          all_counties: false,
          counties: stateItem.counties.map((countyItem) => ({
            id: countyItem.county_id,
          })),
        });
      }
    }
  }

  return {
    id: subscription.id,
    account_id: accountId,
    items: serverModelItems,
  };
};

const fromServerModel = (subscription: SubscriptionServerModel): PayPerLeadSubscription => {
  const groupedByStateId: Record<number, SubscriptionStateItem> = {};
  let totalPrice = 0;

  for (const item of subscription.items ?? []) {
    if (item.price) {
      totalPrice += item.price;
    }

    if (item.state_id) {
      if (item.all_counties) {
        groupedByStateId[item.state_id] = {
          state_id: item.state_id,
          leads_total: item.leads_total,
          all_counties: true,
          force_total_per_county: false,
          counties: [],
        };
      } else {
        if (groupedByStateId[item.state_id]) {
          const stateItem = groupedByStateId[item.state_id];

          const newCounties = item.counties.map(({ id }) => ({
            county_id: id,
            total_leads: item.leads_total,
          }));

          stateItem.leads_total = (stateItem.leads_total as number) + item.leads_total;
          stateItem.counties.push(...newCounties);
        } else {
          groupedByStateId[item.state_id] = {
            force_total_per_county: item.counties.length === 1,
            state_id: item.state_id,
            leads_total: item.leads_total,
            all_counties: false,
            counties: item.counties.map(({ id }) => ({
              county_id: id,
              total_leads: item.leads_total,
            })),
          };
        }
      }
    }
  }

  return {
    items: Object.values(groupedByStateId),
    price: totalPrice,
    id: subscription.id,
    is_active: subscription.is_active,
    created_at: subscription.created_at,
    updated_at: subscription.updated_at,
    start_date: subscription.start_date,
    end_date: subscription.end_date,
  };
};

export const SubscriptionConverter = { fromServerModel, toServerModel };
