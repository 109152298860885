import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon } from "@shared/components";
import { NameOfChildRoutes } from "@shared/constants";

export const NoSubscription: FC = () => {
  const navigate = useNavigate();
  const purchase = () => {
    navigate(
      `${NameOfChildRoutes.PAY_PER_LEAD.ROOT}/${NameOfChildRoutes.PAY_PER_LEAD.SUBSCRIPTIONS}/${NameOfChildRoutes.PAY_PER_LEAD.ADD}`,
    );
  };

  return (
    <div className="pay-per-lead-empty-state">
      <Icon type="empty-screen" />
      <div className="pay-per-lead-empty-state-title">Lead generation is not active</div>
      <div className="pay-per-lead-empty-state-text">
        Use our powerful AI engine to streamline your property acquisitions, and connect directly with homeowners ready
        to sell.
      </div>
      <Button onClick={purchase} buttonStyle="accent-green">
        Purchase Leads
      </Button>
    </div>
  );
};
