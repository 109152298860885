import { Configuration, ConfigurationObject } from "@shared/interfaces/Configuration";

const { REACT_APP_ENV = "dev" } = process.env;

const dev: Configuration = {
  env: REACT_APP_ENV,
  baseApiUrl: "http://localhost:4000/api/v1/",
  aws: {
    s3: {
      cdnDomain: "https://cdn.dev.dataflik.com",
    },
  },
  googleMapKey: "AIzaSyAlidstHmcUV9zUlJ951ljxCsXFxms3Lf4",
};

const qa: Configuration = {
  ...dev,
  baseApiUrl: "https://api.qa.dataflik.com/api/v1/",
};

const uat: Configuration = {
  ...dev,
  baseApiUrl: "https://api.uat.dataflik.com/api/v1/",
};

const production: Configuration = {
  ...dev,
  baseApiUrl: "https://api.dataflik.com/api/v1/",
  aws: {
    s3: {
      cdnDomain: "https://cdn.dataflik.com",
    },
  },
};

const config: ConfigurationObject = {
  dev,
  qa,
  uat,
  production,
};

const configElement: Configuration = config[REACT_APP_ENV];
export default configElement;
