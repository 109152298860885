import { useCallback, useEffect, useState } from "react";

export default function usePolling(action: () => void, delay = 3000) {
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);

  const stopPolling = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  }, [timer]);

  const startPolling = useCallback(() => {
    if (timer) {
      stopPolling();
    }
    setTimer(
      setInterval(() => {
        action();
      }, delay),
    );
  }, [action, delay, stopPolling, timer]);

  useEffect(
    () => () => {
      stopPolling();
    },
    [stopPolling],
  );

  return { startPolling, stopPolling, isPolling: !!timer };
}
