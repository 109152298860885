import { call, debounce, put, select, takeLatest } from "redux-saga/effects";
import * as sharedActions from "@shared/store/actions";
import * as sharedSelectors from "@shared/store/selectors";
import { Account, County, State, ZipCode } from "@shared/models";
import sharedApi from "@shared/api";
import {
  ActionWithPayload,
  BuyBoxMetrics,
  CountyGeoInterface,
  CountyMetricsResponse,
  GetBuyBoxDataQuery,
  MarketMapState,
  ResponseError,
  ZipMetricsResponse,
} from "@shared/interfaces";
import { DEFAULT_ZIPS_METRICS } from "@shared/constants";
import { getBuyBoxDefaultValues } from "@containers/MarketMap/store/functions";
import { download } from "@shared/utils/request";
import { getActiveAccount } from "@containers/Account/store/selectors";
import { NeighborhoodMetrics } from "@shared/interfaces/NeighborhoodMetrics.interface";
import DEFAULT_NEIGHBORHOOD_METRICS from "@shared/constants/defaultNeighborhoodMetrics";
import { Neighborhood } from "@shared/models/Neighborhood";

import { getFileName } from "./functions";
import {
  filterChange,
  getBuyBoxData,
  getCountyGeoData,
  getCountyNeighborhoodMetrics,
  getCountyNeighborhoodsGeoData,
  getInitData,
  getMetrics,
  getNeighborhoodsCSVFile,
  getZipsCSVFile,
  resetBuyBoxFilters,
  resetMarketMapState,
  startDownloadingZipsCSV,
  stopDownloadingZipsCSV,
} from "./actions";
import api from "../api";
import { getMarketMap } from "./selectors";

function* fetchMetrics({ payload: account }: ActionWithPayload<Account>) {
  const state: MarketMapState = yield select(getMarketMap());

  if (!account) return;

  const accountID = account.id;

  try {
    if (!accountID) return;

    if (!state.county) {
      yield put(getMetrics.failure({ message: "Could not fetch data" }));
      return;
    }

    if (state.zipCode && state.zipCode !== "") {
      yield put(sharedActions.startLoading());
      const response: ZipMetricsResponse = yield call(api.getZipMetrics, {
        accountID,
        county: state.county.code,
        zip: state.zipCode,
      });

      const buyBoxDefaultValues = getBuyBoxDefaultValues({
        countyMetrics: response.countyMetrics,
      });

      yield put(
        getMetrics.success({
          metrics: {
            county: {
              ...response.countyMetrics,
            },
            zip: {
              ...response.zipMetrics,
            },
          },
          defaultBuyBoxFilters: buyBoxDefaultValues,
        }),
      );
    } else if (state.county) {
      // check if this county has already been fetched

      if (state.metrics?.county?.fips !== state.county.code) {
        yield put(sharedActions.startLoading());
        const response: CountyMetricsResponse = yield call(api.getCountyMetrics, {
          accountID,
          county: state.county.code,
        });
        const buyBoxDefaultValues = getBuyBoxDefaultValues(response);

        yield put(
          getMetrics.success({
            metrics: {
              county: {
                ...response.countyMetrics,
              },
              zip: {
                ...DEFAULT_ZIPS_METRICS,
              },
              neighborhood: {
                ...DEFAULT_NEIGHBORHOOD_METRICS,
              },
            },
            defaultBuyBoxFilters: buyBoxDefaultValues,
          }),
        );
      }
    }
  } catch (error) {
    const err = error as ResponseError;
    yield put(getMetrics.failure(err));

    if (err.code === 403) {
      yield put(
        sharedActions.handleCompanyForbiddenError(
          err as {
            code: number;
          },
        ),
      );
    } else {
      yield put(
        sharedActions.showNotification({ message: err.message ?? "Could not fetch data", appearance: "error" }),
      );
    }
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* onFilterChange({ payload }: ReturnType<typeof filterChange.request>) {
  const { name, value } = payload;
  const { viewType }: MarketMapState = yield select(getMarketMap());
  const account: Account = yield select(getActiveAccount());

  const isOverview = viewType === "Overview";

  switch (name) {
    case "state": {
      const states: State[] = yield select(sharedSelectors.getStates());

      const state = states.find((state) => state.id === value);

      if (!state) {
        break;
      }

      yield put(filterChange.success({ name, value: state }));

      const { items: counties }: { items: County[] } = yield call(sharedApi.getCounties, state.id);
      yield put(sharedActions.getCountiesByState.success(counties));

      const county = counties[0];

      yield put(filterChange.success({ name: "county", value: county }));
      if (county && isOverview) yield put(getBuyBoxData.request(account));

      break;
    }
    case "county": {
      const counties: County[] = yield select(sharedSelectors.getCounties());

      const county = counties.find((county) => String(county.id) === String(value));

      if (county) {
        yield put(filterChange.success({ name, value: county }));
        if (isOverview) yield put(getBuyBoxData.request(account));
      }
      break;
    }
    case "neighborhood": {
      const neighborhoods: Neighborhood[] = yield select(sharedSelectors.getNeighborhoods());

      const neighborhood = neighborhoods.find((neighborhood) => String(neighborhood.id) === String(value));

      yield put(filterChange.success({ name, value: neighborhood ?? null }));

      break;
    }
    case "zipCode": {
      const zipCodes: ZipCode[] = yield select(sharedSelectors.getZips());

      const zipCode = zipCodes.find((i) => String(i.zip_code) === value);

      if (zipCode || !value) {
        yield put(filterChange.success({ name, value }));
      }
      break;
    }
    case "viewType": {
      yield put(filterChange.success({ name, value }));
      break;
    }
    case "area_building":
    case "year_built":
    case "property_value":
    case "bedrooms_count":
    case "bath_count": {
      yield put(filterChange.success({ name, value }));
      yield put(getBuyBoxData.request(account));
      break;
    }
  }
}

function* fetchBuyBoxData({ payload: account }: ActionWithPayload<Account>) {
  const { buyBoxFilters, ...state }: MarketMapState = yield select(getMarketMap());

  const fips = state.county?.code;
  const hasFiltersLoaded = !!state.county;

  if (!hasFiltersLoaded) return;

  if (!fips) {
    yield put(sharedActions.showNotification({ message: "You need to select county", appearance: "error" }));
    return;
  }

  const buyBoxDataPayload: GetBuyBoxDataQuery = {
    fips,
    ...buyBoxFilters,
    accountID: account.id,
  };

  try {
    const response: BuyBoxMetrics = yield call(api.getBuyBoxData, buyBoxDataPayload);

    yield put(getBuyBoxData.success(response));
  } catch (error) {
    const err = error as ResponseError;
    yield put(getBuyBoxData.failure(err));

    if (err.code === 403) {
      yield put(
        sharedActions.handleCompanyForbiddenError(
          err as {
            code: number;
          },
        ),
      );
    } else {
      yield put(
        sharedActions.showNotification({ message: err.message ?? "Could not fetch data", appearance: "error" }),
      );
    }
  }
}

function* resetBuyBoxFiltersSaga() {
  const account: Account = yield select(getActiveAccount());
  yield put(resetBuyBoxFilters.success(null));
  yield put(getBuyBoxData.request(account));
}

function* getZipsTableCSVFile({ payload: account }: ActionWithPayload<Account>) {
  try {
    const state: MarketMapState = yield select(getMarketMap());

    const fips = state.county ? state.county.code : null;
    const accountID = account ? account.id : null;

    if (!accountID || !state.state) return;

    yield put(startDownloadingZipsCSV());

    const response: Blob = yield call(api.exportZipsToCSV, {
      accountID,
      fips,
      state: state.state.abbreviation,
      zip: state.zipCode,
    });
    const urlObj = window.URL.createObjectURL(response);
    const fileName = getFileName(state);
    yield call(download, urlObj, fileName);
  } catch (error) {
    const err = error as ResponseError;
    yield put(
      sharedActions.showNotification({ message: err.message ?? "Could not download CSV File", appearance: "error" }),
    );
  } finally {
    yield put(stopDownloadingZipsCSV());
  }
}

function* getNeighborhoodsTableCSVFile({ payload: account }: ActionWithPayload<Account>) {
  try {
    const state: MarketMapState = yield select(getMarketMap());

    const fips = state.county?.code;

    if (!state.state) return;

    yield put(startDownloadingZipsCSV());

    const response: Blob = yield call(api.neighborhoodsZipsToCSV, {
      accountId: account.id,
      fips,
      state: state.state.abbreviation,
      neighborhood: state.neighborhood?.name,
    });
    const urlObj = window.URL.createObjectURL(response);
    const fileName = getFileName(state);
    yield call(download, urlObj, fileName);
  } catch (error) {
    const err = error as ResponseError;
    yield put(
      sharedActions.showNotification({ message: err.message ?? "Could not download CSV File", appearance: "error" }),
    );
  } finally {
    yield put(stopDownloadingZipsCSV());
  }
}

function* getCountryGeoDataSaga({ payload: code }: ActionWithPayload<string>) {
  try {
    yield put(sharedActions.startLoading());

    const geoData: Omit<CountyGeoInterface, "neighborhoodsGeo"> = yield call(api.getCountyGeoDataByCode, code);
    yield put(getCountyGeoData.success(geoData));
  } catch (error) {
    yield put(getCountyGeoData.failure(error as Error));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* getCountyNeighborhoodsGeoDataSaga({ payload: code }: ActionWithPayload<string>) {
  try {
    yield put(sharedActions.startLoading());

    const geoData: Omit<CountyGeoInterface, "geometry"> = yield call(
      api.getCountyNeighborhoodsGeoDataByCountyCode,
      code,
    );
    yield put(getCountyNeighborhoodsGeoData.success(geoData));
  } catch (error) {
    yield put(getCountyGeoData.failure(error as Error));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* resetMarketMapStateSaga({ payload }: ActionWithPayload<null>) {
  yield put(resetMarketMapState.success(payload));
}

function* getCountyNeighborhoodMetricsSaga({ payload: id }: ActionWithPayload<string>) {
  try {
    yield put(sharedActions.startLoading());
    const data: NeighborhoodMetrics = yield call(api.getCountyNeighborhoodMetricsByECId, id);
    yield put(getCountyNeighborhoodMetrics.success(data));
  } catch (error) {
    yield put(getCountyNeighborhoodMetrics.failure(error as Error));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* getInitDataSaga() {
  try {
    yield put(sharedActions.startLoading());
    const { items: states }: { items: State[] } = yield call(sharedApi.getStates);
    yield put(sharedActions.getStates.success(states));

    const account: Account = yield select(getActiveAccount());
    yield put(getBuyBoxData.request(account));

    const state = states.find((s) => s.id === account.state_id) || states[0];
    const { items: counties }: { items: County[] } = yield call(sharedApi.getCounties, state.id);
    yield put(sharedActions.getCountiesByState.success(counties));

    const county = counties.find((c) => c.id === account.county_id) || counties[0];
    yield put(getInitData.success({ state, county }));
  } catch (error) {
    yield put(getInitData.failure(error as Error));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* accountSagas() {
  yield takeLatest(getInitData.request, getInitDataSaga);
  yield takeLatest(getMetrics.request, fetchMetrics);
  yield takeLatest(filterChange.request, onFilterChange);
  yield debounce(500, getBuyBoxData.request, fetchBuyBoxData);
  yield takeLatest(resetBuyBoxFilters.request, resetBuyBoxFiltersSaga);
  yield takeLatest(getZipsCSVFile.request, getZipsTableCSVFile);
  yield takeLatest(getNeighborhoodsCSVFile.request, getNeighborhoodsTableCSVFile);
  yield takeLatest(getCountyGeoData.request, getCountryGeoDataSaga);
  yield takeLatest(getCountyNeighborhoodsGeoData.request, getCountyNeighborhoodsGeoDataSaga);
  yield takeLatest(resetMarketMapState.request, resetMarketMapStateSaga);
  yield takeLatest(getCountyNeighborhoodMetrics.request, getCountyNeighborhoodMetricsSaga);
}

export default accountSagas;
