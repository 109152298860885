import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCache } from "@containers/PayPerLead/store/selectors";
import { actions, selectors } from "@containers/PayPerLead/store";

import { StateOptionsProvider } from "./StateOptionsProvider";
import { SubscriptionCacheProvider } from "./SubscriptionCacheProvider";

export const CacheProvider: FC = () => {
  const dispatch = useDispatch();
  const allSubscriptions = useSelector(selectors.getSubscriptions());
  const currentSubscription = useSelector(selectors.getSubscription());
  const subscriptions = [currentSubscription];

  if (allSubscriptions) {
    for (const subscription of allSubscriptions) {
      if (subscription.id !== currentSubscription.id) {
        subscriptions.push(subscription);
      }
    }
  }

  useEffect(() => {
    return () => {
      dispatch(actions.clearCache());
    };
  }, [dispatch]);

  return (
    <StateOptionsProvider>
      {subscriptions.map((subscription) => (
        <SubscriptionCacheProvider key={subscription.id ?? "new"} subscription={subscription} />
      ))}
    </StateOptionsProvider>
  );
};

export const useCache = () => useSelector(getCache());
