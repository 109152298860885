import { SkipTracing, UpdateFileMappingType } from "@shared/models";
import * as Yup from "yup";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { FileMappingFormValues } from "@containers/SkipTracing/interfaces";

export const dataFlikColumnNames: Array<keyof UpdateFileMappingType> = [
  "first_name",
  "last_name",
  "address",
  "city",
  "state",
  "mailing_address",
  "mailing_city",
  "mailing_state",
];

export const dataFlikInitialMapping: UpdateFileMappingType = {
  first_name: null,
  last_name: null,
  address: null,
  city: null,
  state: null,
  mailing_address: null,
  mailing_city: null,
  mailing_state: null,
};

const columnMappingValidationSchema = {
  first_name: Yup.string().nullable(),
  last_name: Yup.string().nullable(),
  address: Yup.string().nullable(),

  state: Yup.string()
    .nullable()
    .when("city", {
      is: (city: string | null) => !!city,
      then: Yup.string().nullable().required(`State ${FORM_ERROR_MESSAGES.REQUIRED}`),
    }),

  city: Yup.string()
    .nullable()
    .when("state", {
      is: (state: string | null) => !!state,
      then: Yup.string().nullable().required(`City ${FORM_ERROR_MESSAGES.REQUIRED}`),
    }),

  mailing_address: Yup.string().nullable(),
  mailing_city: Yup.string()
    .nullable()
    .when("mailing_state", {
      is: (mailing_state: string | null) => !!mailing_state,
      then: Yup.string().nullable().required(`Mailing city ${FORM_ERROR_MESSAGES.REQUIRED}`),
    }),
  mailing_state: Yup.string()
    .nullable()
    .when("mailing_city", {
      is: (mailing_city: string | null) => !!mailing_city,
      then: Yup.string().nullable().required(`Mailing state ${FORM_ERROR_MESSAGES.REQUIRED}`),
    }),
};

export const validationSchema = Yup.object().shape({
  columnMapping: Yup.object().shape({ ...columnMappingValidationSchema }, [
    ["city", "state"],
    ["mailing_city", "mailing_state"],
  ]),
});

const getColumnMappingInitialValues = (
  dfColumnNames: UpdateFileMappingType,
  skipTracing: SkipTracing | null,
): UpdateFileMappingType => {
  const columnMapping = { ...dfColumnNames };
  if (skipTracing) {
    dataFlikColumnNames.map((dfColumnName) => {
      columnMapping[dfColumnName] = skipTracing.input_file.mapping[dfColumnName];
    });

    return columnMapping;
  }

  return columnMapping;
};

export const getInitValues = (skipTracing: SkipTracing | null): FileMappingFormValues => ({
  columnMapping: getColumnMappingInitialValues(dataFlikInitialMapping, skipTracing),

  selectOptions:
    dataFlikColumnNames.map((column) => ({
      label: column.replace("_", " "),
      value: column,
    })) || [],
});
