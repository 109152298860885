import React, { FC } from "react";
import { Parallelogram } from "@shared/components";

const Parallelograms: FC = () => {
  return (
    <>
      <Parallelogram
        width="43px"
        height="52px"
        right="220px"
        top="600px"
        background="rgba(173, 184, 202, 0.1)"
        clipPath="polygon(10% 0, 100% 0%, 90% 100%, 0% 100%)"
        className="hide-on-small"
      />
      <Parallelogram
        width="163px"
        height="193px"
        right="205px"
        top="680px"
        background="rgba(30, 87, 199, 0.06)"
        clipPath="polygon(10% 0, 100% 0%, 90% 100%, 0% 100%)"
        className="hide-on-small"
      />
      <Parallelogram
        width="84px"
        height="88px"
        right="110px"
        top="830px"
        background="rgba(173, 184, 202, 0.1)"
        clipPath="polygon(10% 0, 100% 0%, 90% 100%, 0% 100%)"
        className="hide-on-small"
      />
    </>
  );
};

export default Parallelograms;
