import React, { LegacyRef, useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "@shared/hooks";
import { Icon } from "@shared/components";

import "./index.scss";

export interface InputSearchProps extends React.HTMLProps<HTMLInputElement> {
  onChangeSearch: (value: string) => void;
  showClearText?: boolean;
  showClose?: boolean;
  onChangeShowSearch?: (value: boolean) => void;
}

const InputSearch = (props: InputSearchProps) => {
  const { onChangeSearch, value, showClose, onChangeShowSearch, ...inputProps } = props;
  const hasBeenRendered = useRef(false);
  const inputRef = useRef<HTMLInputElement>();
  const [inputValue, setInputValue] = useState<string>(value ? String(value) : "");
  const [showSearch, setShowSearch] = useState(!showClose);
  const debounceValue = useDebounce<string>(inputValue, 500);

  const focusInput = useCallback(() => inputRef.current?.focus(), [inputRef]);

  useEffect(() => {
    setInputValue(value ? String(value) : "");
    if (value) {
      setShowSearch(true);
    }
  }, [value]);

  useEffect(() => {
    onChangeShowSearch && onChangeShowSearch(showSearch);
  }, [onChangeShowSearch, showSearch]);

  useEffect(() => {
    // prevent trigger on component init
    if (hasBeenRendered.current) {
      onChangeSearch(debounceValue);
      return;
    }
    hasBeenRendered.current = true;
  }, [debounceValue, onChangeSearch]);

  const handleChange = (event: React.SyntheticEvent) => {
    setInputValue((event.target as HTMLInputElement).value);
  };

  const handleSearchIconClick = useCallback(() => {
    focusInput();
    setShowSearch(true);
  }, [focusInput, setShowSearch]);

  const clearInput = useCallback(() => {
    setInputValue("");
    focusInput();
  }, [setInputValue, focusInput]);

  return (
    <div className="search custom-input">
      <>
        <Icon type="find" onClick={handleSearchIconClick} className="search-icon-block" />
        <input
          ref={inputRef as LegacyRef<HTMLInputElement>}
          className="input-search"
          {...inputProps}
          onChange={handleChange}
          value={inputValue}
        />
        {!!inputValue && (
          <div className="search-controls">
            <div className="clear-text" onClick={clearInput}>
              <Icon type="clear" />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default InputSearch;
