import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon } from "@shared/components";
import { selectors as AccountSelectors } from "@containers/Account/store";
import "./index.scss";
import { Input, InputGroup } from "rsuite";
import { showNotification } from "@shared/store/actions";
import { showHttpErrorNotification, tokenHandler } from "@shared/utils";
import { API } from "@shared/constants";
import { download } from "@shared/utils/request";

interface Props {
  heading?: string;
  data?: {
    listId?: number;
    fileName?: string;
  };
  onClose: () => void;
}
const INPUT_MAX_LENGTH = 50;

const ModalDownloadCsvPropertyRecords: FC<Props> = (props) => {
  const { onClose, data } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const account = useSelector(AccountSelectors.getActiveAccount());

  const [fileName, setFileName] = useState("");

  const handleDownload = () => {
    if (account?.id) {
      const token = tokenHandler.get();

      const baseUrl = API.ACCOUNT.DOWNLOAD_CSV_PROPERTY_RECORDS(account.id);

      const queryParams = new URLSearchParams({ token: String(token) });

      if (data?.listId) queryParams.append("listId", String(data.listId));

      if (fileName) queryParams.append("fileName", fileName);

      const url = `${baseUrl}?${queryParams}`;

      download(url, undefined, () => dispatch(showHttpErrorNotification({})));

      dispatch(
        showNotification({
          message: "The file download will start shortly",
          appearance: "success",
        }),
      );
      onClose();
    }
  };

  const clear = useCallback(() => {
    setFileName("");
    inputRef.current?.focus();
  }, [inputRef, setFileName]);

  useEffect(() => {
    if (data?.fileName) setFileName(data?.fileName.slice(0, INPUT_MAX_LENGTH));
  }, [data?.fileName, setFileName]);

  return (
    <div className="download-csv-property-records-modal-content-wrapper">
      <div className="download-csv-property-records-modal-heading">Download List</div>

      <div className="download-csv-property-records-modal-content">
        <label>File Name</label>
        <InputGroup>
          <Input
            ref={inputRef}
            className="input"
            placeholder="File Name"
            type="text"
            maxLength={INPUT_MAX_LENGTH}
            onChange={setFileName}
            value={fileName}
          />

          {!!fileName.length && (
            <InputGroup.Button onClick={clear}>
              <Icon type="close" />
            </InputGroup.Button>
          )}
        </InputGroup>
      </div>

      <div className="download-csv-property-records-modal-actions">
        <div className="download-csv-property-records-modal-actions-btn-block">
          <Button
            className="download-csv-property-records-modal-close-btn"
            buttonStyle="blue-bordered"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="download-csv-property-records-modal-success-btn"
            type="submit"
            buttonStyle="accent-green"
            disabled={!fileName?.trim().length}
            onClick={handleDownload}
          >
            <Icon type="download" />
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ModalDownloadCsvPropertyRecords;
