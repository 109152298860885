import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces/State.interface";
import { AuthErrorType } from "@containers/Auth/interface";

const selectAuth = (state: AppState) => state.auth;

export const authentificated = () => createSelector(selectAuth, (state) => state.authentificated);
export const impersonated = () => createSelector(selectAuth, (state) => state.impersonated);
export const getActivateUserInfo = () => createSelector(selectAuth, (state) => state.activateUserInfo);
export const getFilledEmail = () => createSelector(selectAuth, (state) => state.filledEmail);
export const getUserExistence = () => createSelector(selectAuth, (state) => state.userExistence);

export const selectCheckUserError = () =>
  createSelector(selectAuth, (state) => state.errors[AuthErrorType.checkUserError]);
export const selectLoginError = () => createSelector(selectAuth, (state) => state.errors[AuthErrorType.loginError]);
export const selectRedirectTo = () => createSelector(selectAuth, (state) => state.redirectTo);
