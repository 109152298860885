import React from "react";

import "./index.scss";

export type ToolbarProps = React.FC<React.PropsWithChildren>;

export type ToolbarComponent = { Left: React.FC<React.PropsWithChildren>; Right: React.FC<React.PropsWithChildren> };

const Left: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="toolbar-left">{children}</div>;
};

const Right: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="toolbar-right">{children}</div>;
};

export const Toolbar: React.FC<React.PropsWithChildren> & ToolbarComponent = ({ children }) => {
  return <div className="page-toolbar">{children}</div>;
};

Toolbar.Left = Left;

Toolbar.Right = Right;

export default Toolbar;
