import { ActionWithPayload, PaginatedResponse, ResponseError, SkipTracingFilter } from "@shared/interfaces";
import { call, put, takeLatest } from "redux-saga/effects";
import { startLoading, stopLoading } from "@shared/store/actions";
import { SkipTracing } from "@shared/models/SkipTracing";
import {
  createSkipTracing,
  getSkipTracing,
  getSkipTracingList,
  updateSkipTracing,
  deleteSkipTracing,
  testSkipTracing,
  processSkipTracing,
  payForSkipTracing,
  getPriceRange,
  checkStripeCoupon,
} from "@containers/SkipTracing/store/actions";
import {
  CreateSkipTracingDto,
  SkipTracingPriceRange,
  SkipTracingStripeCoupon,
  UpdateSkipTracingDto,
} from "@containers/SkipTracing/interfaces";
import { showHttpErrorNotification } from "@shared/utils";

import api from "../api";

function* getSkipTracingListSaga({ payload }: ActionWithPayload<SkipTracingFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<SkipTracing> = yield call(api.getSkipTracingList, payload);
    yield put(
      getSkipTracingList.success({
        ...response,
        clear: !payload.page,
      }),
    );
  } catch (error) {
    yield put(getSkipTracingList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getSkipTracingSaga({ payload }: ReturnType<typeof getSkipTracing.request>) {
  const { callback, hideLoader, ...rest } = payload;
  try {
    if (!hideLoader) {
      yield put(startLoading());
    }
    const response: SkipTracing = yield call(api.getSkipTracing, rest);
    yield put(getSkipTracing.success(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(getSkipTracing.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    if (!hideLoader) {
      yield put(stopLoading());
    }
  }
}

function* createSkipTracingSaga({ payload }: ActionWithPayload<CreateSkipTracingDto>) {
  try {
    yield put(startLoading());
    const response: SkipTracing = yield call(api.createSkipTracing, payload);
    yield put(createSkipTracing.success(response));
  } catch (error) {
    yield put(createSkipTracing.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* testSkipTracingSaga({ payload }: ActionWithPayload<{ id: number; callback: () => void }>) {
  try {
    const { callback, ...rest } = payload;
    yield call(api.testSkipTracing, rest);
    yield put(testSkipTracing.success());
    if (callback) {
      callback();
    }
    yield put(getSkipTracing.request({ ...rest }));
  } catch (error) {
    yield put(testSkipTracing.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  }
}

function* updateSkipTracingSaga({ payload }: ActionWithPayload<UpdateSkipTracingDto & { id: number }>) {
  try {
    yield put(startLoading());
    const response: SkipTracing = yield call(api.updateSkipTracing, payload);
    yield put(updateSkipTracing.success(response));
  } catch (error) {
    yield put(updateSkipTracing.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteSkipTracingSaga({ payload }: ActionWithPayload<{ id: number }>) {
  try {
    yield put(startLoading());
    yield call(api.deleteSkipTracing, payload);
    yield put(deleteSkipTracing.success());
  } catch (error) {
    yield put(deleteSkipTracing.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* processSkipTracingSaga({ payload }: ReturnType<typeof processSkipTracing.request>) {
  try {
    yield put(startLoading());
    const skipTracing: SkipTracing = yield call(api.processSkipTracing, payload);
    yield put(processSkipTracing.success(skipTracing));
  } catch (error) {
    yield put(processSkipTracing.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* payForSkipTracingSaga({ payload: { id, coupon } }: ReturnType<typeof payForSkipTracing.request>) {
  try {
    yield put(startLoading());
    yield call(api.payForSkipTracing, id, coupon);
    yield put(payForSkipTracing.success(true));
  } catch (error) {
    yield put(payForSkipTracing.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getPriceRangeSaga({ payload }: ReturnType<typeof getPriceRange.request>) {
  try {
    yield put(startLoading());
    const priceRange: SkipTracingPriceRange = yield call(api.getPriceRange, payload);
    yield put(getPriceRange.success(priceRange));
  } catch (error) {
    yield put(getPriceRange.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* checkStripeCouponSaga({ payload }: ReturnType<typeof checkStripeCoupon.request>) {
  try {
    yield put(startLoading());
    const couponInfo: SkipTracingStripeCoupon = yield call(api.checkStripeCoupon, {
      ...payload,
      coupon: encodeURIComponent(payload.coupon),
    });
    yield put(checkStripeCoupon.success(couponInfo));
  } catch (error) {
    yield put(checkStripeCoupon.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* skipTracingSaga() {
  yield takeLatest(getSkipTracingList.request, getSkipTracingListSaga);
  yield takeLatest(getSkipTracing.request, getSkipTracingSaga);
  yield takeLatest(createSkipTracing.request, createSkipTracingSaga);
  yield takeLatest(updateSkipTracing.request, updateSkipTracingSaga);
  yield takeLatest(deleteSkipTracing.request, deleteSkipTracingSaga);
  yield takeLatest(testSkipTracing.request, testSkipTracingSaga);
  yield takeLatest(processSkipTracing.request, processSkipTracingSaga);
  yield takeLatest(payForSkipTracing.request, payForSkipTracingSaga);
  yield takeLatest(getPriceRange.request, getPriceRangeSaga);
  yield takeLatest(checkStripeCoupon.request, checkStripeCouponSaga);
}

export default skipTracingSaga;
