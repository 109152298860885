import { Feature } from "ol";
import { Neighborhood } from "@shared/components/MapContainer/OLMap/features/Neighborhood";

import MapUtilized from "./MapUtilized";

class MapFocusFeature extends MapUtilized {
  private initExtent = [Infinity, Infinity, -Infinity, -Infinity];

  focusNeighborhood(toFocus: Neighborhood) {
    const allNeighborhoods = this.getCustomLayerFeatures().filter(
      (feature) => feature instanceof Neighborhood,
    ) as Neighborhood[];

    allNeighborhoods.forEach((neighborhood) => {
      if (neighborhood.id === toFocus.id) {
        neighborhood.setSelected(true);
      } else {
        neighborhood.setSelected(false);
      }
    });

    const selected = allNeighborhoods.filter((n) => n.id === toFocus.id);
    const extent = this.getFeaturesExtent(selected);

    if (extent && extent.length) {
      this.getView().fit(extent, {
        padding: [50, 50, 50, 50],
        duration: 200,
      });
    }
  }

  private getFeaturesExtent(features: Feature[]) {
    return features.reduce(([minX, minY, maxX, maxY], feature) => {
      const geometry = feature.getGeometry();

      if (geometry) {
        geometry.getExtent().forEach(function (coords, index) {
          if (index % 2 === 0) {
            // Even indices are X coordinates
            minX = Math.min(minX, coords);
            maxX = Math.max(maxX, coords);
          } else {
            // Odd indices are Y coordinates
            minY = Math.min(minY, coords);
            maxY = Math.max(maxY, coords);
          }
        });
      }

      return [minX, minY, maxX, maxY];
    }, this.initExtent);
  }

  focusLayersIntoView(padding: [number, number, number, number] = [50, 50, 50, 50]) {
    const extent = this.getMapExtent();

    if (extent && extent.length) {
      this.getView().fit(extent, { padding });
    }
  }

  private getMapExtent() {
    const features = this.getCustomLayerFeatures();

    if (features.length === 0) {
      return null;
    }

    return this.getFeaturesExtent(features);
  }

  private getCustomLayerFeatures(): Feature[] {
    if (!this.areasLayer) {
      return [];
    }

    const source = this.areasLayer.getSource();

    if (!source) {
      return [];
    }

    return source.getFeatures();
  }
}

export default MapFocusFeature;
