import React, { useCallback, useEffect } from "react";
import { Icon } from "@shared/components";
import * as AccountStore from "@containers/Account/store";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberWithCommas } from "@shared/utils";
import { actions, actions as sharedActions } from "@shared/store";
import { MODAL_TYPES } from "@shared/constants";
import { useSearchParams } from "react-router-dom";
import { actions as accountActions } from "@containers/Account/store";
import { usePolling } from "@shared/hooks";
import { showNotification } from "@shared/store/actions";

import "./index.scss";

const Wallet: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const account = useSelector(AccountStore.selectors.getActiveAccount());
  const credits = account?.credits || 0;
  const accountPayment = useSelector(AccountStore.selectors.getAccountsPayment());

  const requestCheckPaymentStatus = useCallback(
    (withLoader = false) => {
      if (account?.id && accountPayment?.id) {
        dispatch(
          accountActions.getAccountPaymentStatus.request({ accountId: account.id, invoiceId: accountPayment.id }),
        );
        if (withLoader) {
          dispatch(sharedActions.startLoading());
        }
      }
    },
    [dispatch, account?.id, accountPayment?.id],
  );

  const { startPolling, stopPolling, isPolling } = usePolling(requestCheckPaymentStatus);

  useEffect(() => {
    requestCheckPaymentStatus(true);
  }, [requestCheckPaymentStatus]);

  const openModalAccountPayment = () => {
    dispatch(
      actions.showModal({
        type: MODAL_TYPES.ACCOUNT_PAYMENT,
        props: {
          heading: "Add wallet funds",
          content: "",
          successBtnText: "Pay",
          onSuccess: () => null,
        },
      }),
    );
  };

  useEffect(() => {
    const savedAmount = searchParams.get("amount");
    if (savedAmount) {
      searchParams.delete("amount");
      dispatch(
        actions.showModal({
          type: MODAL_TYPES.ACCOUNT_PAYMENT,
          props: {
            heading: "Add wallet funds",
            content: "",
            successBtnText: "Pay",
            onSuccess: () => null,
            data: { amount: parseFloat(savedAmount) },
          },
        }),
      );
    }
    setSearchParams(searchParams);
  }, [dispatch, searchParams, setSearchParams, accountPayment]);

  useEffect(() => {
    dispatch(sharedActions.getUserDetails.request());
  }, [dispatch]);

  useEffect(() => {
    if (accountPayment?.id && accountPayment?.status === "paid" && isPolling) {
      stopPolling();
      dispatch(accountActions.clearAccountPayment());
      dispatch(sharedActions.getUserDetails.request());
      dispatch(sharedActions.stopLoading());
      dispatch(
        showNotification({
          message: "The payment was successfully completed",
          appearance: "success",
        }),
      );
    }
    if (accountPayment?.id && !isPolling) {
      startPolling();
    }
  }, [accountPayment, isPolling, stopPolling, startPolling, dispatch]);

  return (
    <div className="wallet">
      <div className="balance">
        <Icon type={credits > 0 ? "wallet" : "wallet-grey"} className="icons-wallet" />
        <div className={`total ${credits === 0 ? "inactive" : ""}`}>${formatNumberWithCommas(credits / 100)}</div>
      </div>
      <div className="button" onClick={openModalAccountPayment}>
        <Icon type="plus" className="icons-plus" />
        {credits === 0 ? "Add Funds" : null}
      </div>
    </div>
  );
};

export default Wallet;
