import { InvestorFilePreviewFields } from "@shared/interfaces";
import {
  InvestorPreviewPreFields,
  PreviewPostFields,
  PreviewPreFields,
  RealtorPreviewPreFields,
} from "@shared/interfaces/Preset.interface";

export const InvestorFileColumnNames: Record<InvestorFilePreviewFields, string> = {
  model_score: "Model Score",
  county: "County",
  parcel_number: "Parcel Number",
  combined_statistical_area: "Combined Statistical Area",
  cbsa_name: "CBSA Name",
  msa_name: "MSA Name",
  census_tract: "Census Tract",
  property_address: "Property Address",
  property_unit_prefix: "Property Unit Prefix",
  property_unit: "Property Unit",
  property_city: "Property City",
  property_state: "Property State",
  property_zip: "Property ZipMetrics",
  property_zip4: "Property Address ZIP4",
  legal_description: "Legal Description",
  year_built: "Year Built",
  property_sqft: "Property SqFt",
  bath_count: "Bath Count",
  partial_bath_count: "Partial Bath Count",
  bedroom_count: "Bedroom Count",
  room_count: "Room Count",
  story_count: "Story Count",
  unit_count: "Unit Count",
  owner_occupied: "Owner Occupied",
  property_use_group: "Property Use Group",
  neighborhood_l1: "Property Neighborhood L1",
  neighborhood_l2: "Property Neighborhood L2",
  neighborhood_l3: "Property Neighborhood L3",
  property_value: "Property Value",
  last_sale_date: "Last Sale Date",
  last_sale_price: "Last Sale Price",
  full_name: "Full Name",
  first_name: "First Name",
  last_name: "Last Name",
  full_name2: "Owner2 Full Name",
  first_name2: "Owner2 First Name",
  last_name2: "Owner2 Last Name",
  mailing_address: "Mailing Address",
  mailing_address_unit_prefix: "Mailing Address Unit Prefix",
  mailing_address_unit: "Mailing Address Unit",
  mailing_address_city: "Mailing Address City",
  mailing_address_state: "Mailing Address State",
  mailing_address_zip: "Mailing Address ZipMetrics",
  mailing_address_zip4: "Contact Owner Mail Address ZIP4",
  property_type: "Property Type",
  exterior_type: "Exterior Type",
  structure_style: "Structure Style",
  utilities_sewage_usage: "Utilities Sewage Usage",
  utilities_water_source: "Utilities Water Source",
  foundation: "Foundation",
  construction: "Construction",
  interior_structure: "Interior Structure",
  roof_material: "Roof Material",
  foreclosure: "Foreclosure",
  case_number: "Case Number",
  original_loan_amount: "Original Loan Amount",
  foreclosure_recording_date: "Foreclosure Recording Date",
  auction_date: "Auction Date",
  auction_time: "Auction Time",
  auction_address: "Auction Address",
  lender_name: "Lender Name",
  recorded_auction_opening_bid: "Recorded Auction Opening Bid",
  date_added: "Date Added",
  dataflik_id: "DataflikId",
};

export const ESFilePreColumnNames: Record<PreviewPreFields, string> = {
  county: "County",
  parcel_number: "Parcel Number",
  property_address: "Property Address",
  property_unit_prefix: "Property Unit Prefix",
  property_unit: "Property Unit",
  property_city: "Property City",
  property_state: "Property State",
  property_zip: "Property Zip",
  neighborhood: "Neighborhood",
  property_value: "Property Value",
  full_name: "Full Name",
  first_name: "First Name",
  last_name: "Last Name",
  mailing_address: "Mailing Address",
  mailing_address_unit_prefix: "Mailing Address Unit Prefix",
  mailing_address_unit: "Mailing Address Unit",
  mailing_address_city: "Mailing Address City",
  mailing_address_state: "Mailing Address State",
  mailing_address_zip: "Mailing Address Zip",
  mailing_address_zip4: "Mailing Address Zip4",
  year_built: "Year Built",
  owner_occupied: "Owner Occupied",
  property_use_group: "Property Use Group",
  property_type: "Property Type",
  exterior_type: "Exterior Type",
  structure_style: "Structure Style",
  last_sale_date: "Last Sale Date",
  last_sale_price: "Last Sale Price",
  property_sqft: "Property Sqft",
  area_lot_acres: "Area Lot Acres",
  bath_count: "Bath Count",
  partial_bath_count: "Partial Bath Count",
  bedroom_count: "Bedroom Count",
  room_count: "Room Count",
  story_count: "Story Count",
  unit_count: "Unit Count",
  case_number: "Case Number",
  original_loan_amount: "Original Loan Amount",
  foreclosure_recording_date: "Foreclosure Recording Date",
  lender_name: "Lender Name",
  auction_address: "Auction Address",
  auction_date: "Auction Date",
  auction_time: "Auction Time",
  recorded_auction_opening_bid: "Recorded Auction Opening Bid",
};

export const InvestorESFileColumnNames: Record<InvestorPreviewPreFields, string> = {
  investor_score: "Investor Score",
  ...ESFilePreColumnNames,
};

export const RealtorESFileColumnNames: Record<RealtorPreviewPreFields, string | number> = {
  model_score: "Model Score",
  ...ESFilePreColumnNames,
};

export const ESFilePostColumnNames: Record<PreviewPostFields, string> = {
  date_added: "Date Added",
  dataflik_id: "Dataflik Id",
  auctions: "Auctions",
  bankruptcy: "Bankruptcy",
  free_clear: "Free Clear",
  high_equity: "High Equity",
  liens: "Liens",
  pre_foreclosure: "Pre Foreclosure",
  pre_probate: "Pre Probate",
  vacant: "Vacant",
  zombie: "Zombie",
  short_term_loan: "Short Term Loan",
  criminal_felony: "Criminal Felony",
  absentee_low_income: "Absentee Low Income",
  absentee_bad_credit: "Absentee Bad Credit",
  absentee_vacant: "Absentee Vacant",
  owner_occupied_low_income: "Owner Occupied Low Income",
  owner_occupied_low_credit: "Owner Occupied Low Credit",
  senior_owner: "Senior Owner",
  senior_owner_two: "Senior Owner Two",
  owner_eviction: "Owner Eviction",
};
