import moment from "moment";
import { customAlphabet } from "nanoid";
import { AnyType, BuyBoxMetrics, BuyBoxMetricsEntry, DropDown, ResponseError } from "@shared/interfaces";
import { Account, DetailedCounty, Subscription, User } from "@shared/models";
import { showNotification } from "@shared/store/actions";

export const debounce = <F extends (...args: AnyType) => AnyType>(func: F, waitFor = 500) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  const debounced = (...args: AnyType) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export function getUniqueArray<T>(a: T[], property: keyof T) {
  return a.filter((item, pos, self) => self.findIndex((v) => v[property] === item[property]) === pos);
}

export const validateFile = (file: File | null, options: { maxSize?: number }): string => {
  if (file && options.maxSize && file.size > options.maxSize) {
    return `Max size of the file should be ${options.maxSize / 1000000} MB`;
  }

  return "";
};

export const getUSFormattedDate = (date: Date | string | undefined | null, timeZone?: string) => {
  return date ? new Date(date).toLocaleString("en-US", { timeZone }).split(",")[0] : "-";
};

export const getUSFormattedDateShortYear = (
  date: Date | string | undefined | null,
  timeZone?: string,
  withQuote?: boolean,
) => {
  if (!date) return "-";

  return moment(date).format(`MMM ${withQuote ? "'" : ""}YY`);
};

// May 23, 2024
export const getUSFormattedDateLong = (date: Date | string | undefined | null, timeZone?: string) => {
  return date
    ? new Date(date).toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric", timeZone })
    : "-";
};

export const getUSFormatedTime = (date: Date | string, timeZone?: string) => {
  const tzDate = new Date(date).toLocaleString("en-US", { timeZone });

  return date ? moment(tzDate, "MM/DD/YYYY, hh:mm:ss A").locale("en-US").format("LT") : "-";
};

export const getUSFormatedDateTime = (date: Date | string | null, timeZone?: string) => {
  return date ? `${getUSFormattedDate(date, timeZone)} ${getUSFormatedTime(date, timeZone)}` : "-";
};

export const getUSTimeAndDate = (date: Date) => `${getUSFormattedDateLong(date)} at ${getUSFormatedTime(date)}`;

export const getFullName = (user: User | null) => (user ? `${user.first_name} ${user.last_name}`.trim() : "");

const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);
export const generateHash = (length?: number) => nanoid(length);

export const formatFilenameDate = (date: Date, delimiter = "/", locale = "en-US") => {
  const year = new Intl.DateTimeFormat(locale, { year: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat(locale, { month: "2-digit" }).format(date);
  const day = new Intl.DateTimeFormat(locale, { day: "2-digit" }).format(date);

  return `${day}${delimiter}${month}${delimiter}${year}`;
};

export const showHttpErrorNotification = (error: ResponseError, customMessageText?: string) => {
  let message = "Something went wrong. Please try again.";

  if (error.customMessage && error.message) {
    message = error.message;
  } else if (customMessageText) {
    message = customMessageText;
  }

  return showNotification({
    message: message,
    appearance: "error",
  });
};

export const getExtension = (fileName: string) => {
  const parts = fileName.split(".");
  return parts.length > 1 ? parts[parts.length - 1] : "";
};

export const hasCounties = (account: Account | null): boolean => {
  const counties = getCountiesFromAccount(account);

  return !(!counties || !Array.isArray(counties) || counties.length === 0);
};

const getActiveSubscriptions = (accounts: Account[]): Subscription[] => {
  return accounts
    .filter(
      (account) =>
        account.subscriptions &&
        account.subscriptions.length > 0 &&
        account.subscriptions.some((subscription) => subscription.status === "active"),
    )
    .map((account) => account.subscriptions as Subscription[])
    .flat()
    .filter((subscription) => subscription.status === "active");
};

export const getCountiesFromSubscription = (subscriptions: Subscription[]): DetailedCounty[] => {
  if (!subscriptions || subscriptions.length === 0) {
    return [];
  }

  const uniqueCountyCodes = new Set<string>();

  return subscriptions
    .flatMap(({ counties }) => counties)
    .filter((county) => {
      if (uniqueCountyCodes.has(county.code)) {
        return false;
      }
      uniqueCountyCodes.add(county.code);
      return true;
    })
    .map((county) => ({ ...county, name: county.type === "city" ? `${county.name} City` : county.name }));
};

export const getCountiesFromUser = (user: User | null): DetailedCounty[] => {
  if (!user || !Array.isArray(user.accounts) || user.accounts.length === 0) return [];

  const account = getAccountWithActiveSubscription(user);
  if (!account) return [];

  const activeSubscriptions = getActiveSubscriptions([account]);

  return getCountiesFromSubscription(activeSubscriptions);
};

export const getCountiesFromAccount = (account: Account | null): DetailedCounty[] => {
  if (!account) return [];

  const activeSubscriptions = getActiveSubscriptions([account]);

  return getCountiesFromSubscription(activeSubscriptions);
};

/**
 * @description Just tells if the user has any subscription. Be it active or inactive or something else
 * @param user
 */
export const hasSubscription = (user: User | null): boolean => {
  if (!user || !Array.isArray(user.accounts) || user.accounts.length === 0) return false;

  // count number of accounts that have 0 subscriptions
  const accountsWithNoSubscriptions = user.accounts.filter(
    (account) => !account.subscriptions || (account.subscriptions && account.subscriptions.length === 0),
  );

  return user.accounts.length != accountsWithNoSubscriptions.length;
};

export const hasAccountActiveSubscription = (account?: Account | null): boolean => {
  if (!(account && account.subscriptions?.length)) {
    return false;
  }

  return account.subscriptions.some((subscription) => subscription.status === "active");
};

export const hasInactiveSubscription = (user: User | null): boolean => {
  if (!user || !Array.isArray(user.accounts) || user.accounts.length === 0) return false;

  return !user.accounts.some(
    (account) =>
      account.subscriptions &&
      account.subscriptions.length > 0 &&
      account.subscriptions.some((subscription) => subscription.status === "active"),
  );
};

export const getAccountWithActiveSubscription = (user: User | null): Account | null => {
  if (!user || !Array.isArray(user.accounts) || user.accounts.length === 0) return null;

  return (
    user.accounts.find(
      (account) =>
        account.subscriptions &&
        account.subscriptions.length > 0 &&
        account.subscriptions.some(
          (subscription) => subscription.status === "active" && subscription.counties?.length > 0,
        ),
    ) ?? null
  );
};

export const formatNumberWithCommas = (number: number) => {
  if (isNaN(number)) return number;
  return number.toLocaleString("en-US");
};

export const LocalStorageActiveAccountId = {
  get: () => {
    const id = localStorage.getItem("ACTIVE_ACCOUNT_ID");
    return id ? Number(id) : null;
  },
  set: (id: number) => localStorage.setItem("ACTIVE_ACCOUNT_ID", String(id)),
};

export function isOfType<T>(value: unknown, type: T): value is T {
  return typeof value === typeof type;
}

export function isArrayOfType<T>(value: unknown, type: T): value is T[] {
  return Array.isArray(value) && value.every((item) => isOfType(item, type));
}

export function areNumbers(...args: unknown[]): boolean {
  return args.every((arg) => typeof arg === "number");
}

export function notNull(...args: unknown[]): boolean {
  return args.every((arg) => arg !== null);
}

export function isDate(value: unknown): value is Date {
  if (typeof value === "string" || typeof value === "number" || value instanceof Date) {
    return Object.prototype.toString.call(new Date(value)) === "[object Date]";
  }
  return false;
}

export function parseQueryString(params: URLSearchParams): Record<string, string | string[]> {
  return Array.from(params.entries()).reduce<Record<string, string | string[]>>((acc, [key, value]) => {
    acc[key] = acc[key] ? [...[acc[key]].flat(), value] : value;
    return acc;
  }, {});
}

export const convertBuyBoxMetricsToEntries = (metrics: BuyBoxMetrics): BuyBoxMetricsEntry[] => {
  return Object.entries(metrics).map(([key, value]) => ({ key, value } as BuyBoxMetricsEntry));
};

export const formatPhoneNumber = (val?: string) => {
  if (!val) return;

  const cleaned = val.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
};

export const mapDropDownDataToInputPickerData = (arr: DropDown[]) => arr.map((i) => ({ id: i.value, name: i.label }));
