import React, { useState, FC, useMemo, useCallback } from "react";
import classnames from "classnames";
import { Field } from "formik";
import { InputProps } from "@shared/interfaces";
import { INPUT_TYPES } from "@shared/constants";
import { Icon } from "@shared/components";
import { onlyNumberInputValidation, onlyPasteNumberInputValidation } from "@shared/utils";
import InputMask from "react-input-mask";
import "./index.scss";

const Input: FC<InputProps> = (props: InputProps) => {
  const { className, isRequiredField, helpText, type, isShowPasswordIcon = true, ...inputProps } = props;

  const [inputType, setInputType] = useState(type);

  const isPasswordType = useMemo(() => {
    return type === INPUT_TYPES.PASSWORD;
  }, [type]);

  const onShowPassword = useCallback(() => {
    setInputType((prev) => (prev === INPUT_TYPES.PASSWORD ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD));
  }, []);

  return (
    <div className={classnames("input-wrapper", className)}>
      <label className={classnames("label", { disabled: props.disabled })}>
        {props.label} {isRequiredField && <span className="required-field">*</span>}
      </label>
      {type === "phone" ? (
        <Field
          as={InputMask}
          mask="(***) ***-****"
          placeholder="Phone Number"
          onKeyDown={onlyNumberInputValidation}
          onPaste={onlyPasteNumberInputValidation}
          {...inputProps}
        />
      ) : (
        <Field {...{ ...inputProps, type: inputType }} />
      )}
      {helpText && <div className="help-text">{helpText}</div>}
      {isShowPasswordIcon && isPasswordType ? (
        <div className="view-password">
          <Icon type={inputType === INPUT_TYPES.PASSWORD ? "eye" : "eye-close"} onClick={onShowPassword} />
        </div>
      ) : null}
    </div>
  );
};

export default Input;
