import { BuyBoxMetrics } from "@shared/interfaces";

const DEFAULT_BUY_BOX_METRICS: BuyBoxMetrics = {
  buyBoxMatch: 0,
  directMail: 0,
  totalListCount: 0,
};

export const BUY_BOX_TITLES: Partial<Record<keyof BuyBoxMetrics, string>> = {
  buyBoxMatch: "Buy Box Match",
  directMail: "Direct Mail",
  totalListCount: "Total List Count",
};

export default DEFAULT_BUY_BOX_METRICS;
