import { PropertyFilterType } from "@shared/interfaces/RecordManagement";

export const propertiesFiltersOptions = [
  {
    label: "General",
    children: [
      {
        label: "Owners",
        value: "owners",
        type: PropertyFilterType.TAGS,
      },
      {
        label: "Tags",
        value: "tags",
        type: PropertyFilterType.TAGS,
      },
      {
        label: "Lists",
        value: "lists",
        type: PropertyFilterType.TAGS,
      },
      {
        label: "Lists Count",
        value: "listsCount",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "Distressors",
        value: "distressors",
        type: PropertyFilterType.TAGS,
      },
    ],
  },
  {
    label: "Location",
    children: [
      {
        label: "State",
        value: "state",
        type: PropertyFilterType.TAGS,
      },
      {
        label: "County",
        value: "county",
        type: PropertyFilterType.TAGS,
      },
      {
        label: "ZIP codes",
        value: "zipCodes",
        type: PropertyFilterType.TAGS,
      },
      {
        label: "Neighborhood",
        value: "neighborhood",
        type: PropertyFilterType.TAGS,
      },
    ],
  },
  {
    label: "Property Characteristics",
    children: [
      {
        label: "Property Value (USD)",
        value: "propertyValue",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "Property Type",
        value: "propertyType",
        type: PropertyFilterType.TAGS,
      },
      {
        label: "Bedrooms",
        value: "bedrooms",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "Bathrooms",
        value: "bathrooms",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "Building Area (SqFt)",
        value: "buildingAreaSqFt",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "Year Built",
        value: "yearBuilt",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "Lot Size",
        value: "lotSize",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "Story Count",
        value: "storyCount",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "Unit Count",
        value: "unitCount",
        type: PropertyFilterType.MIN_MAX,
      },
    ],
  },
  {
    label: "Ownership Information",
    children: [
      {
        label: "Ownership / Vacancy Status",
        value: "ownershipVacancyStatus",
        type: PropertyFilterType.TAGS,
      },
      {
        label: "Last Sale Date",
        value: "lastSaleDate",
        type: PropertyFilterType.FROM_TO,
      },
      {
        label: "Last Sale Price",
        value: "lastSalePrice",
        type: PropertyFilterType.MIN_MAX,
      },
    ],
  },
  {
    label: "Marketing",
    children: [
      {
        label: "Direct Mail Attempts",
        value: "mailAttemptsQty",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "Call Attempts",
        value: "callAttemptsQty",
        type: PropertyFilterType.MIN_MAX,
      },
      {
        label: "SMS Attempts",
        value: "messageAttemptsQty",
        type: PropertyFilterType.MIN_MAX,
      },
    ],
  },
];
