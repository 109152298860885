import { AnyAction } from "redux";
import { all, call, put, race, select, spawn, take, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { actions } from "@shared/store";
import { ActionWithPayload, AppState, AWSCreds, AWSUploadMultipleItem } from "@shared/interfaces";
import {
  handleCompanyForbiddenError,
  navigate,
  showNotification,
  startLoading,
  stopLoading,
  uploadToS3,
  uploadToS3Multiple,
  uploadToS3MultipleItem,
} from "@shared/store/actions";
import { generateHash, LocalStorageActiveAccountId, tokenHandler } from "@shared/utils";
import fileApi from "@containers/File/api";
import { createFile } from "@containers/File/store/actions";
import api from "@shared/api";
import { County, PropertyType, State, User } from "@shared/models";
import { getUserDetails } from "@shared/store/selectors";
import { setActiveAccount } from "@containers/Account/store/actions";
import sharedApi from "@shared/api/api";
import { ZipCode } from "@shared/models/ZipCode";
import { Neighborhood } from "@shared/models/Neighborhood";

import config from "../../config";
import { store } from "../../index";

function* uploadToS3Saga({ payload }: ReturnType<typeof uploadToS3.request>) {
  const { file, name, type } = payload;
  let uploadObj: Upload | null = null;
  try {
    yield put(startLoading());
    const credentials: AWSCreds = yield call(fileApi.getTempAWSCreds);
    const client = new S3Client({
      region: credentials.region,
      credentials: credentials.credentials,
    });
    const user: User = yield select(getUserDetails());
    const hash = generateHash();
    const extension = name.split(".").pop();
    const key = `${credentials.pathDir}/skip-tracing/input-files/${user.id}/${hash}.${extension}`;
    const url = `${config.aws.s3.cdnDomain}/${key}`;
    uploadObj = new Upload({
      client,
      params: {
        ACL: "private",
        Bucket: credentials.Bucket,
        Key: key,
        Body: file,
        ContentDisposition: `attachment; filename=${encodeURIComponent(name)}`,
      },
    });
    yield call([uploadObj, uploadObj.done]);
    yield put(uploadToS3.success({ url, name }));
    yield put(createFile.request({ url, name, type }));
    yield race([take(createFile.success), take(createFile.failure)]);
  } catch (error) {
    yield put(uploadToS3.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* processItemUpload(uploadData: AWSUploadMultipleItem, index: number) {
  try {
    uploadData.uploadObj.on("httpUploadProgress", ({ loaded, total }) => {
      if (loaded && total) {
        const progress = Math.round((100 * loaded) / total);
        store.dispatch(
          uploadToS3MultipleItem.success({
            index,
            data: { progress },
          }),
        );
      }
    });
    yield call([uploadData.uploadObj, uploadData.uploadObj.done]);

    if (uploadData?.cb) {
      const { accountId, name, url } = uploadData;
      if (accountId && name && url) {
        uploadData.cb({
          account_id: accountId,
          name,
          url,
        });
      }
    }
  } catch (e) {
    if ((e as Error).name !== "AbortError") {
      yield put(
        uploadToS3MultipleItem.failure({
          index,
          error: e as Error,
        }),
      );
    }
  }
}

function* uploadToS3MultipleSaga({ payload }: ReturnType<typeof uploadToS3Multiple.request>) {
  try {
    yield put(startLoading());
    const credentials: AWSCreds = yield call(fileApi.getTempAWSCreds);
    const client = new S3Client({
      region: credentials.region,
      credentials: credentials.credentials,
    });
    const uploads = payload.map((uploadData) => {
      const key = `${credentials.pathDir}/investor-files/${uploadData.key}`;
      const url = `${config.aws.s3.cdnDomain}/${key}`;
      const uploadObj = new Upload({
        client,
        params: {
          ACL: "private",
          Bucket: credentials.Bucket,
          Key: key,
          Body: uploadData.file,
          ContentDisposition: `attachment; filename=${encodeURIComponent(uploadData.name)}`,
        },
      });

      return {
        url,
        uploadObj,
        name: uploadData.name,
        progress: 0,
        error: null,
        cb: uploadData?.cb,
        accountId: uploadData?.accountId,
        sendEmail: uploadData?.sendEmail,
      };
    });

    yield all(uploads.map((uploadData, i) => spawn(processItemUpload, uploadData, i)));

    yield put(uploadToS3Multiple.success(uploads));
  } catch (error) {
    yield put(uploadToS3.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getUserDetailsSaga({ payload }: ReturnType<typeof actions.getUserDetails.request>) {
  try {
    if (!payload?.hideLoader) {
      yield put(actions.startLoading());
    }
    const response: { user: User } = yield call(api.getUserDetails);
    yield tokenHandler.setUser(response.user);
    const user = response.user;

    yield put(actions.getUserDetails.success(user));
    if (payload?.callback) {
      payload.callback();
    }

    const activeAccountId = LocalStorageActiveAccountId.get();

    const accounts = user?.accounts;

    const activeAccount = accounts?.find?.(({ id }) => id === activeAccountId) || accounts?.[0];

    if (!activeAccount) {
      return;
    }

    LocalStorageActiveAccountId.set(activeAccount.id);
    yield put(setActiveAccount.success(activeAccount));
  } catch (error) {
    yield put(actions.getUserDetails.failure(error as Error));
  } finally {
    if (!payload?.hideLoader) {
      yield put(actions.stopLoading());
    }
  }
}

function* watchLoaders(payload: AnyAction) {
  const state: AppState = yield select();
  const { loadingTypes } = state.shared;

  const startedSection = loadingTypes.find(({ startActions }) => startActions.includes(payload.type));
  const stoppedSection = loadingTypes.find(({ stopActions }) => stopActions[payload.type]);

  if (startedSection) {
    yield put(actions.addLoadingSection({ loadingSection: startedSection.name, requestName: payload.type }));
  }
  if (stoppedSection) {
    yield put(
      actions.removeLoadingSection({
        loadingSection: stoppedSection.name,
        requestName: stoppedSection.stopActions[payload.type],
      }),
    );
  }
}

function* handleForbiddenSaga({ payload: error }: ActionWithPayload<{ code: number }>) {
  if (error && error.code === 403) {
    yield put(navigate({ to: "/" }));
    yield put(
      showNotification({
        appearance: "error",
        message: "You are not allowed to fetch this data. Change company and try again",
      }),
    );
    yield put(actions.getUserDetails.request());
  }
}

function* getStates() {
  try {
    const { items }: { items: State[] } = yield call(api.getStates);
    yield put(actions.getStates.success(items));
  } catch (error) {
    yield put(actions.getStates.failure(error as Error));
  }
}

function* getPropertyTypes() {
  try {
    const { items }: { items: PropertyType[] } = yield call(api.getPropertyTypes);
    yield put(actions.getPropertyTypes.success(items));
  } catch (error) {
    yield put(actions.getStates.failure(error as Error));
  }
}

function* getCountiesByState({ payload }: ActionWithPayload<number | undefined>) {
  try {
    const { items }: { items: County[] } = yield call(api.getCounties, payload);
    yield put(actions.getCountiesByState.success(items));
  } catch (error) {
    yield put(actions.getCountiesByState.failure(error as Error));
  }
}

function* getZipsSaga({ payload }: ActionWithPayload<number>) {
  try {
    const { items }: { items: ZipCode[] } = yield call(sharedApi.getZips, payload);
    yield put(actions.getZips.success(items));
  } catch (error) {
    yield put(actions.getCountiesByState.failure(error as Error));
  }
}

function* getNeighborhoodsSaga({ payload: countyId }: ActionWithPayload<number>) {
  try {
    const items: Neighborhood[] = yield call(sharedApi.getNeighborhoods, countyId);
    yield put(actions.getNeighborhoods.success(items));
  } catch (error) {
    yield put(actions.getNeighborhoods.failure(error as Error));
  }
}

function* sharedSagas() {
  yield takeLatest(actions.getUserDetails.request, getUserDetailsSaga);
  yield takeLeading(uploadToS3.request, uploadToS3Saga);
  yield takeLeading(uploadToS3Multiple.request, uploadToS3MultipleSaga);
  yield takeLeading(actions.getStates.request, getStates);
  yield takeLeading(actions.getPropertyTypes.request, getPropertyTypes);
  yield takeLeading(actions.getCountiesByState.request, getCountiesByState);
  yield takeLeading(actions.getZips.request, getZipsSaga);
  yield takeLeading(actions.getNeighborhoods.request, getNeighborhoodsSaga);
  yield takeLeading(handleCompanyForbiddenError, handleForbiddenSaga);
  yield takeEvery("*", watchLoaders);
}

export default sharedSagas;
