import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";
import { SkipTracingError } from "@containers/SkipTracing/interfaces";

const selectSkipTracing = (state: AppState) => state.skipTracing;

export const getSkipTracingList = () => createSelector(selectSkipTracing, (state) => state.skipTracingList);
export const getSkipTracingListTotal = () => createSelector(selectSkipTracing, (state) => state.skipTracingTotal);
export const getSkipTracing = () => createSelector(selectSkipTracing, (state) => state.skipTracing);
export const getFilter = () => createSelector(selectSkipTracing, (state) => state.filter);
export const selectPaymentProcessed = () => createSelector(selectSkipTracing, (state) => state.paymentProcessed);
export const selectPriceRange = () => createSelector(selectSkipTracing, (state) => state.priceRange);
export const selectCoupon = () => createSelector(selectSkipTracing, (state) => state.coupon);
export const selectError = (errorType: SkipTracingError) =>
  createSelector(selectSkipTracing, (state) => state.errors[errorType]);
