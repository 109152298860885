import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "@containers/PayPerLead/store";
import { PayPerLeadSubscription } from "@containers/PayPerLead/interface";
import { actions as accountActions, selectors as accountSelectors } from "@containers/Account/store";
import "./index.scss";

interface PaymentFailedProps {
  failedSubscriptions: PayPerLeadSubscription[];
}

export const PaymentFailed: React.FC<PaymentFailedProps> = ({ failedSubscriptions }) => {
  const dispatch = useDispatch();
  const [totalPaymentAmount, setTotalPaymentAmount] = useState<number>(0);
  const hasPaymentMethod = useSelector(accountSelectors.selectPaymentMethodExistence());
  const hasCardPaymentMethod = useSelector(accountSelectors.selectCardPaymentMethodExistence());
  const account = useSelector(accountSelectors.getActiveAccount());
  const allowPayment = hasCardPaymentMethod || (account?.credits ?? 0) >= totalPaymentAmount;
  const reason = !hasPaymentMethod
    ? "Payment method is missing."
    : !hasCardPaymentMethod
    ? "Only card payment is allowed."
    : "";

  const hasFetchedTotalPayment = useRef(false);

  useEffect(() => {
    if (account?.id && !hasCardPaymentMethod) {
      dispatch(accountActions.checkPaymentMethodExistence.request(account));
    }
  }, [dispatch, account, hasCardPaymentMethod]);

  useEffect(() => {
    if (failedSubscriptions.length && !hasFetchedTotalPayment.current) {
      hasFetchedTotalPayment.current = true; // Mark as fetched
      dispatch(
        actions.fetchTotalPaymentAmount.request({
          ids: failedSubscriptions.map((subscription) => subscription.id as number),
          callback: (paymentAmount: number) => setTotalPaymentAmount(paymentAmount),
        }),
      );
    }
  }, [dispatch, failedSubscriptions]);

  if (!failedSubscriptions?.length) return null;

  const tryAgain = () => {
    if (account) {
      if (allowPayment) {
        dispatch(actions.purchaseSubscriptions.request(failedSubscriptions));
      } else {
        const successUrl = new URL(location.href);

        dispatch(
          accountActions.goToCheckout({
            id: account?.id as number,
            successUrl: successUrl.toString(),
            cancelUrl: location.href,
          }),
        );
      }
    }
  };

  return (
    <div className="pay-per-lead-payment-failed">
      <div className="pay-per-lead-payment-failed-text">
        <b>Your payment has failed</b>
        Your payment has failed. {reason} We will stop delivering leads. If you still want to receive leads, please
        check your payment method and press &quot;Try Again.&quot;
      </div>
      <div className="pay-per-lead-payment-failed-button" onClick={tryAgain}>
        Try again
      </div>
    </div>
  );
};
