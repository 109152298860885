import { ResponseError } from "@shared/interfaces";
import { File, MappedToAccountFile } from "@shared/models";

export enum FileError {
  createFileError = "createFileError",
  mapFilesToAccountError = "mapFilesToAccountError",
}

export interface FileStateType {
  file: File | null;
  mappedToAccountFiles: MappedToAccountFile[];
  errors: {
    [key in FileError]: ResponseError | null;
  };
}
