import React, { FC } from "react";
import { Icon } from "@shared/components";
import { LinearProgressBar } from "@containers/SkipTracing/components";
import { getQueueSubtitleText } from "@containers/SkipTracing/constants/getQueueSubtitleText";

import "./index.scss";

interface ProgressBarSectionProps {
  fileName: string | undefined;
  processed?: number;
  skipTracingQueuePosition?: number;
}

const ProgressBarSection: FC<ProgressBarSectionProps> = (props) => {
  const { fileName, processed, skipTracingQueuePosition } = props;

  return (
    <div className="progress-bar-section-wrapper">
      <div className="filename-section">
        <Icon type="file" />
        <span>{fileName}</span>
      </div>
      <div className="horizontal-divider"></div>
      <div className="icon-section">
        <Icon type="monitor-with-loupe" />
      </div>
      <div className="second-title"> Skip Tracing Made Easy</div>
      <div className="linear-progress-bar-section">
        <LinearProgressBar />
      </div>

      <div className="queue-messages-count">
        {skipTracingQueuePosition ? getQueueSubtitleText(skipTracingQueuePosition) : null}
      </div>

      <div className="subtitle">{processed ? `${processed}% of records are processed` : null}</div>
    </div>
  );
};

export default ProgressBarSection;
