import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces";

const selectTicketState = (state: AppState) => state.tickets;

export const getTicketList = () => createSelector(selectTicketState, (state) => state.tickets);

export const getTicketById = (id: number) => {
  return createSelector(getTicketList(), (tickets) => {
    return tickets?.items ? tickets.items.find((ticket) => ticket.id === id) : null;
  });
};
