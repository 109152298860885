import React, { FC } from "react";
import "./index.scss";
import classNames from "classnames";

export interface ErrorMessageProps {
  isTouched?: boolean;
  error?: string;
  className?: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = (props) => {
  const { isTouched, error, className } = props;

  return isTouched && error ? <div className={classNames("error-message", className)}>{error}</div> : null;
};
