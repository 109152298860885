export const ACCEPT_FILE_TYPES = ".doc, .docx, .jpg, .jpeg, .psd, .pdf, .txt, .xls, .xlsx, .png";

export enum INPUT_TYPES {
  PASSWORD = "password",
  TEXT = "text",
}

export enum MODAL_TYPES {
  CONFIRM = "CONFIRM",
  GENERATE_FILE = "GENERATE_FILE",
  ACCOUNT_PAYMENT = "ACCOUNT_PAYMENT",
  CREATE_PROPERTY_LIST = "CREATE_PROPERTY_LIST",
  ADD_PROPERTIES_FROM_MONTHLY_FILE = "ADD_PROPERTIES_FROM_MONTHLY_FILE",
  NEW_PROPERTY_LIST = "NEW_PROPERTY_LIST",
  DOWNLOAD_CSV_PROPERTY_RECORDS = "DOWNLOAD_CSV_PROPERTY_RECORDS",
  PURCHASE_PAY_PER_LEAD = "PURCHASE_PAY_PER_LEAD",
  DISPUTE_PAY_PER_LEAD = "DISPUTE_PAY_PER_LEAD",
}

export enum OVERLAY_TYPES {
  CREATE_EDIT_CLIENT = "CREATE_EDIT_CLIENT",
  CREATE_EDIT_COMPANY = "CREATE_EDIT_COMPANY",
  EDIT_DEAL = "EDIT_DEAL",
  FILTER_OWNERS = "FILTER_OWNERS",
  FILTER_PROPERTIES = "FILTER_PROPERTIES",
  TICKET_HISTORY = "TICKET_HISTORY",
  TICKET_NOTES = "TICKET_NOTES",
}

export enum CHECK_USER {
  USER_EXIST = "User exist",
  USER_DOESNT_EXIST = "User does not exist",
}

export const FILE_PREVIEW_GET_REQUEST_INTERVAL = 5000;
export const INVESTOR_FILE_GET_REQUEST_INTERVAL = 5000;

export enum MAX_FIELDS_COUNTS {
  MAX_EMAILS_COUNT = 3,
  MAX_NUMBERS_COUNT = 4,
}

export const NotAvailableText = "N/A";
export const NotAvailablePlaceholder = "-";
