import { PayPerLeadSubscription, SubscriptionStateItem } from "@containers/PayPerLead/interface";

export const MIN_LEADS_COUNT = 1;
export const MAX_LEADS_COUNT = 500;

export const isDuplicate = (item: SubscriptionStateItem, subscriptions?: PayPerLeadSubscription[]): boolean => {
  const checkItemAllCounties = (item: SubscriptionStateItem): boolean => {
    return !!subscriptions?.some((subscription) =>
      subscription?.items?.some(
        (subItem) => subItem.state_id === item.state_id && subItem.all_counties && subscription.is_active,
      ),
    );
  };

  const checkItemCounty = (item: SubscriptionStateItem, countyId: number): boolean => {
    return !!subscriptions?.some((subscription) =>
      subscription?.items?.some(
        (subItem) =>
          subItem.state_id === item.state_id &&
          !subItem.all_counties &&
          subItem?.counties?.some((subCounty) => subCounty.county_id === countyId) &&
          subscription.is_active,
      ),
    );
  };

  const checkItemCounties = (item: SubscriptionStateItem): boolean => {
    return item.counties?.some((county) => checkItemCounty(item, county.county_id));
  };

  if (item.all_counties) {
    return checkItemAllCounties(item);
  } else {
    return checkItemCounties(item);
  }
};

export const isStateItemValid = (item: SubscriptionStateItem): boolean => {
  if (!item.state_id) {
    return false;
  }

  if (item.isDuplicate) {
    return false;
  }

  if (item.all_counties) {
    return isValidLeadsCount(item.leads_total);
  } else {
    if (item.counties.length === 0) {
      return false;
    }

    if (item.force_total_per_county) {
      return item.counties.every((item) => isValidLeadsCount(item.total_leads));
    } else {
      return isValidLeadsCount(item.leads_total);
    }
  }
};

export const isValidLeadsCount = (total?: number) => !!(total && total >= MIN_LEADS_COUNT && total <= MAX_LEADS_COUNT);
