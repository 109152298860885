import React, { FC, PropsWithChildren } from "react";
import classnames from "classnames";

import "./index.scss";

export type EmptyScreenComponentProps = PropsWithChildren<{ className?: string }>;

export type EmptyScreenComponents = {
  Figure: FC<EmptyScreenComponentProps>;
  Content: FC<EmptyScreenComponentProps>;
  Title: FC<EmptyScreenComponentProps>;
  Subtitle: FC<EmptyScreenComponentProps>;
  Actions: FC<EmptyScreenComponentProps>;
};

const Figure: FC<EmptyScreenComponentProps> = ({ className, children }) => {
  return <div className={classnames("empty-page-screen-figure", className)}>{children}</div>;
};

const Content: FC<EmptyScreenComponentProps> = ({ className, children }) => {
  return <div className={classnames("empty-page-screen-content", className)}>{children}</div>;
};

const Title: FC<EmptyScreenComponentProps> = ({ className, children }) => {
  return <div className={classnames("empty-page-screen-title", className)}>{children}</div>;
};

const Subtitle: FC<EmptyScreenComponentProps> = ({ className, children }) => {
  return <div className={classnames("empty-page-screen-subtitle", className)}>{children}</div>;
};

const Actions: FC<EmptyScreenComponentProps> = ({ className, children }) => {
  return <div className={classnames("empty-page-screen-actions", className)}>{children}</div>;
};

export const EmptyScreen: FC<EmptyScreenComponentProps> & EmptyScreenComponents = ({ className, children }) => {
  return <div className={classnames("empty-page-screen", className)}>{children}</div>;
};

EmptyScreen.Figure = Figure;
EmptyScreen.Title = Title;
EmptyScreen.Subtitle = Subtitle;
EmptyScreen.Actions = Actions;
EmptyScreen.Content = Content;
