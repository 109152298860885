import { Contacts } from "@shared/interfaces";

import { ORDER_TYPE, MinMax, FromTo } from "./Filter.interface";
export interface Attempts {
  call: number;
  message: number;
  mail: number;
}

export type PropertyStateType = {
  property: Property | null;
  propertyTotal: number;
  propertyList: Property[] | null;
  ownerTotal: number;
  ownerList: Owner[] | null;
  ownerDetails: OwnerDetails | null;
  ownerPropertiesTotal: number;
  ownerProperties: OwnerProperty[];
  error: object | null;
};

export type PropertyList = {
  id: number;
  name: string;
  account_id: number;
  created_at?: Date | null;
};

export type Property = {
  property_id: number;
  dataflik_id: number;
  property_value: number;
  property_zip: string;
  property_zip4: string;
  investor_score: number;
  county: string;
  full_name: string;
  property_address: string;
  property_city: string;
  property_state: string;
  lists: PropertyList[];
  structure_style?: string;
  property_type?: string;
  exterior_type?: string;
  bath_count?: number;
  year_built?: number;
  bedroom_count?: number;
  property_sqft?: number;
  distressor: { name: string; value: string }[];
  property_unit_prefix: string;
  property_unit: string;
  neighborhood: string;
  owner_occupied: string;
  property_use_group: string;
  partial_bath_count: number;
  room_count: number;
  story_count: number;
  unit_count: number;
  auction_address: string;
  auction_date: string;
  auction_time: string;
  recorded_auction_opening_bid: string;
  case_number: string;
  original_loan_amount: string;
  foreclosure_recording_date: string;
  lender_name: string;
  parcel_number: string;
  contacts: Contacts;
  mailing_address: string;
  mailing_address_city: string;
  mailing_address_state: string;
  mailing_address_zip: string;
  reach_out_attempts?: Attempts;
};

export type PropertyListItem = Property & {
  lists: string;
  reach_out_attempts?: null;
};

export type RequestPropertyListPayload = {
  accountId: number;
  listId?: number;
  from?: number;
  size?: number;
  search?: string;
  filters?: Record<string, MinMax | FromTo | string[]>;
  order?: string;
  direction?: ORDER_TYPE;
};

export type SuccessPropertyListPayload = {
  total: number;
  rows: Property[];
};

export type Owner = {
  fullName: string;
  propertiesCount: number;
  soldPropertiesCount?: number;
  mailingAddress: string;
  phonesCount: number;
  phoneNumbers?: string[];
  emails?: string[];
  attempts: Attempts;
};

export type OwnerDetails = Owner & {
  mailingAddresses: string[];
  phones: string[];
  emails: string[];
  soldPropertiesCount: number;
  leads?: number;
  offers?: number;
  callsMade?: number;
  verifiedNumbers?: number;
  totalInvestment?: number;
};

export type RequestPropertyPayload = {
  accountId: number;
  propertyId: number;
};

export type SuccessPropertyPayload = Property;

export type RequestOwnerListPayload = {
  accountId: number;
  from?: number;
  size?: number;
  search?: string;
  filters?: OwnersFilters;
};

export type SuccessOwnerListPayload = {
  total: number;
  rows: Owner[];
};

export type RequestOwnerDetailsPayload = {
  accountId: number;
  ownerName: string;
};

export type SuccessOwnerDetailsPayload = OwnerDetails;

export type RequestOwnerPropertiesPayload = {
  accountId: number;
  ownerName: string;
  from?: number;
  size?: number;
};

export type OwnerProperty = Property & { lists: number };

export type SuccessOwnerPropertiesPayload = {
  total: number;
  rows: OwnerProperty[];
};

export enum OwnerDetailsTab {
  Details = "Details",
  Properties = "Properties",
}

export type RequestUnlinkPropertyPayload = {
  accountId: number;
  listId: number;
  propertyId: number;
};

export type SuccessUnlinkPropertyPayload = {
  accountId: number;
  listId: number;
  propertyId: number;
};

export interface OwnersFilters {
  propertyQty?: MinMax;
  phoneQty?: MinMax;
  emailQty?: MinMax;
  lastUpdateDate?: FromTo;
  mailAttemptsQty?: MinMax;
  callAttemptsQty?: MinMax;
  smsAttemptsQty?: MinMax;
}

export type FilterValue = null | string | number | number[];

export enum PropertyFilterType {
  SELECT = "select",
  TAGS = "tags",
  TAGS_FREE = "tagsFree",
  MIN_MAX = "minMax",
  FROM_TO = "fromTo",
  FROM_TO_PREDEFINED = "fromToPredefined",
}

export type PropertyFilterTypeValue =
  | {
      type: PropertyFilterType.SELECT;
      filter?: string | number;
    }
  | {
      type: PropertyFilterType.TAGS | PropertyFilterType.TAGS_FREE;
      filter?: number[];
    }
  | {
      type: PropertyFilterType.MIN_MAX;
      filter?: MinMax;
    }
  | {
      type: PropertyFilterType.FROM_TO | PropertyFilterType.FROM_TO_PREDEFINED;
      filter?: FromTo;
    };

export type PropertyFilterOption = {
  label: string;
  value: string;
} & PropertyFilterTypeValue;
