export enum AuthActionTypes {
  LOGIN = "@@AUTH/LOGIN",
  LOGIN_SUCCESS = "@@AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@AUTH/LOGIN_FAILURE",

  CHECK_USER = "@@AUTH/CHECK_USER",
  CHECK_USER_SUCCESS = "@@AUTH/CHECK_USER_SUCCESS",
  CHECK_USER_FAILURE = "@@AUTH/CHECK_USER_FAILURE",

  LOGOUT = "@@AUTH/LOGOUT",
  LOGOUT_SUCCESS = "@@AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "@@AUTH/LOGOUT_FAILURE",

  REGISTRATION = "@@AUTH/REGISTRATION",
  REGISTRATION_SUCCESS = "@@AUTH/REGISTRATION_SUCCESS",
  REGISTRATION_FAILURE = "@@AUTH/REGISTRATION_FAILURE",

  FORGOT_PASSWORD = "@@AUTH/FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS = "@@AUTH/FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE = "@@AUTH/FORGOT_PASSWORD_FAILURE",

  SET_PASSWORD = "@@AUTH/SET_PASSWORD",
  SET_PASSWORD_SUCCESS = "@@AUTH/SET_PASSWORD_SUCCESS",
  SET_PASSWORD_FAILURE = "@@AUTH/SET_PASSWORD_FAILURE",

  RESET_PASSWORD = "@@AUTH/RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS = "@@AUTH/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE = "@@AUTH/RESET_PASSWORD_FAILURE",

  ACTIVATE_ACCOUNT = "@@AUTH/ACTIVATE_ACCOUNT",
  ACTIVATE_ACCOUNT_SUCCESS = "@@AUTH/ACTIVATE_ACCOUNT_SUCCESS",
  ACTIVATE_ACCOUNT_FAILURE = "@@AUTH/ACTIVATE_ACCOUNT_FAILURE",

  SET_REDIRECT_TO = "@@AUTH/SET_REDIRECT_TO",

  CLEAR_ERROR = "@@AUTH/CLEAR_ERROR",
  CLEAR_USER_EXISTENCE = "@@AUTH/CLEAR_USER_EXISTENCE",
  CLEAR_FILLED_EMAIL = "@@AUTH/CLEAR_FILLED_EMAIL",

  IMPERSONATE = "@@AUTH/IMPERSONATE",
  IMPERSONATE_SUCCESS = "@@AUTH/IMPERSONATE_SUCCESS",
  IMPERSONATE_FAILURE = "@@AUTH/IMPERSONATE_FAILURE",

  IMPERSONATION_EXIT = "@@AUTH/IMPERSONATION_EXIT",
  IMPERSONATION_EXIT_SUCCESS = "@@AUTH/IMPERSONATION_EXIT_SUCCESS",
  IMPERSONATION_EXIT_FAILURE = "@@AUTH/IMPERSONATION_EXIT_FAILURE",
}
