import { Account, StripeSubscriptionStatus, User } from "@shared/models";

import config from "../../config";

const getChargebeeSubscriptionId = (currentUser: User | null, account: Account | null) =>
  currentUser &&
  account &&
  (config.env === "production"
    ? account.subscriptions?.find((s) => s.status === StripeSubscriptionStatus.ACTIVE && s.chargebee_subscription_id)
        ?.chargebee_subscription_id
    : "dataflik-test");

export const createVitallyUser = (currentUser: User | null, account: Account | null) => {
  const chargebeeSubscriptionId = getChargebeeSubscriptionId(currentUser, account);

  if (!chargebeeSubscriptionId) return;

  const name = config.env === "production" ? (account as Account).name : "dataflik-test";
  const user = currentUser as User;

  window.Vitally?.account({
    accountId: chargebeeSubscriptionId,
    traits: {
      name,
    },
  });

  window.Vitally?.user({
    accountId: chargebeeSubscriptionId,
    userId: user.email,
    traits: {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      createdAt: user.created_at,
    },
  });
};

export const sendVitallySurvey = (currentUser: User | null, account: Account | null) =>
  getChargebeeSubscriptionId(currentUser, account) &&
  window.Vitally?.nps("survey", {
    productName: "Dataflik",
    primaryColor: "#2589f5",
    delay: 1000,
  });
