import { createStore, applyMiddleware, compose, Middleware, Dispatch } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import freeze from "redux-freeze";
import createSagaMiddleware from "redux-saga";
import { showNotification } from "@shared/store/actions";
import { AccountActionTypes } from "@containers/Account/store/constants";
import { PayPerLeadActionTypes } from "@containers/PayPerLead/store/constants";

import appSagas from "./saga";
import rootReducer from "./reducer";

const SKIPPED_SUCCESS_MESSAGES = [
  PayPerLeadActionTypes.DISPUTE_LEAD_SUCCESS,
  AccountActionTypes.GET_ACCOUNT_ATTEMPTS_SUCCESS,
  AccountActionTypes.SAVE_ACCOUNT_ATTEMPTS_SUCCESS,
];
const sagaMiddleware = createSagaMiddleware();
let middleware: Array<Middleware>;
// eslint-disable-next-line @typescript-eslint/ban-types
let composer: Function;

if (process.env.NODE_ENV === "development") {
  middleware = [freeze, sagaMiddleware];
  composer = composeWithDevTools({ trace: true, traceLimit: 25 });
} else {
  middleware = [sagaMiddleware];
  composer = compose;
}

const errorHandlerMiddleware: Middleware = () => (next: Dispatch) => (action) => {
  if (
    action.type.includes("SUCCESS") &&
    action.payload &&
    action.payload.message &&
    !SKIPPED_SUCCESS_MESSAGES.includes(action.type)
  ) {
    next(
      showNotification({
        message: action.payload.message,
        appearance: "success",
      }),
    );
  }

  return next(action);
};

export default function configureStore() {
  const store = createStore(rootReducer(), undefined, composer(applyMiddleware(...middleware, errorHandlerMiddleware)));

  sagaMiddleware.run(appSagas);

  // eslint-disable-next-line
  if ((module as any).hot) {
    // eslint-disable-next-line
    (module as any).hot.accept(() => store.replaceReducer(rootReducer()));
  }

  return { store };
}
