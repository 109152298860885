export type FilterKey =
  | "buybox_area_building_high"
  | "buybox_area_building_low"
  | "buybox_bath_count_high"
  | "buybox_bath_count_low"
  | "buybox_bedrooms_count_high"
  | "buybox_bedrooms_count_low"
  | "buybox_property_value_high"
  | "buybox_property_value_low"
  | "buybox_year_built_high"
  | "buybox_year_built_low";
export const BUY_BOX_FILTER_KEYS: FilterKey[] = [
  "buybox_area_building_high",
  "buybox_area_building_low",
  "buybox_bath_count_high",
  "buybox_bath_count_low",
  "buybox_bedrooms_count_high",
  "buybox_bedrooms_count_low",
  "buybox_property_value_high",
  "buybox_property_value_low",
  "buybox_year_built_high",
  "buybox_year_built_low",
];

type BuyBoxESToUIKeys = Record<FilterKey, string>;
export const BUY_BOX_ES_TO_UI_KEYS = BUY_BOX_FILTER_KEYS.reduce<Partial<BuyBoxESToUIKeys>>((acc, key) => {
  const uiKey = key.split("_").slice(1, -1).join("_");
  return {
    ...acc,
    [key]: uiKey,
  };
}, {}) as BuyBoxESToUIKeys;
