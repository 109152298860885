import React, { ReactNode, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { actions } from "@containers/PayPerLead/store";
import { CountyOption } from "@containers/PayPerLead/interface/Actions";

import { useCache } from "./CacheProvider";

export interface CountyOptionsProviderProps {
  stateId?: number;
  children?: ReactNode;
}

export const CountyOptionsProvider = ({ stateId, children }: CountyOptionsProviderProps) => {
  const dispatch = useDispatch();
  const cache = useCache();
  const isStateCached = !!(stateId && cache.stateOptions[stateId]);

  useEffect(() => {
    if (isStateCached) {
      dispatch(actions.fetchCountyOptions.request({ stateId }));
    }

    return () => {
      if (stateId) {
        dispatch(
          actions.fetchCountyOptions.success({
            stateId,
            counties: {},
          }),
        );
      }
    };
  }, [dispatch, stateId, isStateCached]);

  return <>{children}</>;
};

export const useCountyOptions = (stateId?: number): CountyOption[] => {
  const cache = useCache();

  return useMemo(() => {
    if (stateId) {
      const stateOption = cache.stateOptions[stateId];

      if (stateOption) {
        return Object.values(stateOption.counties).sort((a, b) => a.label.localeCompare(b.label));
      }
    }

    return [];
  }, [stateId, cache.stateOptions]);
};

export const useCountyOption = (stateId?: number, countyId?: number): CountyOption | undefined => {
  const cache = useCache();
  if (stateId) {
    const stateOption = cache.stateOptions[stateId];

    if (stateOption && countyId) {
      return stateOption.counties[countyId];
    }
  }
};
