import React, { useEffect } from "react";
import { Divider } from "rsuite";
import "./index.scss";
import { FilterInputGroup } from "@containers/RecordManagement/components";
import { useDispatch, useSelector } from "react-redux";
import * as sharedSelectors from "@shared/store/selectors";
import { getStates } from "@shared/store/actions";
import { FilterValue, PropertyFilterOption } from "@shared/interfaces/RecordManagement";
import { FromTo, MinMax } from "@shared/interfaces";

interface Props {
  state: PropertyFilterOption[];
  onChange: (s: PropertyFilterOption[]) => void;
}
export const PropertiesFiltersState = ({ state, onChange }: Props) => {
  const dispatch = useDispatch();
  const states = useSelector(sharedSelectors.getStates());

  useEffect(() => {
    if (states) return;

    dispatch(getStates.request());
  }, [dispatch, states]);

  const handleChange = (index: number) => (field: string, subField?: string) => (value: FilterValue) => {
    onChange(
      state.map((i, ind) => {
        if (ind !== index) return i;

        return {
          ...state[index],
          filter: subField
            ? {
                ...(state[index].filter as MinMax | FromTo),
                [subField]: value,
              }
            : value,
        } as PropertyFilterOption;
      }),
    );
  };

  const handleRemove = (index: number) => onChange(state.filter((_, ind) => ind !== index));

  if (!state.length) return null;

  return (
    <>
      <Divider />

      {state.map(({ value, ...rest }, index) => (
        <div key={rest.label}>
          <FilterInputGroup
            {...rest}
            field={value}
            onChange={handleChange(index)}
            onRemove={() => handleRemove(index)}
          />
        </div>
      ))}
    </>
  );
};
