import React from "react";
import { Icon } from "@shared/components";

import { ButtonProps } from "./Button";
import { ButtonPrimary } from "./ButtonEnhanced";

export const ButtonDownload: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <ButtonPrimary {...props}>
      <Icon type="download" />
      {children}
    </ButtonPrimary>
  );
};
