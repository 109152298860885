import React from "react";

import "./styles.scss";

const LoadingIndicator: React.FC = () => (
  <div className="loading-indicator">
    <span className="loader-spinner" />
  </div>
);

export default LoadingIndicator;
