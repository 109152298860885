import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces";

const selectDataDeliveryState = (state: AppState) => state.dataDelivery;

export const getHubspotPropertiesHistory = () =>
  createSelector(selectDataDeliveryState, (state) => state.hubspotPropertiesHistory);
export const getHubspotPropertiesHistoryTotal = () =>
  createSelector(selectDataDeliveryState, (state) => state.hubspotPropertiesHistoryTotal);
export const getHubspotPropertiesHistoryFilter = () =>
  createSelector(selectDataDeliveryState, (state) => state.hubspotPropertiesHistoryFilter);
