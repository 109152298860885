import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import {
  GetPayPerLeadShape,
  DisputePayPerLead,
  LeadPriceResponse,
  PayPerLeadSubscription,
  SubscriptionServerModel,
  GetDisputesRequest,
  UpdateDisputeRequest,
  QualifyLeadsRequest,
  CheckPurchaseResponse,
  DownloadLeadsRequestPayload,
  UpdateDisputeResponse,
} from "@containers/PayPerLead/interface";
import { SubscriptionConverter } from "@containers/PayPerLead/api/converter";
import { SubscriptionsListQuery, SubscriptionsListResponse } from "@containers/PayPerLead/interface/Actions";
import { pick } from "lodash";
import { BaseFilter, PayPerLeadStatisticsFilter } from "@shared/interfaces";

interface List<T> {
  items: T[];
  total: number;
}

export default {
  getLead: (id: string) =>
    request(
      METHODS.GET,
      API.PAY_PER_LEAD.GET_LEAD_BY_ID(id),
    )({ params: { show_detail: true, show_dispute: true, show_state: true, show_county: true } }),
  disputeLead: (body: DisputePayPerLead) => request(METHODS.POST, API.PAY_PER_LEAD.DISPUTE_LEAD)(body),
  getLeads: (params: GetPayPerLeadShape) => request(METHODS.GET, API.PAY_PER_LEAD.GET_LEADS)({ params }),
  getLeadPrice: (state: number, county?: number): Promise<LeadPriceResponse> =>
    request(
      METHODS.GET,
      API.PAY_PER_LEAD.GET_LEAD_PRICE,
    )({
      params: {
        stateId: state,
        countyId: county,
      },
    }),
  getSubscriptions: async (accountId: number, query: SubscriptionsListQuery): Promise<SubscriptionsListResponse> => {
    const response: List<SubscriptionServerModel> = await request(
      METHODS.GET,
      API.PAY_PER_LEAD.GET_SUBSCRIPTIONS_LIST,
    )({
      params: {
        account_id: accountId,
        is_active: query.isActive,
        show_items: query.showItems,
        order: query.order,
        direction: query.direction,
      },
    });

    return {
      total: response.total,
      items: response.items.map(SubscriptionConverter.fromServerModel),
    };
  },
  getSubscriptionDetails: async (id: number, draftItems: boolean): Promise<PayPerLeadSubscription> => {
    const response: SubscriptionServerModel = await request(
      METHODS.GET,
      API.PAY_PER_LEAD.GET_SUBSCRIPTION(id),
    )({ params: { show_items: true, draft_items: draftItems } });

    return SubscriptionConverter.fromServerModel(response);
  },
  createSubscription: async (accountId: number, subscription: PayPerLeadSubscription) => {
    const serverModel = SubscriptionConverter.toServerModel(subscription, accountId);
    const response: SubscriptionServerModel = await request(
      METHODS.POST,
      API.PAY_PER_LEAD.CREATE_SUBSCRIPTION,
    )(serverModel);

    return SubscriptionConverter.fromServerModel(response);
  },
  updateSubscription: async (subscription: PayPerLeadSubscription) => {
    const serverModel = SubscriptionConverter.toServerModel(subscription);

    const response: SubscriptionServerModel = await request(
      METHODS.PUT,
      API.PAY_PER_LEAD.UPDATE_SUBSCRIPTION,
    )(pick(serverModel, "id", "items"));

    return SubscriptionConverter.fromServerModel(response);
  },
  purchaseSubscription: async (id: number) => {
    await request(METHODS.POST, API.PAY_PER_LEAD.PURCHASE_SUBSCRIPTION(id))();
  },
  deactivateSubscription: async (id: number) => {
    await request(METHODS.DELETE, API.PAY_PER_LEAD.GET_SUBSCRIPTION(id))();
  },
  checkPurchase: async (id: number): Promise<CheckPurchaseResponse> =>
    request(METHODS.GET, API.PAY_PER_LEAD.PURCHASE_SUBSCRIPTION(id))(),
  getDisputes: (params: GetDisputesRequest) => {
    return request(METHODS.GET, API.PAY_PER_LEAD.GET_ADMIN_DISPUTES)({ params });
  },
  updateDispute: ({ disputeId, ...params }: UpdateDisputeRequest): Promise<UpdateDisputeResponse> => {
    return request(METHODS.PUT, API.PAY_PER_LEAD.UPDATE_ADMIN_DISPUTES(disputeId))(params);
  },
  qualifyLeads: (params: QualifyLeadsRequest): Promise<void> =>
    request(METHODS.PUT, API.PAY_PER_LEAD.BATCH_QUALIFY_LEADS)(params),
  exportLeads: (params: DownloadLeadsRequestPayload): Promise<Blob> =>
    request(METHODS.POST, API.PAY_PER_LEAD.BATCH_EXPORT_LEADS)(params, {
      responseType: "blob",
    }),
  getSubscriptionsWithCustomers: (params: BaseFilter) =>
    request(METHODS.GET, API.PAY_PER_LEAD.GET_ADMIN_CUSTOMERS)({ params }),
  getStatistics: (params: PayPerLeadStatisticsFilter) =>
    request(METHODS.GET, API.PAY_PER_LEAD.GET_STATISTICS)({ params }),
  getFilterOptions: (account_id?: number | undefined) => {
    const params = account_id ? { account_id } : {};
    return request(METHODS.GET, API.PAY_PER_LEAD.GET_FILTER_OPTIONS)({ params });
  },
};
