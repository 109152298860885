export enum MARKET_MAP_ACTIONS {
  GET_METRICS = "@@MARKET_MAP/GET_METRICS",
  GET_METRICS_SUCCESS = "@@MARKET_MAP/GET_METRICS_SUCCESS",
  GET_METRICS_FAILURE = "@@MARKET_MAP/GET_METRICS_FAILURE",

  GET_INIT_DATA = "@@MARKET_MAP/INIT_DATA",
  GET_INIT_DATA_SUCCESS = "@@MARKET_MAP/INIT_DATA_SUCCESS",
  GET_INIT_DATA_FAILURE = "@@MARKET_MAP/INIT_DATA_FAILURE",

  FILTER_CHANGE = "@@MARKET_MAP/FILTER_CHANGE",
  FILTER_CHANGE_SUCCESS = "@@MARKET_MAP/FILTER_CHANGE_SUCCESS",
  FILTER_CHANGE_FAILURE = "@@MARKET_MAP/FILTER_CHANGE_FAILURE",

  BUY_BOX = "@@MARKET_MAP/BUY_BOX",
  BUY_BOX_SUCCESS = "@@MARKET_MAP/BUY_BOX_SUCCESS",
  BUY_BOX_FAILURE = "@@MARKET_MAP/BUY_BOX_FAILURE",

  RESET_BUY_BOX_FILTERS = "@@MARKET_MAP/RESET_BUY_BOX_FILTERS",
  RESET_BUY_BOX_FILTERS_SUCCESS = "@@MARKET_MAP/RESET_BUY_BOX_FILTERS_SUCCESS",
  RESET_BUY_BOX_FILTERS_FAILURE = "@@MARKET_MAP/RESET_BUY_BOX_FILTERS_FAILURE",

  GET_ZIPS_CSV = "@@MARKET_MAP/GET_ZIPS_CSV",
  GET_ZIPS_CSV_SUCCESS = "@@MARKET_MAP/GET_ZIPS_CSV_SUCCESS",
  GET_ZIPS_CSV_FAILURE = "@@MARKET_MAP/GET_ZIPS_CSV_FAILURE",

  GET_NEIGHBORHOODS_CSV = "@@MARKET_MAP/GET_NEIGHBORHOODS_CSV",
  GET_NEIGHBORHOODS_CSV_SUCCESS = "@@MARKET_MAP/GET_NEIGHBORHOODS_CSV_SUCCESS",
  GET_NEIGHBORHOODS_CSV_FAILURE = "@@MARKET_MAP/GET_NEIGHBORHOODS_CSV_FAILURE",

  GET_COUNTY_GEO_DATA = "@@MARKET_MAP/GET_COUNTY_GEO_DATA",
  GET_COUNTY_GEO_DATA_SUCCESS = "@@MARKET_MAP/GET_COUNTY_GEO_DATA_SUCCESS",
  GET_COUNTY_GEO_DATA_FAILURE = "@@MARKET_MAP/GET_COUNTY_GEO_DATA_FAILURE",

  GET_COUNTY_NEIGHBORHOODS_GEO_DATA = "@@MARKET_MAP/GET_COUNTY_NEIGHBORHOODS_GEO_DATA",
  GET_COUNTY_NEIGHBORHOODS_GEO_DATA_SUCCESS = "@@MARKET_MAP/GET_COUNTY_NEIGHBORHOODS_GEO_DATA_SUCCESS",
  GET_COUNTY_NEIGHBORHOODS_GEO_DATA_FAILURE = "@@MARKET_MAP/GET_COUNTY_NEIGHBORHOODS_GEO_DATA_FAILURE",

  GET_COUNTY_NEIGHBORHOOD_METRICS = "@@MARKET_MAP/GET_COUNTY_NEIGHBORHOOD_METRICS",
  GET_COUNTY_NEIGHBORHOOD_METRICS_SUCCESS = "@@MARKET_MAP/GET_COUNTY_NEIGHBORHOOD_METRICS_SUCCESS",
  GET_COUNTY_NEIGHBORHOOD_METRICS_FAILURE = "@@MARKET_MAP/GET_COUNTY_NEIGHBORHOOD_METRICS_FAILURE",

  CLEAR_COUNTY_GEO_DATA = "@@MARKET_MAP/CLEAR_COUNTY_GEO_DATA",

  START_DOWNLOADING_ZIPS_CSV = "@@MARKET_MAP/START_DOWNLOADING_ZIPS_CSV",
  STOP_DOWNLOADING_ZIPS_CSV = "@@MARKET_MAP/STOP_DOWNLOADING_ZIPS_CSV",

  RESET_STATE_REQUEST = "@@MARKET_MAP/RESET_STATE_REQUEST",
  RESET_STATE_SUCCESS = "@@MARKET_MAP/RESET_STATE_SUCCESS",
  RESET_STATE_FAILURE = "@@MARKET_MAP/RESET_STATE_FAILURE",
}
