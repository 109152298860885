import { call, put, takeLatest } from "redux-saga/effects";
import { startLoading, stopLoading } from "@shared/store/actions";
import {
  ActionWithPayload,
  HubspotPropertiesHistoryFilter,
  PaginatedResponse,
  ResponseError,
} from "@shared/interfaces";
import { showHttpErrorNotification } from "@shared/utils";
import { HubspotPropertiesHistory } from "@shared/models";

import { getHubspotHistoryList, updateHubspotHistoryItemProcessingStatus } from "./actions";
import api from "../api";

function* getHubspotHistoryListSaga({ payload }: ActionWithPayload<HubspotPropertiesHistoryFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<HubspotPropertiesHistory> = yield call(api.getHubspotHistoryList, payload);
    yield put(getHubspotHistoryList.success({ ...response, clear: payload.page === 1 }));
  } catch (error) {
    yield put(getHubspotHistoryList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* updateHubspotHistoryItemStatusSaga({ payload }: ActionWithPayload<{ id: number; processed: boolean }>) {
  try {
    yield put(startLoading());
    const response: HubspotPropertiesHistory = yield call(api.updateHubspotHistoryItemStatus, payload);
    yield put(updateHubspotHistoryItemProcessingStatus.success(response));
  } catch (error) {
    yield put(updateHubspotHistoryItemProcessingStatus.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* accountSagas() {
  yield takeLatest(getHubspotHistoryList.request, getHubspotHistoryListSaga);
  yield takeLatest(updateHubspotHistoryItemProcessingStatus.request, updateHubspotHistoryItemStatusSaga);
}

export default accountSagas;
