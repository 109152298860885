import { createAction, createAsyncAction } from "typesafe-actions";
import { File, FileToMapAccount, MappedToAccountFile, UpdateFileMappingType } from "@shared/models";
import { ResponseError, WithCallbackWithRequiredArg } from "@shared/interfaces";

import { FileActionTypes } from "./constants";
import { CreateFileShape, FileError } from "../interface";

export const createFile = createAsyncAction(
  FileActionTypes.CREATE_FILE,
  FileActionTypes.CREATE_FILE_SUCCESS,
  FileActionTypes.CREATE_FILE_FAILURE,
)<CreateFileShape, File, ResponseError>();

export const updateFileMapping = createAsyncAction(
  FileActionTypes.UPDATE_FILE_MAPPING,
  FileActionTypes.UPDATE_FILE_MAPPING_SUCCESS,
  FileActionTypes.UPDATE_FILE_MAPPING_FAILURE,
)<UpdateFileMappingType & { id: number; callback?: () => void }, File, ResponseError>();

export const mapFilesToAccount = createAsyncAction(
  FileActionTypes.MAP_FILES_TO_ACCOUNT,
  FileActionTypes.MAP_FILES_TO_ACCOUNT_SUCCESS,
  FileActionTypes.MAP_FILES_TO_ACCOUNT_FAILURE,
)<
  WithCallbackWithRequiredArg<{ files: FileToMapAccount[] }, MappedToAccountFile[]>,
  MappedToAccountFile[],
  ResponseError
>();

export const downloadFile = createAction(FileActionTypes.DOWNLOAD_FILE)<{ id: number; callback?: () => void }>();

export const clearFile = createAction(FileActionTypes.CLEAR_FILE)();
export const clearErrors = createAction(FileActionTypes.CLEAR_ERRORS)<FileError[] | void>();
