import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "@shared/store";
import { ProgressScreen, ProgressScreenStatus } from "@containers/ProgressScreen";

import "./index.scss";

const ProgressScreenWrapper: FC = () => {
  const dispatch = useDispatch();

  const progressScreen = useSelector(selectors.getProgressScreen());

  const onClose = useCallback(() => {
    progressScreen?.onGoBack?.();

    dispatch(actions.hideProgressScreen());
  }, [progressScreen, dispatch]);

  useEffect(() => {
    (document.querySelector("body") as HTMLElement).style.overflow = progressScreen ? "hidden" : "";
  }, [progressScreen]);

  useEffect(() => {
    if (progressScreen?.status === ProgressScreenStatus.Completed) {
      const timeout = setTimeout(() => dispatch(actions.hideProgressScreen()), 5000);
      return () => clearTimeout(timeout);
    }
  }, [dispatch, progressScreen?.status]);

  return progressScreen ? (
    <div className="progress-screen-wrapper">
      <ProgressScreen {...progressScreen} onGoBack={onClose} />
    </div>
  ) : null;
};

export default ProgressScreenWrapper;
