import { AnyAction, combineReducers } from "redux";
import { AppState } from "@shared/interfaces";
import { PayPerLeadReducer } from "@containers/PayPerLead/store/reducer";

import { AuthReducer } from "../containers/Auth/store/reducer";
import { reducer as FileReducer } from "../containers/File/store";
import { reducer as SkipTracingReducer } from "../containers/SkipTracing/store";
import { reducer as AccountReducer } from "../containers/Account/store";
import { reducer as ContactReducer } from "../containers/Contact/store";
import { reducer as InvestorFileReducer } from "../containers/InvestorFile/store";
import { reducer as PresetReducer } from "../containers/Preset/store";
import { reducer as ClaritasReducer } from "../containers/Claritas/store";
import { reducer as MarketMapReducer } from "../containers/MarketMap/store";
import { reducer as PresetBuilderReducer } from "../containers/PresetBuilder/store";
import { reducer as DataDeliveryReducer } from "../containers/DataDelivery/store";
import { reducer as RecordManagementReducer } from "../containers/RecordManagement/store";
import { reducer as TicketsReducer } from "../containers/Tickets/store";
import { SharedReducer } from "../shared/store/reducer";

export default () => {
  const appReducer = combineReducers({
    auth: AuthReducer,
    shared: SharedReducer,
    file: FileReducer,
    skipTracing: SkipTracingReducer,
    account: AccountReducer,
    client: ContactReducer,
    investorFile: InvestorFileReducer,
    preset: PresetReducer,
    claritas: ClaritasReducer,
    marketMap: MarketMapReducer,
    presetBuilder: PresetBuilderReducer,
    dataDelivery: DataDeliveryReducer,
    recordManagement: RecordManagementReducer,
    payPerLead: PayPerLeadReducer,
    tickets: TicketsReducer,
  });

  return (state: AppState | undefined, action: AnyAction) => {
    return appReducer(state, action);
  };
};
