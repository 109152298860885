export enum ClientActionTypes {
  GET_CLIENT_LIST = "@@CLIENT/GET_CLIENT_LIST",
  GET_CLIENT_LIST_SUCCESS = "@@CLIENT/GET_CLIENT_LIST_SUCCESS",
  GET_CLIENT_LIST_FAILURE = "@@CLIENT/GET_CLIENT_LIST_FAILURE",

  GET_CLIENT = "@@CLIENT/GET_CLIENT",
  GET_CLIENT_SUCCESS = "@@CLIENT/GET_CLIENT_SUCCESS",
  GET_CLIENT_FAILURE = "@@CLIENT/GET_CLIENT_FAILURE",

  CREATE_CLIENT = "@@CLIENT/CREATE_CLIENT",
  CREATE_CLIENT_SUCCESS = "@@CLIENT/CREATE_CLIENT_SUCCESS",
  CREATE_CLIENT_FAILURE = "@@CLIENT/CREATE_CLIENT_FAILURE",

  UPDATE_CLIENT = "@@CLIENT/UPDATE_CLIENT",
  UPDATE_CLIENT_SUCCESS = "@@CLIENT/UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_FAILURE = "@@CLIENT/UPDATE_CLIENT_FAILURE",

  DELETE_CLIENT = "@@CLIENT/DELETE_CLIENT",
  DELETE_CLIENT_SUCCESS = "@@CLIENT/DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAILURE = "@@CLIENT/DELETE_CLIENT_FAILURE",

  ACTIVATE_CLIENT = "@@CLIENT/ACTIVATE_CLIENT",
  ACTIVATE_CLIENT_SUCCESS = "@@CLIENT/ACTIVATE_CLIENT_SUCCESS",
  ACTIVATE_CLIENT_FAILURE = "@@CLIENT/ACTIVATE_CLIENT_FAILURE",

  GET_STATES = "@@CLIENT/GET_STATES",
  GET_STATES_SUCCESS = "@@CLIENT/GET_STATES_SUCCESS",
  GET_STATES_FAILURE = "@@CLIENT/GET_STATES_FAILURE",

  GET_COUNTIES = "@@CLIENT/GET_COUNTIES",
  GET_COUNTIES_SUCCESS = "@@CLIENT/GET_COUNTIES_SUCCESS",
  GET_COUNTIES_FAILURE = "@@CLIENT/GET_COUNTIES_FAILURE",

  CHANGE_CLIENT_ACCOUNT_STATUS = "@@CLIENT/CHANGE_CLIENT_ACCOUNT_STATUS",
  CHANGE_CLIENT_ACCOUNT_STATUS_SUCCESS = "@@CLIENT/CHANGE_CLIENT_ACCOUNT_STATUS_SUCCESS",
  CHANGE_CLIENT_ACCOUNT_STATUS_FAILURE = "@@CLIENT/CHANGE_CLIENT_ACCOUNT_STATUS_FAILURE",

  CLEAR_CLIENT_LIST = "@@CLIENT/CLEAR_CLIENT_LIST",
  CLEAR_CLIENT = "@@CLIENT/CLEAR_CLIENT",
  CLEAR_STATES = "@@CLIENT/CLEAR_STATES",
  CLEAR_COUNTIES = "@@CLIENT/CLEAR_COUNTIES",

  SET_FILTER = "@@CLIENT/SET_FILTER",
  SET_STATES_FILTER = "@@CLIENT/SET_STATES_FILTER",
  SET_COUNTIES_FILTER = "@@CLIENT/SET_COUNTIES_FILTER",

  CLEAR_ERRORS = "@@CLIENT/CLEAR_ERRORS",
}
