import { ExportZipsToCSVQuery, GetBuyBoxDataQuery, GetZipsQuery, ZipsToCSVQueryParams } from "@shared/interfaces";
import { BuyBoxPresetDto } from "@shared/interfaces/PresetBuilder.interface";
import {
  RequestOwnerDetailsPayload,
  RequestPropertyListPayload,
  RequestPropertyPayload,
  RequestUnlinkPropertyPayload,
  RequestOwnerListPayload,
} from "@shared/interfaces/RecordManagement";

import config from "../../config";

export default {
  AUTH: {
    LOGIN: `${config.baseApiUrl}auth/login`,
    CHECK_USER: `${config.baseApiUrl}auth/user`,
    REGISTER: `${config.baseApiUrl}auth/register`,
    FORGOT_PASSWORD: `${config.baseApiUrl}auth/password/recovery`,
    SET_PASSWORD: `${config.baseApiUrl}auth/password`,
    RESET_PASSWORD: `${config.baseApiUrl}auth/password`,
    REGISTRATION: `${config.baseApiUrl}auth/registration`,
    ACTIVATE: `${config.baseApiUrl}auth/activate`,
    IMPERSONATE: `${config.baseApiUrl}auth/impersonation`,
  },
  USER: {
    GET_USER_DETAILS: `${config.baseApiUrl}users/details`,
    GET_USER: (id: string) => `${config.baseApiUrl}users/${id}`,
    GET_USER_LIST: `${config.baseApiUrl}users`,
    CREATE_USER: `${config.baseApiUrl}users`,
    UPDATE_USER: (id: string) => `${config.baseApiUrl}users/${id}`,
    DELETE_USER: (id: string) => `${config.baseApiUrl}users/${id}`,
    ACTIVATE_USER: (id: string) => `${config.baseApiUrl}users/${id}/activate`,
    CHANGE_USER_STATUS: (id: string) => `${config.baseApiUrl}users/${id}/status`,
  },
  FILE: {
    GET_TEMP_AWS_CREDS: `${config.baseApiUrl}files/aws/credentials`,
    CREATE: `${config.baseApiUrl}files`,
    UPDATE_FILE_MAPPING: (id: string) => `${config.baseApiUrl}files/${id}/mapping`,
    GET_FILE_SIGNED_URL: (id: number) => `${config.baseApiUrl}files/${id}/signed-url`,
    MAP_FILE_TO_ACCOUNT: `${config.baseApiUrl}files/account-file-mapping`,
  },
  SKIP_TRACING: {
    GET_SKIP_TRACING_LIST: `${config.baseApiUrl}skip-tracings`,
    GET_SKIP_TRACING: (id: string) => `${config.baseApiUrl}skip-tracings/${id}`,
    CREATE_SKIP_TRACING: `${config.baseApiUrl}skip-tracings`,
    UPDATE_SKIP_TRACING: (id: string) => `${config.baseApiUrl}skip-tracings/${id}`,
    DELETE_SKIP_TRACING: (id: string) => `${config.baseApiUrl}skip-tracings/${id}`,
    TEST_SKIP_TRACING: (id: string) => `${config.baseApiUrl}skip-tracings/${id}/test`,
    PROCESS_SKIP_TRACING: (id: number) => `${config.baseApiUrl}skip-tracings/${id}/processing`,
    PAY_FOR_SKIP_TRACING: (id: number) => `${config.baseApiUrl}skip-tracings/${id}/payment`,
    GET_PRICE_RANGE: (id: number) => `${config.baseApiUrl}skip-tracings/${id}/price-range`,
    CHECK_STRIPE_COUPON: (id: number, couponId: string) => `${config.baseApiUrl}skip-tracings/${id}/coupon/${couponId}`,
  },
  INVESTOR_FILE: {
    GET_INVESTOR_FILE: (id: string) => `${config.baseApiUrl}investor-files/${id}`,
    GET_INVESTOR_FILE_LIST: `${config.baseApiUrl}investor-files`,
    SEND_INVESTOR_FILE: (id: string) => `${config.baseApiUrl}investor-files/${id}/email`,
    UNSEND_INVESTOR_FILE: (id: string) => `${config.baseApiUrl}investor-files/${id}`,
    CREATE_INVESTOR_FILE: `${config.baseApiUrl}investor-files/file`,
    SEND_INVESTOR_FILE_EMAIL: `${config.baseApiUrl}investor-files/email`,
    SEND_INVESTOR_FILES_EMAIL: `${config.baseApiUrl}investor-files/multiple/email`,
  },

  INVESTOR_SETTINGS: {
    GET_PRESET_LIST: (id: string) => `${config.baseApiUrl}investor-settings/${id}/filters`,
    GET_ES_PRESET_LIST: (id: string) => `${config.baseApiUrl}investor-settings/${id}/es-filters`,
    GET_PRESET: (id: string, filterId: string) => `${config.baseApiUrl}investor-settings/${id}/filters/${filterId}`,
    GET_ES_PRESET: (id: string, filterId: string) =>
      `${config.baseApiUrl}investor-settings/${id}/es-filters/${filterId}`,
    CREATE_PRESET: (id: string) => `${config.baseApiUrl}investor-settings/${id}/filters`,
    CREATE_ES_PRESET: (id: string) => `${config.baseApiUrl}investor-settings/${id}/es-filters`,

    UPDATE_PRESET: (id: string, filterId: string) => `${config.baseApiUrl}investor-settings/${id}/filters/${filterId}`,
    UPDATE_ES_PRESET: (id: string, filterId: string) =>
      `${config.baseApiUrl}investor-settings/${id}/es-filters/${filterId}`,
    COPY_ES_PRESET: (id: string, filterId: string) =>
      `${config.baseApiUrl}investor-settings/${id}/es-filters/${filterId}/copy`,
    DELETE_PRESET: (id: string, filterId: string) => `${config.baseApiUrl}investor-settings/${id}/filters/${filterId}`,
    DELETE_ES_PRESET: (id: string, filterId: string) =>
      `${config.baseApiUrl}investor-settings/${id}/es-filters/${filterId}`,
    GET_FILTER_FIELDS: `${config.baseApiUrl}investor-settings/filter-fields`,
    PROCESS_INVESTOR_FILE: (id: string) => `${config.baseApiUrl}investor-settings/${id}/processings`,
  },
  ACCOUNT: {
    CREATE_ACCOUNT: `${config.baseApiUrl}accounts`,
    UPDATE_ACCOUNT: (id: number) => `${config.baseApiUrl}accounts/${id}`,
    GET_CHECKOUT_URL: (id: number) => `${config.baseApiUrl}accounts/${id}/checkout-url`,
    CHECK_PAYMENT_METHOD_EXISTENCE: (id: number) => `${config.baseApiUrl}accounts/${id}/payment-method`,
    GET_BILLING_PORTAL_URL: (id: number) => `${config.baseApiUrl}accounts/${id}/billing-portal-url`,
    GET_INVOICES: (id: number) => `${config.baseApiUrl}accounts/${id}/invoices`,
    PAY_INVOICE: (accountId: number, invoiceId: number) =>
      `${config.baseApiUrl}accounts/${accountId}/invoices/${invoiceId}/payment`,
    GET_ACCOUNTS_LIST: `${config.baseApiUrl}accounts`,
    GET_ACCOUNT: (id: number) => `${config.baseApiUrl}accounts/${id}`,
    GET_ACCOUNT_PROPERTY_LISTS: (id: number) => `${config.baseApiUrl}accounts/${id}/property-lists`,
    CREATE_ACCOUNT_PROPERTY_LIST: (id: number) => `${config.baseApiUrl}accounts/${id}/property-lists`,
    GET_ACCOUNT_NOTES: (id: number) => `${config.baseApiUrl}accounts/${id}/notes`,
    CREATE_ACCOUNT_NOTE: (id: number) => `${config.baseApiUrl}accounts/${id}/notes`,
    GET_ACCOUNT_ATTEMPTS: (id: number) => `${config.baseApiUrl}accounts/${id}/attempts`,
    SAVE_ACCOUNT_ATTEMPTS: (id: number) => `${config.baseApiUrl}accounts/${id}/attempts`,
    LIST_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE: (id: number) =>
      `${config.baseApiUrl}accounts/${id}/property-lists/link-to-properties-from-file`,
    LIST_ACCOUNT_PROPERTY_LIST_TO_PROPERTY: (id: number) =>
      `${config.baseApiUrl}accounts/${id}/property-lists/link-to-property`,
    GET_FEATURES: (id: number) => `${config.baseApiUrl}accounts/${id}/features`,
    DOWNLOAD_CSV_PROPERTY_RECORDS: (id: number) => `${config.baseApiUrl}accounts/${id}/download-property-records`,
    CHECK_ACCOUNT: `${config.baseApiUrl}accounts/check`,
    ACTIVATE: (id: number) => `${config.baseApiUrl}accounts/${id}/activate`,
    DEACTIVATE: (id: number) => `${config.baseApiUrl}accounts/${id}/deactivate/`,
    GET_SUBSCRIPTION: (id: number) => `${config.baseApiUrl}accounts/subscription/${id}`,
    UPDATE_SUBSCRIPTION: (id: number) => `${config.baseApiUrl}accounts/subscription/${id}`,
    ACCOUNT_PAYMENT: (id: number) => `${config.baseApiUrl}accounts/${id}/payment`,
    GET_ACCOUNT_INVOICE: (id: number, invoiceId: number) => `${config.baseApiUrl}accounts/${id}/invoices/${invoiceId}`,
  },
  HUBSPOT_HISTORY: {
    GET_HUBSPOT_HISTORY_LIST: `${config.baseApiUrl}hubspot-properties-history`,
    UPDATE_HUBSPOT_HISTORY_ITEM_STATUS: (id: string) => `${config.baseApiUrl}hubspot-properties-history/${id}`,
  },
  STATE: {
    GET_STATES_LIST: `${config.baseApiUrl}states`,
  },
  COUNTY: {
    GET_COUNTIES_LIST: `${config.baseApiUrl}counties`,
    GET_COUNTY_ZIPS: `${config.baseApiUrl}counties/zips`,
    GET_COUNTY_NEIGHBORHOOD_LIST: (id: number) => `${config.baseApiUrl}counties/${id}/neighborhoods`,
  },
  CLARITAS: {
    GET_VISITORS: (id: string) => `${config.baseApiUrl}claritas/${id}/visitors`,
    GET_STATISTICS: (id: string) => `${config.baseApiUrl}claritas/${id}/statistics`,
    GET_VISITORS_FILE: (id: string) => `${config.baseApiUrl}claritas/${id}/visitors/file`,
  },
  PAY_PER_LEAD: {
    GET_LEADS: `${config.baseApiUrl}leads`,
    DISPUTE_LEAD: `${config.baseApiUrl}leads/disputes`,
    GET_LEAD_BY_ID: (id: string) => `${config.baseApiUrl}leads/${id}`,
    GET_LEAD_PRICE: `${config.baseApiUrl}leads/price`,
    BATCH_QUALIFY_LEADS: `${config.baseApiUrl}leads/batch/qualify`,
    BATCH_EXPORT_LEADS: `${config.baseApiUrl}leads/batch/export`,
    GET_SUBSCRIPTIONS_LIST: `${config.baseApiUrl}leads/subscriptions`,
    GET_SUBSCRIPTION: (id: number) => `${config.baseApiUrl}leads/subscriptions/${id}`,
    CREATE_SUBSCRIPTION: `${config.baseApiUrl}leads/subscriptions`,
    UPDATE_SUBSCRIPTION: `${config.baseApiUrl}leads/subscriptions`,
    PURCHASE_SUBSCRIPTION: (id: number) => `${config.baseApiUrl}leads/subscriptions/${id}/purchase`,
    // admin section
    GET_ADMIN_CUSTOMERS: `${config.baseApiUrl}leads/customers`,
    GET_ADMIN_DISPUTES: `${config.baseApiUrl}leads/disputes`,
    UPDATE_ADMIN_DISPUTES: (disputeId: number) => `${config.baseApiUrl}leads/disputes/${disputeId}`,
    UPDATE_LEAD_QUALITY: `${config.baseApiUrl}leads/batch/qualify`,
    GET_STATISTICS: `${config.baseApiUrl}leads/statistics`,
    GET_FILTER_OPTIONS: `${config.baseApiUrl}leads/filter-options`,
  },
  MARKET_MAP: {
    GET_ZIP_CODES: (accountId: number, fip: string, zipsOnly: boolean) => {
      if (zipsOnly) {
        return `${config.baseApiUrl}accounts/${accountId}/counties/${fip}/zips`;
      } else {
        const query = new URLSearchParams({ zipsOnly: "false" });
        return `${config.baseApiUrl}accounts/${accountId}/counties/${fip}/zips?${query}`;
      }
    },
    GET_COUNTY_GEO_DATA: (code: string) => `${config.baseApiUrl}counties/geo/${code}`,
    GET_COUNTY_NEIGHBORHOODS_GEO_DATA: (code: string) => `${config.baseApiUrl}counties/${code}/geo/neighborhoods`,
    GET_COUNTY_NEIGHBORHOOD_METRICS_BY_ES_ID: (esId: string) =>
      `${config.baseApiUrl}counties/metrics/neighborhood/${esId}`,
    GET_ZIP_METRICS: (accountId: number, fips: string, zipCode: string) =>
      `${config.baseApiUrl}accounts/${accountId}/counties/${fips}/zips/${zipCode}/metrics`,

    GET_COUNTY_METRICS: (accountId: number, fips: string) =>
      `${config.baseApiUrl}accounts/${accountId}/counties/${fips}/metrics`,

    GET_ZIPS_TABLE: ({ accountID, from, size, state, fips, zip }: GetZipsQuery) => {
      const filters: { fips?: string; zip?: string } = {};

      if (fips) filters.fips = fips;
      if (zip) filters.zip = zip;

      const query = new URLSearchParams({
        from: from.toString(),
        size: size.toString(),
        state,
        ...filters,
      });

      return `${config.baseApiUrl}accounts/${accountID}/zips-paginated?${query}`;
    },
    GET_NEIGHBORHOODS_TABLE: (accountId: number) => `${config.baseApiUrl}accounts/${accountId}/neighborhoods-paginated`,
    GET_FILTERED_BUY_BOX_DATA: ({ fips, accountID, ...filters }: GetBuyBoxDataQuery) => {
      const query = new URLSearchParams({ fips });

      for (const [key, value] of Object.entries(filters)) {
        value.forEach((value) => {
          query.append(key, value.toString());
        });
      }

      if (accountID) {
        query.append("account", accountID.toString());
      }

      return `${config.baseApiUrl}buy-box/${fips}/?${query}`;
    },

    GET_PRESET_BUY_BOX_DATA: (filters: BuyBoxPresetDto) => {
      const query = new URLSearchParams();

      for (const [key, value] of Object.entries(filters)) {
        if (Array.isArray(value)) {
          value.length && query.append(key, value.join(","));
        } else {
          if (typeof value === "boolean") {
            query.append(key, value.toString());
          } else {
            value && query.append(key, value);
          }
        }
      }
      return `${config.baseApiUrl}buy-box/presets?${query}`;
    },
    EXPORT_ZIPS_TO_CSV: ({ accountID, state, fips, zip }: ExportZipsToCSVQuery) => {
      const queryParams: ZipsToCSVQueryParams = {
        state: state.toString(),
      };

      if (fips) queryParams.fips = fips.toString();
      if (zip) queryParams.zip = zip.toString();

      const query = new URLSearchParams(queryParams as unknown as Record<string, string>);

      return `${config.baseApiUrl}accounts/${accountID}/zips-table-csv?${query}`;
    },
    EXPORT_NEIGHBORHOODS_ZIPS_TO_CSV: (accountId: number) =>
      `${config.baseApiUrl}accounts/${accountId}/neighborhoods-table-csv`,
  },
  RECORD_MANAGEMENT: {
    GET_PROPERTY_LIST: ({ accountId }: RequestPropertyListPayload) => {
      return `${config.baseApiUrl}accounts/${accountId}/properties`;
    },
    GET_PROPERTY: ({ accountId, propertyId }: RequestPropertyPayload) => {
      return `${config.baseApiUrl}accounts/${accountId}/properties/${propertyId}`;
    },
    GET_PROPERTY_TYPES: `${config.baseApiUrl}property-types`,
    UNLINK_PROPERTY: ({ accountId }: Pick<RequestUnlinkPropertyPayload, "accountId">) => {
      return `${config.baseApiUrl}accounts/${accountId}/property-lists/unlink-property`;
    },
    GET_OWNER_LIST: ({ accountId }: RequestOwnerListPayload) => {
      return `${config.baseApiUrl}accounts/${accountId}/owners`;
    },
    GET_OWNER_DETAILS: ({ accountId, ownerName }: RequestOwnerDetailsPayload) => {
      return `${config.baseApiUrl}accounts/${accountId}/owners/${ownerName}/details`;
    },
    GET_OWNER_PROPERTIES: ({ accountId, ownerName }: RequestOwnerDetailsPayload) => {
      return `${config.baseApiUrl}accounts/${accountId}/owners/${ownerName}/properties`;
    },
  },
  TICKETS: {
    GET_TICKET_LIST: `${config.baseApiUrl}tickets`,
    UPDATE_TICKET: (id: number) => `${config.baseApiUrl}tickets/${id}`,
  },
};
