import React from "react";
import Progress from "@shared/components/Progress";

export enum ProgressScreenStatus {
  Processing = "processing",
  Completed = "completed",
}

export type ProgressScreenProps = {
  title?: string;
  subtitle?: string;
  currentStep?: number;
  totalSteps?: number;
  status?: ProgressScreenStatus;
  children?: React.ReactNode;
  onGoBack?: () => void;
};

export const ProgressScreen: React.FC<ProgressScreenProps> = (props) => {
  const { title, subtitle, status, currentStep, totalSteps, children, onGoBack } = props;

  const progressStateComponents = {
    processing: <Progress.StatusProcessing />,
    completed: <Progress.StatusComplete />,
  };

  const progressContent = status ? progressStateComponents[status] : children;

  return (
    <Progress>
      <Progress.StatusBar currentStep={currentStep} totalSteps={totalSteps} />

      <Progress.Header>
        <Progress.GoBackButton onClick={onGoBack} />
        <Progress.Title>{title}</Progress.Title>
        <Progress.Subtitle>{subtitle}</Progress.Subtitle>
      </Progress.Header>

      <Progress.Content>{progressContent}</Progress.Content>
    </Progress>
  );
};
