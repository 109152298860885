import React, { FC } from "react";

import "./index.scss";

interface InformationBlockProps {
  text: string | null;
}

const InformationBlock: FC<InformationBlockProps> = (props) => {
  const { text } = props;
  return text ? (
    <div className="information-wrapper">
      <div className="information-block">
        <div className="information-text">
          <div>{text}</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default InformationBlock;
