import OLMap from "ol/Map";
import { Feature } from "ol";
import VectorLayer from "ol/layer/Vector";
import { VectorLayerType } from "@shared/components/MapContainer/OLMap/types";
import { Vector as VectorSource } from "ol/source";

class MapUtilized extends OLMap {
  protected areasLayer: VectorLayerType | null = null;

  rerender(features: Feature[]) {
    if (this.areasLayer) {
      this.removeLayer(this.areasLayer);
    }

    this.areasLayer = new VectorLayer({
      source: new VectorSource({
        features: features,
      }),
    });

    this.addLayer(this.areasLayer);
  }
}

export default MapUtilized;
