export enum SkipTracingActionTypes {
  GET_SKIP_TRACING = "@@SKIP_TRACING/GET_SKIP_TRACING",
  GET_SKIP_TRACING_SUCCESS = "@@SKIP_TRACING/GET_SKIP_TRACING_SUCCESS",
  GET_SKIP_TRACING_FAILURE = "@@SKIP_TRACING/GET_SKIP_TRACING_FAILURE",

  GET_SKIP_TRACING_LIST = "@@SKIP_TRACING/GET_SKIP_TRACING_LIST",
  GET_SKIP_TRACING_LIST_SUCCESS = "@@SKIP_TRACING/GET_SKIP_TRACING_LIST_SUCCESS",
  GET_SKIP_TRACING_LIST_FAILURE = "@@SKIP_TRACING/GET_SKIP_TRACING_LIST_FAILURE",

  CREATE_SKIP_TRACING = "@@SKIP_TRACING/CREATE_SKIP_TRACING",
  CREATE_SKIP_TRACING_SUCCESS = "@@SKIP_TRACING/CREATE_SKIP_TRACING_SUCCESS",
  CREATE_SKIP_TRACING_FAILURE = "@@SKIP_TRACING/CREATE_SKIP_TRACING_FAILURE",

  UPDATE_SKIP_TRACING = "@@SKIP_TRACING/UPDATE_SKIP_TRACING",
  UPDATE_SKIP_TRACING_SUCCESS = "@@SKIP_TRACING/UPDATE_SKIP_TRACING_SUCCESS",
  UPDATE_SKIP_TRACING_FAILURE = "@@SKIP_TRACING/UPDATE_SKIP_TRACING_FAILURE",

  DELETE_SKIP_TRACING = "@@SKIP_TRACING/DELETE_SKIP_TRACING",
  DELETE_SKIP_TRACING_SUCCESS = "@@SKIP_TRACING/DELETE_SKIP_TRACING_SUCCESS",
  DELETE_SKIP_TRACING_FAILURE = "@@SKIP_TRACING/DELETE_SKIP_TRACING_FAILURE",

  TEST_SKIP_TRACING = "@@SKIP_TRACING/TEST_SKIP_TRACING",
  TEST_SKIP_TRACING_SUCCESS = "@@SKIP_TRACING/TEST_SKIP_TRACING_SUCCESS",
  TEST_SKIP_TRACING_FAILURE = "@@SKIP_TRACING/TEST_SKIP_TRACING_FAILURE",

  PROCESS_SKIP_TRACING = "@@SKIP_TRACING/PROCESS_SKIP_TRACING",
  PROCESS_SKIP_TRACING_SUCCESS = "@@SKIP_TRACING/PROCESS_SKIP_TRACING_SUCCESS",
  PROCESS_SKIP_TRACING_FAILURE = "@@SKIP_TRACING/PROCESS_SKIP_TRACING_FAILURE",

  PAY_FOR_SKIP_TRACING = "@@SKIP_TRACING/PAY_FOR_SKIP_TRACING",
  PAY_FOR_SKIP_TRACING_SUCCESS = "@@SKIP_TRACING/PAY_FOR_SKIP_TRACING_SUCCESS",
  PAY_FOR_SKIP_TRACING_FAILURE = "@@SKIP_TRACING/PAY_FOR_SKIP_TRACING_FAILURE",

  GET_PRICE_RANGE = "@@SKIP_TRACING/GET_PRICE_RANGE",
  GET_PRICE_RANGE_SUCCESS = "@@SKIP_TRACING/GET_PRICE_RANGE_SUCCESS",
  GET_PRICE_RANGE_FAILURE = "@@SKIP_TRACING/GET_PRICE_RANGE_FAILURE",

  CHECK_STRIPE_COUPON = "@@SKIP_TRACING/CHECK_STRIPE_COUPON",
  CHECK_STRIPE_COUPON_SUCCESS = "@@SKIP_TRACING/CHECK_STRIPE_COUPON_SUCCESS",
  CHECK_STRIPE_COUPON_FAILURE = "@@SKIP_TRACING/CHECK_STRIPE_COUPON_FAILURE",

  CLEAR_SKIP_TRACING_LIST = "@@SKIP_TRACING/CLEAR_SKIP_TRACING_LIST",
  CLEAR_SKIP_TRACING = "@@SKIP_TRACING/CLEAR_SKIP_TRACING",
  CLEAR_STRIPE_COUPON = "@@SKIP_TRACING/CLEAR_STRIPE_COUPON",
  SET_FILTER = "@@SKIP_TRACING/SET_FILTER",

  CLEAR_ERRORS = "@@SKIP_TRACING/CLEAR_ERRORS",
}
