import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { ORDER_COLUMN_TYPE, ORDER_TYPE } from "@shared/interfaces";

import * as actions from "./actions";
import { ClaritasError, ClaritasStateType } from "../interface";

const defaultFilter = {
  limit: 25,
  page: 1,
  order: ORDER_COLUMN_TYPE.CRITERIA_V2,
  direction: ORDER_TYPE.DESC,
};

const initialState: ClaritasStateType = {
  visitors: null,
  statistics: null,
  filter: { ...defaultFilter },
  errors: {
    [ClaritasError.getClaritasVisitorsError]: null,
    [ClaritasError.getClaritasStatisticsError]: null,
  },
};

const reducer = createReducer<ClaritasStateType, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getVisitors.success, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        const { items, total, clear } = action.payload;
        nextState.visitors = {
          items: clear || nextState.visitors === null ? [...items] : [...nextState.visitors.items, ...items],
          total,
        };
      } else {
        nextState.visitors = action.payload;
      }
      nextState.errors[ClaritasError.getClaritasVisitorsError] = null;
    }),
  )
  .handleAction(actions.getVisitors.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClaritasError.getClaritasVisitorsError] = action.payload;
    }),
  )
  .handleAction(actions.getStatistics.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.statistics = action.payload;
      nextState.errors[ClaritasError.getClaritasStatisticsError] = null;
    }),
  )
  .handleAction(actions.getStatistics.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClaritasError.getClaritasStatisticsError] = action.payload;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || defaultFilter;
    }),
  )
  .handleAction(actions.clearErrors, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        action.payload.forEach((err) => {
          nextState.errors[err] = null;
        });
      } else {
        nextState.errors = initialState.errors;
      }
    }),
  );

export { reducer as ClaritasReducer };
