import { API, METHODS } from "@shared/constants";
import { request } from "@shared/utils";

import { GetTicketListRequest, UpdateTicketRequest } from "../interface";

export default {
  getTicketList(params: GetTicketListRequest) {
    return request(METHODS.GET, API.TICKETS.GET_TICKET_LIST)({ params });
  },
  updateTicket({ id, ...params }: UpdateTicketRequest) {
    return request(METHODS.PATCH, API.TICKETS.UPDATE_TICKET(id))(params);
  },
};
