import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import * as actions from "./actions";
import { FileError, FileStateType } from "../interface";

const initialState: FileStateType = {
  file: null,
  mappedToAccountFiles: [],
  errors: {
    [FileError.createFileError]: null,
    [FileError.mapFilesToAccountError]: null,
  },
};

const reducer = createReducer<FileStateType, ActionType<typeof actions>>(initialState)
  .handleAction(actions.createFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.file = action.payload;
    }),
  )
  .handleAction(actions.createFile.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[FileError.createFileError] = action.payload;
    }),
  )
  .handleAction(actions.updateFileMapping.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.file = action.payload;
    }),
  )
  .handleAction(actions.updateFileMapping.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[FileError.createFileError] = action.payload;
    }),
  )
  .handleAction(actions.mapFilesToAccount.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.mappedToAccountFiles = action.payload;
    }),
  )
  .handleAction(actions.mapFilesToAccount.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[FileError.mapFilesToAccountError] = action.payload;
    }),
  )
  .handleAction(actions.clearFile, (state) =>
    produce(state, (nextState) => {
      nextState.file = null;
    }),
  )
  .handleAction(actions.clearErrors, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        action.payload.forEach((err) => {
          nextState.errors[err] = null;
        });
      } else {
        nextState.errors = initialState.errors;
      }
    }),
  );

export { reducer as FileReducer };
