import { AnyType } from "@shared/interfaces";
import { Neighborhood } from "@shared/components/MapContainer/OLMap/features/Neighborhood";

import MapFocusFeature from "./MapFocusFeature";

class MapFeatured extends MapFocusFeature {
  addFeatureTooltip(tooltipId: string) {
    this.on("pointermove", (evt) => {
      const info = document.getElementById(tooltipId);

      if (!info) {
        return;
      }

      if (evt.dragging) {
        info.style.visibility = "hidden";
        return;
      }

      const pixel = this.getEventPixel(evt.originalEvent);

      const target = evt.originalEvent.target;

      const feature = target.closest(".ol-control")
        ? undefined
        : this.forEachFeatureAtPixel(pixel, (feature) => feature);

      if (feature instanceof Neighborhood && feature.name) {
        const [x, y] = pixel;

        info.style.left = `${x + 8}px`;
        info.style.top = `${y + 16}px`;
        info.style.visibility = "visible";
        info.innerText = feature.name;
      } else {
        info.style.visibility = "hidden";
      }
    });

    this.getTargetElement().addEventListener("pointerleave", () => {
      const info = document.getElementById(tooltipId);

      if (info) {
        info.style.visibility = "hidden";
      }
    });

    return this;
  }

  addNeighborhoodSelectedListener(listener: (id: string) => AnyType) {
    this.on("click", (event) => {
      const feature = this.getFeaturesAtPixel(event.pixel)?.[0];
      if (feature instanceof Neighborhood) {
        listener(feature.id);
      }
    });

    return this;
  }
}

export default MapFeatured;
