export enum ClaritasActionTypes {
  GET_VISITORS = "@@CLARITAS/GET_VISITORS",
  GET_VISITORS_SUCCESS = "@@CLARITAS/GET_VISITORS_SUCCESS",
  GET_VISITORS_FAILURE = "@@CLARITAS/GET_VISITORS_FAILURE",

  GET_STATISTICS = "@@CLARITAS/GET_STATISTICS",
  GET_STATISTICS_SUCCESS = "@@CLARITAS/GET_STATISTICS_SUCCESS",
  GET_STATISTICS_FAILURE = "@@CLARITAS/GET_STATISTICS_FAILURE",

  GET_VISITORS_FILE = "@@CLARITAS/GET_VISITORS_FILE",
  GET_VISITORS_FILE_SUCCESS = "@@CLARITAS/GET_VISITORS_FILE_SUCCESS",
  GET_VISITORS_FILE_FAILURE = "@@CLARITAS/GET_VISITORS_FILE_FAILURE",

  SET_FILTER = "@@CLARITAS/SET_FILTER",
  CLEAR_ERRORS = "@@CLARITAS/CLEAR_ERRORS",
}
