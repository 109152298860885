import React, { useEffect } from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authentificated, selectRedirectTo } from "@containers/Auth/store/selectors";
import { setRedirectTo } from "@containers/Auth/store/actions";
import { selectors as SharedSelectors } from "@shared/store";
import { checkIfAdmin } from "@shared/utils/ACL";

import { NameOfChildRoutes } from "../constants";

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const currentUser = useSelector(SharedSelectors.getUserDetails());
  const isAuthenticated = useSelector(authentificated());
  const redirectTo = useSelector(selectRedirectTo());
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      setTimeout(() => {
        dispatch(setRedirectTo(null));
      }, 0);
    },
    [dispatch],
  );

  if (isAuthenticated && currentUser) {
    return (
      <Navigate
        to={
          checkIfAdmin(currentUser) ? NameOfChildRoutes.CONTACT.ROOT : redirectTo || NameOfChildRoutes.SKIP_TRACING.ROOT
        }
      />
    );
  }

  return <>{children}</>;
};

export default GuestGuard;
