import { createAsyncAction } from "typesafe-actions";

import { TICKETS_ACTIONS } from "./constants";
import {
  GetTicketListRequest,
  GetTicketListSuccess,
  GetTicketListFailure,
  UpdateTicketRequest,
  UpdateTicketSuccess,
  UpdateTicketFailure,
} from "../interface/TicketActions";

export const getTicketList = createAsyncAction(
  TICKETS_ACTIONS.GET_TICKETS_REQUEST,
  TICKETS_ACTIONS.GET_TICKETS_SUCCESS,
  TICKETS_ACTIONS.GET_TICKETS_FAILURE,
)<GetTicketListRequest, GetTicketListSuccess, GetTicketListFailure>();

export const updateTicket = createAsyncAction(
  TICKETS_ACTIONS.UPDATE_TICKET_REQUEST,
  TICKETS_ACTIONS.UPDATE_TICKET_SUCCESS,
  TICKETS_ACTIONS.UPDATE_TICKET_FAILURE,
)<UpdateTicketRequest, UpdateTicketSuccess, UpdateTicketFailure>();
