import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, SelectPickerInput } from "@shared/components";
import { Preset } from "@shared/models";
import { Option } from "@shared/interfaces";

import "./index.scss";

interface ModalConfirmProps {
  heading?: string;
  content?: string;
  data?: Preset[];
  onClose: () => void;
  onSuccess: (presetId: string) => void;
}

const ModalGenerateFile: FC<ModalConfirmProps> = (props) => {
  const { heading, content, onClose, onSuccess, data } = props;

  const [presetOptions, setPresetOptions] = useState<Option<string>[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      const options = data
        .map((preset) => ({
          label: preset.name,
          value: String(preset.id),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setPresetOptions(options);
    }
  }, [data]);

  const onGenerateFileButtonClickHandler = useCallback(() => {
    if (!selectedPreset) {
      return setErrorMessage("Please select the preset");
    } else {
      onSuccess(selectedPreset);
    }
  }, [onSuccess, selectedPreset]);

  return (
    <div className="generate-file-modal-content-wrapper">
      <div className="generate-file-modal-heading">{heading}</div>
      <div className="generate-file-modal-content">{content}</div>
      <div className="generate-file-modal-select">
        <SelectPickerInput
          data={presetOptions}
          searchable
          name="preset-select"
          cleanable={false}
          placeholder="Select preset"
          onChange={(value) => {
            if (value) {
              setErrorMessage(null);
            }
            setSelectedPreset(value);
          }}
          value={selectedPreset}
        />
      </div>
      <div className="generate-file-modal-error">{errorMessage}</div>
      <div className="generate-file-modal-actions">
        <div className="generate-file-modal-actions-btn-block">
          <Button className="generate-file-modal-close-btn" buttonStyle="blue-bordered" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="generate-file-modal-success-btn"
            type="submit"
            buttonStyle="accent-green"
            onClick={onGenerateFileButtonClickHandler}
          >
            Generate File
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ModalGenerateFile;
