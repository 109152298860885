import React, { useEffect, useMemo, useState, useCallback } from "react";
import { MenuItem } from "@shared/interfaces";
import { NavLink, useLocation } from "react-router-dom";
import { AccessControl } from "@shared/components/AccessControl";
import { useSelector } from "react-redux";
import { selectors as sharedSelectors } from "@shared/store";
import { getActiveAccount } from "@containers/Account/store/selectors";
import SidebarItems from "@shared/components/Sidebar/components/SidebarItems/SidebarItems";
import classnames from "classnames";

import "./index.scss";

interface SidebarItemProps {
  item: MenuItem;
  className: string | undefined;
}

const SidebarItem = (props: SidebarItemProps) => {
  const { item, className } = props;

  const { pathname } = useLocation();

  const { path, name, icon, permission, feature, onClick, hideForAdmin, childItems } = item;

  const isNestedMenuActive = useCallback(
    (pathname: string) => {
      return !!childItems?.some(({ path }) => pathname.includes(path as string));
    },
    [childItems],
  );

  const [isChildMenuOpen, setIsChildMenuOpen] = useState<boolean>(isNestedMenuActive(pathname));

  useEffect(() => {
    setIsChildMenuOpen(isNestedMenuActive(pathname));
  }, [pathname, isNestedMenuActive]);

  const handleToggle = (evt: React.MouseEvent) => {
    evt.preventDefault();

    setIsChildMenuOpen((prevState) => !prevState);
  };

  const user = useSelector(sharedSelectors.getUserDetails());
  const account = useSelector(getActiveAccount());

  const renderOnclick = item.renderOnclick?.(user, account);

  const menuItem = useMemo(() => {
    const itemContent = (
      <>
        <div className="item-icon">{icon}</div>
        <div className="item-name">{name}</div>
      </>
    );

    if (path && !renderOnclick) {
      return <NavLink to={path}>{itemContent}</NavLink>;
    }

    if (onClick) {
      return <a onClick={onClick}>{itemContent}</a>;
    }
    return null;
  }, [icon, name, onClick, path, renderOnclick]);

  let content = <div className={`sidebar-item ${className} ${item.className || ""}`}>{menuItem}</div>;

  if (childItems?.length) {
    content = (
      <>
        <div className={`sidebar-item ${className} ${item.className || ""}`}>
          <a onClick={handleToggle}>
            <div className="item-icon">{icon}</div>
            <div className="item-name">{name}</div>
          </a>
        </div>
        {isChildMenuOpen && (
          <SidebarItems items={childItems} className={classnames(className, item.className, `sidebar-item-children`)} />
        )}
      </>
    );
  }

  return (
    <AccessControl permission={permission} feature={feature} hideForAdmin={hideForAdmin}>
      {content}
    </AccessControl>
  );
};

export default SidebarItem;
