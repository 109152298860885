import { call, debounce, put, select, takeLatest } from "redux-saga/effects";
import { Account, Preset, User } from "@shared/models";
import { getUserDetails } from "@shared/store/selectors";
import {
  ActionWithPayload,
  BuyBoxMetrics,
  FetchZips,
  ResponseError,
  WithCallback,
  ZipsExpanded,
  ZipsListResponse,
} from "@shared/interfaces";
import { extractStatesAndCounties } from "@containers/MarketMap/components/FilterBar/util";
import { showHttpErrorNotification } from "@shared/utils";
import {
  BuyBoxPresetDto,
  ESPresetDto,
  ESPresetResponse,
  PresetBuilderState,
  PresetFilters,
} from "@shared/interfaces/PresetBuilder.interface";
import { filterChange, getBuyBoxData } from "@containers/PresetBuilder/store/actions";
import { showNotification, startLoading, stopLoading } from "@shared/store/actions";
import { PresetBuilderLocationFilters } from "@containers/Preset/interfaces/Preset.interface";
import { getSelectedAccount } from "@containers/Account/store/selectors";
import { getErrMessage, parsePresetError } from "@containers/PresetBuilder/util";
import { WithFilterId, WithInvestorSettingsId } from "@containers/PresetBuilder/api/api";

import { copyPreset, createPreset, deletePreset, getPreset, getPresetList, getZips, updatePreset } from "./actions";
import { getPresetBuilder } from "./selectors";
import api from "../../MarketMap/api";
import presetAPI from "../api";

async function fetchZips({
  accountID,
  fips,
  zipsOnly = true,
}: {
  accountID: number;
  fips: string;
  zipsOnly?: boolean;
}) {
  try {
    const response: ZipsListResponse = await api.getZips({ accountID, county: fips, zipsOnly });
    return {
      zips: response?.zips ?? [],
      dropDown: response.zips.map((zip) => ({ value: zip, label: zip })),
    } as FetchZips;
  } catch (error) {
    return {
      zips: [],
      dropDown: [],
    } as FetchZips;
  }
}

function* onFilterChange({ payload }: ReturnType<typeof filterChange.request>) {
  const { name, value } = payload;

  const user: User = yield select(getUserDetails());
  const selectedAccount: Account | null = yield select(getSelectedAccount());
  const presetState: PresetBuilderState = yield select(getPresetBuilder());

  if (!user || !selectedAccount) return;

  const { states, counties } = extractStatesAndCounties(selectedAccount);

  if (!selectedAccount) return;

  switch (name) {
    case "states": {
      if (!value || isNaN(value as number)) return;
      const state = states.find((state) => state.id === value);
      if (!state) return;
      const newStates = [...presetState.filterState.states, { ...state }];
      const stateIDs = newStates.map((state) => state.id);
      // TODO rewrite this code
      const stateCounties = counties.filter((county) => stateIDs.includes(county.state.id));
      let newCounties = [...presetState.filterState.counties, ...stateCounties];
      // TODO rewrite this code
      newCounties = newCounties.filter(
        (county, index, self) => index === self.findIndex((c) => c.code === county.code && c.name === county.name),
      );

      if (stateCounties.length > 0) {
        yield put(filterChange.success({ name, value: newStates, counties: newCounties }));
      } else {
        yield put(filterChange.success({ name, value: newStates }));
      }
      break;
    }
    case "counties": {
      const county = counties.find((county) => county.code === value);
      if (county) {
        const newCounties = [...presetState.filterState.counties, county];
        yield put(startLoading());
        const zipsResponse: FetchZips = yield call(fetchZips, {
          accountID: selectedAccount.id,
          fips: newCounties.map(({ code }) => code).join(","),
          zipsOnly: false,
        });
        yield put(filterChange.success({ name, value: newCounties, zipsResponse }));
      } else {
        yield put(filterChange.success({ name, value: null, zipsResponse: { zips: [], dropDown: [] } }));
      }
      break;
    }
    case "zips": {
      yield put(filterChange.success({ name, value }));
      break;
    }
    default: {
      const fieldName = name as keyof PresetFilters;
      yield put(filterChange.success({ name: fieldName, value }));
    }
  }

  yield put(stopLoading());
}

function* setupFilters() {
  const user: User = yield select(getUserDetails());
  const selectedAccount: Account | null = yield select(getSelectedAccount());

  if (!user || !selectedAccount) return;

  const { states } = extractStatesAndCounties(selectedAccount);

  if (!states) return;

  try {
    const filters: PresetBuilderLocationFilters = {
      states: states.map((state) => ({ value: state.id + "", label: state.name })),
      counties: [],
      zips: [],
    };

    yield put(getZips.success({ filters: { ...filters } }));
  } catch (error) {
    console.info("ERR", error);
  }
}

function* fetchBuyBoxData() {
  const state: PresetBuilderState = yield select(getPresetBuilder());

  const hasFiltersLoaded = state.filters.counties.length > 0;
  const hasSelectedCounties = state.filterState.counties.length;

  if (!hasFiltersLoaded) return;

  if (!hasSelectedCounties) {
    yield put(showNotification({ message: "You need to select county", appearance: "error" }));
    return;
  }

  try {
    const response: BuyBoxMetrics = yield call(api.getPresetBuyBoxData, {
      ...state.preset.filters,
      counties: state.filterState.counties.map((county) => county.code),
      states: state.filterState.states.map((state) => state.abbreviation),
      zips: state.filterState.zips.map((zip) => zip.zip),
      property_zips: state.filterState.property_zips.map((zip) => zip.zip),
    } as BuyBoxPresetDto);

    delete response.query;

    yield put(getBuyBoxData.success(response));
  } catch (error) {
    const err = error as ResponseError;
    yield put(getBuyBoxData.failure(err));
    yield put(
      showNotification({ message: parsePresetError(err.message) ?? "Could not fetch data", appearance: "error" }),
    );
  }
}

function* createPresetSaga({
  payload,
}: ActionWithPayload<
  ESPresetDto & {
    investorSettingsId: number;
    filterId: number;
    callback?: (presetId?: string) => void;
  }
>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: ESPresetResponse = yield call(presetAPI.createPreset, rest);
    delete response.ESQuery;
    yield put(createPreset.success(response));
    if (callback) {
      callback(String(response.id));
    }
  } catch (error) {
    yield put(createPreset.failure(error as Error));

    yield put(showHttpErrorNotification(error as ResponseError, parsePresetError((error as Error).message)));
  } finally {
    yield put(stopLoading());
  }
}

function* updatePresetSaga({
  payload,
}: ActionWithPayload<WithCallback<ESPresetDto & { investorSettingsId: number; filterId: number }>>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: ESPresetResponse = yield call(presetAPI.updatePreset, rest);
    delete response.ESQuery;
    yield put(updatePreset.success(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(updatePreset.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError, parsePresetError((error as Error).message)));
  } finally {
    yield put(stopLoading());
  }
}

function* copyPresetSaga({
  payload,
}: ActionWithPayload<WithCallback<WithInvestorSettingsId & WithFilterId, ESPresetResponse>>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: ESPresetResponse = yield call(presetAPI.copyPreset(rest));

    yield copyPreset.success(response);

    if (callback) {
      callback(response);
    }

    yield put(getPresetList.request({ investorSettingsId: payload.investorSettingsId }));
  } catch (error) {
    yield copyPreset.failure(error as ResponseError);
    yield put(showHttpErrorNotification(error as ResponseError, getErrMessage(error as Error)));
  } finally {
    yield put(stopLoading());
  }
}

function* deletePresetSaga({
  payload,
}: ActionWithPayload<WithCallback<{ investorSettingsId: number; filterId: number }>>) {
  try {
    yield put(startLoading());
    yield call(presetAPI.deletePreset, payload);
    yield put(deletePreset.success());
    payload?.callback?.();

    yield put(getPresetList.request({ investorSettingsId: payload.investorSettingsId }));
  } catch (error) {
    yield put(deletePreset.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getPresetSaga({ payload }: ReturnType<typeof getPreset.request>) {
  const { callback, hideLoader, ...rest } = payload;
  try {
    if (!hideLoader) {
      yield put(startLoading());
    }

    const user: User = yield select(getUserDetails());
    const selectedAccount: Account | null = yield select(getSelectedAccount());
    if (!user) return;

    if (!selectedAccount) return;

    const { states, counties } = extractStatesAndCounties(selectedAccount);

    const response: ESPresetResponse = yield call(presetAPI.getPreset, rest);

    const filters: PresetBuilderLocationFilters = {
      states: states.map((state) => ({ value: state.id + "", label: state.name })),
      counties: [],
      zips: [],
    };

    if (response.counties && response.counties.length > 0) {
      const filterState: PresetBuilderState["filterState"] = {
        states: states
          .filter((state) => response.states?.includes(`${state.abbreviation}`))
          .map((state) => ({ value: state.id + "", label: state.name, ...state })),
        counties: counties
          .filter((county) => response.counties?.includes(county.code))
          .map((county) => ({ value: county.name, label: county.code, ...county })),
        zips: [],
        property_zips: [],
      };

      filters.counties = counties
        .filter((county) => response.states?.includes(county.state.abbreviation))
        .map((county) => ({
          value: county.code,
          label: county.name,
          state: county.state.abbreviation,
        }));

      const selectedCountyCodes = filterState.counties
        .filter((county) => response.counties?.includes(county.code))
        .map((county) => county.code);

      const zipsResponse: FetchZips = yield call(fetchZips, {
        accountID: selectedAccount.id,
        fips: selectedCountyCodes.map((code) => code).join(","),
        zipsOnly: false,
      });

      filters.zips = (zipsResponse?.zips as ZipsExpanded[]).map((zip: ZipsExpanded) => ({
        value: zip.zip,
        label: zip.zip,
        ...zip,
      }));

      filterState.zips = (zipsResponse?.zips as ZipsExpanded[])
        .map((zip: ZipsExpanded) => ({ value: zip.zip, label: zip.zip, ...zip }))
        .filter((zip) => response.zips?.includes(zip.value));

      filterState.property_zips = (zipsResponse?.zips as ZipsExpanded[])
        .map((zip: ZipsExpanded) => ({ value: zip.zip, label: zip.zip, ...zip }))
        .filter((zip) => response.property_zips?.includes(zip.value));

      yield put(getPreset.success({ preset: response, filters, filterState }));
    } else {
      const filterState = {
        states: states
          .filter((state) => response.states?.includes(`${state.abbreviation}`))
          .map((state) => ({ value: state.id + "", label: state.name, ...state })),
        counties: counties
          .filter((county) => response.counties?.includes(county.code))
          .map((county) => ({ value: county.name, label: county.code, ...county })),
        zips: [],
        property_zips: [],
      };

      yield put(getPreset.success({ preset: response, filters, filterState }));
    }

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(getPreset.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError, getErrMessage(error as Error)));
  } finally {
    if (!hideLoader) {
      yield put(stopLoading());
    }
  }
}

function* getPresetListSaga({ payload }: ActionWithPayload<{ investorSettingsId: number }>) {
  try {
    yield put(startLoading());
    const response: Preset[] = yield call(presetAPI.getPresetList, payload);
    yield put(getPresetList.success(response));
  } catch (error) {
    yield put(getPresetList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* presetBuilderSagas() {
  yield takeLatest(getZips.request, setupFilters);
  yield takeLatest(getPresetList.request, getPresetListSaga);
  yield takeLatest(getPreset.request, getPresetSaga);
  yield takeLatest(filterChange.request, onFilterChange);
  yield takeLatest(createPreset.request, createPresetSaga);
  yield takeLatest(updatePreset.request, updatePresetSaga);
  yield takeLatest(copyPreset.request, copyPresetSaga);
  yield takeLatest(deletePreset.request, deletePresetSaga);
  yield debounce(500, getBuyBoxData.request, fetchBuyBoxData);
}

export default presetBuilderSagas;
