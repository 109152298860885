import React, { useCallback, useEffect } from "react";
import * as SharedStore from "@shared/store";
import * as ClientStore from "@containers/Contact/store";
import * as AccountStore from "@containers/Account/store";
import { Account } from "@shared/models";
import { useDispatch, useSelector } from "react-redux";
import { DealForm } from "@containers/Account/components/DealEditContainer";
import { UpdateSubscriptionInterface } from "@containers/Account/interface/UpdateSubscriptionInterface";

interface DealEditContainerProps {
  onClose: () => void;
  id?: number;
  callback?: () => void;
  contact?: Account;
}

const DealEditContainer = (props: DealEditContainerProps) => {
  const { onClose, id: dealId, callback, contact } = props;
  const dispatch = useDispatch();

  const selectedSubscription = useSelector(AccountStore.selectors.getSelectedSubscription());

  useEffect(() => {
    if (dealId) {
      dispatch(AccountStore.actions.getSubscription.request(dealId));
    }
  }, [dispatch, dealId]);

  useEffect(() => {
    return () => {
      dispatch(ClientStore.actions.clearStates());
      dispatch(ClientStore.actions.clearCounties());
    };
  }, [dispatch]);

  const changeFormValues = useCallback(() => {
    dispatch(SharedStore.actions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: Omit<UpdateSubscriptionInterface, "accountId">) => {
      if (contact) {
        dispatch(AccountStore.actions.updateSubscription.request({ ...payload, accountId: contact.id, callback }));
      }
    },
    [dispatch, contact, callback],
  );

  if (!selectedSubscription) {
    return null;
  }

  return (
    <div>
      <DealForm
        deal={selectedSubscription}
        onChangeForm={changeFormValues}
        onCloseForm={onClose}
        onSubmitForm={handleSubmitForm}
      />
    </div>
  );
};

export default DealEditContainer;
