import { ActionWithPayload, InvestorFileFilter, PaginatedResponse, ResponseError } from "@shared/interfaces";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { showNotification, startLoading, stopLoading } from "@shared/store/actions";
import { InvestorFile, InvestorFileSendingInfo } from "@shared/models";
import {
  createInvestorFile,
  getInvestorFile,
  getInvestorFileList,
  processInvestorFile,
  sendInvestorFile,
  sendInvestorFileEmail,
  sendInvestorFileEmails,
  unsendInvestorFile,
} from "@containers/InvestorFile/store/actions";
import { showHttpErrorNotification } from "@shared/utils";

import api from "../api";
import { getErrMessage } from "../../PresetBuilder/util";
import { updatePreset } from "../../PresetBuilder/store/actions";

function* getInvestorFileListSaga({ payload }: ActionWithPayload<InvestorFileFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<InvestorFile> = yield call(api.getInvestorFileList, payload);
    yield put(
      getInvestorFileList.success({
        ...response,
        clear: !payload.page || payload.page === 1,
      }),
    );
  } catch (error) {
    yield put(getInvestorFileList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getInvestorFileSaga({ payload }: ReturnType<typeof getInvestorFile.request>) {
  const { callback, hideLoader, ...rest } = payload;
  try {
    if (!hideLoader) {
      yield put(startLoading());
    }
    const response: InvestorFile = yield call(api.getInvestorFile, rest);
    yield put(getInvestorFile.success(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(getInvestorFile.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    if (!hideLoader) {
      yield put(stopLoading());
    }
  }
}

function* processInvestorFileSaga({
  payload,
}: ActionWithPayload<{ investorSettingsId: number; filterId: number; fileName: string; callback?: () => void }>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const investorFile: InvestorFile = yield call(api.processInvestorFile, rest);
    yield put(processInvestorFile.success(investorFile));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(processInvestorFile.failure(error as Error));
    yield put(updatePreset.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError, getErrMessage(error as ResponseError, "fileName")));
  } finally {
    yield put(stopLoading());
  }
}

function* sendInvestorFileSaga({
  payload,
}: ActionWithPayload<{ id: number; accountId: number; callback: () => void }>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    yield call(api.sendInvestorFile, rest);
    yield put(sendInvestorFile.success());
    yield put(
      showNotification({
        message: "Email was sent successfully.",
        appearance: "success",
      }),
    );
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(sendInvestorFile.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* unsendInvestorFileSaga({ payload }: ActionWithPayload<{ id: number; callback: () => void }>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    yield call(api.unsendInvestorFile, rest);
    yield put(unsendInvestorFile.success());

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(unsendInvestorFile.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* createInvestorFileSaga({ payload }: ReturnType<typeof createInvestorFile.request>) {
  try {
    const response: InvestorFile = yield call(api.createInvestorFile, payload);
    yield put(createInvestorFile.success(response));
  } catch (error) {
    yield put(createInvestorFile.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  }
}

function* sendInvestorFileEmailSaga({ payload }: ReturnType<typeof sendInvestorFileEmail.request>) {
  try {
    yield call(api.sendInvestorFileEmail, payload);
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError));
  }
}

function* sendInvestorFilesEmailSaga({ payload }: ReturnType<typeof sendInvestorFileEmails.request>) {
  try {
    const info: Omit<InvestorFileSendingInfo, "id"> = yield call(api.sendInvestorFilesEmail, payload);
    yield put(
      showNotification({
        message: "Email was sent successfully.",
        appearance: "success",
      }),
    );

    const result: InvestorFileSendingInfo[] = payload.filesIds.map((id) => ({ id, ...info }));

    yield put(sendInvestorFileEmails.success(result));
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError));
  }
}

function* investorFileSaga() {
  yield takeLatest(getInvestorFileList.request, getInvestorFileListSaga);
  yield takeLatest(getInvestorFile.request, getInvestorFileSaga);
  yield takeLatest(processInvestorFile.request, processInvestorFileSaga);
  yield takeLatest(sendInvestorFile.request, sendInvestorFileSaga);
  yield takeLatest(unsendInvestorFile.request, unsendInvestorFileSaga);
  yield takeEvery(createInvestorFile.request, createInvestorFileSaga);
  yield takeEvery(sendInvestorFileEmail.request, sendInvestorFileEmailSaga);
  yield takeEvery(sendInvestorFileEmails.request, sendInvestorFilesEmailSaga);
}

export default investorFileSaga;
