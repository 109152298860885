import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { AccountFilterStatus } from "@shared/models";
import { AccountsFilter, ORDER_COLUMN_TYPE, ORDER_TYPE } from "@shared/interfaces";
import { featuresStorage } from "@shared/utils/featuresStorage";

import * as actions from "./actions";
import { AccountError, AccountStateType } from "../interface";

const defaultAccountsFilter: AccountsFilter = {
  page: 1,
  limit: 10,
  search: null,
  order: ORDER_COLUMN_TYPE.NAME,
  direction: ORDER_TYPE.ASC,
  status: AccountFilterStatus.active,
};

const defaultAccountsListFilter: AccountsFilter = {
  page: 1,
  limit: 50,
  search: null,
  order: ORDER_COLUMN_TYPE.NAME,
  direction: ORDER_TYPE.ASC,
  status: AccountFilterStatus.all,
};

const initialState: AccountStateType = {
  paymentMethodExists: null,
  cardPaymentMethodExists: null,
  invoices: null,
  paidInvoiceId: null,
  accountsList: null,
  accountsListFilter: defaultAccountsListFilter,

  accountPropertyList: null,
  accountPropertyLists: null,

  accountNotes: {},
  accountAttempts: {},

  accounts: [],
  accountsTotal: 0,
  accountsFilter: defaultAccountsFilter,
  selectedAccount: null,
  activeAccount: null,
  features: featuresStorage.get(),
  accountPayment: undefined,

  errors: {
    [AccountError.checkPaymentMethodExistenceError]: null,
    [AccountError.getInvoicesError]: null,
    [AccountError.payInvoiceError]: null,
    [AccountError.getAccountsError]: null,
    [AccountError.getAccountsListError]: null,
    [AccountError.getAccountPropertyListsError]: null,
    [AccountError.createAccountPropertyListError]: null,
    [AccountError.getAccountNotesError]: null,
    [AccountError.createAccountNoteError]: null,
    [AccountError.getAccountAttemptsError]: null,
    [AccountError.saveAccountAttemptsError]: null,
    [AccountError.linkAccountPropertyListToPropertiesFromFileError]: null,
    [AccountError.getAccountError]: null,
    [AccountError.createAccount]: null,
    [AccountError.updateAccount]: null,
    [AccountError.accountPaymentError]: null,
  },

  accountCheck: {
    filledChargebeeId: null,
    isChargebeeIdValid: null,
  },

  selectedSubscription: null,
};

const reducer = createReducer<AccountStateType, ActionType<typeof actions>>(initialState)
  .handleAction(actions.checkPaymentMethodExistence.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.paymentMethodExists = action.payload.exists;
      nextState.cardPaymentMethodExists = action.payload.cardExists;
      nextState.errors[AccountError.checkPaymentMethodExistenceError] = null;
    }),
  )
  .handleAction(actions.checkPaymentMethodExistence.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.checkPaymentMethodExistenceError] = action.payload;
    }),
  )
  .handleAction(actions.getInvoices.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.invoices = action.payload;
      nextState.errors[AccountError.getInvoicesError] = null;
    }),
  )
  .handleAction(actions.getInvoices.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.getInvoicesError] = action.payload;
    }),
  )
  .handleAction(actions.payInvoice.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.paidInvoiceId = action.payload;
      nextState.errors[AccountError.payInvoiceError] = null;
    }),
  )
  .handleAction(actions.payInvoice.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.payInvoiceError] = action.payload;
    }),
  )
  .handleAction(actions.getAccountsList.success, (state, action) =>
    produce(state, (nextState) => {
      const { items, total, clear } = action.payload;
      if (nextState.accountsList) {
        nextState.accountsList.list = !clear ? [...nextState.accountsList.list, ...items] : [...items];
        nextState.accountsList.total = total;
      } else {
        nextState.accountsList = { list: items, total };
      }
    }),
  )
  .handleAction(actions.getAccountsList.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.getAccountsListError] = action.payload;
    }),
  )

  .handleAction(actions.getAccounts.success, (state, action) =>
    produce(state, (nextState) => {
      const { items, total, clear } = action.payload;
      nextState.accounts = !clear ? [...nextState.accounts, ...items] : [...items];
      nextState.accountsTotal = total;
    }),
  )
  .handleAction(actions.getAccounts.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.getAccountsError] = action.payload;
    }),
  )

  .handleAction(actions.getAccountPropertyLists.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.accountPropertyLists = action.payload.sort((a, b) => a.name.localeCompare(b.name));
      nextState.errors[AccountError.getAccountPropertyListsError] = null;
    }),
  )
  .handleAction(actions.getAccountPropertyLists.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.getAccountPropertyListsError] = action.payload;
    }),
  )

  .handleAction(actions.createAccountPropertyList.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.accountPropertyLists = nextState.accountPropertyLists
        ? nextState.accountPropertyLists.concat(action.payload)
        : [action.payload];

      nextState.accountPropertyList = action.payload;
      nextState.errors[AccountError.createAccountPropertyListError] = null;
    }),
  )
  .handleAction(actions.createAccountPropertyList.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.createAccountPropertyListError] = action.payload;
    }),
  )

  .handleAction(actions.getAccountNotes.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload, res } = action.payload;

      nextState.accountNotes = {
        ...nextState.accountNotes,
        [payload.type]: {
          ...nextState.accountNotes[payload.type],
          [payload.entity]: res,
        },
      };
      nextState.errors[AccountError.getAccountNotesError] = null;
    }),
  )
  .handleAction(actions.getAccountNotes.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.getAccountNotesError] = action.payload;
    }),
  )

  .handleAction(actions.createAccountNote.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload, res } = action.payload;

      nextState.accountNotes = {
        ...nextState.accountNotes,
        [payload.type]: {
          ...nextState.accountNotes[payload.type],
          [payload.entity]: [res, ...(nextState.accountNotes[payload.type]?.[payload.entity] || [])],
        },
      };
      nextState.errors[AccountError.createAccountNoteError] = null;
    }),
  )
  .handleAction(actions.createAccountNote.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.createAccountNoteError] = action.payload;
    }),
  )

  .handleAction(actions.getAccountAttempts.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload, res } = action.payload;

      nextState.accountAttempts = {
        ...nextState.accountAttempts,
        [payload.type]: {
          ...nextState.accountAttempts[payload.type],
          [payload.entity]: res,
        },
      };

      nextState.errors[AccountError.getAccountAttemptsError] = null;
    }),
  )
  .handleAction(actions.getAccountAttempts.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.getAccountAttemptsError] = action.payload;
    }),
  )

  .handleAction(actions.saveAccountAttempts.success, (state, action) =>
    produce(state, (nextState) => {
      const { payload, res } = action.payload;

      nextState.accountAttempts = {
        ...nextState.accountAttempts,
        [payload.type]: {
          ...nextState.accountAttempts[payload.type],
          [payload.entity]: res,
        },
      };
      nextState.errors[AccountError.saveAccountAttemptsError] = null;
    }),
  )
  .handleAction(actions.saveAccountAttempts.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.saveAccountAttemptsError] = action.payload;
    }),
  )

  .handleAction(actions.linkAccountPropertyListToPropertiesFromFile.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.linkAccountPropertyListToPropertiesFromFileError] = action.payload;
    }),
  )

  .handleAction(actions.getAccount.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.selectedAccount = action.payload;
    }),
  )
  .handleAction(actions.getAccount.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.getAccountError] = action.payload;
    }),
  )
  .handleAction(actions.createAccount.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.createAccount] = action.payload;
    }),
  )
  .handleAction(actions.updateAccount.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AccountError.updateAccount] = action.payload;
    }),
  )

  .handleAction(actions.setAccountsListFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.accountsListFilter = action.payload
        ? { ...nextState.accountsListFilter, ...action.payload }
        : { ...defaultAccountsListFilter };
    }),
  )

  .handleAction(actions.checkAccount.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.accountCheck = {
        filledChargebeeId: action.payload.filledChargebeeId,
        isChargebeeIdValid: action.payload.isChargebeeIdValid,
      };
    }),
  )
  .handleAction(actions.clearAccountCheck, (state) =>
    produce(state, (nextState) => {
      nextState.accountCheck = initialState.accountCheck;
    }),
  )
  .handleAction(actions.clearAccountsList, (state) =>
    produce(state, (nextState) => {
      nextState.accountsList = null;
    }),
  )
  .handleAction(actions.clearAccountPropertyList, (state) =>
    produce(state, (nextState) => {
      nextState.accountPropertyList = null;
    }),
  )
  .handleAction(actions.clearAllAccountPropertyLists, (state) =>
    produce(state, (nextState) => {
      nextState.accountPropertyList = initialState.accountPropertyList;
      nextState.accountPropertyLists = initialState.accountPropertyLists;
    }),
  )
  .handleAction(actions.clearAccount, (state) =>
    produce(state, (nextState) => {
      nextState.selectedAccount = null;
    }),
  )
  .handleAction(actions.clearErrors, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        action.payload.forEach((err) => {
          nextState.errors[err] = null;
        });
      } else {
        nextState.errors = initialState.errors;
      }
    }),
  )
  .handleAction(actions.clearAccountPayment, (state) =>
    produce(state, (nextState) => {
      nextState.accountPayment = undefined;
    }),
  )
  .handleAction(actions.getSubscription.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.selectedSubscription = action.payload;
    }),
  )
  .handleAction(actions.updateSubscription.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.selectedSubscription = action.payload;
    }),
  )
  .handleAction(actions.setActiveAccount.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.activeAccount = action.payload;
    }),
  )
  .handleAction(actions.accountPayment.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.accountPayment = action.payload;
      nextState.errors[AccountError.accountPaymentError] = null;
    }),
  )
  .handleAction(actions.accountPayment.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.accountPayment = undefined;
      nextState.errors[AccountError.accountPaymentError] = action.payload;
    }),
  )
  .handleAction(actions.getAccountPaymentStatus.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.accountPayment = action.payload;
    }),
  )
  .handleAction(actions.getAccountPaymentStatus.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.accountPayment = initialState.accountPayment;
      nextState.errors[AccountError.accountPaymentError] = action.payload;
    }),
  )
  .handleAction(actions.setFeatures, (state, action) =>
    produce(state, (nextState) => {
      featuresStorage.set(action.payload);
      nextState.features = action.payload;
    }),
  );

export { reducer as AccountReducer };
