import React from "react";
import { PropertyFilterOption, PropertyFilterType } from "@shared/interfaces/RecordManagement";
import { SelectedFiltersItem } from "@containers/RecordManagement/components/SelectedFilters/SelectedFiltersItem";
import "./index.scss";

interface Props {
  filters: PropertyFilterOption[];
  onTagRemove: (id: string, idx?: number) => void;
}

export const SelectedFilters = ({ filters, onTagRemove }: Props) => {
  if (!filters) return null;

  return (
    <div className="selected-filters">
      {filters
        ?.filter((f) => (f.type === PropertyFilterType.TAGS ? f.filter?.length : f.filter))
        .map((f) => (
          <div key={f.value}>
            <label>{f.label}:</label>
            <SelectedFiltersItem filter={f} onTagRemove={(idx) => onTagRemove(f.value, idx)} />
          </div>
        ))}
    </div>
  );
};
