import React, { FC } from "react";
import { PayPerLead } from "@shared/models/PayPerLead";
import { CheckboxInput } from "@shared/components";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@containers/PayPerLead/store";

export interface LeadSelectCheckboxProps {
  lead: PayPerLead;
}

export const SelectLeadCheckbox: FC<LeadSelectCheckboxProps> = ({ lead }) => {
  const dispatch = useDispatch();
  const selected: number[] = useSelector(selectors.getSelectedLeadIds());
  const isChecked = selected.includes(lead.id);

  const toggleSelected = () => {
    if (isChecked) {
      dispatch(actions.unselectLead(lead.id));
    } else {
      dispatch(actions.selectLead(lead.id));
    }
  };

  return <CheckboxInput onChange={toggleSelected} value={isChecked ? 1 : 0} />;
};

export const SelectAllLeadsCheckbox = () => {
  const dispatch = useDispatch();
  const all = useSelector(selectors.getLeads())?.items ?? [];
  const selected: number[] = useSelector(selectors.getSelectedLeadIds());
  const isChecked = all.length === selected.length;

  const toggleSelected = () => {
    if (isChecked) {
      dispatch(actions.unselectAllLeads());
    } else {
      dispatch(actions.selectAllLeads());
    }
  };

  return <CheckboxInput onChange={toggleSelected} value={isChecked ? 1 : 0} />;
};
