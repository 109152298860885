import * as Yup from "yup";
import { Account } from "@shared/models";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { CreateAccountInterface } from "@containers/Account/interface/CreateAccount.interface";
import { stringValidator } from "@shared/validations";

export interface CompanyFormShape {
  name: string;

  stripeId?: string;
  chargebeeId?: string;

  flickId: string;
  flickName: string;

  email?: string;
  firstName?: string;
  lastName?: string;
}

export const createValidationSchema = Yup.object().shape({
  name: stringValidator
    .max(50, `Company name ${FORM_ERROR_MESSAGES.LONG}`)
    .required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),

  chargebeeId: stringValidator.nullable(),

  flickId: stringValidator.max(100, `Flikid ${FORM_ERROR_MESSAGES.LONG}`).nullable(),
  flickName: stringValidator.max(100, `Flikid Nickname ${FORM_ERROR_MESSAGES.LONG}`).nullable(),

  firstName: stringValidator
    .max(35, `First name ${FORM_ERROR_MESSAGES.LONG}`)
    .required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),
  lastName: stringValidator
    .max(35, `Last name ${FORM_ERROR_MESSAGES.LONG}`)
    .required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),
  email: stringValidator
    .email(FORM_ERROR_MESSAGES.EMAIL)
    .max(150, `Email ${FORM_ERROR_MESSAGES.LONG}`)
    .required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),
});

export const editValidationSchema = Yup.object().shape({
  name: stringValidator
    .max(50, `Company name ${FORM_ERROR_MESSAGES.LONG}`)
    .required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),

  stripeId: stringValidator.max(100, `Stripe ID ${FORM_ERROR_MESSAGES.LONG}`).nullable(),
  chargebeeId: stringValidator
    .max(100, `Chargebee ID ${FORM_ERROR_MESSAGES.LONG}`)
    .nullable()
    .when("stripeId", {
      is: (stripeId: string) => !stripeId || stripeId.length === 0, // check if stripeId is empty
      then: stringValidator.required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`), // require chargebeeId if stripeId is empty
    }),

  flickId: stringValidator.max(100, `Flikid ${FORM_ERROR_MESSAGES.LONG}`).nullable(),
  flickName: stringValidator.max(100, `Flikid Nickname ${FORM_ERROR_MESSAGES.LONG}`).nullable(),
});

export const getInitValues = (account: Account | null) => {
  if (account) {
    return {
      name: account.name,
      flickId: account.claritas_id || "",
      flickName: account.claritas_nickname || "",
      stripeId: account.stripe_customer_id || "",
      chargebeeId: account.chargebee_customer_id || "",
    };
  }

  return {
    name: "",
    states: [],
    counties: [],
    flickId: "",
    flickName: "",
    chargebeeId: "",
    email: "",
    firstName: "",
    lastName: "",
  };
};

export const prepareSubmitValue = (value: CompanyFormShape): CreateAccountInterface => ({
  name: value.name,

  flickId: value.flickId || null,
  flickName: value.flickName || null,

  stripeId: value.stripeId,
  chargebeeId: value.chargebeeId,

  email: value.email,
  lastName: value.lastName,
  firstName: value.firstName,
});
