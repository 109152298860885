import React, { FC } from "react";
import ReactSelect, {
  Props as SelectInputProps,
  components,
  MenuListProps,
  MultiValueGenericProps,
} from "react-select";
import classnames from "classnames";
import { Option } from "@shared/interfaces";

import "./index.scss";

interface SelectInputExtraProps {
  menuWrapper?: (props: MenuListProps) => JSX.Element;
  multiValueContainer?: (props: MultiValueGenericProps) => JSX.Element;
  customPosition?: boolean;
}

const MenuListDefault = (props: MenuListProps) => {
  return <components.MenuList {...props}>{props.children}</components.MenuList>;
};

const SelectInput: FC<SelectInputProps & SelectInputExtraProps> = (props) => {
  const {
    menuWrapper = MenuListDefault,
    multiValueContainer = () => null,
    className,
    customPosition,
    ...inputProps
  } = props;
  return (
    <ReactSelect
      className={classnames("custom-select custom-input", { disabled: props.isDisabled }, className)}
      {...inputProps}
      menuPortalTarget={customPosition ? document.body : undefined}
      menuPosition={customPosition ? "fixed" : undefined}
      onChange={(newValue, actionMeta) => {
        const valueToChange = props.isMulti ? newValue : newValue ? (newValue as Option).value : null;
        props.onChange && props.onChange(valueToChange, actionMeta);
      }}
      components={{
        MenuList: menuWrapper,
        IndicatorSeparator: () => null,
        MultiValueContainer: multiValueContainer,
      }}
      classNamePrefix="custom-select"
    />
  );
};

export default SelectInput;
