import { ActionWithPayload, ResponseError, WithCallback } from "@shared/interfaces";
import { call, put, takeLatest } from "redux-saga/effects";
import { startLoading, stopLoading } from "@shared/store/actions";
import { Preset, PresetField } from "@shared/models";
import {
  createPreset,
  deletePreset,
  getPreset,
  getPresetFields,
  getPresetList,
  updatePreset,
} from "@containers/Preset/store/actions";
import { CreatePresetDto } from "@containers/Preset/interfaces/Preset.interface";
import { showHttpErrorNotification } from "@shared/utils";

import api from "../api";

function* getPresetListSaga({ payload }: ActionWithPayload<{ investorSettingsId: number }>) {
  try {
    yield put(startLoading());
    const response: Preset[] = yield call(api.getPresetList, payload);
    yield put(getPresetList.success(response));
  } catch (error) {
    yield put(getPresetList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getPresetSaga({ payload }: ReturnType<typeof getPreset.request>) {
  const { callback, hideLoader, ...rest } = payload;
  try {
    if (!hideLoader) {
      yield put(startLoading());
    }
    const response: Preset = yield call(api.getPreset, rest);
    yield put(getPreset.success(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(getPreset.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    if (!hideLoader) {
      yield put(stopLoading());
    }
  }
}

function* createPresetSaga({
  payload,
}: ActionWithPayload<
  CreatePresetDto & { investorSettingsId: number; filterId: number; callback?: (presetId?: string) => void }
>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: Preset = yield call(api.createPreset, rest);
    yield put(createPreset.success(response));
    if (callback) {
      callback(String(response.id));
    }
  } catch (error) {
    yield put(createPreset.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* updatePresetSaga({
  payload,
}: ActionWithPayload<WithCallback<Preset & { investorSettingsId: number; filterId: number }>>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: Preset = yield call(api.updatePreset, rest);
    yield put(updatePreset.success(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(updatePreset.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* deletePresetSaga({ payload }: ActionWithPayload<{ investorSettingsId: number; filterId: number }>) {
  try {
    yield put(startLoading());
    yield call(api.deletePreset, payload);
    yield put(deletePreset.success());
  } catch (error) {
    yield put(deletePreset.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getPresetFieldsSaga() {
  try {
    yield put(startLoading());
    const response: PresetField[] = yield call(api.getFilterFields);
    yield put(getPresetFields.success(response));
  } catch (error) {
    yield put(getPresetFields.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* PresetSaga() {
  yield takeLatest(getPresetList.request, getPresetListSaga);
  yield takeLatest(getPreset.request, getPresetSaga);
  yield takeLatest(createPreset.request, createPresetSaga);
  yield takeLatest(updatePreset.request, updatePresetSaga);
  yield takeLatest(deletePreset.request, deletePresetSaga);
  yield takeLatest(getPresetFields.request, getPresetFieldsSaga);
}

export default PresetSaga;
