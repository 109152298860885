import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import {
  AccountPaymentShape,
  CheckAccountShape,
  CreateAccountInterface,
  GetInvoicesShape,
  GoToBillingPortalShape,
  GoToCheckoutShape,
  LinkAccountPropertyListToPropertiesFromFileInterface,
  LinkAccountPropertyListToPropertyInterface,
  PayInvoiceShape,
  UpdateAccountInterface,
} from "@containers/Account/interface";
import { BaseFilter, WithCallback } from "@shared/interfaces";
import { GetAccountEntity, SaveAccountAttempts } from "@shared/models";

export default {
  getCheckoutUrl: ({ id, ...params }: GoToCheckoutShape) =>
    request(METHODS.GET, API.ACCOUNT.GET_CHECKOUT_URL(id))({ params }),
  getBillingPortalUrl: ({ id, ...params }: GoToBillingPortalShape) =>
    request(METHODS.GET, API.ACCOUNT.GET_BILLING_PORTAL_URL(id))({ params }),
  checkPaymentMethodExistence: (id: number) => request(METHODS.GET, API.ACCOUNT.CHECK_PAYMENT_METHOD_EXISTENCE(id))(),
  getInvoices: ({ id, ...params }: GetInvoicesShape) => request(METHODS.GET, API.ACCOUNT.GET_INVOICES(id))({ params }),
  payInvoice: ({ accountId, invoiceId }: PayInvoiceShape) =>
    request(METHODS.POST, API.ACCOUNT.PAY_INVOICE(accountId, invoiceId))(),
  getAccounts: (payload: BaseFilter) => request(METHODS.GET, API.ACCOUNT.GET_ACCOUNTS_LIST)({ params: payload }),
  accountPayment: (payload: AccountPaymentShape) =>
    request(METHODS.POST, API.ACCOUNT.ACCOUNT_PAYMENT(payload.accountId))({ amount: payload.amount }),
  getAccountInvoice: (payload: { accountId: number; invoiceId: number }) =>
    request(METHODS.GET, API.ACCOUNT.GET_ACCOUNT_INVOICE(payload.accountId, payload.invoiceId))(),

  checkAccount: (payload: CheckAccountShape) => request(METHODS.GET, API.ACCOUNT.CHECK_ACCOUNT)({ params: payload }),

  createAccount: (payload: CreateAccountInterface) => request(METHODS.POST, API.ACCOUNT.CREATE_ACCOUNT)(payload),
  updateAccount: (payload: UpdateAccountInterface) =>
    request(METHODS.PUT, API.ACCOUNT.UPDATE_ACCOUNT(payload.id))(payload),
  activateAccount: (id: number) => request(METHODS.PUT, API.ACCOUNT.ACTIVATE(id))(),
  deactivateAccount: (id: number) => request(METHODS.PUT, API.ACCOUNT.DEACTIVATE(id))(),

  getSubscription: (id: number) => request(METHODS.GET, API.ACCOUNT.GET_SUBSCRIPTION(id))(),
  updateSubscription: (id: number, counties: number[]) =>
    request(METHODS.PUT, API.ACCOUNT.UPDATE_SUBSCRIPTION(id))({ counties }),

  getAccount: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.GET, API.ACCOUNT.GET_ACCOUNT(id))();
  },
  getAccountPropertyLists: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.GET, API.ACCOUNT.GET_ACCOUNT_PROPERTY_LISTS(id))();
  },
  createAccountPropertyList: (payload: { id: number; name: string }) => {
    const { id, name } = payload;
    return request(METHODS.POST, API.ACCOUNT.CREATE_ACCOUNT_PROPERTY_LIST(id))({ name });
  },
  getAccountNotes: (payload: GetAccountEntity) => {
    const { id, ...params } = payload;
    return request(METHODS.GET, API.ACCOUNT.GET_ACCOUNT_NOTES(id))({ params });
  },
  createAccountNote: (payload: GetAccountEntity & { text: string }) => {
    const { id, type, entity, text } = payload;
    return request(METHODS.POST, API.ACCOUNT.CREATE_ACCOUNT_NOTE(id))({ text, type, entity });
  },
  getAccountAttempts: (payload: WithCallback<GetAccountEntity>) => {
    const { id, type, entity } = payload;
    return request(METHODS.GET, API.ACCOUNT.GET_ACCOUNT_ATTEMPTS(id))({ params: { type, entity } });
  },
  saveAccountAttempts: (payload: SaveAccountAttempts) => {
    const { id, type, entity, call, mail, message } = payload;
    return request(METHODS.PUT, API.ACCOUNT.SAVE_ACCOUNT_ATTEMPTS(id))({ call, mail, message, type, entity });
  },
  linkAccountPropertyListToPropertiesFromFile: (payload: LinkAccountPropertyListToPropertiesFromFileInterface) => {
    const { id, fileId, listIds } = payload;
    return request(
      METHODS.POST,
      API.ACCOUNT.LIST_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE(id),
    )({ fileId, listIds });
  },
  linkAccountPropertyListToProperty: (payload: LinkAccountPropertyListToPropertyInterface) => {
    const { id, propertyId, listIds } = payload;
    return request(METHODS.POST, API.ACCOUNT.LIST_ACCOUNT_PROPERTY_LIST_TO_PROPERTY(id))({ propertyId, listIds });
  },
  getFeatures: (id: number) => request(METHODS.GET, API.ACCOUNT.GET_FEATURES(id))(),
};
