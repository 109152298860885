export enum PresetActionTypes {
  GET_PRESET = "@@PRESET/GET_PRESET",
  GET_PRESET_SUCCESS = "@@PRESET/GET_PRESET_SUCCESS",
  GET_PRESET_FAILURE = "@@PRESET/GET_PRESET_FAILURE",

  GET_PRESET_LIST = "@@PRESET/GET_PRESET_LIST",
  GET_PRESET_LIST_SUCCESS = "@@PRESET/GET_PRESET_LIST_SUCCESS",
  GET_PRESET_LIST_FAILURE = "@@PRESET/GET_PRESET_LIST_FAILURE",

  CREATE_PRESET = "@@PRESET/CREATE_PRESET",
  CREATE_PRESET_SUCCESS = "@@PRESET/CREATE_PRESET_SUCCESS",
  CREATE_PRESET_FAILURE = "@@PRESET/CREATE_PRESET_FAILURE",

  UPDATE_PRESET = "@@PRESET/UPDATE_PRESET",
  UPDATE_PRESET_SUCCESS = "@@PRESET/UPDATE_PRESET_SUCCESS",
  UPDATE_PRESET_FAILURE = "@@PRESET/UPDATE_PRESET_FAILURE",

  DELETE_PRESET = "@@PRESET/DELETE_PRESET",
  DELETE_PRESET_SUCCESS = "@@PRESET/DELETE_PRESET_SUCCESS",
  DELETE_PRESET_FAILURE = "@@PRESET/DELETE_PRESET_FAILURE",

  GET_PRESET_FIELDS = "@@PRESET/GET_PRESET_FIELDS",
  GET_PRESET_FIELDS_SUCCESS = "@@PRESET/GET_PRESET_FIELDS_SUCCESS",
  GET_PRESET_FIELDS_FAILURE = "@@PRESET/GET_PRESET_FIELDS_FAILURE",

  CLEAR_PRESET_LIST = "@@PRESET/CLEAR_PRESET_LIST",
  CLEAR_PRESET = "@@PRESET/CLEAR_PRESET",

  CLEAR_ERRORS = "@@PRESET/CLEAR_ERRORS",
}
