import React, { FC } from "react";
import { CheckBoxInputComponent } from "@shared/interfaces/PresetBuilder.interface";

import { Checkbox } from "../../Checkbox";

import "./index.scss";

const CheckboxBlock: FC<CheckBoxInputComponent> = (props) => {
  const { data } = props;

  return (
    <div className="checkbox-block">
      {data && data.map((data, idx) => data.stateAccessor && <Checkbox {...data} key={idx} />)}
    </div>
  );
};

export default CheckboxBlock;
