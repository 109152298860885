import React, { FC, useCallback } from "react";
import { useFormikContext } from "formik";
import { SelectPickerInput } from "@shared/components";
import { SkipTracing, UpdateFileMappingType } from "@shared/models";
import { FileMappingFormValues } from "@containers/SkipTracing/interfaces";

import "./index.scss";

interface MappingLineProps {
  idx: number;
  stColumnName: string;
  getKeyByValue: (object: UpdateFileMappingType, value: string | number) => string | null;
  skipTracing: SkipTracing | null;
}

const MappingLine: FC<MappingLineProps> = (props) => {
  const { idx, stColumnName, getKeyByValue, skipTracing } = props;

  const { values, setFieldValue } = useFormikContext<FileMappingFormValues>();

  const selectedValue = getKeyByValue(values.columnMapping, idx);

  const onChangeHandler = useCallback(
    (value: string | null) => {
      if (selectedValue) {
        setFieldValue(`columnMapping.${selectedValue}`, null);
      }
      if (value !== "unselect") {
        setFieldValue(`columnMapping.${value}`, idx);
      }
    },
    [idx, selectedValue, setFieldValue],
  );

  const example = skipTracing?.input_file?.records?.[0]?.data?.[idx] || "";

  const options = selectedValue
    ? [
        {
          label: <span className="unselect-column">Unselect</span>,
          value: "unselect",
        },
        ...values.selectOptions,
      ]
    : values.selectOptions;

  return (
    <div className="mapping-field-line">
      <div className="df-column-title">{stColumnName}</div>
      <div className="document-column-title-select">
        <SelectPickerInput
          data={options}
          searchable={false}
          name="columnMapping"
          cleanable={false}
          placeholder="Please select"
          onChange={onChangeHandler}
          value={selectedValue}
          classPrefix="skip-tracing-mapping"
          placement="auto"
        />
      </div>
      <div className="column-content-example">
        <div>{example}</div>
      </div>
    </div>
  );
};

export default MappingLine;
