import React, { FC } from "react";
import classNames from "classnames";
import { Icon } from "@shared/components";
import { Account } from "@shared/models";

interface CompanyItemType {
  account: Account;
  isActive: boolean;
  handleSelectAccount: () => void;
}

const CompanyItem: FC<CompanyItemType> = (props) => {
  const { account, isActive, handleSelectAccount } = props;

  return (
    <div className="wrapper" key={account.id}>
      <div className={classNames("account", { selected: isActive })} onClick={handleSelectAccount}>
        {account.name}
      </div>
      {isActive ? <Icon className="icon" type="check-blue" /> : <div className="icon" />}
    </div>
  );
};

export default CompanyItem;
