import React, { FC } from "react";

import "./index.scss";

const FormTitleBlock: FC = () => {
  return (
    <div className="mapping-form-title-block">
      <div>Your file columns</div>
      <div>Dataflik columns</div>
      <div>Examples</div>
    </div>
  );
};

export default FormTitleBlock;
