import React, { FC, useMemo } from "react";
import moment from "moment";

export enum DateFormat {
  LongMonthDayComaYear = "MMMM D, YYYY",
}

export interface DateFormattedProps {
  date?: Date | string;
  format?: DateFormat;
}

export const DateFormatted: FC<DateFormattedProps> = ({ date, format }) => {
  const dateFormatted = useMemo(() => {
    return date ? moment(date).format(format ?? DateFormat.LongMonthDayComaYear) : null;
  }, [date, format]);

  return <>{dateFormatted}</>;
};
