import React, { FC } from "react";
import classNames from "classnames";
import { ReactComponent as ThumbUpIcon } from "@assets/files/icons/general/thumb-up.svg";
import { ReactComponent as ThumbUpFilledIcon } from "@assets/files/icons/general/thumb-up-filled.svg";
import { Button } from "@shared/components";

import "./Thumb.scss";

export interface ThumbProps {
  direction: "up" | "down";
  onClick?: (e?: React.MouseEvent<Element, MouseEvent>) => void;
  active?: boolean;
}

export const Thumb: FC<ThumbProps> = ({ onClick, active, direction }) => {
  return (
    <Button onClick={onClick} buttonStyle="link" className={classNames("thumb", direction)}>
      {active ? <ThumbUpFilledIcon /> : <ThumbUpIcon />}
    </Button>
  );
};
