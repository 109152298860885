import { AccountsFilter, ResponseError } from "@shared/interfaces";
import { Invoice } from "@shared/models/Invoice";
import { Account, AccountAttempts, AccountPropertyList, AccountNote, Feature, Subscription } from "@shared/models";

export enum AccountError {
  checkPaymentMethodExistenceError = "checkPaymentMethodExistenceError",
  getInvoicesError = "getInvoicesError",
  payInvoiceError = "payInvoiceError",
  getAccountsError = "getAccountsError",
  getAccountsListError = "getAccountsListError",
  getAccountPropertyListsError = "getAccountPropertyListsError",
  createAccountPropertyListError = "createAccountPropertyList",
  getAccountNotesError = "getAccountNotesError",
  createAccountNoteError = "createAccountNoteError",
  getAccountAttemptsError = "getAccountAttemptsError",
  saveAccountAttemptsError = "saveAccountAttemptsError",
  linkAccountPropertyListToPropertiesFromFileError = "linkAccountPropertyListToPropertiesFromFile",
  getAccountError = "getAccountError",
  createAccount = "createAccount",
  updateAccount = "updateAccount",
  accountPaymentError = "accountPaymentError",
}

export interface AccountStateType {
  paymentMethodExists: boolean | null;
  cardPaymentMethodExists: boolean | null;
  invoices: Invoice[] | null;
  paidInvoiceId: number | null;
  accounts: Account[];
  accountsTotal: number;
  accountsFilter: AccountsFilter;
  accountsList: { list: Account[]; total: number } | null;
  accountsListFilter: AccountsFilter;
  selectedAccount: Account | null;
  features: Feature[];
  accountPayment:
    | {
        id: number;
        status: string;
      }
    | undefined;
  accountPropertyList: AccountPropertyList | null;
  accountPropertyLists: AccountPropertyList[] | null;
  accountNotes: { [type: string]: { [id: string | number]: AccountNote[] } };
  accountAttempts: { [type: string]: { [id: string | number]: AccountAttempts } };
  errors: {
    [key in AccountError]: ResponseError | null;
  };
  accountCheck: {
    filledChargebeeId: string | null;
    isChargebeeIdValid: boolean | null;
  };
  selectedSubscription: Subscription | null;
  activeAccount: Account | null;
}
