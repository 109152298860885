import { call, debounce, put, select, takeLatest, takeLeading } from "redux-saga/effects";
import { showNotification, startLoading, stopLoading } from "@shared/store/actions";
import { AccountsFilter, ActionWithPayload, PaginatedResponse, ResponseError, WithCallback } from "@shared/interfaces";
import { LocalStorageActiveAccountId, showHttpErrorNotification } from "@shared/utils";
import { Invoice } from "@shared/models/Invoice";
import {
  Account,
  AccountAttempts,
  AccountPropertyList,
  AccountNote,
  SaveAccountAttempts,
  Subscription,
  Feature,
  User,
  GetAccountEntity,
  PaymentMethodExistence,
} from "@shared/models";
import {
  CreateAccountInterface,
  CheckAccountShape,
  UpdateSubscriptionInterface,
  UpdateAccountInterface,
  LinkAccountPropertyListToPropertiesFromFileInterface,
  LinkAccountPropertyListToPropertyInterface,
} from "@containers/Account/interface";
import { getAccountsListFilter } from "@containers/Account/store/selectors";
import { getUserDetails } from "@shared/store/selectors";
import { actions } from "@shared/store";
import { updateInvestorFile } from "@containers/InvestorFile/store/actions";

import {
  accountPayment,
  activateAccount,
  checkAccount,
  checkPaymentMethodExistence,
  createAccount,
  deactivateAccount,
  getAccount,
  getAccountPaymentStatus,
  getAccounts,
  getAccountPropertyLists,
  createAccountPropertyList,
  getAccountsList,
  getInvoices,
  getSubscription,
  goToBillingPortal,
  goToCheckout,
  payInvoice,
  setActiveAccount,
  setFeatures,
  updateAccount,
  updateSelfAccount,
  updateSubscription,
  linkAccountPropertyListToPropertiesFromFile,
  linkAccountPropertyListToProperty,
  getAccountNotes,
  createAccountNote,
  getAccountAttempts,
  saveAccountAttempts,
} from "./actions";
import api from "../api";

function* checkPaymentMethodExistenceSaga({ payload }: ReturnType<typeof checkPaymentMethodExistence.request>) {
  const { id, hideLoader } = payload;
  try {
    if (!hideLoader) {
      yield put(startLoading());
    }
    const result: PaymentMethodExistence = yield call(api.checkPaymentMethodExistence, id);
    yield put(checkPaymentMethodExistence.success(result));
  } catch (error) {
    yield put(checkPaymentMethodExistence.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    if (!hideLoader) {
      yield put(stopLoading());
    }
  }
}

function* goToCheckoutSaga({ payload }: ReturnType<typeof goToCheckout>) {
  try {
    yield put(startLoading());
    const { url }: { url: string } = yield call(api.getCheckoutUrl, payload);
    location.replace(url);
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError, "Can't open checkout page. Please try again later."));
  } finally {
    yield put(stopLoading());
  }
}

function* goToBillingPortalSaga({ payload }: ReturnType<typeof goToBillingPortal>) {
  try {
    yield put(startLoading());
    const { url }: { url: string } = yield call(api.getBillingPortalUrl, payload);
    location.replace(url);
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError, "Can't open billing portal. Please try again later."));
  } finally {
    yield put(stopLoading());
  }
}

function* getInvoicesSaga({ payload }: ReturnType<typeof getInvoices.request>) {
  try {
    yield put(startLoading());

    const invoices: Invoice[] = yield call(api.getInvoices, payload);
    yield put(getInvoices.success(invoices));
  } catch (error) {
    yield put(getInvoices.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* payInvoiceSaga({ payload }: ReturnType<typeof payInvoice.request>) {
  try {
    yield put(startLoading());

    yield call(api.payInvoice, payload);
    yield put(payInvoice.success(payload.invoiceId));
  } catch (error) {
    yield put(payInvoice.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError, "Failed! Your payment has failed"));
  } finally {
    yield put(stopLoading());
  }
}

function* getAccountsSaga({ payload }: ActionWithPayload<AccountsFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Account> = yield call(api.getAccounts, payload);
    yield put(
      getAccounts.success({
        ...response,
        clear: payload.page === 1,
      }),
    );
  } catch (error) {
    yield put(getAccounts.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getAccountSaga({ payload }: ActionWithPayload<{ id: number }>) {
  try {
    yield put(startLoading());
    const response: Account = yield call(api.getAccount, payload);
    yield put(getAccount.success(response));
  } catch (error) {
    yield put(getAccount.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getAccountPropertyListsSaga({ payload }: ActionWithPayload<{ id: number }>) {
  try {
    yield put(startLoading());
    const response: AccountPropertyList[] = yield call(api.getAccountPropertyLists, payload);
    yield put(getAccountPropertyLists.success(response));
  } catch (error) {
    yield put(getAccountPropertyLists.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* createAccountPropertyListSaga({ payload }: ActionWithPayload<{ id: number; name: string }>) {
  try {
    yield put(startLoading());
    const response: AccountPropertyList = yield call(api.createAccountPropertyList, payload);
    yield put(createAccountPropertyList.success(response));
  } catch (error) {
    yield put(createAccountPropertyList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getAccountNotesSaga({ payload }: ActionWithPayload<GetAccountEntity>) {
  try {
    yield put(startLoading());
    const res: AccountNote[] = yield call(api.getAccountNotes, payload);
    yield put(getAccountNotes.success({ payload, res }));
  } catch (error) {
    yield put(getAccountNotes.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* createAccountNoteSaga({ payload }: ActionWithPayload<WithCallback<GetAccountEntity & { text: string }>>) {
  try {
    yield put(startLoading());
    const res: AccountNote = yield call(api.createAccountNote, payload);
    yield put(createAccountNote.success({ payload, res }));

    payload.callback?.();
  } catch (error) {
    yield put(createAccountNote.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getAccountAttemptsSaga({ payload }: ActionWithPayload<WithCallback<GetAccountEntity, AccountAttempts>>) {
  try {
    yield put(startLoading());
    const res: AccountAttempts = yield call(api.getAccountAttempts, payload);
    yield put(getAccountAttempts.success({ payload, res }));

    payload.callback?.(res);
  } catch (error) {
    yield put(getAccountAttempts.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* saveAccountAttemptsSaga({ payload }: ActionWithPayload<WithCallback<SaveAccountAttempts, AccountAttempts>>) {
  try {
    if (!payload.hideLoader) yield put(startLoading());
    const res: AccountAttempts = yield call(api.saveAccountAttempts, payload);
    yield put(saveAccountAttempts.success({ payload, res }));

    payload.callback?.(res);
  } catch (error) {
    yield put(saveAccountAttempts.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    if (!payload.hideLoader) yield put(stopLoading());
  }
}

function* linkAccountPropertyListToPropertiesFromFileSaga({
  payload,
}: ActionWithPayload<WithCallback<LinkAccountPropertyListToPropertiesFromFileInterface>>) {
  try {
    yield call(api.linkAccountPropertyListToPropertiesFromFile, payload);

    yield put(
      updateInvestorFile.success({
        id: payload.investorFileId,
        investor_file: {
          property_list_files: payload.listIds.map((id) => ({ property_list_id: id })),
        },
      }),
    );

    payload.callback?.();
  } catch (error) {
    yield put(linkAccountPropertyListToPropertiesFromFile.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  }
}

function* linkAccountPropertyListToPropertySaga({
  payload,
}: ActionWithPayload<WithCallback<LinkAccountPropertyListToPropertyInterface>>) {
  try {
    yield put(startLoading());
    yield call(api.linkAccountPropertyListToProperty, payload);

    payload.callback?.();
  } catch (error) {
    yield put(linkAccountPropertyListToProperty.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getAccountsListSaga({ payload }: ActionWithPayload<AccountsFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<Account> = yield call(api.getAccounts, payload);
    yield put(
      getAccountsList.success({
        ...response,
        clear: payload.page === 1,
      }),
    );
  } catch (error) {
    yield put(getAccountsList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* createAccountSaga({ payload }: ActionWithPayload<WithCallback<CreateAccountInterface>>) {
  try {
    const { callback, ...data } = payload;
    yield put(startLoading());

    yield call(api.createAccount, data);

    const filter: AccountsFilter = yield select(getAccountsListFilter());
    yield put(getAccountsList.request(filter));

    yield put(
      showNotification({
        message: "The company was successfully created.",
        appearance: "success",
      }),
    );

    callback?.();
  } catch (error) {
    yield put(createAccount.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* updateAccountSaga({ payload }: ActionWithPayload<WithCallback<UpdateAccountInterface>>) {
  try {
    const { callback, ...data } = payload;

    yield put(startLoading());

    yield call(api.updateAccount, data);

    const filter: AccountsFilter = yield select(getAccountsListFilter());
    yield put(getAccountsList.request(filter));

    callback?.();
    yield put(
      showNotification({
        message: "The company was successfully updated.",
        appearance: "success",
      }),
    );
  } catch (error) {
    yield put(updateAccount.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* updateSelfAccountSaga({ payload }: ActionWithPayload<UpdateAccountInterface>) {
  try {
    yield put(startLoading());

    yield call(api.updateAccount, payload);

    yield put(actions.getUserDetails.request());
  } catch (error) {
    yield put(updateSelfAccount.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* checkAccountSaga({ payload }: ActionWithPayload<CheckAccountShape>) {
  try {
    yield put(startLoading());

    const { isChargebeeIdValid }: { isChargebeeIdValid: boolean } = yield call(api.checkAccount, payload);
    yield put(checkAccount.success({ filledChargebeeId: payload.chargebeeId, isChargebeeIdValid }));
  } catch (error) {
    yield put(checkAccount.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* activateAccountSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());

    yield call(api.activateAccount, payload);

    const filter: AccountsFilter = yield select(getAccountsListFilter());
    yield put(getAccountsList.request(filter));
  } catch (error) {
    yield put(updateAccount.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* deactivateAccountSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());

    yield call(api.deactivateAccount, payload);

    const filter: AccountsFilter = yield select(getAccountsListFilter());
    yield put(getAccountsList.request(filter));
  } catch (error) {
    yield put(updateAccount.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getSubscriptionSaga({ payload }: ActionWithPayload<number>) {
  try {
    yield put(startLoading());

    const response: Subscription = yield call(api.getSubscription, payload);
    yield put(getSubscription.success(response));
  } catch (error) {
    yield put(getSubscription.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* updateSubscriptionSaga({ payload }: ActionWithPayload<WithCallback<UpdateSubscriptionInterface>>) {
  try {
    const { id, counties, accountId, callback } = payload;
    yield put(startLoading());

    const response: Subscription = yield call(api.updateSubscription, id, counties);
    yield put(updateSubscription.success(response));
    yield put(getAccount.request({ id: accountId }));
    callback?.();
    yield put(
      showNotification({
        message: "The subscription was successfully updated.",
        appearance: "success",
      }),
    );
  } catch (error) {
    yield put(updateSubscription.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* setActiveAccountSaga({ payload: accountId }: ActionWithPayload<number>) {
  LocalStorageActiveAccountId.set(accountId);

  const { accounts }: User = yield select(getUserDetails());

  const selectedAccount = accounts.find(({ id }) => id === accountId) || accounts[0];

  yield put(setActiveAccount.success(selectedAccount));
}

function* accountPaymentSaga({ payload }: ActionWithPayload<{ accountId: number; amount: number }>) {
  try {
    yield put(startLoading());
    const result: { id: number; status: string } = yield call(api.accountPayment, payload);
    yield put(accountPayment.success(result));
  } catch (error) {
    yield put(accountPayment.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getAccountPaymentStatusSaga({ payload }: ActionWithPayload<{ accountId: number; invoiceId: number }>) {
  try {
    const result: { id: number; status: string } = yield call(api.getAccountInvoice, payload);
    yield put(getAccountPaymentStatus.success(result));
  } catch (error) {
    yield put(getAccountPaymentStatus.failure(error as Error));
  }
}

function* featuresSaga({ payload: account }: ActionWithPayload<Account>) {
  yield put(startLoading());

  try {
    const result: { features: Feature[] } = yield call(api.getFeatures, account.id);

    yield put(setFeatures(result.features));
  } catch (error) {
    yield put(showHttpErrorNotification(error as Error));
    yield put(setFeatures([]));
  } finally {
    yield put(stopLoading());
  }
}

function* accountSagas() {
  yield takeLatest(checkPaymentMethodExistence.request, checkPaymentMethodExistenceSaga);
  yield takeLatest(goToCheckout, goToCheckoutSaga);
  yield takeLatest(goToBillingPortal, goToBillingPortalSaga);
  yield takeLatest(getInvoices.request, getInvoicesSaga);
  yield takeLatest(payInvoice.request, payInvoiceSaga);
  yield takeLeading(getAccounts.request, getAccountsSaga);
  yield takeLatest(getAccountsList.request, getAccountsListSaga);
  yield takeLatest(getAccount.request, getAccountSaga);
  yield takeLatest(getAccountPropertyLists.request, getAccountPropertyListsSaga);
  yield takeLatest(createAccountPropertyList.request, createAccountPropertyListSaga);
  yield takeLatest(getAccountNotes.request, getAccountNotesSaga);
  yield takeLatest(createAccountNote.request, createAccountNoteSaga);
  yield takeLatest(getAccountAttempts.request, getAccountAttemptsSaga);
  yield debounce(500, saveAccountAttempts.request, saveAccountAttemptsSaga);
  yield takeLatest(
    linkAccountPropertyListToPropertiesFromFile.request,
    linkAccountPropertyListToPropertiesFromFileSaga,
  );
  yield takeLatest(linkAccountPropertyListToProperty.request, linkAccountPropertyListToPropertySaga);
  yield takeLatest(createAccount.request, createAccountSaga);
  yield takeLatest(updateAccount.request, updateAccountSaga);
  yield takeLatest(updateSelfAccount.request, updateSelfAccountSaga);
  yield takeLatest(checkAccount.request, checkAccountSaga);
  yield takeLatest(getSubscription.request, getSubscriptionSaga);
  yield takeLatest(updateSubscription.request, updateSubscriptionSaga);
  yield takeLatest(setActiveAccount.request, setActiveAccountSaga);
  yield takeLatest(setActiveAccount.success, featuresSaga);
  yield takeLatest(activateAccount, activateAccountSaga);
  yield takeLatest(deactivateAccount, deactivateAccountSaga);
  yield takeLatest(accountPayment.request, accountPaymentSaga);
  yield takeLatest(getAccountPaymentStatus.request, getAccountPaymentStatusSaga);
}

export default accountSagas;
