import { Account } from "@shared/models";
import { useDispatch, useSelector } from "react-redux";
import * as contactStore from "@containers/Contact/store";
import * as accountStore from "@containers/Account/store";
import { actions as sharedActions } from "@shared/store";
import React, { useCallback, useEffect } from "react";
import { MODAL_TYPES } from "@shared/constants";
import { CompanyForm } from "@containers/Account/components/CompanyCreateEditContainer/CompanyForm";
import { CreateAccountInterface } from "@containers/Account/interface/CreateAccount.interface";

interface CompanyCreateEditContainerProps {
  onClose: () => void;
  account?: Account;
  callback?: () => void;
}

const CompanyCreateEditContainer = (props: CompanyCreateEditContainerProps) => {
  const { onClose, account, callback } = props;
  const dispatch = useDispatch();

  const selectedAccount = useSelector(accountStore.selectors.getSelectedAccount());

  useEffect(() => {
    if (account && !selectedAccount) {
      dispatch(accountStore.actions.getAccount.request({ id: account.id }));
    }
  }, [dispatch, account, selectedAccount]);

  useEffect(() => {
    return () => {
      dispatch(accountStore.actions.clearAccount());
      dispatch(contactStore.actions.clearStates());
      dispatch(contactStore.actions.clearCounties());
    };
  }, [dispatch]);

  const handleDeactivateAccount = useCallback(() => {
    if (account) {
      dispatch(
        sharedActions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "Deactivate Company",
            content: `Are you sure you want to deactivate ${account.name}?`,
            cancelBtnText: "Cancel",
            successBtnText: "Deactivate",
            successBtnStyle: "clear-red",
            onSuccess: () => {
              dispatch(accountStore.actions.deactivateAccount(account?.id));
              dispatch(sharedActions.hideOverlay());
            },
          },
        }),
      );
    }
  }, [account, dispatch]);

  const handleActivateAccount = useCallback(() => {
    if (account) {
      dispatch(
        sharedActions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "Activate Company",
            content: `Are you sure you want to activate ${account.name}?`,
            cancelBtnText: "Cancel",
            successBtnText: "Activate",
            successBtnStyle: "get-output",
            onSuccess: () => {
              dispatch(accountStore.actions.activateAccount(account?.id));
              dispatch(sharedActions.hideOverlay());
            },
          },
        }),
      );
    }
  }, [account, dispatch]);

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: CreateAccountInterface) => {
      if (account) {
        return dispatch(accountStore.actions.updateAccount.request({ ...payload, id: Number(account.id), callback }));
      }

      return dispatch(accountStore.actions.createAccount.request({ ...payload, callback }));
    },
    [dispatch, account, callback],
  );

  return (
    <div>
      <CompanyForm
        account={selectedAccount}
        onDelete={handleDeactivateAccount}
        onActivate={handleActivateAccount}
        onChangeForm={changeFormValues}
        onCloseForm={onClose}
        onSubmitForm={handleSubmitForm}
      />
    </div>
  );
};

export default CompanyCreateEditContainer;
