import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

export type ButtonType = "button" | "submit";
export type ButtonStyle =
  | "link"
  | "accent"
  | "accent-green"
  | "accent-blue"
  | "accent-red"
  | "secondary-blue"
  | "bordered"
  | "draft"
  | "clear"
  | "clear-red"
  | "get-output"
  | "get-output-red"
  | "get-original"
  | "form-cancel"
  | "view"
  | "edit"
  | "royal-blue"
  | "blue-bordered"
  | "unsend";

export interface ButtonProps {
  type?: ButtonType;
  buttonStyle?: ButtonStyle;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  block?: boolean;
  children: ReactNode;
}

const disabledStyle = css`
  &:disabled {
    background: #9e9e9e;
    color: #fff;
    cursor: not-allowed;
  }
`;

//TODO: make import background from css module
const accentStyle = css`
  background: black;
  color: #fff;
  ${disabledStyle}
`;

const linkStyle = css`
  display: inline-block;
  color: #0a5dc2;
  font-size: inherit;
  font-weight: inherit;
  background-color: inherit;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
`;

const accentGeenStyle = css`
  background: #5cbfa2;
  color: #fff;
  border-radius: 16px;
  font-weight: 700;
  font-size: 16px;
  ${disabledStyle}
`;

const accentRedStyle = css`
  background: #e9587b;
  color: #fff;
  border-radius: 16px;
  font-weight: 700;
  font-size: 16px;
  ${disabledStyle}
`;

const borderedStyle = css`
  background: white;
  color: #292929;
  border: 1px solid #292929;
  ${disabledStyle}
`;

const draftStyle = css`
  background: transparent;
  color: #e4611e;
  border: 2px solid #e4611e;
  font-weight: 600;
  &:disabled {
    border-color: #bbb;
    color: #bbb;
    cursor: not-allowed;
  }
`;

const clearStyle = css`
  background: transparent;
  color: #3b445a;
  border: none;
  padding: 0;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    text-decoration: none;
  }
`;
const clearRedStyle = css`
  background: transparent;
  color: #fe637f;
  border: none;
  padding: 0;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    text-decoration: none;
  }
`;

const getOutputStyle = css`
  display: flex;
  align-items: center;
  background: transparent;
  color: #5cbfa2;
  border: none;
  padding: 0;
  font-weight: 700;
  @media only screen and (max-width: 1440px) {
    font-size: 12px;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const getOutputRedStyle = css`
  display: flex;
  align-items: center;
  background: transparent;
  color: #e9587b;
  border: none;
  padding: 0;
  font-weight: 700;
  @media only screen and (max-width: 1440px) {
    font-size: 12px;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const getUnsendStyle = css`
  display: flex;
  align-items: center;
  background: transparent;
  color: #e9587b;
  border: none;
  padding: 0;
  font-weight: 700;
  @media only screen and (max-width: 1440px) {
    font-size: 12px;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const getOriginalStyle = css`
  display: flex;
  align-items: center;
  background: transparent;
  color: #1e57c7;
  border: none;
  padding: 0;
  font-weight: 700;
  @media only screen and (max-width: 1440px) {
    font-size: 12px;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const getFormCancelStyle = css`
  display: flex;
  align-items: center;
  background: transparent;
  color: #1e57c7;
  border: none;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  &:hover {
    text-decoration: underline;
  }
`;

const accentBlueStyle = css`
  background: #3eb6fe;
  color: #ffffff;
  border-radius: 0;
  ${disabledStyle}
`;

const royalBlueStyle = css`
  background-color: #1e57c7;
  color: white;
`;

const getViewStyle = css`
  display: flex;
  align-items: center;
  background: transparent;
  color: #1e57c7;
  border: none;
  padding: 0;
  font-weight: 700;
  @media only screen and (max-width: 1440px) {
    font-size: 12px;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const getEditStyle = css`
  display: flex;
  align-items: center;
  background: transparent;
  color: #5cbfa2;
  border: none;
  padding: 0;
  font-weight: 700;
  @media only screen and (max-width: 1440px) {
    font-size: 12px;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const getBlueBorderedStyle = css`
  text-align: center;
  color: #1e57c7;
  border: 2px solid #1e57c7;
  background: transparent;
  border-radius: 16px;
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    text-decoration: none;
  }
`;

function getButtonStyle(props: ButtonProps) {
  switch (props.buttonStyle) {
    case "link":
      return linkStyle;
    case "bordered":
      return borderedStyle;
    case "draft":
      return draftStyle;
    case "clear":
      return clearStyle;
    case "clear-red":
      return clearRedStyle;
    case "accent-green":
      return accentGeenStyle;
    case "accent-blue":
      return accentBlueStyle;
    case "accent-red":
      return accentRedStyle;
    case "royal-blue":
      return royalBlueStyle;
    case "get-output":
      return getOutputStyle;
    case "get-output-red":
      return getOutputRedStyle;
    case "form-cancel":
      return getFormCancelStyle;
    case "get-original":
      return getOriginalStyle;
    case "view":
      return getViewStyle;
    case "edit":
      return getEditStyle;
    case "blue-bordered":
      return getBlueBorderedStyle;
    case "unsend":
      return getUnsendStyle;
    default:
      return accentStyle;
  }
}

const ButtonComponent = styled.button`
  padding: 16px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  ${(props: ButtonProps) => getButtonStyle(props)}
  ${({ block }: ButtonProps) => (block ? "display: block; width: 100%;" : "")}
`;

const Button: React.FC<ButtonProps> = (props) => {
  return <ButtonComponent {...props}>{props.children}</ButtonComponent>;
};

export default Button;
