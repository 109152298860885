import { createAction, createAsyncAction } from "typesafe-actions";
import {
  AWSUpload,
  AWSUploadMultiple,
  AWSUploadMultipleItemFailure,
  AWSUploadMultipleItemSuccess,
  AWSUploadMultipleSuccess,
  AWSUploadSuccess,
  LoadingType,
  NavigationRoute,
  WithCallback,
} from "@shared/interfaces";
import { County, PropertyType, State, User, ZipCode } from "@shared/models";
import { Neighborhood } from "@shared/models/Neighborhood";
import { ProgressScreenProps } from "@containers/ProgressScreen";

import { Notification, ModalComponent, OverlayComponent } from "../interfaces";
import { SharedActionTypes } from "./constants";

export const startLoading = createAction(SharedActionTypes.START_LOADING)();
export const stopLoading = createAction(SharedActionTypes.STOP_LOADING)();
export const addLoadingType = createAction(SharedActionTypes.ADD_LOADING_TYPE)<LoadingType>();
export const removeLoadingType = createAction(SharedActionTypes.REMOVE_LOADING_TYPE)<string>();

export const addLoadingSection = createAction(SharedActionTypes.ADD_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();
export const removeLoadingSection = createAction(SharedActionTypes.REMOVE_LOADING_SECTION)<{
  loadingSection: string;
  requestName: string;
}>();

export const showNotification = createAction(SharedActionTypes.SHOW_NOTIFICATION)<Notification>();

export const navigate = createAction(SharedActionTypes.NAVIGATE)<NavigationRoute | string | number | null>();

export const showOverlay = createAction(SharedActionTypes.SHOW_OVERLAY)<OverlayComponent>();
export const hideOverlay = createAction(SharedActionTypes.HIDE_OVERLAY)();

export const showModal = createAction(SharedActionTypes.SHOW_MODAL)<ModalComponent>();
export const updateOverlay = createAction(SharedActionTypes.UPDATE_OVERLAY)<Partial<OverlayComponent>>();
export const hideModal = createAction(SharedActionTypes.HIDE_MODAL)();

export const showProgressScreen = createAction(SharedActionTypes.SHOW_PROGRESS_SCREEN)<ProgressScreenProps>();
export const hideProgressScreen = createAction(SharedActionTypes.HIDE_PROGRESS_SCREEN)();

export const uploadToS3 = createAsyncAction(
  SharedActionTypes.UPLOAD_TO_S3,
  SharedActionTypes.UPLOAD_TO_S3_SUCCESS,
  SharedActionTypes.UPLOAD_TO_S3_FAILURE,
)<AWSUpload, AWSUploadSuccess, Error>();

export const getCountiesByState = createAsyncAction(
  SharedActionTypes.GET_COUNTIES_BY_STATE,
  SharedActionTypes.GET_COUNTIES_BY_STATE_SUCCESS,
  SharedActionTypes.GET_COUNTIES_BY_STATE_FAILURE,
)<number | undefined, County[], Error>();

export const getPropertyTypes = createAsyncAction(
  SharedActionTypes.GET_PROPERTY_TYPES,
  SharedActionTypes.GET_PROPERTY_TYPES_SUCCESS,
  SharedActionTypes.GET_PROPERTY_TYPES_FAILURE,
)<undefined, PropertyType[], Error>();

export const getStates = createAsyncAction(
  SharedActionTypes.GET_STATES,
  SharedActionTypes.GET_STATES_SUCCESS,
  SharedActionTypes.GET_STATES_FAILURE,
)<undefined, State[], Error>();

export const getZips = createAsyncAction(
  SharedActionTypes.GET_ZIPS,
  SharedActionTypes.GET_ZIPS_SUCCESS,
  SharedActionTypes.GET_ZIPS_FAILURE,
)<number | undefined, ZipCode[], Error>();

export const getNeighborhoods = createAsyncAction(
  SharedActionTypes.GET_NEIGHBORHOODS,
  SharedActionTypes.GET_NEIGHBORHOODS_SUCCESS,
  SharedActionTypes.GET_NEIGHBORHOODS_FAILURE,
)<number, Neighborhood[], Error>();

export const uploadToS3Multiple = createAsyncAction(
  SharedActionTypes.UPLOAD_TO_S3_MULTIPLE,
  SharedActionTypes.UPLOAD_TO_S3_MULTIPLE_SUCCESS,
  SharedActionTypes.UPLOAD_TO_S3_MULTIPLE_FAILURE,
  SharedActionTypes.UPLOAD_TO_S3_MULTIPLE_CANCEL,
)<AWSUploadMultiple[], AWSUploadMultipleSuccess, Error, number | void>();

export const uploadToS3MultipleItem = createAsyncAction(
  SharedActionTypes.UPLOAD_TO_S3_MULTIPLE_ITEM,
  SharedActionTypes.UPLOAD_TO_S3_MULTIPLE_ITEM_SUCCESS,
  SharedActionTypes.UPLOAD_TO_S3_MULTIPLE_ITEM_FAILURE,
  SharedActionTypes.UPLOAD_TO_S3_MULTIPLE_ITEM_CANCEL,
)<void, AWSUploadMultipleItemSuccess, AWSUploadMultipleItemFailure, number>();

export const getUserDetails = createAsyncAction(
  SharedActionTypes.GET_USER_DETAIL,
  SharedActionTypes.GET_USER_DETAIL_SUCCESS,
  SharedActionTypes.GET_USER_DETAIL_FAILURE,
)<
  WithCallback<{
    hideLoader: boolean;
  } | void> | void,
  User | null,
  Error
>();

export const clearUploadedFile = createAction(SharedActionTypes.CLEAR_FILE)();
export const clearMultipleUploads = createAction(SharedActionTypes.CLEAR_MULTIPLE_UPLOADS)();
export const showBackgroundFilesUploadWidget = createAction(SharedActionTypes.SHOW_BACKGROUND_UPLOAD_WIDGET)();
export const hideBackgroundFilesUploadWidget = createAction(SharedActionTypes.HIDE_BACKGROUND_UPLOAD_WIDGET)();
export const handleCompanyForbiddenError = createAction(SharedActionTypes.HANDLE_FORBIDDEN_ERROR)<{ code: number }>();
