import { MapType } from "@shared/components/MapContainer/MapContainer";
import React, { FC, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { Icon } from "@shared/components";
import { getMap, GoogleMapType, mapTypes, MapTypesEnum } from "@containers/MarketMap/components/MarketMapMap/utils";
import "./index.scss";

type PropsType = Omit<MapType, "mapInstance">;

const GoogleMap: FC<PropsType> = (props) => {
  const [mapType, setMapType] = useState<GoogleMapType>(MapTypesEnum.roadmap);
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = useCallback((type: GoogleMapType) => setMapType(type), []);

  const Map = useMemo(() => getMap(mapType), [mapType]);

  return (
    <div className="maps-wrapper">
      <div className="selector-wrapper" onClick={() => setIsOpen((v) => !v)}>
        <div className="heading">
          <Icon type="details" />
        </div>
        <div className={classNames("menu-wrapper", { collapsed: !isOpen })}>
          <div className="horizontal-divider" />
          {mapTypes.map((type) => (
            <p
              className={classNames("item", { selected: type === mapType })}
              key={type}
              onClick={() => handleChange(type)}
            >
              {type}
            </p>
          ))}
        </div>
      </div>
      <Map {...props} />
    </div>
  );
};

export default GoogleMap;
