import { createAction, createAsyncAction } from "typesafe-actions";
import {
  BaseFilter,
  PaginatedResponse,
  PayPerLeadFilter,
  PayPerLeadStatisticsFilter,
  ResponseError,
} from "@shared/interfaces";
import {
  PayPerLeadStatistics,
  PayPerLeadSubscription,
  PayPerLeadSubscriptionWithCustomer,
  QualifyLeadsPayloadBase,
  QualifyLeadsActionPayload,
  SubscriptionStateItem,
  DownloadLeadsActionPayload,
  PayPerLeadPurchaseInfo,
  GetSubscriptionDetailsPayload,
  LeadFilterOptions,
  SubscriptionIdsWithCallbackPayload,
} from "@containers/PayPerLead/interface";
import {
  AddSubscriptionCountyPayload,
  CacheCountyOptionsResult,
  CacheCountyPriceResult,
  DeleteSubscriptionCountyPayload,
  FetchCountyOptionsPayload,
  FetchCountyPricePayload,
  FetchStateLeadPricePayload,
  FetchStateLeadPriceResponse,
  StatesById,
  SubscriptionsListQuery,
  SubscriptionsListResponse,
  SubscriptionWithCallbackPayload,
  UpdateSubscriptionCountyPayload,
  UpdateSubscriptionItemPayload,
} from "@containers/PayPerLead/interface/Actions";
import { PayPerLead, PayPerLeadDispute, PayPerLeadWithDetail } from "@shared/models/PayPerLead";
import {
  DisputePayPerLead,
  GetDisputesRequest,
  GetDisputesSuccess,
  GetPayPerLeadShape,
  UpdateDisputeRequest,
  UpdateDisputeSuccess,
} from "@containers/PayPerLead/interface/PayPerLeadActions";

import { PayPerLeadActionTypes } from "./constants";

export const getLeadSubscriptions = createAsyncAction(
  PayPerLeadActionTypes.GET_SUBSCRIPTIONS,
  PayPerLeadActionTypes.GET_SUBSCRIPTIONS_SUCCESS,
  PayPerLeadActionTypes.GET_SUBSCRIPTIONS_FAILURE,
)<SubscriptionsListQuery, SubscriptionsListResponse, ResponseError>();

export const getLead = createAsyncAction(
  PayPerLeadActionTypes.GET_LEAD,
  PayPerLeadActionTypes.GET_LEAD_SUCCESS,
  PayPerLeadActionTypes.GET_LEAD_FAILURE,
)<string, PayPerLeadWithDetail | null, ResponseError>();

export const disputeLead = createAsyncAction(
  PayPerLeadActionTypes.DISPUTE_LEAD,
  PayPerLeadActionTypes.DISPUTE_LEAD_SUCCESS,
  PayPerLeadActionTypes.DISPUTE_LEAD_FAILURE,
)<DisputePayPerLead, PayPerLeadDispute | null, ResponseError>();

export const getLeads = createAsyncAction(
  PayPerLeadActionTypes.GET_LEADS,
  PayPerLeadActionTypes.GET_LEADS_SUCCESS,
  PayPerLeadActionTypes.GET_LEADS_FAILURE,
)<GetPayPerLeadShape, PaginatedResponse<PayPerLeadWithDetail> | null, ResponseError>();

export const selectLead = createAction(PayPerLeadActionTypes.SELECT_LEAD)<number>();
export const unselectLead = createAction(PayPerLeadActionTypes.UNSELECT_LEAD)<number>();

export const selectAllLeads = createAction(PayPerLeadActionTypes.SELECT_ALL_LEADS)();
export const unselectAllLeads = createAction(PayPerLeadActionTypes.UNSELECT_ALL_LEADS)();

export const qualifyLeads = createAsyncAction(
  PayPerLeadActionTypes.QUALIFY_LEADS,
  PayPerLeadActionTypes.QUALIFY_LEADS_SUCCESS,
  PayPerLeadActionTypes.QUALIFY_LEADS_FAILURE,
)<QualifyLeadsActionPayload, QualifyLeadsPayloadBase, ResponseError>();

export const downloadLeadsCSV = createAsyncAction(
  PayPerLeadActionTypes.DOWNLOAD_LEADS_CSV,
  PayPerLeadActionTypes.DOWNLOAD_LEADS_CSV_SUCCESS,
  PayPerLeadActionTypes.DOWNLOAD_LEADS_CSV_FAILURE,
)<DownloadLeadsActionPayload, undefined, ResponseError>();

export const getCurrentMonthLeads = createAsyncAction(
  PayPerLeadActionTypes.GET_CURRENT_MONTH_LEADS,
  PayPerLeadActionTypes.GET_CURRENT_MONTH_SUCCESS,
  PayPerLeadActionTypes.GET_CURRENT_MONTH_FAILURE,
)<GetPayPerLeadShape, PaginatedResponse<PayPerLead> | null, ResponseError>();

export const getSubscriptionDetails = createAsyncAction(
  PayPerLeadActionTypes.GET_SUBSCRIPTION_DETAILS,
  PayPerLeadActionTypes.GET_SUBSCRIPTION_DETAILS_SUCCESS,
  PayPerLeadActionTypes.GET_SUBSCRIPTION_DETAILS_FAILURE,
)<GetSubscriptionDetailsPayload, PayPerLeadSubscription, ResponseError>();

export const getSubscriptionsWithCustomers = createAsyncAction(
  PayPerLeadActionTypes.GET_CUSTOMERS,
  PayPerLeadActionTypes.GET_CUSTOMERS_SUCCESS,
  PayPerLeadActionTypes.GET_CUSTOMERS_FAILURE,
)<BaseFilter, PaginatedResponse<PayPerLeadSubscriptionWithCustomer> | null, ResponseError>();

export const deactivateSubscription = createAsyncAction(
  PayPerLeadActionTypes.DEACTIVATE_SUBSCRIPTION,
  PayPerLeadActionTypes.DEACTIVATE_SUBSCRIPTION_SUCCESS,
  PayPerLeadActionTypes.DEACTIVATE_SUBSCRIPTION_FAILURE,
)<SubscriptionWithCallbackPayload, PayPerLeadSubscription, ResponseError>();

export const addSubscription = createAction(PayPerLeadActionTypes.ADD_SUBSCRIPTION)<PayPerLeadSubscription>();
export const addSubscriptionItem = createAction(PayPerLeadActionTypes.ADD_SUBSCRIPTION_ITEM)();

export const resetSubscription = createAction(PayPerLeadActionTypes.RESET_SUBSCRIPTION)();

export const cacheStatesOptions = createAction(PayPerLeadActionTypes.CACHE_STATE_OPTIONS)<StatesById>();
export const fetchCountyOptions = createAsyncAction(
  PayPerLeadActionTypes.FETCH_COUNTY_OPTIONS,
  PayPerLeadActionTypes.FETCH_COUNTY_OPTIONS_SUCCESS,
  PayPerLeadActionTypes.FETCH_COUNTY_OPTIONS_FAILURE,
)<FetchCountyOptionsPayload, CacheCountyOptionsResult, ResponseError>();

export const fetchCountyPrice = createAsyncAction(
  PayPerLeadActionTypes.FETCH_COUNTY_PRICE,
  PayPerLeadActionTypes.FETCH_COUNTY_PRICE_SUCCESS,
  PayPerLeadActionTypes.FETCH_COUNTY_PRICE_FAILURE,
)<FetchCountyPricePayload, CacheCountyPriceResult, ResponseError>();

export const fetchStateLeadPrice = createAsyncAction(
  PayPerLeadActionTypes.FETCH_STATE_LEAD_PRICE,
  PayPerLeadActionTypes.FETCH_STATE_LEAD_PRICE_SUCCESS,
  PayPerLeadActionTypes.FETCH_STATE_LEAD_PRICE_FAILURE,
)<FetchStateLeadPricePayload, FetchStateLeadPriceResponse, ResponseError>();

export const fetchFilterOptions = createAsyncAction(
  PayPerLeadActionTypes.FETCH_FILTER_OPTIONS,
  PayPerLeadActionTypes.FETCH_FILTER_OPTIONS_SUCCESS,
  PayPerLeadActionTypes.FETCH_FILTER_OPTIONS_FAILURE,
)<undefined, LeadFilterOptions, ResponseError>();

export const resetFilterOptions = createAction(PayPerLeadActionTypes.RESET_FILTER_OPTIONS)();

export const clearCache = createAction(PayPerLeadActionTypes.CLEAR_CACHE)();

export const purchaseSubscription = createAsyncAction(
  PayPerLeadActionTypes.PURCHASE_SUBSCRIPTION,
  PayPerLeadActionTypes.PURCHASE_SUBSCRIPTION_SUCCESS,
  PayPerLeadActionTypes.PURCHASE_SUBSCRIPTION_FAILURE,
)<SubscriptionWithCallbackPayload, PayPerLeadSubscription, ResponseError>();

export const purchaseSubscriptions = createAsyncAction(
  PayPerLeadActionTypes.PURCHASE_SUBSCRIPTIONS,
  PayPerLeadActionTypes.PURCHASE_SUBSCRIPTIONS_SUCCESS,
  PayPerLeadActionTypes.PURCHASE_SUBSCRIPTIONS_FAILURE,
)<PayPerLeadSubscription[], PayPerLeadSubscription[], ResponseError>();

export const awaitPurchaseProcessed = createAsyncAction(
  PayPerLeadActionTypes.AWAIT_PURCHASE_PROCESSED,
  PayPerLeadActionTypes.AWAIT_PURCHASE_PROCESSED_SUCCESS,
  PayPerLeadActionTypes.AWAIT_PURCHASE_PROCESSED_FAILURE,
)<SubscriptionWithCallbackPayload, PayPerLeadSubscription, ResponseError>();

export const fetchPurchaseInfo = createAsyncAction(
  PayPerLeadActionTypes.FETCH_PURCHASE_INFO,
  PayPerLeadActionTypes.FETCH_PURCHASE_INFO_SUCCESS,
  PayPerLeadActionTypes.FETCH_PURCHASE_INFO_FAILURE,
)<number, PayPerLeadPurchaseInfo, ResponseError>();

export const fetchTotalPaymentAmount = createAsyncAction(
  PayPerLeadActionTypes.FETCH_TOTAL_PAYMENT_AMOUNT,
  PayPerLeadActionTypes.FETCH_TOTAL_PAYMENT_AMOUNT_SUCCESS,
  PayPerLeadActionTypes.FETCH_TOTAL_PAYMENT_AMOUNT_FAILURE,
)<SubscriptionIdsWithCallbackPayload, void, ResponseError>();

export const saveSubscription = createAsyncAction(
  PayPerLeadActionTypes.SAVE_SUBSCRIPTION,
  PayPerLeadActionTypes.SAVE_SUBSCRIPTION_SUCCESS,
  PayPerLeadActionTypes.SAVE_SUBSCRIPTION_FAILURE,
)<SubscriptionWithCallbackPayload, PayPerLeadSubscription, ResponseError>();

export const setSubscriptionTouched = createAction(PayPerLeadActionTypes.SET_SUBSCRIPTION_TOUCHED)();

export const updateSubscriptionItem = createAction(
  PayPerLeadActionTypes.UPDATE_SUBSCRIPTION_ITEM,
)<UpdateSubscriptionItemPayload>();

export const updateFilter = createAction(PayPerLeadActionTypes.UPDATE_FILTER)<Partial<PayPerLeadFilter>>();

export const setStatisticsFilter = createAction(
  PayPerLeadActionTypes.SET_STATISTICS_FILTER,
)<Partial<PayPerLeadStatisticsFilter> | null>();

export const deleteSubscriptionItem = createAction(
  PayPerLeadActionTypes.DELETE_SUBSCRIPTION_ITEM,
)<SubscriptionStateItem>();

export const addSubscriptionCounty = createAction(
  PayPerLeadActionTypes.ADD_SUBSCRIPTION_COUNTY,
)<AddSubscriptionCountyPayload>();

export const updateSubscriptionCounty = createAction(
  PayPerLeadActionTypes.UPDATE_SUBSCRIPTION_COUNTY,
)<UpdateSubscriptionCountyPayload>();

export const deleteSubscriptionCounty = createAction(
  PayPerLeadActionTypes.DELETE_SUBSCRIPTION_COUNTY,
)<DeleteSubscriptionCountyPayload>();

export const getDisputes = createAsyncAction(
  PayPerLeadActionTypes.GET_ADMIN_DISPUTES_REQUEST,
  PayPerLeadActionTypes.GET_ADMIN_DISPUTES_SUCCESS,
  PayPerLeadActionTypes.GET_ADMIN_DISPUTES_FAILURE,
)<GetDisputesRequest, GetDisputesSuccess, ResponseError>();

export const updateDispute = createAsyncAction(
  PayPerLeadActionTypes.UPDATE_ADMIN_DISPUTE_REQUEST,
  PayPerLeadActionTypes.UPDATE_ADMIN_DISPUTE_SUCCESS,
  PayPerLeadActionTypes.UPDATE_ADMIN_DISPUTE_FAILURE,
)<UpdateDisputeRequest, UpdateDisputeSuccess, ResponseError>();

export const getStatistics = createAsyncAction(
  PayPerLeadActionTypes.GET_STATISTICS,
  PayPerLeadActionTypes.GET_STATISTICS_SUCCESS,
  PayPerLeadActionTypes.GET_STATISTICS_FAILURE,
)<PayPerLeadStatisticsFilter, PayPerLeadStatistics | null, ResponseError>();

export const cleanup = createAction(PayPerLeadActionTypes.CLEANUP)();
