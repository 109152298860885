import { ButtonStyle } from "@shared/components";

export enum ModalSize {
  NORMAL = "normal",
  EXTENDED = "extended",
  RESIZABLE = "resizable",
}

export interface ModalComponent {
  type: string;
  draggable?: boolean;
  resizable?: boolean;
  closeIcon?: boolean;
  size?: ModalSize;
  className?: string;
  props: {
    onClose?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess?: (value?: any) => void;
    heading?: string;
    content?: string;
    cancelBtnText?: string;
    successBtnText?: string;
    successBtnStyle?: ButtonStyle;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
  };
}
