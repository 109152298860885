export enum SIMPLIFIED_COLUMN_TYPE {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  DATE = "date",
}

export interface PresetField {
  column: string;
  type: SIMPLIFIED_COLUMN_TYPE;
}
