import React from "react";

import "./index.scss";

export interface TabBarItemInterface {
  label: string;
  isAccess?: boolean;
  children: React.ReactNode;
}

const TabBarItem: React.FC<TabBarItemInterface> = (props) => {
  const { children } = props;

  return <div className="tab-bar-item">{children}</div>;
};

export default TabBarItem;
