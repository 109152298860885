import { BuyBoxFilters } from "@shared/interfaces";

const DEFAULT_BUY_BOX_FILTERS: BuyBoxFilters = {
  area_building: [100, 4000],
  year_built: [1800, 2024],
  property_value: [100, 2_000_000],
  bedrooms_count: [1, 3],
  bath_count: [1, 2],
};

export default DEFAULT_BUY_BOX_FILTERS;
