import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { selectors, actions } from "@shared/store";
import { MODAL_TYPES } from "@shared/constants";
import { ModalSize } from "@shared/interfaces";
import { useDraggable, useResize } from "@shared/hooks";
import {
  Icon,
  ModalAccountPayment,
  ModalCreatePropertyList,
  ModalGenerateFile,
  ModalDownloadCsvPropertyRecords,
  ModalAddPropertiesFromMonthlyFile,
  ModalNewPropertyList,
} from "@shared/components";
import { ModalDisputeLead } from "@containers/PayPerLead/components/PayPerLeadDashboardContainer";

import { ModalConfirm } from "../ModalConfirm";

import "./index.scss";

const ModalWrapper: FC = () => {
  const dispatch = useDispatch();
  const dragElementRef = useRef<HTMLDivElement>(null);
  const modalElementRef = useRef<HTMLDivElement>(null);
  const resizerElementRef = useRef<HTMLDivElement>(null);

  const { dragging } = useDraggable(dragElementRef, modalElementRef);
  useResize(resizerElementRef, modalElementRef);

  const modal = useSelector(selectors.getModal());

  const onClose = useCallback(() => {
    if (modal?.props.onClose) {
      modal.props.onClose();
    }

    dispatch(actions.hideModal());
  }, [modal, dispatch]);

  const onSuccess = useCallback(
    (value?: unknown) => {
      if (modal?.props.onSuccess) {
        modal.props.onSuccess(value);
      }

      dispatch(actions.hideModal());
    },
    [modal, dispatch],
  );

  const Component = useMemo(() => {
    if (!modal) return;
    return modalStrategy(modal.type);
  }, [modal]);

  useEffect(() => {
    const original = document.body.style.overflow;
    if (modal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = original;
    };
  }, [modal]);

  return modal ? (
    <>
      {!modal.draggable && <div className="dynamic-modal-overlay" />}
      <div
        className={classnames("dynamic-modal-wrapper", `${modal.type?.toLowerCase()}`, {
          "dynamic-modal-wrapper_draggable": modal.draggable,
        })}
      >
        <div
          ref={modalElementRef}
          className={classnames(
            "dynamic-modal",
            "box",
            modal.className,
            `dynamic-modal_size-${modal.size || ModalSize.NORMAL}`,
            {
              "dynamic-modal_dragging": dragging,
            },
          )}
        >
          {modal.draggable && (
            <div ref={dragElementRef} className="dynamic-modal-drag-panel">
              <span className="dynamic-modal-drag-panel-dot" />
              <span className="dynamic-modal-drag-panel-dot" />
              <span className="dynamic-modal-drag-panel-dot" />
            </div>
          )}
          <div className="dynamic-modal-content">
            {Component ? <Component {...modal.props} onClose={onClose} onSuccess={onSuccess} /> : null}
          </div>
          {modal.resizable && (
            <div ref={resizerElementRef} className="dynamic-modal-resizer">
              ⟷
            </div>
          )}
          {modal.closeIcon && <Icon type="close" className="modal-close" onClick={onClose} />}
        </div>
      </div>
    </>
  ) : null;
};

export default ModalWrapper;

const modalStrategy = (type: string) => {
  switch (type) {
    case MODAL_TYPES.CONFIRM: {
      return ModalConfirm;
    }
    case MODAL_TYPES.GENERATE_FILE: {
      return ModalGenerateFile;
    }
    case MODAL_TYPES.ACCOUNT_PAYMENT: {
      return ModalAccountPayment;
    }
    case MODAL_TYPES.CREATE_PROPERTY_LIST: {
      return ModalCreatePropertyList;
    }
    case MODAL_TYPES.ADD_PROPERTIES_FROM_MONTHLY_FILE: {
      return ModalAddPropertiesFromMonthlyFile;
    }
    case MODAL_TYPES.NEW_PROPERTY_LIST: {
      return ModalNewPropertyList;
    }
    case MODAL_TYPES.DOWNLOAD_CSV_PROPERTY_RECORDS: {
      return ModalDownloadCsvPropertyRecords;
    }
    case MODAL_TYPES.DISPUTE_PAY_PER_LEAD: {
      return ModalDisputeLead;
    }
  }
};
