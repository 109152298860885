import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { logout } from "@containers/Auth/store/actions";

import tokenHandler from "./tokenHandler";
import { store } from "../../index";

axios.defaults.headers.post["Content-Type"] = "application/json";

const axiosInstance: AxiosInstance = axios.create();

const makeRequest = (instance: AxiosInstance) => (method: string, url: string, params: unknown[]) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return instance[method](url, ...params);
};

axiosInstance.interceptors.request.use((config) => {
  const token = tokenHandler.get();

  if (token) {
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    const response = res.data || res;
    if (response.error) {
      return Promise.reject(response.error);
    }
    return response;
  },
  (error: AxiosError) => {
    const { response } = error || {};

    const { data } = response || {};

    if (data) {
      const unauthorized: boolean = (data as { unauthorized: boolean }).unauthorized;

      if (unauthorized) {
        store.dispatch(logout.request());
      }

      return Promise.reject(data);
    }

    return Promise.reject(error);
  },
);
/**
 * Axios wrapper
 *
 * @param  {string} method Method of the request
 * @param  {string} url url of the request
 *
 * @return {object} wrapped axios function that receives params
 */
export default (method: string, url: string) =>
  (...params: unknown[]) =>
    makeRequest(axiosInstance)(method, url, params);

export const download = async (url: string, fileName?: string, onError?: (e?: Event) => void) => {
  if (fileName) {
    const link = document.createElement("a");
    link.href = url;
    fileName && link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } else {
    // TODO: it's a hack for downloading multiple files
    // There is no way to provide a file name with an iframe
    // We should refactor it in the feature, probably split downloading by single and multiple files
    const iframe = document.createElement("iframe");
    iframe.setAttribute("sandbox", "allow-downloads allow-scripts");
    iframe.src = url;
    iframe.setAttribute("style", "display: none");
    if (onError) iframe.onload = onError;
    document.body.appendChild(iframe);
  }
};
