import { createAction, createAsyncAction } from "typesafe-actions";
import { ResponseError } from "@shared/interfaces";
import {
  RequestPropertyPayload,
  SuccessPropertyPayload,
  RequestOwnerListPayload,
  SuccessPropertyListPayload,
  RequestPropertyListPayload,
  SuccessOwnerListPayload,
  SuccessOwnerDetailsPayload,
  SuccessOwnerPropertiesPayload,
  RequestOwnerPropertiesPayload,
  RequestOwnerDetailsPayload,
  RequestUnlinkPropertyPayload,
  SuccessUnlinkPropertyPayload,
  PropertyList,
} from "@shared/interfaces/RecordManagement";

import { RECORD_MANAGEMENT_ACTIONS } from "./constants";

export const getPropertyList = createAsyncAction(
  RECORD_MANAGEMENT_ACTIONS.GET_PROPERTY_LIST_REQUEST,
  RECORD_MANAGEMENT_ACTIONS.GET_PROPERTY_LIST_SUCCESS,
  RECORD_MANAGEMENT_ACTIONS.GET_PROPERTY_LIST_FAILURE,
)<RequestPropertyListPayload, SuccessPropertyListPayload, ResponseError>();

export const clearPropertyList = createAction(RECORD_MANAGEMENT_ACTIONS.CLEAR_PROPERTY_LIST)();

export const getProperty = createAsyncAction(
  RECORD_MANAGEMENT_ACTIONS.GET_PROPERTY_REQUEST,
  RECORD_MANAGEMENT_ACTIONS.GET_PROPERTY_SUCCESS,
  RECORD_MANAGEMENT_ACTIONS.GET_PROPERTY_FAILURE,
)<RequestPropertyPayload, SuccessPropertyPayload, ResponseError>();

export const linkProperty = createAction(RECORD_MANAGEMENT_ACTIONS.LINK_PROPERTY)<PropertyList[]>();

export const unlinkProperty = createAsyncAction(
  RECORD_MANAGEMENT_ACTIONS.UNLINK_PROPERTY_REQUEST,
  RECORD_MANAGEMENT_ACTIONS.UNLINK_PROPERTY_SUCCESS,
  RECORD_MANAGEMENT_ACTIONS.UNLINK_PROPERTY_FAILURE,
)<RequestUnlinkPropertyPayload, SuccessUnlinkPropertyPayload, ResponseError>();

export const getOwnerList = createAsyncAction(
  RECORD_MANAGEMENT_ACTIONS.GET_OWNER_LIST_REQUEST,
  RECORD_MANAGEMENT_ACTIONS.GET_OWNER_LIST_SUCCESS,
  RECORD_MANAGEMENT_ACTIONS.GET_OWNER_LIST_FAILURE,
)<RequestOwnerListPayload, SuccessOwnerListPayload, ResponseError>();

export const getOwnerDetails = createAsyncAction(
  RECORD_MANAGEMENT_ACTIONS.GET_OWNER_DETAILS_REQUEST,
  RECORD_MANAGEMENT_ACTIONS.GET_OWNER_DETAILS_SUCCESS,
  RECORD_MANAGEMENT_ACTIONS.GET_OWNER_DETAILS_FAILURE,
)<RequestOwnerDetailsPayload, SuccessOwnerDetailsPayload, ResponseError>();

export const getOwnerProperties = createAsyncAction(
  RECORD_MANAGEMENT_ACTIONS.GET_OWNER_PROPERTIES_REQUEST,
  RECORD_MANAGEMENT_ACTIONS.GET_OWNER_PROPERTIES_SUCCESS,
  RECORD_MANAGEMENT_ACTIONS.GET_OWNER_PROPERTIES_FAILURE,
)<RequestOwnerPropertiesPayload, SuccessOwnerPropertiesPayload, ResponseError>();
