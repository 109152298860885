import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { Ticket } from "@shared/models/Ticket";

import * as actions from "./actions";
import { TicketStateType, TicketError } from "../interface";

export const initialState: TicketStateType = {
  tickets: null,
  errors: {
    [TicketError.getTicketListError]: null,
    [TicketError.updateTicketError]: null,
  },
};

export const reducer = createReducer<TicketStateType, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getTicketList.request, (state) => {
    return produce(state, (nextState) => {
      nextState.errors[TicketError.getTicketListError] = null;
    });
  })
  .handleAction(actions.getTicketList.success, (state, action) => {
    return produce(state, (nextState) => {
      nextState.tickets = action.payload;
      nextState.errors[TicketError.getTicketListError] = null;
    });
  })
  .handleAction(actions.getTicketList.failure, (state, action) => {
    return produce(state, (nextState) => {
      nextState.errors[TicketError.getTicketListError] = action.payload;
    });
  })
  .handleAction(actions.updateTicket.request, (state) => {
    return produce(state, (nextState) => {
      nextState.errors[TicketError.updateTicketError] = null;
    });
  })
  .handleAction(actions.updateTicket.success, (state, action) => {
    return produce(state, (nextState) => {
      nextState.errors.updateTicketError = null;

      if (nextState.tickets) {
        const index = nextState.tickets.items.findIndex((ticket) => ticket.id === action.payload.id);
        if (index !== -1) {
          nextState.tickets.items[index] = action.payload as Ticket;
        }
      }
    });
  });
