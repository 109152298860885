import React, { FC, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "@containers/PayPerLead/store";

export interface StatePriceProviderProps {
  stateId: number;
  children: ReactNode;
}
export const StatePriceProvider: FC<StatePriceProviderProps> = ({ stateId, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (stateId) {
      dispatch(actions.fetchStateLeadPrice.request({ stateId }));
    }
  }, [dispatch, stateId]);

  return <>{children}</>;
};
