import { PaginatedResponse, PayPerLeadFilter, PayPerLeadStatisticsFilter, ResponseError } from "@shared/interfaces";
import { PayPerLead, PayPerLeadWithDetail, PayPerLeadDispute } from "@shared/models/PayPerLead";
import { Account } from "@shared/models";

import {
  PayPerLeadPurchaseInfo,
  PayPerLeadStatistics,
  PayPerLeadSubscription,
  PayPerLeadSubscriptionWithCustomer,
} from "./PayPerLeadSubscription";
import { LeadPriceByCounty, LeadPriceByState, StatesById } from "./Actions";

export enum PayPerLeadsError {
  getPayPerLeadError = "getPayPerLeadError",
  getPayPerLeadsError = "getPayPerLeadsError",
  getPayPerLeadsOrderError = "getPayPerLeadsOrderError",
  getPayPerCurrentMonthLeadsError = "getPayPerCurrentMonthLeadsError",
  disputePayPerLeadError = "disputePayPerLeadError",
  getPayPerLeadCustomersError = "getPayPerLeadCustomersError",
  getPayPerLeadStatisticsError = "getPayPerLeadStatisticsError",
}

export interface PayPerLeadStateType {
  lead: PayPerLeadWithDetail | null;
  leads: PaginatedResponse<PayPerLeadWithDetail> | null;
  selectedLeadIds: number[];
  currentMonthLeads: PaginatedResponse<PayPerLead> | null;
  filter: PayPerLeadFilter;
  filterOptions?: LeadFilterOptions;
  statisticsFilter: PayPerLeadStatisticsFilter;
  subscriptions: PayPerLeadSubscription[] | null;
  subscription: PayPerLeadSubscription;
  errors: {
    [key in PayPerLeadsError]: ResponseError | null;
  };
  disputes: PaginatedResponse<PayPerLeadDispute> | null;
  customers: PaginatedResponse<PayPerLeadSubscriptionWithCustomer> | null;
  cache: PayPerLeadCacheType;
  statistics: PayPerLeadStatistics | null;
  purchaseInfo: PayPerLeadPurchaseInfo | null;
}

export interface LeadFilterOptions {
  states: number[];
  counties: number[];
  dates?: string[];
  customers?: Account[];
}

export interface PayPerLeadCacheType {
  leadPricesByCounty: LeadPriceByCounty;
  leadPriceByState: LeadPriceByState;
  stateOptions: StatesById;
}
