import { ResponseError } from "@shared/interfaces";
import {
  OWNER_OCCUPIED_VALUES,
  SELECT_INPUT_FIELDS,
  TAGGED_INPUT_FIELDS,
} from "@containers/PresetBuilder/containers/PresetBuilderContainer/constant";

export const getErrMessage = (err: ResponseError | null, field?: string) => {
  if (err && field && err.message) {
    const errMessages = err.message.split(",");
    const fieldErr = errMessages.find((msg) => msg.includes(field));
    if (fieldErr) return formatErrMessage(fieldErr, field as keyof typeof PRESETS_FIELD_MAPPING);
  } else if (err && !field) {
    return err.message;
  }
};

export const parsePresetError = (err: string | undefined) => {
  if (!err) return;
  const errMessages = err.split(",");
  return errMessages
    .map((msg) => {
      const field = msg.match(/"(.*?)"/)?.[1];
      if (!field) return msg;
      return formatErrMessage(msg, field as keyof typeof PRESETS_FIELD_MAPPING);
    })
    .join(", ");
};

const PRESETS_FIELD_MAPPING = {
  name: "Preset Title",
  states: "State",
  counties: "County",
  fileName: "File name",
};

export const formatErrMessage = (message: string, field: keyof typeof PRESETS_FIELD_MAPPING) =>
  message.replace(field, PRESETS_FIELD_MAPPING[field]);

export const isAllowedSelectBlockField = (value: string): value is "property_type" | "owner_occupied" =>
  SELECT_INPUT_FIELDS.includes(value);
export const isAllowedTaggedInputField = (
  value: string,
): value is "counties" | "states" | "zips" | "property_type" | "property_zips" => TAGGED_INPUT_FIELDS.includes(value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAllowedOwnerOccupiedValues = (value: any): value is "Yes" | "No" | "All" =>
  OWNER_OCCUPIED_VALUES.includes(value);
