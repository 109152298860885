import { Feature } from "@shared/models";

const KEY = "features";

export const featuresStorage = {
  get: (): Feature[] => {
    try {
      const json = localStorage.getItem(KEY);

      if (json) {
        return JSON.parse(json);
      }

      return [];
    } catch (error) {
      return [];
    }
  },
  set: (features: Feature[]) => {
    localStorage.setItem(KEY, JSON.stringify(features));
  },
};
