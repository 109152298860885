import React, { useCallback } from "react";
import moment from "moment";
import { FieldItemType } from "@shared/interfaces";
import { DatePicker, ErrorMessage } from "@shared/components";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface DatePickerFieldItem extends RenderField {
  type: FieldItemType.DATEPICKER;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  disablePast?: boolean;
  disabledDate?: (date?: Date) => boolean;
  onChange?: (date: Date | null) => void;
  calendarDefaultDate?: Date;
}

export const GenerateDatepicker: GenerateRenderField<DatePickerFieldItem> = (props) => {
  const {
    name,
    wrapperClass,
    label,
    disabled,
    disablePast,
    disabledDate,
    onChange,
    formikProps: { setFieldValue, values, touched, errors },
    placeholder,
    calendarDefaultDate,
  } = props;

  const handleChange = useCallback(
    (value: moment.Moment | null) => {
      const date = value ? value.toDate() : null;
      setFieldValue(name, date);
      onChange && onChange(date);
    },
    [setFieldValue, onChange, name],
  );

  return (
    <div key={name} id={name}>
      <DatePicker
        label={label}
        className={wrapperClass}
        onChange={handleChange}
        disablePast={disablePast}
        disabledDate={disabledDate}
        disabled={disabled}
        value={values[name] ? moment(values[name]) : null}
        placeholder={placeholder}
        calendarDefaultDate={calendarDefaultDate}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
