import { createAction, createAsyncAction } from "typesafe-actions";
import { HubspotPropertiesHistoryFilter, PaginatedResponse, ResponseError } from "@shared/interfaces";
import { HubspotPropertiesHistory } from "@shared/models";
import { DataDeliveryError } from "@containers/DataDelivery/interface";

import { DataDeliveryActionTypes } from "./constants";

export const getHubspotHistoryList = createAsyncAction(
  DataDeliveryActionTypes.GET_HUBSPOT_HISTORY_LIST,
  DataDeliveryActionTypes.GET_ACCOUNT_HUBSPOT_HISTORY_SUCCESS,
  DataDeliveryActionTypes.GET_ACCOUNT_HUBSPOT_HISTORY_FAILURE,
)<HubspotPropertiesHistoryFilter, PaginatedResponse<HubspotPropertiesHistory>, ResponseError>();

export const updateHubspotHistoryItemProcessingStatus = createAsyncAction(
  DataDeliveryActionTypes.UPDATE_HUBSPOT_HISTORY_ITEM_STATUS,
  DataDeliveryActionTypes.UPDATE_HUBSPOT_HISTORY_ITEM_STATUS_SUCCESS,
  DataDeliveryActionTypes.UPDATE_HUBSPOT_HISTORY_ITEM_STATUS_FAILURE,
)<{ id: number; processed: boolean }, HubspotPropertiesHistory, ResponseError>();

export const setHubspotHistoryFilter = createAction(
  DataDeliveryActionTypes.SET_HUBSPOT_HISTORY_FILTER,
)<HubspotPropertiesHistoryFilter | null>();

export const clearHubspotHistoryList = createAction(DataDeliveryActionTypes.CLEAR_HUBSPOT_HISTORY_LIST)();

export const clearErrors = createAction(DataDeliveryActionTypes.CLEAR_ERRORS)<DataDeliveryError[] | void>();
