import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import {
  GetClaritasStatisticsShape,
  GetClaritasVisitorsFileShape,
  GetClaritasVisitorsShape,
} from "@containers/Claritas/interface";

export default {
  getVisitors: ({ id, ...params }: GetClaritasVisitorsShape) =>
    request(METHODS.GET, API.CLARITAS.GET_VISITORS(id))({ params }),
  getStatistics: ({ id, ...params }: GetClaritasStatisticsShape) =>
    request(METHODS.GET, API.CLARITAS.GET_STATISTICS(id))({ params }),
  getVisitorsFile: ({ id, ...params }: GetClaritasVisitorsFileShape) =>
    request(METHODS.GET, API.CLARITAS.GET_VISITORS_FILE(id))({ responseType: "blob", params }),
};
