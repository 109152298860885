import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as AccountActions, selectors as AccountSelectors } from "@containers/Account/store";
import { allActions as RecordsActions } from "@containers/RecordManagement/store";
import { actions as InvestorFileActions, selectors as InvestorFileSelectors } from "@containers/InvestorFile/store";
import { ProgressScreenStatus } from "@containers/ProgressScreen";
import "./index.scss";
import { INVESTOR_FILE_STATUS, InvestorFile } from "@shared/models";
import { useDebouncedFunction } from "@shared/hooks";
import * as sharedActions from "@shared/store/actions";
import { ORDER_COLUMN_TYPE, ORDER_TYPE } from "@shared/interfaces";
import { getUSFormatedDateTime, getUSFormattedDateShortYear } from "@shared/utils";
import { MODAL_TYPES } from "@shared/constants";

interface Props {
  heading?: string;
  data?: {
    listIds: number[];
    refetch?: { owners?: boolean; properties?: boolean };
  };
  onClose: () => void;
}

const ModalAddPropertiesFromMonthlyFile: FC<Props> = (props) => {
  const { onClose, data } = props;

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const account = useSelector(AccountSelectors.getActiveAccount());
  const files = useSelector(InvestorFileSelectors.getInvestorFileList());

  const handleAdd = useCallback(
    (f: InvestorFile) => {
      if (account?.id && f.investor_file?.id) {
        onClose();

        if (data?.listIds.length) {
          dispatch(
            sharedActions.showProgressScreen({
              title: "Processing Your File",
              subtitle:
                "Please wait for us to take a quick look to your file and mapping so we can generate the best matching results.",
              status: ProgressScreenStatus.Processing,
              totalSteps: 2,
            }),
          );

          dispatch(
            AccountActions.linkAccountPropertyListToPropertiesFromFile.request({
              id: account.id,
              investorFileId: f.id,
              listIds: data.listIds,
              fileId: f.investor_file.id,
              callback: () => {
                dispatch(
                  sharedActions.showProgressScreen({
                    status: ProgressScreenStatus.Completed,
                    currentStep: 2,
                  }),
                );

                if (data?.refetch?.properties) {
                  dispatch(
                    RecordsActions.getPropertyList.request({
                      accountId: account.id,
                      from: 0,
                      size: 10,
                      ...(data?.listIds?.[0] && { listId: data.listIds[0] }),
                    }),
                  );
                }
              },
            }),
          );
        } else {
          dispatch(
            sharedActions.showModal({
              type: MODAL_TYPES.CREATE_PROPERTY_LIST,
              className: "create-property-list",
              props: {
                data: {
                  id: f.id,
                  fileId: f.investor_file.id,
                  onFinished: () => {
                    if (data?.refetch) {
                      const payload = { accountId: account.id, from: 0, size: 10 };

                      if (data.refetch.properties) {
                        dispatch(RecordsActions.getPropertyList.request(payload));
                      }
                      if (data.refetch.owners) {
                        dispatch(RecordsActions.getOwnerList.request(payload));
                      }
                    }
                  },
                },
              },
            }),
          );
        }
      }
    },
    [dispatch, onClose, data?.listIds, data?.refetch, account?.id],
  );

  useEffect(() => {
    if (account?.id) {
      dispatch(
        InvestorFileActions.getInvestorFileList.request({
          account_id: [account.id],
          status: INVESTOR_FILE_STATUS.ACTIVE,
          page,
          limit: 50,
          order: ORDER_COLUMN_TYPE.CREATED_AT,
          direction: ORDER_TYPE.DESC,
        }),
      );
    }
  }, [dispatch, page, account?.id]);

  const onFinishScroll = useCallback(() => {
    if (files?.total && 50 * page < files?.total) {
      setPage((p) => p + 1);
    }
  }, [setPage, files?.total, page]);

  const debouncedFinishScroll = useDebouncedFunction(onFinishScroll);

  const onScroll = useCallback(
    (event: React.SyntheticEvent<Element>) => {
      const maxScroll = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
      if (event.currentTarget.scrollTop >= maxScroll - 1 && debouncedFinishScroll) {
        debouncedFinishScroll();
      }
    },
    [debouncedFinishScroll],
  );

  const groupedFiles = useMemo(() => {
    if (!files?.list) return [];

    const list: Record<string, InvestorFile[]> = files.list.reduce((acc, i) => {
      const key = getUSFormattedDateShortYear(i.last_sent_at || i.created_at, undefined, true).toLocaleUpperCase();

      if (!acc[key]) acc[key] = [];
      acc[key].push(i);
      return acc;
    }, {} as Record<string, InvestorFile[]>);

    return Object.entries(list) as [string, InvestorFile[]][];
  }, [files?.list]);

  return (
    <div className="add-properties-from-monthly-file-modal-content-wrapper">
      <div className="add-properties-from-monthly-file-modal-heading">Choose Monthly File</div>

      <div className="add-properties-from-monthly-file-modal-content" onScroll={onScroll}>
        {groupedFiles.map(([date, fs]) => (
          <div key={date}>
            <div className="row">
              <label>{date}</label>
            </div>

            {fs.map((f) => (
              <div className="row file" key={f.id} onClick={() => handleAdd(f)}>
                <p>{f?.investor_file?.name}</p>
                <p>{getUSFormatedDateTime(f.last_sent_at || f.created_at)}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
export default ModalAddPropertiesFromMonthlyFile;
