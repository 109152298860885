import { PaginatedResponse, ResponseError } from "@shared/interfaces";
import { Ticket } from "@shared/models/Ticket";

export enum TicketError {
  getTicketListError = "getTicketListError",
  updateTicketError = "updateTicketError",
}

export type TicketStateType = {
  tickets: PaginatedResponse<Ticket> | null;
  errors: {
    [key in TicketError]: ResponseError | null;
  };
};
