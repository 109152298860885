import { InvestorFile } from "@shared/models";
import { InvestorFileFilter, ResponseError } from "@shared/interfaces";

export enum InvestorFileError {
  getInvestorFileError = "getInvestorFileError",
  getInvestorFileListError = "getInvestorFileListError",
  processInvestorFileError = "processInvestorFileError",
  createInvestorFileError = "createInvestorFileError",
}

export interface InvestorFileStateType {
  investorFile: InvestorFile | null;
  investorFileList: { list: InvestorFile[]; total: number } | null;
  filter: InvestorFileFilter;

  errors: {
    [key in InvestorFileError]: ResponseError | null;
  };
}
