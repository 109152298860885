import React, { SyntheticEvent } from "react";
import { SelectPicker } from "rsuite";
import { Option } from "@shared/interfaces";
import classnames from "classnames";
import "./index.scss";
import { usePlacement } from "@shared/components/SelectPickerInput/hooks/usePlacement";

interface SelectPickerProps {
  data: Option<string>[];
  searchable: boolean;
  name: string;
  cleanable?: boolean;
  placeholder: string;
  onChange: (value: string | null, event: SyntheticEvent<Element, Event>) => void;
  value: string | null;
  classPrefix?: string;
  className?: string;
  defaultValue?: string;
  dropdownHeight?: number;
  placement?: "auto" | "topStart";
  disableContainerStyles?: boolean;
  useContainer?: boolean;
}

const SelectPickerInput = (props: SelectPickerProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const placement = usePlacement({
    ref,
    dropDownHeight: props.dropdownHeight,
  });

  const _className = classnames(
    "select-picker-input",
    props?.placement === "topStart" ? "transform-button" : "",
    props.disableContainerStyles ? "reset-styles" : "",
  );

  return (
    <div className={_className} ref={ref}>
      <SelectPicker
        {...props}
        placement={props.placement || placement}
        container={props.useContainer && ref && ref.current ? () => ref.current as HTMLDivElement : undefined}
      />
    </div>
  );
};

export default SelectPickerInput;
