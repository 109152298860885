import React, { FC, useEffect, useRef } from "react";
import { Button, ErrorMessage, Input } from "@shared/components";
import { Formik, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { actions as accountActions, selectors as accountSelectors } from "@containers/Account/store";
import { getActiveAccount } from "@containers/Account/store/selectors";

import { formValidationSchema } from "./formHelper";
import "./index.scss";

const amountOptions = [50, 100, 150, 200];

interface ModalAccountPaymentProps {
  heading?: string;
  content?: string;
  data?: { amount: number };
  onClose: () => void;
  onSuccess: () => void;
}

const ModalAccountPayment: FC<ModalAccountPaymentProps> = (props) => {
  const dispatch = useDispatch();
  const { heading, content, onClose, data, onSuccess } = props;
  const formValues = { amount: data?.amount || 0 };
  const formikRef = useRef<FormikProps<{ amount: number }>>(null);
  const hasCardPaymentMethod = useSelector(accountSelectors.selectCardPaymentMethodExistence());

  const account = useSelector(getActiveAccount());
  const accountId = account?.id;

  useEffect(() => {
    if (accountId) {
      dispatch(accountActions.checkPaymentMethodExistence.request({ id: accountId }));
    }
  }, [dispatch, accountId]);

  const handleSubmit = () => {
    const amount = formikRef?.current?.values.amount;
    if (!hasCardPaymentMethod) {
      const returnUrl = `${location.href}${location.search ? "&" : "?"}amount=${amount}`;
      dispatch(
        accountActions.goToCheckout({
          id: accountId as number,
          successUrl: `${returnUrl}`,
          cancelUrl: `${returnUrl}`,
        }),
      );
    } else {
      dispatch(
        accountActions.accountPayment.request({
          accountId: accountId as number,
          amount: Math.round((amount as number) * 100),
        }),
      );
      onSuccess();
    }
  };

  const amountOptionSelectHandler = (value: number) => {
    formikRef?.current?.setFieldValue("amount", value);
  };

  return (
    <div className="account-payment-modal-content-wrapper">
      <div className="account-payment-modal-heading">{heading}</div>
      <div className="account-payment-modal-content">{content}</div>
      <Formik
        validationSchema={formValidationSchema}
        enableReinitialize={true}
        validateOnBlur={false}
        onSubmit={handleSubmit}
        initialValues={formValues}
        innerRef={formikRef}
      >
        {({ values, handleSubmit, errors, touched, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <div className="account-payment-modal-input">
              <Input
                type="number"
                name="amount"
                label="Enter amount manualy"
                isRequiredField={true}
                value={values?.amount || undefined}
                placeholder="$00.00"
                style={{ borderRadius: "10px" }}
                onChange={handleChange}
              />
              <div className="account-payment-modal-errors">
                <ErrorMessage isTouched={!!touched["amount"]} error={errors["amount"]} />
              </div>
            </div>
            <div className="account-payment-modal-payment-options">
              <label className="account-payment-modal-options-label">Or Select</label>
              {amountOptions.map((option) => (
                <AmountEllipsis
                  value={option}
                  selectOption={amountOptionSelectHandler}
                  className={option === values.amount ? "selected" : ""}
                  key={option}
                />
              ))}
            </div>
            <div className="account-payment-modal-actions">
              <div className="account-payment-modal-actions-btn-block">
                <Button className="account-payment-modal-close-btn" buttonStyle="blue-bordered" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="account-payment-modal-success-btn"
                  type="submit"
                  buttonStyle="accent-green"
                  disabled={!formValidationSchema.isValidSync(values)}
                >
                  Pay
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default ModalAccountPayment;

export interface AmountEllipsisProps {
  value: number;
  selectOption: (value: number) => void;
  className?: string;
}

export const AmountEllipsis = ({ value, className, selectOption }: AmountEllipsisProps) => {
  return (
    <span className={`ellipsis ${className}`} onClick={() => selectOption(value)}>
      ${value}
    </span>
  );
};
