import React, { ChangeEventHandler } from "react";
import { AnyType } from "@shared/interfaces";

import "./InputNumeric.scss";

const DEFAULT_MAX_DIGITS = 12;

export interface InputNumberProps {
  value?: number;
  placeholder?: string;
  onChange: (value: number | undefined, error: string | null) => void;
  onBlur?: () => AnyType;
  min?: number;
  max?: number;
  maxDigits?: number;
}

export const InputNumeric = (props: InputNumberProps) => {
  const { min, max, maxDigits = DEFAULT_MAX_DIGITS } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const isEmpty = event.target.value === "";
    const numeric = Number(event.target.value);
    let error = null;

    if (!isEmpty && !isNaN(numeric)) {
      if (min !== undefined && numeric < min) {
        error = `Value should be at least ${min}`;
      } else if (max !== undefined && numeric > max) {
        error = `Value should be at most ${max}`;
      }
    }

    if (event.target.value.length > maxDigits) {
      return;
    }

    props.onChange(isEmpty ? undefined : !isNaN(numeric) ? numeric : props.value, error);
  };

  return (
    <input
      className="numeric"
      inputMode="numeric"
      value={props.value ?? ""}
      onBlur={props.onBlur}
      onChange={handleChange}
      placeholder={props.placeholder}
    />
  );
};
