import React, { FC } from "react";
import { StatePriceProvider } from "@containers/PayPerLead/context/StatePriceProvider";
import { CountyOptionsProvider } from "@containers/PayPerLead/context/CountyOptionsProvider";
import { CountyPriceProvider } from "@containers/PayPerLead/context/CountyPriceProvider";
import { PayPerLeadSubscription } from "@containers/PayPerLead/interface";

export interface SubscriptionCacheProviderProps {
  subscription: PayPerLeadSubscription;
}

export const SubscriptionCacheProvider: FC<SubscriptionCacheProviderProps> = ({ subscription }) => {
  const items = subscription.items.filter((item) => !!item.state_id);

  return (
    <>
      {items.map((item) => (
        <StatePriceProvider key={item.state_id} stateId={item.state_id as number}>
          <CountyOptionsProvider stateId={item.state_id}>
            {item.counties.map((county) => (
              <CountyPriceProvider
                key={county.county_id}
                stateId={item.state_id as number}
                countyId={county.county_id}
              />
            ))}
          </CountyOptionsProvider>
        </StatePriceProvider>
      ))}
    </>
  );
};
