import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { InvestorFileFilter } from "@shared/interfaces";
import { CreateInvestorFilePayload } from "@shared/models";

export default {
  getInvestorFile: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.GET, API.INVESTOR_FILE.GET_INVESTOR_FILE(String(id)))();
  },

  getInvestorFileList: (payload: InvestorFileFilter) =>
    request(METHODS.GET, API.INVESTOR_FILE.GET_INVESTOR_FILE_LIST)({ params: payload }),

  processInvestorFile: (payload: { investorSettingsId: number; filterId: number; fileName: string }) => {
    const { investorSettingsId, filterId, fileName } = payload;
    return request(
      METHODS.POST,
      API.INVESTOR_SETTINGS.PROCESS_INVESTOR_FILE(String(investorSettingsId)),
    )({ filter_id: filterId, fileName });
  },
  sendInvestorFile: (payload: { id: number; accountId: number }) => {
    const { id, accountId } = payload;
    return request(METHODS.POST, API.INVESTOR_FILE.SEND_INVESTOR_FILE(String(id)))({ account_id: accountId });
  },
  unsendInvestorFile: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.DELETE, API.INVESTOR_FILE.UNSEND_INVESTOR_FILE(String(id)))();
  },
  createInvestorFile: (payload: CreateInvestorFilePayload) =>
    request(METHODS.POST, API.INVESTOR_FILE.CREATE_INVESTOR_FILE)(payload),

  sendInvestorFileEmail: (payload: number[]) => {
    return request(METHODS.POST, API.INVESTOR_FILE.SEND_INVESTOR_FILE_EMAIL)(payload);
  },
  sendInvestorFilesEmail: (payload: { filesIds: number[]; account_id: number }) => {
    return request(METHODS.POST, API.INVESTOR_FILE.SEND_INVESTOR_FILES_EMAIL)(payload);
  },
};
