import {
  ActionWithPayload,
  BaseFilter,
  CountiesFilter,
  PaginatedResponse,
  ResponseError,
  WithCallback,
} from "@shared/interfaces";
import { call, put, takeLatest } from "redux-saga/effects";
import { showNotification, startLoading, stopLoading } from "@shared/store/actions";
import { County, State, User } from "@shared/models";
import {
  activateClient,
  changeClientAccountStatus,
  createClient,
  deleteClient,
  getClient,
  getClientList,
  getCounties,
  getStates,
  updateClient,
} from "@containers/Contact/store/actions";
import { CreateClientDto, UpdateClientDto } from "@containers/Contact/interfaces";
import { showHttpErrorNotification } from "@shared/utils";

import api from "../api";

function* getClientListSaga({ payload }: ActionWithPayload<BaseFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<User> = yield call(api.getClientsList, payload);
    yield put(
      getClientList.success({
        ...response,
        clear: payload.page === 1,
      }),
    );
  } catch (error) {
    yield put(getClientList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getClientSaga({ payload }: ActionWithPayload<{ id: number }>) {
  try {
    yield put(startLoading());
    const response: User = yield call(api.getClient, payload);
    yield put(getClient.success(response));
  } catch (error) {
    yield put(getClient.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* createClientSaga({ payload }: ActionWithPayload<WithCallback<CreateClientDto>>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: User = yield call(api.createClient, rest);
    yield put(createClient.success(response));
    yield put(
      showNotification({
        message: "The client was successfully created.",
        appearance: "success",
      }),
    );
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(
      showNotification({
        message: "The client was not created. Please try again.",
        appearance: "error",
      }),
    );
    yield put(createClient.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateClientSaga({ payload }: ActionWithPayload<WithCallback<UpdateClientDto & { id: number }>>) {
  const { callback, ...rest } = payload;
  try {
    yield put(startLoading());
    const response: User = yield call(api.updateClient, rest);
    yield put(updateClient.success(response));
    yield put(
      showNotification({
        message: "The client was successfully updated.",
        appearance: "success",
      }),
    );
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(
      showNotification({
        message: "The client was not updated. Please try again.",
        appearance: "error",
      }),
    );
    yield put(updateClient.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteClientSaga({ payload }: ActionWithPayload<{ id: number }>) {
  try {
    yield put(startLoading());
    const response: User = yield call(api.deleteClient, payload);
    yield put(deleteClient.success(response));
  } catch (error) {
    yield put(deleteClient.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* activateClientSaga({ payload }: ActionWithPayload<{ id: number }>) {
  try {
    yield put(startLoading());
    const response: User = yield call(api.activateClient, payload);
    yield put(activateClient.success(response));
  } catch (error) {
    yield put(activateClient.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getStatesSaga({ payload }: ActionWithPayload<BaseFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<State> = yield call(api.getStates, payload);
    yield put(getStates.success(response));
  } catch (error) {
    yield put(getStates.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getCountiesSaga({ payload }: ActionWithPayload<CountiesFilter>) {
  try {
    yield put(startLoading());
    const response: PaginatedResponse<County> = yield call(api.getCounties, payload);
    yield put(getCounties.success(response));
  } catch (error) {
    yield put(getCounties.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* changeClientAccountStatusSaga({
  payload,
}: ActionWithPayload<{ user_id: number; account_id: number; is_active: boolean; callback: () => void }>) {
  try {
    yield put(startLoading());
    const response: User = yield call(api.changeClientAccountStatus, payload);
    yield put(getClient.success(response));

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    yield put(getClient.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* clientSaga() {
  yield takeLatest(getClientList.request, getClientListSaga);
  yield takeLatest(getClient.request, getClientSaga);
  yield takeLatest(createClient.request, createClientSaga);
  yield takeLatest(updateClient.request, updateClientSaga);
  yield takeLatest(deleteClient.request, deleteClientSaga);
  yield takeLatest(activateClient.request, activateClientSaga);
  yield takeLatest(getStates.request, getStatesSaga);
  yield takeLatest(getCounties.request, getCountiesSaga);
  yield takeLatest(changeClientAccountStatus.request, changeClientAccountStatusSaga);
}

export default clientSaga;
