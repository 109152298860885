import { PropertyFilterOption } from "@shared/interfaces/RecordManagement";

const KEY = "recentlyUsedPropertiesFilters";

class RecentlyUsedPropertiesFiltersStorage {
  set(item: PropertyFilterOption[]) {
    localStorage.setItem(KEY, JSON.stringify(item));
  }

  get(): PropertyFilterOption[] {
    try {
      const data = localStorage.getItem(KEY);

      if (data) {
        return JSON.parse(data);
      } else {
        return [];
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return [];
    }
  }

  clear() {
    localStorage.removeItem(KEY);
  }
}

export default new RecentlyUsedPropertiesFiltersStorage();
