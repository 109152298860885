import React, { useCallback, useMemo } from "react";
import { Formik } from "formik";
import { SkipTracing, UpdateFileMappingType } from "@shared/models";
import { Button, ErrorMessage, Icon } from "@shared/components";
import { FormTitleBlock } from "@containers/SkipTracing/components/FileMappingContainer/FileMappingForm/components";

import MappingLine from "./components/MappingLine/MappingLine";
import { getInitValues, validationSchema } from "./formHelpers";
import "./index.scss";

interface ProcedureFormProps {
  skipTracing: SkipTracing | null;
  onSubmitForm: (payload: UpdateFileMappingType) => void;
  onRemoveFile: () => void;
}

const FileMappingForm: React.FC<ProcedureFormProps> = (props) => {
  const { skipTracing, onSubmitForm, onRemoveFile } = props;

  const formValues = useMemo(() => getInitValues(skipTracing), [skipTracing]);

  const getKeyByValue = useCallback(
    (object: UpdateFileMappingType, value: string | number): string | null =>
      Object.keys(object).find((key) => object[key as keyof UpdateFileMappingType] === value) ?? null,
    [],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(values.columnMapping);
        setSubmitting(false);
      }}
      initialValues={formValues}
      validateOnChange={true}
    >
      {({ handleSubmit, errors, touched }) => (
        <div className="file-mapping-form">
          <form onSubmit={handleSubmit}>
            <div className="file-mapping-form-content">
              <div className="file-info-container">
                <div className="mapping-file-wrapper">
                  <div className="info">
                    <Icon type="file" />
                    <span>{skipTracing?.input_file.name}</span>
                  </div>
                  <div className="action" onClick={onRemoveFile}>
                    <Icon type="delete" />
                    <span>Remove</span>
                  </div>
                </div>

                <Button buttonStyle="accent-green" type="submit" className="run-test-btn">
                  Run Test
                </Button>
              </div>
              <div className="horizontal-divider"></div>
              <FormTitleBlock />
              <div className="mapping-section">
                <div className="scroll-container">
                  {skipTracing?.input_file.mapping.columns.map((stColumnName, idx) => {
                    return (
                      <MappingLine
                        key={idx}
                        idx={idx}
                        stColumnName={stColumnName}
                        getKeyByValue={getKeyByValue}
                        skipTracing={skipTracing}
                      />
                    );
                  })}
                </div>
                <div className="error-message-section">
                  {errors.columnMapping &&
                    Object.values(errors.columnMapping).map((item, idx) => (
                      <ErrorMessage key={idx} isTouched={!!touched} error={item} />
                    ))}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default FileMappingForm;
