import { createAction, createAsyncAction } from "typesafe-actions";
import { BaseFilter, CountiesFilter, PaginatedResponse, ResponseError, WithCallback } from "@shared/interfaces";
import { ClientActionTypes } from "@containers/Contact/store/constants";
import { County, State, User } from "@shared/models";
import { ClientError, CreateClientDto, UpdateClientDto } from "@containers/Contact/interfaces";

export const getClientList = createAsyncAction(
  ClientActionTypes.GET_CLIENT_LIST,
  ClientActionTypes.GET_CLIENT_LIST_SUCCESS,
  ClientActionTypes.GET_CLIENT_LIST_FAILURE,
)<BaseFilter, PaginatedResponse<User>, ResponseError>();

export const getClient = createAsyncAction(
  ClientActionTypes.GET_CLIENT,
  ClientActionTypes.GET_CLIENT_SUCCESS,
  ClientActionTypes.GET_CLIENT_FAILURE,
)<{ id: number; callback?: () => void }, User, ResponseError>();

export const updateClient = createAsyncAction(
  ClientActionTypes.UPDATE_CLIENT,
  ClientActionTypes.UPDATE_CLIENT_SUCCESS,
  ClientActionTypes.UPDATE_CLIENT_FAILURE,
)<WithCallback<Partial<UpdateClientDto>> & { id: number }, User, ResponseError>();

export const createClient = createAsyncAction(
  ClientActionTypes.CREATE_CLIENT,
  ClientActionTypes.CREATE_CLIENT_SUCCESS,
  ClientActionTypes.CREATE_CLIENT_FAILURE,
)<WithCallback<Partial<CreateClientDto>>, User, ResponseError>();

export const deleteClient = createAsyncAction(
  ClientActionTypes.DELETE_CLIENT,
  ClientActionTypes.DELETE_CLIENT_SUCCESS,
  ClientActionTypes.DELETE_CLIENT_FAILURE,
)<{ id: number }, User, ResponseError>();

export const activateClient = createAsyncAction(
  ClientActionTypes.ACTIVATE_CLIENT,
  ClientActionTypes.ACTIVATE_CLIENT_SUCCESS,
  ClientActionTypes.ACTIVATE_CLIENT_FAILURE,
)<{ id: number }, User, ResponseError>();

export const getStates = createAsyncAction(
  ClientActionTypes.GET_STATES,
  ClientActionTypes.GET_STATES_SUCCESS,
  ClientActionTypes.GET_STATES_FAILURE,
)<BaseFilter, PaginatedResponse<State>, ResponseError>();

export const getCounties = createAsyncAction(
  ClientActionTypes.GET_COUNTIES,
  ClientActionTypes.GET_COUNTIES_SUCCESS,
  ClientActionTypes.GET_COUNTIES_FAILURE,
)<CountiesFilter, PaginatedResponse<County>, ResponseError>();

export const changeClientAccountStatus = createAsyncAction(
  ClientActionTypes.CHANGE_CLIENT_ACCOUNT_STATUS,
  ClientActionTypes.CHANGE_CLIENT_ACCOUNT_STATUS_SUCCESS,
  ClientActionTypes.CHANGE_CLIENT_ACCOUNT_STATUS_FAILURE,
)<WithCallback<{ user_id: number; account_id: number; is_active: boolean }>, User, ResponseError>();

export const setFilter = createAction(ClientActionTypes.SET_FILTER)<BaseFilter | null>();
export const setStatesFilter = createAction(ClientActionTypes.SET_STATES_FILTER)<BaseFilter | null>();
export const setCountiesFilter = createAction(ClientActionTypes.SET_COUNTIES_FILTER)<BaseFilter | null>();

export const clearClientList = createAction(ClientActionTypes.CLEAR_CLIENT_LIST)();
export const clearClient = createAction(ClientActionTypes.CLEAR_CLIENT)();
export const clearStates = createAction(ClientActionTypes.CLEAR_STATES)();
export const clearCounties = createAction(ClientActionTypes.CLEAR_COUNTIES)();

export const clearErrors = createAction(ClientActionTypes.CLEAR_ERRORS)<ClientError[] | void>();
