import { call, put, takeLatest } from "redux-saga/effects";
import { handleCompanyForbiddenError, startLoading, stopLoading } from "@shared/store/actions";
import { PaginatedResponse } from "@shared/interfaces";
import { ClaritasVisitor } from "@shared/models";
import { download } from "@shared/utils/request";

import { getStatistics, getVisitors, getVisitorsFile } from "./actions";
import { ClaritasStatisticsDataPoint } from "../interface";
import api from "../api";

function* getVisitorsSaga({ payload }: ReturnType<typeof getVisitors.request>) {
  try {
    yield put(startLoading());

    const visitors: PaginatedResponse<ClaritasVisitor> = yield call(api.getVisitors, payload);
    yield put(getVisitors.success({ ...visitors, clear: payload.page === 1 }));
  } catch (error) {
    yield put(getVisitors.failure(error as Error));

    yield put(handleCompanyForbiddenError(error as { code: number }));
  } finally {
    yield put(stopLoading());
  }
}

function* getStatisticsSaga({ payload }: ReturnType<typeof getStatistics.request>) {
  try {
    yield put(startLoading());

    const statistics: ClaritasStatisticsDataPoint[] = yield call(api.getStatistics, payload);
    yield put(getStatistics.success(statistics));
  } catch (error) {
    yield put(getStatistics.failure(error as Error));

    yield put(handleCompanyForbiddenError(error as { code: number }));
  } finally {
    yield put(stopLoading());
  }
}

function* getVisitorsFileSaga({ payload }: ReturnType<typeof getVisitorsFile.request>) {
  try {
    yield put(startLoading());

    const response: Blob = yield call(api.getVisitorsFile, payload);
    const urlObj = window.URL.createObjectURL(response);
    yield call(download, urlObj, `visitors_${payload.from}_${payload.to}.csv`);
    yield put(getVisitorsFile.success());
  } catch (error) {
    yield put(getVisitorsFile.failure(error as Error));

    yield put(handleCompanyForbiddenError(error as { code: number }));
  } finally {
    yield put(stopLoading());
  }
}

function* claritasSagas() {
  yield takeLatest(getVisitors.request, getVisitorsSaga);
  yield takeLatest(getStatistics.request, getStatisticsSaga);
  yield takeLatest(getVisitorsFile.request, getVisitorsFileSaga);
}

export default claritasSagas;
