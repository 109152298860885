import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { WKT } from "ol/format";

const wkt = new WKT();

export abstract class AbstractShape extends Feature<Geometry> {
  protected constructor(shape: string) {
    super(
      wkt.readGeometry(shape, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
    );
  }
}
