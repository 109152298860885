import React from "react";
import { useCheckPermission } from "@shared/hooks";
import { checkIfAdmin } from "@shared/utils/ACL";
import { Feature } from "@shared/models";
import { useFeatureEnabledCheck } from "@shared/hooks/FeatureHook";

export interface AccessControlProps {
  permission?: string;
  feature?: Feature;
  children?: React.ReactNode;
  hideForAdmin?: boolean;
  hideForUser?: boolean;
}

const AccessControl = ({ permission, feature, hideForAdmin, hideForUser, children }: AccessControlProps) => {
  const permitted = useCheckPermission(permission);
  const featureEnabled = useFeatureEnabledCheck(feature);
  const isAdmin = checkIfAdmin();

  return permitted && featureEnabled && !(hideForAdmin && isAdmin) && !(hideForUser && !isAdmin) ? (
    <>{children}</>
  ) : null;
};

export default AccessControl;
