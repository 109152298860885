import React, { FC, useCallback, useMemo } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "@shared/store";
import { MODAL_TYPES, OVERLAY_TYPES } from "@shared/constants";
import { OverlaySize } from "@shared/interfaces";
import "./index.scss";
import ClientCreateEditContainer from "@containers/Contact/containers/ClientCreateEditContainer/ClientCreateEditContainer";
import CompanyCreateEditContainer from "@containers/Account/containers/CompanyCrearteEditContainer/CompanyCreateEditContainer";
import DealEditContainer from "@containers/Account/containers/DealEditContainer/DealEditContainer";
import { FilterOwnersContainer } from "@containers/RecordManagement/containers/FilterOwnersContainer/FilterOwnersContainer";
import { FilterPropertiesContainer } from "@containers/RecordManagement/containers/FilterPropertiesContainer/FilterPropertiesContainer";
import { TicketHistoryContainer } from "@containers/Tickets/containers/TicketHistoryContainer";
import { TicketNotesContainer } from "@containers/Tickets/containers/TicketNotesContainer";

const OverlayWrapper: FC = () => {
  const dispatch = useDispatch();

  const overlay = useSelector(selectors.getOverlay());

  const hideOverlayAction = useCallback(() => {
    dispatch(actions.hideOverlay());
    if (overlay?.props.onClose) {
      overlay.props.onClose();
    }
  }, [dispatch, overlay]);

  const onCloseOverlay = useCallback(() => {
    if (overlay?.leaveConfirm) {
      dispatch(
        actions.showModal({
          type: MODAL_TYPES.CONFIRM,
          props: {
            heading: "Leave",
            content: "Are you sure you want to leave? If you leave this page now your progress will be lost.",
            cancelBtnText: "Cancel",
            successBtnText: "Leave",
            onSuccess: hideOverlayAction,
          },
        }),
      );
      return;
    }

    hideOverlayAction();
  }, [overlay, dispatch, hideOverlayAction]);

  const Component = useMemo(() => {
    if (!overlay) return;

    return overlayStrategy(overlay.type);
  }, [overlay]);
  return overlay ? (
    <>
      <div className="overlay" onClick={() => (overlay.closeOnOverlayClick ? onCloseOverlay() : null)} />
      <div className={classnames("overlay-wrapper", `overlay-wrapper_size-${overlay.size || OverlaySize.NORMAL}`)}>
        <div className="inner-wrapper">
          {Component ? <Component {...overlay.props} onClose={onCloseOverlay} /> : null}
        </div>
      </div>
    </>
  ) : null;
};

export default OverlayWrapper;

const overlayStrategy = (type: string) => {
  switch (type) {
    case OVERLAY_TYPES.CREATE_EDIT_CLIENT: {
      return ClientCreateEditContainer;
    }
    case OVERLAY_TYPES.EDIT_DEAL: {
      return DealEditContainer;
    }
    case OVERLAY_TYPES.CREATE_EDIT_COMPANY: {
      return CompanyCreateEditContainer;
    }
    case OVERLAY_TYPES.FILTER_OWNERS: {
      return FilterOwnersContainer;
    }
    case OVERLAY_TYPES.FILTER_PROPERTIES: {
      return FilterPropertiesContainer;
    }
    case OVERLAY_TYPES.TICKET_HISTORY: {
      return TicketHistoryContainer;
    }
    case OVERLAY_TYPES.TICKET_NOTES: {
      return TicketNotesContainer;
    }
  }
};
