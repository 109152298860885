import React, { useCallback, useEffect, useMemo } from "react";
import { actions as sharedActions } from "@shared/store";
import { actions as ClientActions, actions, selectors as ClientSelectors } from "@containers/Contact/store";
import { actions as AccountActions, selectors as AccountSelectors } from "@containers/Account/store";
import { MODAL_TYPES } from "@shared/constants";
import { getFullName } from "@shared/utils";
import { Account, User } from "@shared/models";
import { CreateClientDto } from "@containers/Contact/interfaces";
import { useDispatch, useSelector } from "react-redux";
import ClientForm from "@containers/Contact/components/ClientCreateEditContainer/ClientForm/ClientForm";

interface ClientCreateEditContainerProps {
  onClose: () => void;
  client?: User;
  callback?: () => void;
  contact?: Account;
}

const ClientCreateEditContainer = (props: ClientCreateEditContainerProps) => {
  const { onClose, client, callback } = props;
  const dispatch = useDispatch();

  const selectedClient = useSelector(ClientSelectors.getSelectedClient());
  const selectedContact = useSelector(AccountSelectors.getSelectedAccount());

  useEffect(() => {
    if (client && !selectedClient) {
      dispatch(ClientActions.getClient.request({ id: client.id }));
    }
  }, [dispatch, client, selectedClient]);

  useEffect(() => {
    return () => {
      dispatch(ClientActions.clearClient());
      dispatch(ClientActions.clearStates());
      dispatch(ClientActions.clearCounties());
    };
  }, [dispatch]);

  const changeFormValues = useCallback(() => {
    dispatch(sharedActions.updateOverlay({ leaveConfirm: true }));
  }, [dispatch]);

  const handleSubmitForm = useCallback(
    (payload: Partial<CreateClientDto>) => {
      dispatch(sharedActions.hideOverlay());

      if (!client) {
        return dispatch(actions.createClient.request({ ...payload, callback }));
      }

      dispatch(actions.updateClient.request({ ...payload, id: Number(client.id), callback }));
    },
    [dispatch, client, callback],
  );

  const isSelectedClientActiveInContact = useMemo(() => {
    return !!selectedContact?.users?.some((user) => {
      return user.is_active_in_account && user.id === selectedClient?.id;
    });
  }, [selectedContact, selectedClient?.id]);

  const changeClientAccountStatus = useCallback(() => {
    if (!client || !selectedContact) {
      return;
    }
    dispatch(
      ClientActions.changeClientAccountStatus.request({
        user_id: client?.id,
        account_id: selectedContact?.id,
        is_active: !isSelectedClientActiveInContact,
        callback: () => dispatch(AccountActions.getAccount.request({ id: selectedContact.id })),
      }),
    );
  }, [client, selectedContact, dispatch, isSelectedClientActiveInContact]);

  const handleChangeClientAccountStatus = useCallback(() => {
    if (!client) return;

    const changeStatusActionName = isSelectedClientActiveInContact ? "Deactivate" : "Activate";

    dispatch(
      sharedActions.showModal({
        type: MODAL_TYPES.CONFIRM,
        props: {
          heading: `${changeStatusActionName} Contact`,
          content: `Are you sure you want to ${changeStatusActionName.toLowerCase()} ${getFullName(client)}?`,
          cancelBtnText: "Cancel",
          successBtnText: changeStatusActionName,
          successBtnStyle: "form-cancel",
          onSuccess: () => {
            changeClientAccountStatus();
          },
        },
      }),
    );
  }, [changeClientAccountStatus, isSelectedClientActiveInContact, client, dispatch]);

  return (
    <div>
      <ClientForm
        client={selectedClient}
        contact={selectedContact || undefined}
        changeClientAccountStatus={handleChangeClientAccountStatus}
        isSelectedClientActiveInContact={isSelectedClientActiveInContact}
        onChangeForm={changeFormValues}
        onCloseForm={onClose}
        onSubmitForm={handleSubmitForm}
      />
    </div>
  );
};

export default ClientCreateEditContainer;
