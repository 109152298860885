import { all, fork } from "redux-saga/effects";
import { saga as authSaga } from "@containers/Auth/store";
import { saga as fileSaga } from "@containers/File/store";
import { saga as skipTracingSaga } from "@containers/SkipTracing/store";
import { saga as accountSaga } from "@containers/Account/store";
import { saga as ClientSaga } from "@containers/Contact/store";
import { saga as InvestorFileSaga } from "@containers/InvestorFile/store";
import { saga as PresetSaga } from "@containers/Preset/store";
import { saga as ESPresetSaga } from "@containers/PresetBuilder/store";
import { saga as sharedSaga } from "@shared/store";
import { saga as claritasSaga } from "@containers/Claritas/store";
import { saga as marketMapSaga } from "@containers/MarketMap/store";
import { saga as dataDeliverySaga } from "@containers/DataDelivery/store";
import { saga as recordManagementSaga } from "@containers/RecordManagement/store";
import { saga as payPerLeadSaga } from "@containers/PayPerLead/store";
import { saga as ticketSaga } from "@containers/Tickets/store";

const allSagas = [
  authSaga,
  sharedSaga,
  fileSaga,
  skipTracingSaga,
  accountSaga,
  ClientSaga,
  InvestorFileSaga,
  claritasSaga,
  marketMapSaga,
  dataDeliverySaga,
  payPerLeadSaga,
  PresetSaga,
  ESPresetSaga,
  recordManagementSaga,
  ticketSaga,
];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
