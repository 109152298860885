import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { HubspotPropertiesHistoryFilter, ORDER_COLUMN_TYPE, ORDER_TYPE } from "@shared/interfaces";

import * as actions from "./actions";
import { DataDeliveryError, DataDeliveryStateType } from "../interface";

const defaultHubspotPropertiesHistoryFilter: HubspotPropertiesHistoryFilter = {
  page: 1,
  limit: 50,
  search: null,
  order: ORDER_COLUMN_TYPE.CREATED_AT,
  direction: ORDER_TYPE.DESC,
  account_id: null,
};

const initialState: DataDeliveryStateType = {
  hubspotPropertiesHistory: [],
  hubspotPropertiesHistoryTotal: 0,
  hubspotPropertiesHistoryFilter: defaultHubspotPropertiesHistoryFilter,
  errors: {
    [DataDeliveryError.getHubspotHistoryListError]: null,
    [DataDeliveryError.updateHubspotHistoryItemProcessingStatusError]: null,
  },
};

const reducer = createReducer<DataDeliveryStateType, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getHubspotHistoryList.success, (state, action) =>
    produce(state, (nextState) => {
      const { items, total, clear } = action.payload;
      nextState.hubspotPropertiesHistory = !clear ? [...nextState.hubspotPropertiesHistory, ...items] : [...items];
      nextState.hubspotPropertiesHistoryTotal = total;
    }),
  )

  .handleAction(actions.getHubspotHistoryList.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[DataDeliveryError.getHubspotHistoryListError] = action.payload;
    }),
  )

  .handleAction(actions.updateHubspotHistoryItemProcessingStatus.success, (state, action) =>
    produce(state, (nextState) => {
      const updatedHubspotHistoryItem = action.payload;
      const hubspotPropertiesHistory = nextState.hubspotPropertiesHistory;
      if (hubspotPropertiesHistory) {
        const foundHubspotHistoryItemIndex = hubspotPropertiesHistory.findIndex(
          (p) => updatedHubspotHistoryItem.id === p.id,
        );
        hubspotPropertiesHistory[foundHubspotHistoryItemIndex] = updatedHubspotHistoryItem;
      }

      nextState.errors[DataDeliveryError.updateHubspotHistoryItemProcessingStatusError] = null;
    }),
  )

  .handleAction(actions.updateHubspotHistoryItemProcessingStatus.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[DataDeliveryError.updateHubspotHistoryItemProcessingStatusError] = action.payload;
    }),
  )

  .handleAction(actions.setHubspotHistoryFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.hubspotPropertiesHistoryFilter = action.payload || { ...defaultHubspotPropertiesHistoryFilter };
    }),
  )

  .handleAction(actions.clearHubspotHistoryList, (state) =>
    produce(state, (nextState) => {
      nextState.hubspotPropertiesHistory = [];
      nextState.hubspotPropertiesHistoryTotal = 0;
    }),
  )

  .handleAction(actions.clearErrors, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        action.payload.forEach((err) => {
          nextState.errors[err] = null;
        });
      } else {
        nextState.errors = initialState.errors;
      }
    }),
  );

export { reducer as DataDeliveryReducer };
