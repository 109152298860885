import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectors } from "@shared/store";
import { checkPermission } from "@shared/utils/ACL";

function useCheckPermission(permission?: string): boolean {
  const user = useSelector(selectors.getUserDetails());

  const permitted = useMemo(() => {
    if (!user) return false;
    return permission ? checkPermission(permission, user) : true;
  }, [permission, user]);

  return permitted;
}

export default useCheckPermission;
