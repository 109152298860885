import React from "react";
import { Icon } from "@shared/components/Icon";

import { ButtonSecondary } from "./ButtonEnhanced";
import { ButtonProps } from "./Button";

export const ButtonFilter: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <ButtonSecondary {...props}>
      <Icon type="filter-toggle" />
      {children}
    </ButtonSecondary>
  );
};
