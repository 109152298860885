import React, { FC } from "react";

import "./index.scss";

const UploadFileTitleBlock: FC = () => {
  return (
    <div className="title-block-wrapper">
      <div className="title">Upload Your File</div>
      <div className="subtitle">
        DataFlik is the industry&apos;s fastest-growing and most accurate skip tracing service. We offer best in class
        pricing and results to give you the best ROI in real estate!
      </div>
      <div className="second-title">Add file with your data so we can start the flow </div>
      <div className="subtitle">Use the area below to upload your CSV or Excel file</div>
    </div>
  );
};

export default UploadFileTitleBlock;
