import React from "react";

import "./index.scss";

export type StatusBarProps = {
  currentStep?: number;
  totalSteps?: number;
};

export const StatusBar: React.FC<StatusBarProps> = ({ currentStep = 1, totalSteps = 3 }) => {
  return (
    <div className="progress-status-bar">
      {[...Array(totalSteps)].map((_, index) => (
        <span
          key={index}
          className={`progress-status-bar-item ${index < currentStep ? "progress-status-bar-item-completed" : ""}`}
        />
      ))}
    </div>
  );
};
