import React, { useCallback, useState } from "react";
import { MenuItem } from "@shared/interfaces";
import { RoleName, User } from "@shared/models";
import { Icon } from "@shared/components";
import { getFullName } from "@shared/utils";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setActiveAccount } from "@containers/Account/store/actions";
import { getActiveAccount } from "@containers/Account/store/selectors";
import { useCheckRoles } from "@shared/hooks";
import { ImpersonationInfo } from "@shared/components/Sidebar/components/ImpersonationInfo/ImpersonationInfo";
import CompanyItem from "@shared/components/Sidebar/components/MainSidebar/CompanyItem";
import noop from "lodash/noop";

import ExpandedContainer from "./ExpandedContainer";
import { SidebarItems } from "../SidebarItems";

import "./index.scss";

export interface MainSidebarProps {
  user: User | null;
  items: MenuItem[];
  onLogoutClick: () => void;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const Sidebar = (props: MainSidebarProps) => {
  const { user, items, collapsed, setCollapsed, onLogoutClick } = props;
  const accounts = user?.accounts;

  const dispatch = useDispatch();
  const activeAccount = useSelector(getActiveAccount());

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const classes = classNames("main-sidebar", collapsed ? "collapsed" : "");
  const sidebarItemsClasses = classNames("sidebar-items", collapsed ? "collapsed" : "");

  const isCompanySwitcherEnabled = !useCheckRoles([RoleName.admin, RoleName.super_admin]);

  const handleExpandLogoutSection = () => setIsExpanded((v) => !v);

  const handleSelectAccount = useCallback((id: number) => dispatch(setActiveAccount.request(id)), [dispatch]);

  return (
    <div className={classes}>
      <div className="sidebar-header">
        <div className="sidebar-logo">
          <Icon type="logo" />
          <div className="sidebar-logo-title">DataFlik</div>
        </div>

        <div className="collapse-button" onClick={() => setCollapsed(!collapsed)}>
          <Icon type={collapsed ? "chevron-right-grey" : "chevron-left-grey"} />
        </div>
      </div>
      <div className="sidebar-horizontal-divider"></div>
      <div className={`sidebar-user-info ${isExpanded ? "expanded" : ""}`}>
        <div className="user-info" onClick={handleExpandLogoutSection}>
          <div className="user-name-section">
            <Icon type="user" />
            <div className="user-name">{getFullName(user)}</div>
          </div>
          {!collapsed && (
            <div className={isExpanded ? "narrow-icon" : "expand-icon"}>
              <Icon type="chevron-down-grey" />
            </div>
          )}
        </div>

        {!collapsed && !isExpanded && isCompanySwitcherEnabled && (
          <>
            <div className="user-horizontal-divider" />
            <div className="company-wrapper">
              <div className="header">Company</div>
              {activeAccount && <CompanyItem account={activeAccount} isActive={false} handleSelectAccount={noop} />}
            </div>
          </>
        )}

        <ExpandedContainer
          accounts={accounts}
          activeAccount={activeAccount}
          isCompanySwitcherEnabled={isCompanySwitcherEnabled}
          isExpanded={isExpanded}
          collapsed={collapsed}
          handleSelectAccount={handleSelectAccount}
          onLogoutClick={onLogoutClick}
        />
      </div>

      <div className={sidebarItemsClasses}>
        <SidebarItems items={items} className={collapsed ? "collapsed" : ""} />
      </div>
      <ImpersonationInfo />
    </div>
  );
};

export default Sidebar;
