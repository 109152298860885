import { User } from ".";

export enum FileType {
  skipTracingInput = "skip_tracing_input",
  skipTracingOutput = "skip_tracing_output",
  investorData = "investor_data",
}
export interface File {
  readonly id: number;
  user_id: number;
  type: FileType;
  name: string;
  url: string;
}

export interface BaseFile {
  id: number;
  url: string;
}

export interface SignatureFile extends BaseFile {
  name: string;
  created_at: string;
  type: FileType;
}

export interface SaveFile extends BaseFile {
  name: string;
  creator: Partial<User>;
  created_at: Date;
}

export interface FileMapping {
  id: number;
  file_id: number;
  first_name: string | null;
  last_name: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  mailing_address: string | null;
  mailing_city: string | null;
  mailing_state: string | null;
  columns: [];
}

export type UpdateFileMappingType = Partial<Omit<FileMapping, "id" | "file_id" | "columns">>;

export interface FileRecord {
  id: number;
  file_id: number;
  data: string | number[];
  skipTracingId?: number;
}

export interface UploadedFile extends BaseFile {
  name: string;
  type: FileType;
  user_id: number;
  mapping: FileMapping;
  records: FileRecord[];
}

export interface MappedToAccountFile {
  name: string;
  uuid: string;
  account: {
    id: number;
    name: string;
  } | null;
}

export interface FileToMapAccount {
  name: string;
  extractedFromZip: boolean;
  uuid: string;
}

export interface MonthlyFileMappingFormResult {
  name: string;
  account_id: number;
  uuid: string;
}
