import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";

import {
  RestoreShape,
  LoginShape,
  ChangePasswordPayloadShape,
  RegistrationPayloadShape,
  ActivatePayloadShape,
  CheckUserShape,
  ImpersonationRequestPayloadShape,
} from "../interface";

export default {
  login: (payload: LoginShape) => request(METHODS.POST, API.AUTH.LOGIN)(payload),
  checkUser: (payload: CheckUserShape) => request(METHODS.POST, API.AUTH.CHECK_USER)(payload),
  forgotPassword: (payload: RestoreShape) => request(METHODS.POST, API.AUTH.FORGOT_PASSWORD)(payload),
  setPassword: (payload: ChangePasswordPayloadShape) => request(METHODS.POST, API.AUTH.SET_PASSWORD)(payload),
  resetPassword: (payload: ChangePasswordPayloadShape) => request(METHODS.PUT, API.AUTH.RESET_PASSWORD)(payload),
  registration: (payload: RegistrationPayloadShape) => request(METHODS.POST, API.AUTH.REGISTRATION)(payload),
  activate: (payload: ActivatePayloadShape) => request(METHODS.POST, API.AUTH.ACTIVATE)(payload),
  impersonate: (payload: ImpersonationRequestPayloadShape) => request(METHODS.POST, API.AUTH.IMPERSONATE)(payload),
};
