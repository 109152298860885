import React, { FC, PropsWithChildren } from "react";
import "./index.scss";

export type ModalComponents = {
  Section: FC<PropsWithChildren>;
  Title: FC<PropsWithChildren>;
  Content: FC<PropsWithChildren>;
  Actions: FC<PropsWithChildren>;
};

export const Modal: FC<PropsWithChildren> & ModalComponents = ({ children }) => {
  return <div className="modal">{children}</div>;
};

const Section: FC<PropsWithChildren> = ({ children }) => {
  return <div className="modal-section">{children}</div>;
};

const Title: FC<PropsWithChildren> = ({ children }) => {
  return <div className="modal-title">{children}</div>;
};

const Content: FC<PropsWithChildren> = ({ children }) => {
  return <div className="modal-content">{children}</div>;
};

const Actions: FC<PropsWithChildren> = ({ children }) => {
  return <div className="modal-actions">{children}</div>;
};

Modal.Section = Section;
Modal.Title = Title;
Modal.Content = Content;
Modal.Actions = Actions;

export default Modal;
