import React, { FC } from "react";

import "./index.scss";

const LinearProgressBar: FC = () => {
  return (
    <div className="linear-progress-wrapper">
      <span className="loader"></span>
    </div>
  );
};

export default LinearProgressBar;
