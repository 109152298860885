import { DropDown } from "@shared/interfaces";

export const DISTRESSORS: DropDown[] = [
  { label: "Auctions", value: "auctions" },
  { label: "Liens", value: "liens" },
  { label: "Zombie Properties", value: "zombie" },
  { label: "Absentee Bad Credit", value: "absentee_bad_credit" },
  { label: "Senior Owner 65+", value: "senior_owner" },
  { label: "Bankruptcy", value: "bankruptcy" },
  { label: "Pre-Foreclosures", value: "pre_foreclosure" },
  { label: "Short Term Loan", value: "short_term_loan" },
  { label: "Absentee Vacant", value: "absentee_vacant" },
  { label: "Senior with 2 Stories", value: "senior_owner_two" },
  { label: "Free & Clear", value: "free_clear" },
  { label: "Pre-Probate (Deceased Owners)", value: "pre_probate" },
  { label: "Criminal Felony", value: "criminal_felony" },
  {
    label: "Owner Occupied Low Income",
    value: "owner_occupied_low_income",
  },
  { label: "Owner Eviction", value: "owner_eviction" },
  { label: "High Equity", value: "high_equity" },
  { label: "Vacant", value: "vacant" },
  { label: "Absentee Low Income", value: "absentee_low_income" },
  {
    label: "Owner Occupied Low Credit",
    value: "owner_occupied_low_credit",
  },
];

export const OWNER_OCCUPIED_DATA: DropDown[] = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "All", value: "All" },
];

export const SELECT_INPUT_FIELDS = ["property_type", "owner_occupied"];
export const TAGGED_INPUT_FIELDS = ["counties", "states", "zips", "property_type", "property_zips"];

export const OWNER_OCCUPIED_VALUES = OWNER_OCCUPIED_DATA.map((item) => item.value);
