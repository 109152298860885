import React, { useCallback } from "react";
import classnames from "classnames";
import { ORDER_TYPE, SortTable } from "@shared/interfaces";
import { CheckboxInput } from "@shared/components";

import { SortableHeader } from "../SortableHeader";
import { TableProperty } from "../interface";
import "./index.scss";

export interface HeadProps<T> extends SortTable {
  properties: TableProperty<T>[];
  isChecked?: boolean;
  selectableRows?: boolean;
  handleSelectAll?: (isSelected: boolean) => void;
}

function Head<T>(props: HeadProps<T>) {
  const { properties, sortBy, onChangeSorting, sortOrder, isChecked, selectableRows, handleSelectAll } = props;

  const handleChange = useCallback(() => {
    return handleSelectAll?.(!isChecked);
  }, [isChecked, handleSelectAll]);

  return (
    <div className="table-header">
      {selectableRows ? (
        <CheckboxInput
          className="table-header-cell checkbox-cell-header"
          value={isChecked ? "1" : ""}
          onChange={handleChange}
        />
      ) : null}
      {properties.map((prop) => {
        if (prop.appearClickBlock) {
          return null;
        }

        if (prop.sort) {
          return (
            <div key={prop.key as string} className={classnames("table-header-cell", prop.headerClassName)}>
              <SortableHeader
                column={prop}
                sortOrder={
                  sortBy && sortBy.find((sf) => prop.sort?.includes(sf)) && sortOrder ? sortOrder : ORDER_TYPE.NONE
                }
                onChangeSorting={onChangeSorting}
              />
            </div>
          );
        }

        return (
          <div key={prop.key as string} className={classnames("table-header-cell", prop.headerClassName)}>
            <div>{prop.title}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Head;
