import React from "react";
import classnames from "classnames";

export type IconType =
  | "back"
  | "call"
  | "mail"
  | "message"
  | "find"
  | "clear"
  | "edit"
  | "view"
  | "back-gray"
  | "close"
  | "edit-blue"
  | "edit-orange"
  | "delete"
  | "delete-white"
  | "download"
  | "back-white"
  | "chevron-orange"
  | "front-orange"
  | "lock"
  | "calendar"
  | "calendar-orange"
  | "menu"
  | "option-selected-green"
  | "menu-orange"
  | "chevron-top-blue"
  | "chevron-bottom-gray"
  | "document"
  | "marker"
  | "check-orange"
  | "check-green"
  | "check-blue"
  | "double-check-green"
  | "check-white"
  | "double-check-white"
  | "clock"
  | "warning"
  | "refresh"
  | "list-blue"
  | "list-grey"
  | "score-grey"
  | "letter"
  | "logout"
  | "logout-white"
  | "sort"
  | "sort-asc"
  | "sort-dsc"
  | "user"
  | "user-shadow"
  | "user-shadow-blue"
  | "eye"
  | "eye-close"
  | "white-cancel"
  | "file"
  | "users"
  | "chevron-left"
  | "upload-file"
  | "monitor-with-loupe"
  | "testing-valid"
  | "not-found"
  | "finished-file"
  | "minus-grey"
  | "nav-users"
  | "nav-home"
  | "nav-bag"
  | "nav-data-delivery"
  | "nav-pay-per-lead"
  | "nav-claritas"
  | "logo"
  | "chevron-left-grey"
  | "chevron-right-grey"
  | "chevron-down-grey"
  | "get-output"
  | "get-original"
  | "generate-file"
  | "empty-screen"
  | "region-not-selected"
  | "auth-screen"
  | "details"
  | "plus"
  | "plus-white"
  | "plus-grey"
  | "send"
  | "send-white"
  | "send-blue"
  | "dollar-black"
  | "unsend"
  | "files"
  | "blue-eye"
  | "sphere-blue"
  | "sphere-blue-crossed"
  | "arrow-bottom-grey"
  | "approve"
  | "decline"
  | "decline-blue"
  | "dispute"
  | "dispute-approved"
  | "dispute-declined"
  | "nav-market-map"
  | "arrow-top-grey"
  | "reset-grey"
  | "reset-blue"
  | "get-original-grey"
  | "nav-records"
  | "wallet"
  | "wallet-grey"
  | "free-and-clear"
  | "off-market"
  | "high-equity"
  | "area"
  | "bathroom"
  | "bedroom"
  | "property"
  | "download"
  | "layers"
  | "email"
  | "chat"
  | "phone"
  | "filter-toggle"
  | "empty-property-records"
  | "empty-property-lists"
  | "calls-made"
  | "calls-check"
  | "offer"
  | "home-sold"
  | "investment"
  | "copy"
  | "empty-general"
  | "nav-tickets"
  | "thumb-up"
  | "empty-tickets"
  | "thumb-up"
  | "empty-leads";

export interface IconProps {
  type: IconType;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  title?: string;
}

export default function Icon(props: IconProps) {
  const { type, className, onClick, title } = props;
  return <div onClick={onClick} title={title} className={classnames("icon", className, type)} />;
}
