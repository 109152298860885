export enum FileActionTypes {
  CREATE_FILE = "@@FILE/CREATE_FILE",
  CREATE_FILE_SUCCESS = "@@FILE/CREATE_FILE_SUCCESS",
  CREATE_FILE_FAILURE = "@@FILE/CREATE_FILE_FAILURE",

  UPDATE_FILE_MAPPING = "@@FILE/UPDATE_FILE_MAPPING",
  UPDATE_FILE_MAPPING_SUCCESS = "@@FILE/UPDATE_FILE_MAPPING_SUCCESS",
  UPDATE_FILE_MAPPING_FAILURE = "@@FILE/UPDATE_FILE_MAPPING_FAILURE",

  MAP_FILES_TO_ACCOUNT = "@@FILE/MAP_FILES_TO_ACCOUNT",
  MAP_FILES_TO_ACCOUNT_SUCCESS = "@@FILE/MAP_FILES_TO_ACCOUNT_SUCCESS",
  MAP_FILES_TO_ACCOUNT_FAILURE = "@@FILE/MAP_FILES_TO_ACCOUNT_FAILURE",

  DOWNLOAD_FILE = "@@FILE/DOWNLOAD_FILE",

  CLEAR_FILE = "@@FILE/CLEAR_FILE",

  CLEAR_ERRORS = "@@FILE/CLEAR_ERRORS",
}
