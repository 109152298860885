import React from "react";
import Lottie from "lottie-react";

import completeAnimationJSON from "./complete.animation.json";

import "./index.scss";

export const StatusComplete: React.FC = () => (
  <div className="progress-status-complete">
    <div className="progress-status-complete-animation">
      <Lottie animationData={completeAnimationJSON} loop={false} />
    </div>
  </div>
);
