import React, { CSSProperties, FC, useEffect, useRef } from "react";
import { Feature } from "ol";
import { Coordinate, getMap, getView } from "@shared/components/MapContainer/utils";
import OlView from "ol/View";
import "./index.scss";
import { OlMap } from "@shared/components/MapContainer/OLMap";
import { Neighborhood } from "@shared/components/MapContainer/OLMap/features/Neighborhood";

export type BaseConfig = {
  zoom?: number;
  center?: Coordinate;
  autoFocus?: true;
  style?: {
    width?: string | number;
    height?: string | number;
  } & CSSProperties;
};

export type MapType = {
  mapId: string;
  config: BaseConfig;
  features: Feature[];
  isTooltipEnabled?: boolean;
  getMapInstance?: (target?: string) => OlMap;
  handleNeighborhoodSelect?: (id: string) => void;
};

const MapContainer: FC<MapType> = (props) => {
  const { config, features, isTooltipEnabled, handleNeighborhoodSelect } = props;

  const tooltipId = `${props.mapId}-info`;

  const view = useRef<OlView>();
  const map = useRef<OlMap>();

  useEffect(
    () => {
      const mapInstance = (props.getMapInstance ? props.getMapInstance : getMap)(props.mapId);

      if (handleNeighborhoodSelect) {
        mapInstance.addNeighborhoodSelectedListener(handleNeighborhoodSelect);
      }

      if (isTooltipEnabled) {
        mapInstance.addFeatureTooltip(tooltipId);
      }

      map.current = mapInstance;

      return () => {
        mapInstance.setTarget(undefined);
      };
    },
    //eslint-disable-next-line
    [],
  );

  useEffect(() => {
    const _map = map.current;
    const _view = view.current;

    if (!_view) {
      const newView = getView(config.zoom, config.center);
      view.current = newView;

      _map?.setView(newView);
    }
  }, [config, config.zoom]);

  useEffect(() => {
    const _map = map.current;
    if (!_map) {
      return;
    }

    _map.rerender(features);

    if (config.autoFocus && features.length > 0) {
      const selectedNeighborhood = features.find((f) => f instanceof Neighborhood && f.isSelected()) as
        | Neighborhood
        | undefined;

      if (selectedNeighborhood) {
        _map.focusNeighborhood(selectedNeighborhood);
      } else {
        _map.focusLayersIntoView();
      }
    }
  }, [config.autoFocus, features]);

  return (
    <div id={props.mapId} style={config.style} className="ol-map-wrapper">
      <div id={tooltipId} className="ol-map-info-item" />
    </div>
  );
};

export default MapContainer;
