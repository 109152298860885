import React from "react";

import "./index.scss";

export const GoBackButton: React.FC<React.HTMLProps<HTMLButtonElement>> = ({ onClick }) => (
  <button className="progress-button" onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9428 24.2765C14.4221 24.7972 13.5779 24.7972 13.0572 24.2765L5.72386 16.9431C5.20316 16.4224 5.20316 15.5782 5.72386 15.0575L13.0572 7.72419C13.5779 7.20349 14.4221 7.20349 14.9428 7.72419C15.4635 8.24488 15.4635 9.0891 14.9428 9.6098L9.88562 14.667L26.6667 14.667C27.403 14.667 28 15.2639 28 16.0003C28 16.7367 27.403 17.3337 26.6667 17.3337L9.88562 17.3337L14.9428 22.3909C15.4635 22.9115 15.4635 23.7558 14.9428 24.2765Z"
        fill="#3B445A"
      />
    </svg>
  </button>
);
