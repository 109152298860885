import { ClipboardEvent, KeyboardEvent } from "react";

export const onlyNumberInputValidation = (e: KeyboardEvent<HTMLInputElement>) => {
  if (
    (e.key.length !== 1 && !["ArrowUp", "ArrowDown"].includes(e.key)) ||
    !!e.key.match(/\d/) ||
    e.ctrlKey ||
    (["v", "c", "a", "x"].includes(e.key) && (e.ctrlKey || e.metaKey))
  ) {
    return;
  }

  e.preventDefault();
};

export const onlyPasteNumberInputValidation = (e: ClipboardEvent<HTMLInputElement>) => {
  if (/[^\d\\(\\)\s-]/g.test(e.clipboardData?.getData("Text"))) {
    e.preventDefault();
  }
};
