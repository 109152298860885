import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { CreatePresetDto } from "@containers/Preset/interfaces/Preset.interface";
import { Preset } from "@shared/models";

export default {
  getPreset: (payload: { investorSettingsId: number; filterId: number }) => {
    const { investorSettingsId, filterId } = payload;
    return request(METHODS.GET, API.INVESTOR_SETTINGS.GET_PRESET(String(investorSettingsId), String(filterId)))();
  },

  getPresetList: (payload: { investorSettingsId: number }) => {
    const { investorSettingsId } = payload;
    return request(METHODS.GET, API.INVESTOR_SETTINGS.GET_PRESET_LIST(String(investorSettingsId)))();
  },

  createPreset: (payload: CreatePresetDto & { investorSettingsId: number }) => {
    const { investorSettingsId, ...rest } = payload;
    return request(METHODS.POST, API.INVESTOR_SETTINGS.CREATE_PRESET(String(investorSettingsId)))(rest);
  },
  updatePreset: (payload: Preset & { investorSettingsId: number; filterId: number }) => {
    const { investorSettingsId, filterId, ...rest } = payload;
    return request(
      METHODS.PATCH,
      API.INVESTOR_SETTINGS.UPDATE_PRESET(String(investorSettingsId), String(filterId)),
    )(rest);
  },
  deletePreset: (payload: { investorSettingsId: number; filterId: number }) => {
    const { investorSettingsId, filterId } = payload;
    return request(METHODS.DELETE, API.INVESTOR_SETTINGS.DELETE_PRESET(String(investorSettingsId), String(filterId)))();
  },

  getFilterFields: () => request(METHODS.GET, API.INVESTOR_SETTINGS.GET_FILTER_FIELDS)(),
};
