import { createAction, createAsyncAction } from "typesafe-actions";
import { InvestorFileFilter, PaginatedResponse, ResponseError, WithOptionalLoader } from "@shared/interfaces";
import { InvestorFileActionTypes } from "@containers/InvestorFile/store/constants";
import { CreateInvestorFilePayload, InvestorFile, InvestorFileSendingInfo } from "@shared/models";
import { InvestorFileError } from "@containers/InvestorFile/interfaces";

export const getInvestorFileList = createAsyncAction(
  InvestorFileActionTypes.GET_INVESTOR_FILE_LIST,
  InvestorFileActionTypes.GET_INVESTOR_FILE_LIST_SUCCESS,
  InvestorFileActionTypes.GET_INVESTOR_FILE_LIST_FAILURE,
)<InvestorFileFilter, PaginatedResponse<InvestorFile>, ResponseError>();

export const getInvestorFile = createAsyncAction(
  InvestorFileActionTypes.GET_INVESTOR_FILE,
  InvestorFileActionTypes.GET_INVESTOR_FILE_SUCCESS,
  InvestorFileActionTypes.GET_INVESTOR_FILE_FAILURE,
)<WithOptionalLoader<{ id: number; callback?: () => void }>, InvestorFile, ResponseError>();

export const processInvestorFile = createAsyncAction(
  InvestorFileActionTypes.PROCESS_INVESTOR_FILE,
  InvestorFileActionTypes.PROCESS_INVESTOR_FILE_SUCCESS,
  InvestorFileActionTypes.PROCESS_INVESTOR_FILE_FAILURE,
)<
  { investorSettingsId: number; filterId: number; fileName?: string; callback?: () => void },
  InvestorFile,
  ResponseError
>();

export const sendInvestorFile = createAsyncAction(
  InvestorFileActionTypes.SEND_INVESTOR_FILE,
  InvestorFileActionTypes.SEND_INVESTOR_FILE_SUCCESS,
  InvestorFileActionTypes.SEND_INVESTOR_FILE_FAILURE,
)<{ id: number; accountId: number; callback?: () => void }, undefined, ResponseError>();

export const unsendInvestorFile = createAsyncAction(
  InvestorFileActionTypes.UNSEND_INVESTOR_FILE,
  InvestorFileActionTypes.UNSEND_INVESTOR_FILE_SUCCESS,
  InvestorFileActionTypes.UNSEND_INVESTOR_FILE_FAILURE,
)<{ id: number; callback?: () => void }, undefined, ResponseError>();

export const createInvestorFile = createAsyncAction(
  InvestorFileActionTypes.CREATE_INVESTOR_FILE,
  InvestorFileActionTypes.CREATE_INVESTOR_FILE_SUCCESS,
  InvestorFileActionTypes.CREATE_INVESTOR_FILE_FAILURE,
)<CreateInvestorFilePayload, InvestorFile, ResponseError>();

export const updateInvestorFile = createAsyncAction(
  InvestorFileActionTypes.UPDATE_INVESTOR_FILE,
  InvestorFileActionTypes.UPDATE_INVESTOR_FILE_SUCCESS,
  InvestorFileActionTypes.UPDATE_INVESTOR_FILE_FAILURE,
)<CreateInvestorFilePayload, DeepPartial<InvestorFile>, ResponseError>();

export const sendInvestorFileEmail = createAsyncAction(
  InvestorFileActionTypes.SEND_INVESTOR_FILE_EMAIL,
  InvestorFileActionTypes.SEND_INVESTOR_FILE_EMAIL_SUCCESS,
  InvestorFileActionTypes.SEND_INVESTOR_FILE_EMAIL_FAILURE,
)<number[], undefined, ResponseError>();

export const sendInvestorFileEmails = createAsyncAction(
  InvestorFileActionTypes.SEND_INVESTOR_FILES_EMAIL,
  InvestorFileActionTypes.SEND_INVESTOR_FILES_EMAIL_SUCCESS,
  InvestorFileActionTypes.SEND_INVESTOR_FILES_EMAIL_FAILURE,
)<{ filesIds: number[]; account_id: number }, InvestorFileSendingInfo[], ResponseError>();

export const setFilter = createAction(InvestorFileActionTypes.SET_FILTER)<InvestorFileFilter | null>();
export const clearInvestorFileList = createAction(InvestorFileActionTypes.CLEAR_INVESTOR_FILE_LIST)();
export const clearInvestorFile = createAction(InvestorFileActionTypes.CLEAR_INVESTOR_FILE)();

export const clearErrors = createAction(InvestorFileActionTypes.CLEAR_ERRORS)<InvestorFileError[] | void>();
