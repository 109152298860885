import React, { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@shared/components";
import { NameOfChildRoutes } from "@shared/constants";

export const ViewSubscriptionsLink: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const navigateToSubscriptions = () => {
    navigate({ pathname: `${NameOfChildRoutes.PAY_PER_LEAD.ROOT}/${NameOfChildRoutes.PAY_PER_LEAD.SUBSCRIPTIONS}` });
  };

  return (
    <Button buttonStyle="link" onClick={navigateToSubscriptions}>
      {children}
    </Button>
  );
};
