import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectors } from "@containers/PayPerLead/store";

export const useSubscriptionFromParams = () => {
  const params = useParams<{ id: string }>();
  const id = Number(params.id);
  const subscriptions = useSelector(selectors.getSubscriptions()) ?? [];

  return subscriptions.find((s) => s.id === id);
};
