import React from "react";
import classnames from "classnames";

import "./index.scss";

export type HeaderProps = React.PropsWithChildren<{
  hasBorder?: boolean;
}>;

export type HeaderComponent = { Left: React.FC<React.PropsWithChildren>; Right: React.FC<React.PropsWithChildren> };

const Left: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="header-left">{children}</div>;
};

const Right: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="header-right">{children}</div>;
};

export const Header: React.FC<HeaderProps> & HeaderComponent = ({ children, hasBorder }) => {
  return <div className={classnames("page-header", { "page-header-border": hasBorder })}>{children}</div>;
};

Header.Left = Left;

Header.Right = Right;

export default Header;
