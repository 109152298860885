import React, { FC } from "react";
import classnames from "classnames";
import DateRangePickerOrigin, { DateRangePickerProps } from "rsuite/DateRangePicker";
import "../DatePicker/rsuite.css";
import "../DatePicker/rsuite.calendar.css";
import "./index.scss";

interface CustomDatePickerProps extends DateRangePickerProps {
  className?: string;
  label?: string;
  disabled?: boolean;
}

const DateRangePicker: FC<CustomDatePickerProps> = ({ className, label, disabled, ...rest }) => {
  return (
    <div className={classnames("custom-date-range-picker", className, { disabled })}>
      {label && <label>{label}</label>}
      <DateRangePickerOrigin {...rest} isoWeek className="custom-date-range-picker-wrapper" placement="auto" />
    </div>
  );
};
export default DateRangePicker;
