import React from "react";
import classNames from "classnames";

import "./index.scss";

interface SidebarItemsProps {
  label: string;
  labelNumber: number;
  className?: string;
  onChangeActiveTab: (label: string) => void;
}

const TabBarNav = (props: SidebarItemsProps) => {
  const { label, labelNumber, className, onChangeActiveTab } = props;
  const classes = classNames("tab-wrapper", className);
  const classesTabItem = classNames("tab-nav-item", className);

  return (
    <div className={classes}>
      <div className={classesTabItem} onClick={() => onChangeActiveTab(label)}>
        <div className="tab-nav-item-content">
          <span className="tab-nav-item-number">{labelNumber}.</span>
          <span className="tab-nav-item-text">{label}</span>
        </div>
      </div>
    </div>
  );
};

export default TabBarNav;
