import React, { FC } from "react";
import { Icon } from "@shared/components";

import "./index.scss";

interface EmptyStateProps {
  title: string;
  subtitle?: string;
  component?: React.ReactNode;
}

const EmptyState: FC<EmptyStateProps> = (props) => {
  const { title, subtitle, component } = props;
  return (
    <div className="empty-state-wrapper">
      <Icon type="empty-screen" />
      <div className="empty-state-title">{title}</div>
      <div className="empty-state-subtitle">{subtitle}</div>
      {component && component}
    </div>
  );
};

export default EmptyState;
