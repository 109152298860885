import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { HubspotPropertiesHistoryFilter } from "@shared/interfaces";

export default {
  getHubspotHistoryList: (payload: HubspotPropertiesHistoryFilter) =>
    request(METHODS.GET, API.HUBSPOT_HISTORY.GET_HUBSPOT_HISTORY_LIST)({ params: payload }),

  updateHubspotHistoryItemStatus: (payload: { id: number; processed: boolean }) => {
    const { id, processed } = payload;
    return request(METHODS.PUT, API.HUBSPOT_HISTORY.UPDATE_HUBSPOT_HISTORY_ITEM_STATUS(String(id)))({ processed });
  },
};
