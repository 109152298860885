import React, { FC } from "react";
import classNames from "classnames";

import "./index.scss";

interface ParallelogramProps {
  width: string;
  height: string;
  background: string;
  clipPath: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  className?: string;
}

const Parallelogram: FC<ParallelogramProps> = (props) => {
  const { width, height, background, clipPath, left, right, top, bottom, className } = props;

  const classes = classNames("parallelogram", className || "");
  return <div style={{ width, height, background, clipPath, left, right, top, bottom }} className={classes}></div>;
};

export default Parallelogram;
