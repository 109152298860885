import {
  CheckoutData,
  CheckoutLineData,
  CheckoutRowData,
  PayPerLeadCacheType,
  SubscriptionStateItem,
} from "@containers/PayPerLead/interface";

import { useCache } from "../context";

class CacheMiss extends Error {}

export const useCheckoutData = (stateItems: SubscriptionStateItem[]): CheckoutData | null => {
  const cache = useCache();

  return getCheckoutData(stateItems, cache);
};

export const getCheckoutData = (
  stateItems: SubscriptionStateItem[],
  cache: PayPerLeadCacheType,
): CheckoutData | null => {
  const rows: CheckoutRowData[] = [];

  try {
    for (const stateItem of stateItems) {
      if (stateItem.all_counties) {
        rows.push(allCountiesRow(stateItem, cache));
      } else {
        if (stateItem.force_total_per_county) {
          rows.push(specificCountiesRow(stateItem, cache));
        } else {
          rows.push(groupedCountiesRow(stateItem, cache));
        }
      }
    }
  } catch (error) {
    if (!(error instanceof CacheMiss)) {
      throw error;
    }

    return null;
  }

  const totalLeads = rows.reduce((acc, row) => acc + row.subtotal.leads, 0);

  return {
    rows,
    total: {
      leads: totalLeads,
      price: rows.reduce((acc, row) => acc + row.subtotal.price, 0),
      label: `Total for ${totalLeads} leads`,
    },
  };
};

const allCountiesRow = (item: SubscriptionStateItem, cache: PayPerLeadCacheType): CheckoutRowData => {
  const stateOption = cache.stateOptions[item.state_id as number];

  if (!stateOption) {
    throw new CacheMiss();
  }

  if (!cache.leadPriceByState[stateOption.value]) {
    throw new CacheMiss();
  }

  const perLead = cache.leadPriceByState[stateOption.value];
  const totalPrice = perLead * (item.leads_total as number);

  return {
    subitems: [
      {
        perLead,
        label: `All Counties, ${stateOption.abbreviation}`,
        leads: item.leads_total as number,
        price: totalPrice,
      },
    ],
    subtotal: {
      label: `${stateOption.label} Total`,
      leads: item.leads_total as number,
      price: totalPrice,
    },
  };
};

const specificCountiesRow = (item: SubscriptionStateItem, cache: PayPerLeadCacheType): CheckoutRowData => {
  let statePrice = 0;
  let stateLeads = 0;

  const stateOption = cache.stateOptions[item.state_id as number];

  if (!stateOption) {
    throw new CacheMiss();
  }

  const lines: CheckoutLineData[] = [];

  for (const countyItem of item.counties) {
    const countyOption = stateOption.counties[countyItem.county_id];

    if (!countyOption) {
      throw new CacheMiss();
    }

    const countyLeads = countyItem.total_leads ?? 0;
    const perLeadPrice = cache.leadPricesByCounty[countyItem.county_id];

    const line: CheckoutLineData = {
      label: `${countyOption.label}, ${stateOption.abbreviation}`,
      leads: countyLeads,
      perLead: perLeadPrice,
      price: perLeadPrice * countyLeads,
    };

    lines.push(line);
    stateLeads += line.leads;
    statePrice += line.price;
  }

  return {
    subitems: lines,
    subtotal: {
      label: `${stateOption.label} Total`,
      leads: stateLeads,
      price: statePrice,
    },
  };
};

const groupedCountiesRow = (item: SubscriptionStateItem, cache: PayPerLeadCacheType): CheckoutRowData => {
  const stateOption = cache.stateOptions[item.state_id as number];

  if (!stateOption) {
    throw new CacheMiss();
  }

  if (!cache.leadPriceByState[stateOption.value]) {
    throw new CacheMiss();
  }

  for (const { county_id } of item.counties) {
    if (!stateOption.counties[county_id]) {
      throw new CacheMiss();
    }

    if (!cache.leadPricesByCounty[county_id]) {
      throw new CacheMiss();
    }
  }

  const leads = Number(item.leads_total);
  let price: number;
  let perLead: number;

  if (item.counties.length === 1) {
    const countyItem = item.counties[0];

    if (!countyItem) {
      throw new CacheMiss();
    }

    perLead = cache.leadPricesByCounty[countyItem.county_id];

    price = leads * perLead;
  } else {
    const priceSum = item.counties.reduce((acc, { county_id }) => {
      const countyPerLead = cache.leadPricesByCounty[county_id];

      return acc + countyPerLead;
    }, 0);

    perLead = priceSum / item.counties.length;

    price = perLead * (item.leads_total as number);
  }

  const firstThree = item.counties.slice(0, 3);
  const parts = firstThree.map(({ county_id }) => {
    const countyOption = stateOption.counties[county_id];

    return `${countyOption.label}, ${stateOption.abbreviation}`;
  });

  if (item.counties.length > 3) {
    const left = item.counties.length - 3;
    parts.push(`${left} more`);
  }

  return {
    subitems: [
      {
        leads,
        price,
        perLead,
        label: parts.join(" + "),
      },
    ],
    subtotal: {
      leads,
      price,
      label: `${stateOption.label} Total`,
    },
  };
};
