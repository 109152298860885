import { Account, User } from "@shared/models";

export enum OverlaySize {
  NORMAL = "normal",
  EXTENDED = "extended",
}

export interface OverlayComponent {
  type: string;
  size?: OverlaySize;
  closeOnOverlayClick: boolean;
  leaveConfirm?: boolean;
  props: {
    id?: number;
    onClose?: () => void;
    client?: User;
    contact?: Account;
    account?: Account;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    callback?: <T>(e?: T) => void;
  };
}
