import React, { lazy, useEffect } from "react";
import { useRoutes, RouteObject, useNavigate, To } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { NamesOfParentRoutes } from "@shared/constants";
import useNotification from "@shared/hooks/NotificationHook";
import { Loadable, LoadingIndicator } from "@shared/components";
import OverlayWrapper from "@shared/components/OverlayWrapper/OverlayWrapper";
import ModalWrapper from "@shared/components/ModalWrapper/ModalWrapper";
import ProgressScreenWrapper from "@shared/components/ProgressScreenWrapper/ProgressScreenWrapper";
import { getActiveAccount } from "@containers/Account/store/selectors";
import { tokenHandler } from "@shared/utils";
import { createVitallyUser } from "@shared/utils/vitally";
import { NavigationScroll } from "@shared/components/NavigationScroll";

import { NotFound } from "../../shared/components";
import { AuthGuard, GuestGuard } from "../../shared/guards";

const AuthContainer = Loadable(lazy(() => import("../Auth/containers/AuthContainer/AuthContainer")));
const DashboardContainer = Loadable(
  lazy(() => import("@containers/Dashboard/containers/DashboardContainer/DashboardContainer")),
);

const routes: RouteObject[] = [
  {
    path: `${NamesOfParentRoutes.DASHBOARD}*`,
    element: (
      <AuthGuard>
        <DashboardContainer />
      </AuthGuard>
    ),
  },
  {
    path: `${NamesOfParentRoutes.AUTH}/*`,
    element: (
      <GuestGuard>
        <AuthContainer />
      </GuestGuard>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notification = useSelector(sharedSelectors.getNotification());
  const navigateURL = useSelector(sharedSelectors.getNavigateURL());
  const loading = useSelector(sharedSelectors.getLoading());
  const currentUser = useSelector(sharedSelectors.getUserDetails());
  const account = useSelector(getActiveAccount());

  useEffect(() => {
    createVitallyUser(currentUser, account);
  }, [currentUser, account]);

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL.to as To, navigateURL.options);
      dispatch(sharedActions.navigate(null));
    }
  }, [navigateURL, dispatch, navigate]);

  useEffect(() => {
    const token = tokenHandler.get();
    if (token) {
      dispatch(sharedActions.getUserDetails.request());
    }
  }, [dispatch]);

  const content = useRoutes(routes);
  useNotification(notification);

  return (
    <>
      <ModalWrapper />
      <OverlayWrapper />
      <ProgressScreenWrapper />
      <NavigationScroll />
      <div className="App">
        {loading && <LoadingIndicator />}
        {content}
      </div>
    </>
  );
};

export default App;
