import React from "react";
import { Divider } from "rsuite";
import { Button } from "@shared/components";
import "./index.scss";
import { PropertyFilterOption } from "@shared/interfaces/RecordManagement";

interface Props {
  recentlyUsed: PropertyFilterOption[];
  usedFilters: Record<string, boolean>;
  onAdd: (i: PropertyFilterOption) => void;
}

export const RecentlyUsedFilters = ({ recentlyUsed, usedFilters, onAdd }: Props) => {
  const list = recentlyUsed.filter((i) => !usedFilters[i.value]);

  if (!list?.length) return null;

  return (
    <>
      <Divider>Recently used filters</Divider>
      <div className="recently-used">
        {list.map((i) => (
          <Button key={i.label} buttonStyle="bordered" className="" onClick={() => onAdd(i)}>
            {i.label}
            <span>Add</span>
          </Button>
        ))}
      </div>
    </>
  );
};
