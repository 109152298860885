import React from "react";

export const Email = () => (
  <div className="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66675 5.83301C1.66675 4.4523 2.78604 3.33301 4.16675 3.33301H15.8334C17.2141 3.33301 18.3334 4.4523 18.3334 5.83301V14.1663C18.3334 15.5471 17.2141 16.6663 15.8334 16.6663H4.16675C2.78604 16.6663 1.66675 15.5471 1.66675 14.1663V5.83301ZM3.40951 5.48462L9.48847 10.2127C9.78939 10.4468 10.2108 10.4468 10.5117 10.2127L16.5907 5.48462C16.4587 5.19837 16.1693 4.99967 15.8334 4.99967H4.16675C3.83088 4.99967 3.54143 5.19837 3.40951 5.48462ZM16.6667 7.53687L11.5349 11.5283C10.6322 12.2304 9.36801 12.2304 8.46523 11.5283L3.33341 7.53687V13.8212L5.24416 11.9104C5.5696 11.585 6.09723 11.585 6.42267 11.9104C6.74811 12.2359 6.74811 12.7635 6.42267 13.0889L4.51192 14.9997H15.4882L13.5775 13.0889C13.2521 12.7635 13.2521 12.2359 13.5775 11.9104C13.9029 11.585 14.4306 11.585 14.756 11.9104L16.6667 13.8212V7.53687Z"
        fill="currentColor"
      />
    </svg>
  </div>
);
