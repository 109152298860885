import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import codes from "country-calling-code";
import { Button, CheckboxInput, ErrorMessage, Input, InputPicker } from "@shared/components";
import { Account, User, UserStatus } from "@shared/models";
import { CreateClientDto } from "@containers/Contact/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getUserExistence } from "@containers/Auth/store/selectors";
import { checkUser, clearUserExistence } from "@containers/Auth/store/actions";
import { AccessControl } from "@shared/components/AccessControl";
import { MODAL_TYPES, USER_PERMISSION } from "@shared/constants";
import { actions } from "@shared/store";

import { ClientFormShape, getInitValues, prepareSubmitValue, validationSchema } from "./formHelpers";

import "./index.scss";

interface ClientFormProps {
  client: User | null;
  contact?: Account;
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: Partial<CreateClientDto>) => void;
  changeClientAccountStatus: () => void;
  isSelectedClientActiveInContact: boolean;
}

const CODES_OVERRIDES: Record<string, string> = {
  "380": "38",
};

const getCode = (code: string) => CODES_OVERRIDES[code] || code;

const ClientForm: FC<ClientFormProps> = (props) => {
  const dispatch = useDispatch();
  const {
    client,
    contact,
    onChangeForm,
    onCloseForm,
    onSubmitForm,
    changeClientAccountStatus,
    isSelectedClientActiveInContact,
  } = props;

  const [assignUser, setAssignUser] = useState(false);

  const formValues = useMemo(() => getInitValues(client, contact), [client, contact]);
  const formikRef = useRef<FormikProps<ClientFormShape>>(null);

  const userExistence = useSelector(getUserExistence());

  useEffect(() => {
    if (userExistence !== null && formikRef.current) {
      dispatch(clearUserExistence());
      if (userExistence) {
        dispatch(
          actions.showModal({
            type: MODAL_TYPES.CONFIRM,
            props: {
              heading: "Existing user",
              content: `This user already exists in the system. Please confirm that you would like to add the user to the ${contact?.name}.`,
              cancelBtnText: "Cancel",
              successBtnText: "Confirm",
              onSuccess: () => {
                setAssignUser(true);
                formikRef.current?.submitForm();
              },
            },
          }),
        );
      } else {
        setAssignUser(true);
        formikRef.current.submitForm();
      }
    }
  }, [client, contact, dispatch, userExistence]);

  const handleCheckUserEmail = (values: ClientFormShape) => {
    dispatch(checkUser.request({ email: values.email, noRedirect: true }));
  };

  const isDisabled = !!client && client?.status !== UserStatus.active;

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        if (client || assignUser) {
          setAssignUser(false);
          onSubmitForm(prepareSubmitValue(values, client));
          setSubmitting(false);
        } else if (contact?.users?.some(({ email }) => email === values.email)) {
          formikRef.current?.setFieldError("email", "User already exists");
        } else {
          handleCheckUserEmail(values);
        }
      }}
      initialValues={formValues}
      validate={() => onChangeForm()}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <div className="client-form">
          <form onSubmit={handleSubmit}>
            <div className="client-form-header">
              <div className="client-form-header-left">{client ? "Edit" : "New"} Contact</div>

              <div className="client-form-header-right">
                <Button type="submit" buttonStyle="accent-green" disabled={isDisabled}>
                  Save
                </Button>
                <Button type="button" buttonStyle="form-cancel" onClick={onCloseForm}>
                  Cancel
                </Button>
              </div>
            </div>
            <div className="horizontal-divider"></div>
            <div className="client-form-content">
              <div className="user-name-input-group">
                <div>
                  <Input
                    type="text"
                    name="first_name"
                    label="First Name"
                    isRequiredField={true}
                    value={values.first_name}
                    onChange={handleChange}
                    placeholder="First Name"
                    disabled={isDisabled}
                  />
                  <ErrorMessage isTouched={touched["first_name"]} error={errors["first_name"]} />
                </div>
                <div>
                  <div>
                    <Input
                      type="text"
                      name="last_name"
                      label="Last Name"
                      isRequiredField={true}
                      value={values.last_name}
                      onChange={handleChange}
                      placeholder="Last Name"
                      disabled={isDisabled}
                    />
                    <ErrorMessage isTouched={touched["last_name"]} error={errors["last_name"]} />
                  </div>
                </div>
              </div>
              <div>
                <Input
                  type="text"
                  name="email"
                  label="Email"
                  isRequiredField={true}
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email"
                  disabled={!!client}
                />
              </div>
              <ErrorMessage isTouched={touched["email"]} error={errors["email"]} />

              <div className="phone">
                <InputPicker
                  searchable
                  cleanable={false}
                  name="phone_country_code"
                  className="select-picker"
                  label="Country code"
                  useContainer
                  disableContainerStyles
                  value={values.phone_country_code}
                  data={codes.map((i) => ({
                    value: getCode(i.countryCodes[0]),
                    label: `${i.country} (+${getCode(i.countryCodes[0])})`,
                  }))}
                  onChange={(value) => handleChange({ target: { id: "phone_country_code", value } })}
                />

                <div className="phone_number">
                  <Input
                    type="phone"
                    name="phone_number"
                    label="Phone Number"
                    isRequiredField={values.sms_on_lead_creation}
                    value={values.phone_number?.toString()}
                    onChange={handleChange}
                    placeholder="Phone Number"
                  />
                  <ErrorMessage isTouched={touched["phone_number"]} error={errors["phone_number"]} />
                </div>
              </div>

              <div>
                <CheckboxInput
                  name="email_on_lead_creation"
                  value={Number(values.email_on_lead_creation)}
                  onChange={handleChange}
                  label="Notify about new PPL via Email"
                />

                <CheckboxInput
                  name="sms_on_lead_creation"
                  value={Number(values.sms_on_lead_creation)}
                  onChange={handleChange}
                  label="Notify about new PPL via SMS"
                />
              </div>
            </div>
            {client && (
              <div className="client-form-footer">
                <div className={`client-form-footer-left ${isSelectedClientActiveInContact ? "active" : "inactive"}`}>
                  <span>{isSelectedClientActiveInContact ? "active" : "inactive"}</span>
                </div>
                <div className="client-form-footer-right">
                  <AccessControl permission={USER_PERMISSION.DELETE_USER}>
                    {isSelectedClientActiveInContact && (
                      <Button type="button" buttonStyle="clear-red" onClick={changeClientAccountStatus}>
                        Deactivate User
                      </Button>
                    )}
                  </AccessControl>
                  <AccessControl permission={USER_PERMISSION.ACTIVATE_USER}>
                    {!isSelectedClientActiveInContact && (
                      <Button type="button" buttonStyle="form-cancel" onClick={changeClientAccountStatus}>
                        Activate User
                      </Button>
                    )}
                  </AccessControl>
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </Formik>
  );
};

export default ClientForm;
