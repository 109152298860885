import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { InvestorFileFilter, ORDER_COLUMN_TYPE, ORDER_TYPE } from "@shared/interfaces";
import { InvestorFileError, InvestorFileStateType } from "@containers/InvestorFile/interfaces";
import merge from "lodash/merge";

import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultFilter: InvestorFileFilter = {
  account_id: null,
  page: 1,
  limit: 50,
  search: null,
  order: ORDER_COLUMN_TYPE.CREATED_AT,
  direction: ORDER_TYPE.DESC,
};

export const initialState: InvestorFileStateType = {
  investorFile: null,
  investorFileList: null,
  filter: { ...defaultFilter },
  errors: {
    [InvestorFileError.getInvestorFileError]: null,
    [InvestorFileError.getInvestorFileListError]: null,
    [InvestorFileError.processInvestorFileError]: null,
    [InvestorFileError.createInvestorFileError]: null,
  },
};

const reducer = createReducer<InvestorFileStateType, Action>(initialState)
  .handleAction(actions.getInvestorFileList.success, (state, action) =>
    produce(state, (nextState) => {
      const { items, total, clear } = action.payload;
      if (nextState.investorFileList) {
        nextState.investorFileList.list = clear ? items : [...nextState.investorFileList.list, ...items];
        nextState.investorFileList.total = total;
      } else {
        nextState.investorFileList = { list: items, total };
      }
    }),
  )
  .handleAction(actions.getInvestorFileList.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[InvestorFileError.getInvestorFileListError] = action.payload;
    }),
  )
  .handleAction(actions.getInvestorFile.success, (state, action) =>
    produce(state, (nextState) => {
      const investorFileList = nextState.investorFileList?.list;
      const investorFile = action.payload;

      if (investorFileList) {
        const foundInvestorFileIndex = investorFileList.findIndex((file) => investorFile.id === file.id);

        investorFileList[foundInvestorFileIndex] = investorFile;

        if (nextState.investorFileList) {
          nextState.investorFileList.list = [...investorFileList];
        }
      }

      nextState.investorFile = investorFile;
    }),
  )
  .handleAction(actions.getInvestorFile.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[InvestorFileError.getInvestorFileError] = action.payload;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.createInvestorFile.success, (state, action) =>
    produce(state, (nextState) => {
      const investorFileList = nextState.investorFileList?.list;
      const investorFile = action.payload;
      if (nextState.investorFileList && investorFileList) {
        nextState.investorFileList.list = [investorFile, ...investorFileList];
      }

      nextState.errors[InvestorFileError.createInvestorFileError] = null;
    }),
  )
  .handleAction(actions.createInvestorFile.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[InvestorFileError.createInvestorFileError] = action.payload;
    }),
  )

  .handleAction(actions.updateInvestorFile.success, (state, action) =>
    produce(state, (nextState) => {
      const investorFile = action.payload;

      if (nextState.investorFileList?.list) {
        const foundInvestorFileIndex = nextState.investorFileList.list.findIndex((file) => investorFile.id === file.id);

        merge(nextState.investorFileList?.list[foundInvestorFileIndex], investorFile);
      }
    }),
  )

  .handleAction(actions.clearInvestorFileList, (state) =>
    produce(state, (nextState) => {
      nextState.investorFileList = null;
    }),
  )

  .handleAction(actions.clearInvestorFile, (state) =>
    produce(state, (nextState) => {
      nextState.investorFile = null;
    }),
  )

  .handleAction(actions.processInvestorFile.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.investorFile = action.payload;
      nextState.errors[InvestorFileError.processInvestorFileError] = null;
    }),
  )
  .handleAction(actions.processInvestorFile.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[InvestorFileError.processInvestorFileError] = action.payload;
    }),
  )

  .handleAction(actions.sendInvestorFileEmails.success, (state, action) =>
    produce(state, (nextState) => {
      if (nextState.investorFileList) {
        nextState.investorFileList.list = nextState.investorFileList.list.map((existing) => {
          const updated = action.payload.find((item) => item.id === existing.id);

          if (updated) {
            return { ...existing, ...updated };
          }

          return existing;
        });
      }
    }),
  )

  .handleAction(actions.clearErrors, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        action.payload.forEach((err) => {
          nextState.errors[err] = null;
        });
      } else {
        nextState.errors = initialState.errors;
      }
    }),
  );

export { reducer as InvestorFileReducer };
