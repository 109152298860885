import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { showNotification, startLoading, stopLoading } from "@shared/store/actions";
import { File, MappedToAccountFile, UpdateFileMappingType } from "@shared/models";
import { ActionWithPayload, ResponseError } from "@shared/interfaces";
import { download } from "@shared/utils/request";
import { showHttpErrorNotification } from "@shared/utils";

import api from "../api";
import { createFile, downloadFile, mapFilesToAccount, updateFileMapping } from "./actions";

function* createFileSaga({ payload }: ReturnType<typeof createFile.request>) {
  try {
    yield put(startLoading());
    const file: File = yield call(api.createFile, payload);
    yield put(createFile.success(file));
  } catch (error) {
    yield put(createFile.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* updateFileMappingSaga({
  payload,
}: ActionWithPayload<UpdateFileMappingType & { id: number; callback: () => void }>) {
  try {
    const { id, callback, ...rest } = payload;
    yield put(startLoading());
    const file: File = yield call(api.updateFileMapping, { id, ...rest });
    yield put(updateFileMapping.success(file));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(updateFileMapping.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* getFileSignedUrlSaga({ payload }: ReturnType<typeof downloadFile>) {
  const { id, callback } = payload;
  try {
    yield put(startLoading());
    const { url }: { url: string } = yield call(api.getFileSignedUrl, id);

    yield call(download, url);

    yield put(
      showNotification({
        message: "The file was successfully downloaded",
        appearance: "success",
      }),
    );
    if (callback) {
      // Fixing Safari issue. It throws error when it starts downloading file
      // and sends request to the server at the same time
      setTimeout(() => {
        callback();
      }, 100);
    }
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError, "Can't download file"));
  } finally {
    yield put(stopLoading());
  }
}

function* mapFilesToAccountSaga({ payload }: ReturnType<typeof mapFilesToAccount.request>) {
  const { callback, files } = payload;

  try {
    yield put(startLoading());
    const response: MappedToAccountFile[] = yield call(api.mapFileToAccount, files);
    yield put(mapFilesToAccount.success(response));
    if (response && callback) {
      callback(response);
    }
  } catch (error) {
    yield put(mapFilesToAccount.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* fileSagas() {
  yield takeLatest(createFile.request, createFileSaga);
  yield takeLatest(updateFileMapping.request, updateFileMappingSaga);
  yield takeLatest(mapFilesToAccount.request, mapFilesToAccountSaga);
  yield takeEvery(downloadFile, getFileSignedUrlSaga);
}

export default fileSagas;
