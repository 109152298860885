import { all, call, put, select, takeLatest, delay, takeEvery } from "redux-saga/effects";
import moment from "moment";
import {
  getStates,
  handleCompanyForbiddenError,
  showNotification,
  startLoading,
  stopLoading,
} from "@shared/store/actions";
import sharedApi from "@shared/api";
import { showHttpErrorNotification } from "@shared/utils";
import { download } from "@shared/utils/request";
import { ActionWithPayload, ORDER_COLUMN_TYPE, ORDER_TYPE, PaginatedResponse, ResponseError } from "@shared/interfaces";
import { PayPerLead, PayPerLeadDispute, PayPerLeadWithDetail } from "@shared/models/PayPerLead";
import { selectors as accountSelectors } from "@containers/Account/store";
import { Account, State } from "@shared/models";
import { getActiveAccount } from "@containers/Account/store/selectors";
import {
  CheckPurchaseResponse,
  CountyById,
  CountyListResponse,
  DownloadLeadsRequestPayload,
  FetchCountyOptionsPayload,
  FetchCountyPricePayload,
  FetchStateLeadPricePayload,
  GetDisputesSuccess,
  LeadPriceResponse,
  PayPerLeadStatistics,
  PayPerLeadSubscription,
  PayPerLeadSubscriptionWithCustomer,
  StatesById,
  SubscriptionsListQuery,
  SubscriptionsListResponse,
  SubscriptionWithCallbackPayload,
  PayPerLeadPurchaseInfo,
  LeadFilterOptions,
  UpdateDisputeResponse,
  SubscriptionIdsWithCallbackPayload,
} from "@containers/PayPerLead/interface";
import { checkIfAdmin } from "@shared/utils/ACL";

import api from "../api";

import { actions } from "./";

function* getSubscriptionsSaga({ payload }: ActionWithPayload<SubscriptionsListQuery>) {
  try {
    yield put(startLoading());

    const subscriptions: SubscriptionsListResponse = yield call(api.getSubscriptions, payload.accountId, payload);

    yield put(actions.getLeadSubscriptions.success(subscriptions));
  } catch (error) {
    yield put(actions.getLeadSubscriptions.failure(error as Error));
    yield put(handleCompanyForbiddenError(error as { code: number }));

    console.error(error);
  } finally {
    yield put(stopLoading());
  }
}

function* getLeadSaga({ payload }: ReturnType<typeof actions.getLead.request>) {
  try {
    yield put(startLoading());

    const lead: PayPerLeadWithDetail = yield call(api.getLead, payload);
    yield put(actions.getLead.success(lead));
  } catch (error) {
    yield put(actions.getLead.failure(error as Error));

    yield put(handleCompanyForbiddenError(error as { code: number }));
  } finally {
    yield put(stopLoading());
  }
}

function* disputeLeadSaga({ payload }: ReturnType<typeof actions.disputeLead.request>) {
  try {
    yield put(startLoading());

    const dispute: PayPerLeadDispute = yield call(api.disputeLead, payload);
    yield put(actions.disputeLead.success(dispute));
  } catch (error) {
    yield put(actions.disputeLead.failure(error as Error));

    yield put(
      showNotification({ message: (error as Error)?.message ?? "Could not dispute lead", appearance: "error" }),
    );
  } finally {
    yield put(stopLoading());
  }
}

function* getLeadsSaga({ payload }: ReturnType<typeof actions.getLeads.request>) {
  try {
    yield put(startLoading());

    const leads: PaginatedResponse<PayPerLeadWithDetail> = yield call(api.getLeads, payload);
    yield put(actions.getLeads.success({ ...leads, clear: payload.page === 1 }));
  } catch (error) {
    yield put(actions.getLeads.failure(error as Error));

    yield put(handleCompanyForbiddenError(error as { code: number }));
  } finally {
    yield put(stopLoading());
  }
}

function* getSubscriptionsWithCustomersSaga({
  payload,
}: ReturnType<typeof actions.getSubscriptionsWithCustomers.request>) {
  try {
    yield put(startLoading());

    const customers: PaginatedResponse<PayPerLeadSubscriptionWithCustomer> = yield call(
      api.getSubscriptionsWithCustomers,
      payload,
    );
    yield put(actions.getSubscriptionsWithCustomers.success({ ...customers, clear: payload.page === 1 }));
  } catch (error) {
    yield put(actions.getSubscriptionsWithCustomers.failure(error as Error));

    yield put(handleCompanyForbiddenError(error as { code: number }));
  } finally {
    yield put(stopLoading());
  }
}

function* getCurrentMonthLeadsSaga({ payload }: ReturnType<typeof actions.getCurrentMonthLeads.request>) {
  try {
    yield put(startLoading());

    const leads: PaginatedResponse<PayPerLead> = yield call(api.getLeads, payload);
    yield put(actions.getCurrentMonthLeads.success({ ...leads, clear: payload.page === 1 }));
  } catch (error) {
    yield put(actions.getCurrentMonthLeads.failure(error as Error));

    yield put(handleCompanyForbiddenError(error as { code: number }));
  } finally {
    yield put(stopLoading());
  }
}

function* getSubscriptionDetailsSaga({
  payload: { id, draftItems },
}: ReturnType<typeof actions.getSubscriptionDetails.request>) {
  try {
    yield put(startLoading());

    const subscription: PayPerLeadSubscription = yield call(api.getSubscriptionDetails, id, draftItems);

    yield put(actions.getSubscriptionDetails.success(subscription));
  } catch (error) {
    showNotification({
      message: "Failed to get subscription",
      appearance: "error",
    });
    console.error(error);
  } finally {
    yield put(stopLoading());
  }
}

function* setStatesCacheSaga({ payload }: ActionWithPayload<State[]>) {
  const map: StatesById = {};

  for (const item of payload) {
    map[item.id] = {
      value: item.id,
      label: item.name,
      abbreviation: item.abbreviation,
      counties: {},
    };
  }

  yield put(actions.cacheStatesOptions(map));
}

function* fetchCountyOptionsSaga({ payload }: ActionWithPayload<FetchCountyOptionsPayload>) {
  try {
    yield put(startLoading());

    const response: CountyListResponse = yield call(sharedApi.getCounties, payload.stateId);
    const counties: CountyById = {};

    for (const county of response.items) {
      counties[county.id] = {
        value: county.id,
        label: county.name,
      };
    }

    yield put(
      actions.fetchCountyOptions.success({
        counties,
        stateId: payload.stateId,
      }),
    );
  } catch (error) {
    yield put(
      showNotification({
        appearance: "error",
        message: "Failed to load county list",
      }),
    );
  } finally {
    yield put(stopLoading());
  }
}

function* fetchCountyPricesSaga({ payload }: ActionWithPayload<FetchCountyPricePayload>) {
  try {
    yield put(startLoading());
    const response: LeadPriceResponse = yield call(api.getLeadPrice, payload.stateId, payload.countyId);
    yield put(
      actions.fetchCountyPrice.success({
        stateId: payload.stateId,
        countyId: payload.countyId,
        price: response.price,
      }),
    );
  } catch (error) {
    yield put(
      showNotification({
        appearance: "error",
        message: "Failed to load price. Please try again later.",
      }),
    );
  } finally {
    yield put(stopLoading());
  }
}

function* fetchStateLeadPriceSaga({ payload }: ActionWithPayload<FetchStateLeadPricePayload>) {
  try {
    yield put(startLoading());

    const response: LeadPriceResponse = yield call(api.getLeadPrice, payload.stateId);

    yield put(
      actions.fetchStateLeadPrice.success({
        stateId: payload.stateId,
        price: response.price,
      }),
    );
  } catch (error) {
    yield put(
      showNotification({
        appearance: "error",
        message: "Failed to load price",
      }),
    );
    yield put(actions.fetchStateLeadPrice.failure(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* fetchFilterOptionsSaga() {
  let accountId;
  const isAdmin = checkIfAdmin();

  if (!isAdmin) {
    const account: Account = yield select(accountSelectors.getActiveAccount());
    accountId = account.id;
  }

  yield put(startLoading());

  try {
    const data: LeadFilterOptions = yield call(api.getFilterOptions, accountId);
    yield put(actions.fetchFilterOptions.success(data));
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError));
    yield put(actions.fetchFilterOptions.failure(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* saveSubscriptionSaga({ payload }: ActionWithPayload<SubscriptionWithCallbackPayload>) {
  const account: Account = yield select(accountSelectors.getActiveAccount());

  yield put(startLoading());

  try {
    const response: PayPerLeadSubscription = payload.subscription.id
      ? yield call(api.updateSubscription, payload.subscription)
      : yield call(api.createSubscription, account.id, payload.subscription);

    yield put(actions.saveSubscription.success(response));

    payload.callback(response.id as number);
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError));
    yield put(actions.saveSubscription.failure(error as ResponseError));
    console.error(error);
  } finally {
    yield put(stopLoading());
  }
}

function* purchaseSubscriptionSaga({ payload }: ActionWithPayload<SubscriptionWithCallbackPayload>) {
  const account: Account = yield select(accountSelectors.getActiveAccount());

  yield put(startLoading());

  try {
    let id = payload.subscription.id;
    if (!id) {
      const response: PayPerLeadSubscription = yield call(api.createSubscription, account.id, payload.subscription);
      id = response.id as number;
    }

    const created: PayPerLeadSubscription = { ...payload.subscription, id };

    yield call(api.purchaseSubscription, id as number);
    yield put(actions.purchaseSubscription.success(created));
    payload.callback(id);
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError));

    console.error(error);
  } finally {
    yield put(stopLoading());
  }
}

function* purchaseSubscriptionsSaga({ payload }: ActionWithPayload<PayPerLeadSubscription[]>) {
  yield put(startLoading());

  try {
    const account: Account = yield select(getActiveAccount());

    yield all(payload.map((s) => call(api.purchaseSubscription, s.id as number)));

    yield put(
      actions.getLeadSubscriptions.request({
        accountId: account.id,
        isActive: true,
        showItems: true,
      }),
    );
  } catch (error) {
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* awaitPurchaseProcessedSaga({ payload }: ActionWithPayload<SubscriptionWithCallbackPayload>) {
  const maxAttempts = 120;
  let attempt = 0;
  if (payload.subscription.id) {
    try {
      yield put(startLoading());

      do {
        attempt++;
        const response: CheckPurchaseResponse = yield call(api.checkPurchase, payload.subscription.id);

        if (response.paid) {
          payload.callback(payload.subscription.id as number);
          break;
        }

        if (!response.hasPendingInvoice) {
          yield call(api.purchaseSubscription, payload.subscription.id);
        }

        if (attempt > maxAttempts) {
          const error: ResponseError = {
            code: 400,
            message: "Max number of attempts reached.",
            customMessage: true,
          };

          yield put(showHttpErrorNotification(error));
          yield put(actions.awaitPurchaseProcessed.failure(error));
          break;
        }
        yield delay(1000);
      } while (true);
    } catch (error) {
      yield put(showHttpErrorNotification(error as ResponseError));
      yield put(actions.awaitPurchaseProcessed.failure(error as ResponseError));

      if (payload.onFailed) {
        payload.onFailed(payload.subscription.id);
      }

      console.error(error);
    } finally {
      yield put(stopLoading());
    }
  }
}

function* fetchTotalPaymentAmountSaga({ payload }: ActionWithPayload<SubscriptionIdsWithCallbackPayload>) {
  if (payload.ids?.length) {
    try {
      yield put(startLoading());
      const responses: PayPerLeadPurchaseInfo[] = yield all(payload.ids.map((id) => call(api.checkPurchase, id)));
      const paymentAmount = responses.reduce((acc, purchaseInfo) => acc + purchaseInfo.paymentAmount, 0);
      payload.callback(paymentAmount);
    } catch (error) {
      yield showHttpErrorNotification(error as ResponseError);
    } finally {
      yield put(stopLoading());
    }
  }
}

function* fetchPurchaseInfoSaga({ payload: id }: ActionWithPayload<number>) {
  if (id) {
    try {
      yield put(startLoading());
      const response: PayPerLeadPurchaseInfo = yield call(api.checkPurchase, id);
      yield put(actions.fetchPurchaseInfo.success(response));
    } catch (error) {
      yield put(showHttpErrorNotification(error as ResponseError));
    } finally {
      yield put(stopLoading());
    }
  }
}

function* getAdminDisputesSaga({ payload }: ReturnType<typeof actions.getDisputes.request>) {
  try {
    yield put(startLoading());
    const disputes: GetDisputesSuccess = yield call(api.getDisputes, payload);
    yield put(actions.getDisputes.success(disputes));
  } catch (error) {
    yield put(actions.getDisputes.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateAdminDisputesSaga({ payload }: ReturnType<typeof actions.updateDispute.request>) {
  try {
    yield put(startLoading());
    const response: UpdateDisputeResponse = yield call(api.updateDispute, payload);
    yield put(actions.updateDispute.success({ ...payload, ...response }));
  } catch (error) {
    yield put(actions.updateDispute.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getStatisticsSaga({ payload }: ReturnType<typeof actions.getStatistics.request>) {
  try {
    yield put(startLoading());
    const statistics: PayPerLeadStatistics = yield call(api.getStatistics, payload);
    yield put(actions.getStatistics.success(statistics));
  } catch (error) {
    yield put(actions.getStatistics.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* deactivateSubscriptionSaga({ payload }: ReturnType<typeof actions.deactivateSubscription.request>) {
  if (!payload.subscription.id) {
    return;
  }

  try {
    yield put(startLoading());
    yield call(api.deactivateSubscription, payload.subscription.id);
    yield put(actions.deactivateSubscription.success(payload.subscription));
    payload.callback(payload.subscription.id);
  } catch (error) {
    yield put(actions.deactivateSubscription.failure(error as ResponseError));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* qualifyLeadsSaga({ payload }: ReturnType<typeof actions.qualifyLeads.request>) {
  try {
    const account: Account = yield select(accountSelectors.getActiveAccount());

    yield call(api.qualifyLeads, {
      ids: payload.ids,
      is_qualified: payload.is_qualified,
      account_id: account.id,
    });

    if (payload.notify && payload.is_qualified !== null) {
      const subject = payload.ids.length > 1 ? "properties" : "property";
      const status = payload.is_qualified ? "Quality Seller Lead" : "Not Quality";

      yield put(
        showNotification({
          appearance: "success",
          message: `${payload.ids.length} ${subject} marked as ${status}`,
        }),
      );
    }

    yield put(actions.qualifyLeads.success(payload));
  } catch (error) {
    yield put(actions.qualifyLeads.failure(error as ResponseError));
    yield put(showHttpErrorNotification(error as ResponseError));

    if (payload.onError) {
      payload.onError(error as Error);
    }
  }
}

function* downloadLeadsCSVSaga({ payload }: ReturnType<typeof actions.downloadLeadsCSV.request>) {
  try {
    yield put(startLoading());
    const params: DownloadLeadsRequestPayload = {
      ids: payload.ids,
      order: ORDER_COLUMN_TYPE.CREATED_AT,
      direction: ORDER_TYPE.DESC,
    };

    if (payload.active_account) {
      const account: Account = yield select(accountSelectors.getActiveAccount());
      params.account_id = account.id;
    }

    const response: Blob = yield call(api.exportLeads, params);

    const now = moment().format("ll");

    yield call(download, URL.createObjectURL(response), `Leads ${now}.csv`);

    yield put(
      showNotification({
        message: "Your file was successfully downloaded",
        appearance: "success",
      }),
    );
  } catch (error) {
    yield put(actions.qualifyLeads.failure(error as ResponseError));
    yield put(showHttpErrorNotification(error as ResponseError));
    console.error(error);
  } finally {
    yield put(stopLoading());
  }
}

function* payPerLeadsSagas() {
  yield takeLatest(getStates.success, setStatesCacheSaga);
  yield takeEvery(actions.fetchCountyOptions.request, fetchCountyOptionsSaga);
  yield takeEvery(actions.fetchStateLeadPrice.request, fetchStateLeadPriceSaga);
  yield takeEvery(actions.fetchCountyPrice.request, fetchCountyPricesSaga);
  yield takeLatest(actions.fetchFilterOptions.request, fetchFilterOptionsSaga);
  yield takeLatest(actions.getLeadSubscriptions.request, getSubscriptionsSaga);
  yield takeLatest(actions.purchaseSubscription.request, purchaseSubscriptionSaga);
  yield takeLatest(actions.purchaseSubscriptions.request, purchaseSubscriptionsSaga);
  yield takeLatest(actions.awaitPurchaseProcessed.request, awaitPurchaseProcessedSaga);
  yield takeLatest(actions.getSubscriptionDetails.request, getSubscriptionDetailsSaga);
  yield takeLatest(actions.saveSubscription.request, saveSubscriptionSaga);
  yield takeLatest(actions.deactivateSubscription.request, deactivateSubscriptionSaga);
  yield takeLatest(actions.getLead.request, getLeadSaga);
  yield takeLatest(actions.getLeads.request, getLeadsSaga);
  yield takeLatest(actions.disputeLead.request, disputeLeadSaga);
  yield takeLatest(actions.getCurrentMonthLeads.request, getCurrentMonthLeadsSaga);
  yield takeLatest(actions.getSubscriptionsWithCustomers.request, getSubscriptionsWithCustomersSaga);
  yield takeLatest(actions.getDisputes.request, getAdminDisputesSaga);
  yield takeLatest(actions.updateDispute.request, updateAdminDisputesSaga);
  yield takeLatest(actions.getStatistics.request, getStatisticsSaga);
  yield takeLatest(actions.qualifyLeads.request, qualifyLeadsSaga);
  yield takeLatest(actions.downloadLeadsCSV.request, downloadLeadsCSVSaga);
  yield takeLatest(actions.fetchPurchaseInfo.request, fetchPurchaseInfoSaga);
  yield takeLatest(actions.fetchTotalPaymentAmount.request, fetchTotalPaymentAmountSaga);
}

export default payPerLeadsSagas;
