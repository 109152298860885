import { useDispatch } from "react-redux";

import * as actions from "../store/actions";

export type QualifyLeadsParams = {
  leadId: number | number[];
  is_qualified: boolean | null;
  notify?: boolean;
  onError?: () => void;
};

export type QualifyLeadsReturn = (params: QualifyLeadsParams) => void;

export const useQualifyLeads = (): QualifyLeadsReturn => {
  const dispatch = useDispatch();

  return ({ leadId, is_qualified, notify, onError }: QualifyLeadsParams) => {
    dispatch(
      actions.qualifyLeads.request({
        ids: Array.isArray(leadId) ? leadId : [leadId],
        is_qualified,
        notify,
        onError,
      }),
    );
  };
};
