import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveAccount } from "@containers/Account/store/selectors";
import { Button, InputPicker, Table, PaginationFacade, usePagination } from "@shared/components";
import { selectors as sharedSelectors } from "@shared/store";
import { NameOfChildRoutes } from "@shared/constants";
import { getCountiesByState, getStates } from "@shared/store/actions";
import { actions as PayPerLeadActions, selectors as PayPerLeadSelectors } from "@containers/PayPerLead/store";
import { NoFilteredLead } from "@containers/PayPerLead/components/PayPerLeadDashboardContainer/EmptyState";
import { ViewSubscriptionsLink } from "@containers/PayPerLead/components/ViewSubscriptionsLink";
import { getLeadsTableProperties } from "@containers/PayPerLead/components/PayPerLeadDashboardContainer/LeadsList/leadsTableProperties";
import { useNavigate } from "react-router-dom";
import { PaymentFailed } from "@containers/PayPerLead/components/PayPerLeadDashboardContainer/PaymentFailed";
import moment from "moment";
import { ORDER_COLUMN_TYPE, ORDER_TYPE, PayPerLeadFilter } from "@shared/interfaces";
import { isPaymentFailed } from "@shared/utils/subscriptions";
import { PayPerLead } from "@shared/models/PayPerLead";
import { useLocation } from "react-router";
import "./index.scss";

const monthDropdown = new Array(24).fill(0).map((_, i) => ({
  value: i.toString(),
  label: moment().subtract(i, "month").format("MMM YYYY"),
}));

const LeadsList: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const account = useSelector(getActiveAccount());
  const leads = useSelector(PayPerLeadSelectors.getLeads());
  const subscriptions = useSelector(PayPerLeadSelectors.getSubscriptions());
  const filter = useSelector(PayPerLeadSelectors.getFilter());
  const states = useSelector(PayPerLeadSelectors.availableStates);
  const counties = useSelector(PayPerLeadSelectors.availableCounties);
  const loading = useSelector(sharedSelectors.getLoading());

  const { pagination, onPageChangeAndScroll } = usePagination();

  const setFilter = useCallback(
    (data: Partial<PayPerLeadFilter>) => dispatch(PayPerLeadActions.updateFilter(data)),
    [dispatch],
  );

  const monthlyLimit = useMemo(() => {
    const items = subscriptions
      ?.filter((s) => {
        if (!s.is_active) {
          return false;
        }

        const month = moment().subtract(filter.monthOffset, "month");

        return (
          moment(s.start_date).isBefore(month.endOf("month")) &&
          (!s.end_date || moment(month.startOf("month")).isBefore(s.end_date))
        );
      })
      ?.flatMap((s) => s.items)
      .filter(
        (i) =>
          i.state_id === +(filter.stateId || 0) &&
          (i.all_counties || i.counties.some((c) => c.county_id === +(filter.countyId || 0))),
      );

    if (items?.[0]?.force_total_per_county && filter.countyId) {
      return items[0].counties.find((c) => c.county_id === Number(filter.countyId))?.total_leads || 0;
    }

    return items?.reduce((sum, i) => sum + +(i.leads_total || 0), 0);
  }, [subscriptions, filter.stateId, filter.countyId, filter.monthOffset]);

  const monthFilteredDropdown = useMemo(() => {
    if (!subscriptions?.length) return monthDropdown;

    const minDate = subscriptions.reduce((minimum, current) => {
      const next = current.created_at ? new Date(current.created_at).getTime() : Date.now();
      return next < minimum ? next : minimum;
    }, Date.now());

    return monthDropdown.filter((m) => moment(m.label).format("YYYY-MM") >= moment(minDate).format("YYYY-MM"));
  }, [subscriptions]);

  useEffect(() => {
    if (states) return;

    dispatch(getStates.request());
  }, [dispatch, states]);

  useEffect(() => {
    dispatch(PayPerLeadActions.fetchFilterOptions.request());

    return () => {
      dispatch(PayPerLeadActions.resetFilterOptions());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!filter.stateId) return;

    dispatch(getCountiesByState.request(+filter.stateId));
  }, [filter.stateId, dispatch]);

  useEffect(() => {
    if (!states || filter.stateId) return;

    setFilter({ stateId: states[0]?.id });
  }, [setFilter, filter.stateId, states]);

  useEffect(() => {
    if (counties?.some((c) => c.id === +(filter.countyId || 0))) return;

    setFilter({ countyId: counties?.[0]?.id });
  }, [setFilter, filter.countyId, counties]);

  const purchase = () => {
    navigate(
      `${NameOfChildRoutes.PAY_PER_LEAD.ROOT}/${NameOfChildRoutes.PAY_PER_LEAD.SUBSCRIPTIONS}/${NameOfChildRoutes.PAY_PER_LEAD.ADD}`,
    );
  };

  useEffect(() => {
    if (!account?.id || !filter.stateId || !filter.countyId) return;

    const month = moment().subtract(filter.monthOffset, "month");

    dispatch(
      PayPerLeadActions.getLeads.request({
        is_active: true,
        account_id: account.id,
        page: pagination.currentPage,
        limit: pagination.resultsPerPage,
        state_id: filter.stateId,
        county_id: filter.countyId,
        from: month.startOf("month").toDate(),
        to: month.endOf("month").toDate(),
        order: ORDER_COLUMN_TYPE.CREATED_AT,
        direction: ORDER_TYPE.DESC,
        show_detail: true,
        show_county: true,
        show_state: true,
      }),
    );
  }, [
    account?.id,
    pagination.currentPage,
    pagination.resultsPerPage,
    filter.monthOffset,
    filter.stateId,
    filter.countyId,
    dispatch,
  ]);

  const failedSubscriptions = (subscriptions ?? []).filter((s) => {
    const item = s.items.find((item) => {
      if (item.state_id !== Number(filter.stateId)) {
        return false;
      }

      if (item.all_counties) {
        return true;
      }

      const countyIdNumeric = Number(filter.countyId);

      return !!item.counties.find((countyItem) => countyItem.county_id === countyIdNumeric);
    });

    return item && isPaymentFailed(s);
  });

  const goToLead = (lead: PayPerLead) =>
    navigate(`${NameOfChildRoutes.PAY_PER_LEAD.ROOT}/${lead.id}`, {
      state: { from: location.pathname + location.search },
    });

  return (
    <div className="pay-per-lead-list">
      <div className="pay-per-lead-list-filter-wrapper">
        <div className="pay-per-lead-list-filter">
          <InputPicker
            name="States"
            placeholder="States"
            searchable
            disableContainerStyles
            className="select-picker"
            cleanable={false}
            value={filter.stateId?.toString()}
            data={
              states?.map(({ id, name }) => ({
                value: String(id),
                label: name,
              })) ?? []
            }
            onChange={(stateId) => setFilter({ stateId, countyId: undefined })}
          />

          <InputPicker
            disabled={!filter.stateId}
            name="County"
            placeholder="County"
            searchable
            className="select-picker select-picker-state"
            useContainer
            disableContainerStyles
            cleanable={false}
            value={filter.countyId?.toString()}
            data={
              counties?.map(({ id, name }) => ({
                value: String(id),
                label: name,
              })) ?? []
            }
            onChange={(countyId) => setFilter({ countyId })}
          />

          <InputPicker
            name="Month"
            placeholder="Month"
            searchable
            className="select-picker select-picker-month"
            useContainer
            disableContainerStyles
            cleanable={false}
            value={filter.monthOffset?.toString()}
            data={monthFilteredDropdown}
            onChange={(monthOffset) => setFilter({ monthOffset })}
          />
        </div>
        <div className="pay-per-lead-list-filter-border" />
        <div className="pay-per-lead-list-filter-month">
          <div className="pay-per-lead-list-total">{loading || !filter.countyId ? "..." : leads?.total || 0}</div>
          {moment().subtract(filter.monthOffset, "month").format("MMM")} Leads
        </div>
        <div className="pay-per-lead-list-filter-border" />
        <div className="pay-per-lead-list-filter-limit">
          <div className="pay-per-lead-list-total">{loading || !filter.countyId ? "..." : monthlyLimit}</div>
          Monthly Limit{" "}
          <span className="pay-per-lead-list-filter-change">
            <ViewSubscriptionsLink>Change</ViewSubscriptionsLink>
          </span>
        </div>
        <Button onClick={purchase} buttonStyle="accent-green">
          Add Leads
        </Button>
      </div>

      {failedSubscriptions && failedSubscriptions.length > 0 ? (
        <PaymentFailed failedSubscriptions={failedSubscriptions} />
      ) : null}

      {leads &&
        (leads.items.length > 0 ? (
          <>
            <Table
              items={leads?.items || []}
              properties={getLeadsTableProperties({ counties })}
              onRowClick={goToLead}
            />

            <PaginationFacade
              currentPage={pagination.currentPage}
              resultsPerPage={pagination.resultsPerPage}
              totalData={leads?.total}
              onPaginationChange={onPageChangeAndScroll}
            />
          </>
        ) : (
          <NoFilteredLead />
        ))}
    </div>
  );
};

export default LeadsList;
