import React from "react";
import { Attempts } from "@shared/interfaces/RecordManagement";
import classNames from "classnames";
import "./index.scss";

import { Chat, Email, Phone } from "./icons";

interface Props {
  attempts?: Attempts;
}

const getColorClassName = (value = 0) => classNames({ grey: value === 0, red: value > 4 });

export const TableCellAttempts = ({ attempts }: Props) => (
  <span className="table-cell-attempts">
    <span className={getColorClassName(attempts?.call)}>
      <Phone />
      {attempts?.call ?? 0}
    </span>

    <span className={classNames(getColorClassName(attempts?.message))}>
      <Chat />
      {attempts?.message ?? 0}
    </span>

    <span className={classNames(getColorClassName(attempts?.mail))}>
      <Email />
      {attempts?.mail ?? 0}
    </span>
  </span>
);
