import { User } from "@shared/models";
import { ResponseError } from "@shared/interfaces";

export enum AuthErrorType {
  loginError = "loginError",
  checkUserError = "checkUserError",
}

export interface AuthStateType {
  authentificated: boolean;
  impersonated: boolean;
  activateUserInfo: Partial<User> | null;
  filledEmail: string | null;
  redirectTo: string | null;
  userExistence: boolean | null;
  errors: {
    [key in AuthErrorType]: ResponseError | null;
  };
}
