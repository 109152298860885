import { ActionType, createReducer } from "typesafe-actions";
import { produce } from "immer";
import {
  ESPreset,
  PresetArrayFilters,
  PresetBooleanFilters,
  PresetBuilderState,
  PresetFilters,
  PresetNumberFilters,
  PresetStringFilters,
} from "@shared/interfaces/PresetBuilder.interface";
import { DetailedCounty, State } from "@shared/models";
import {
  BOOLEAN_TYPE,
  ES_PRESET_FILTER_VALUES,
  NUMBER_TYPE,
  PRESET_BUILDER_DEFAULT_STATE,
  PRESET_DEFAULT_ERRORS,
  PRESET_FILTERS_DEFAULT_VALUES,
  PRESET_RECORD_DEFAULT_VALUES,
  ProductType,
  STRING_TYPE,
} from "@shared/constants/presets";
import { DEFAULT_BUY_BOX_METRICS } from "@shared/constants";
import { isArrayOfType, isDate, isOfType } from "@shared/utils";
import { ZipsExpanded } from "@shared/interfaces";
import { PresetBuilderLocationFilters } from "@containers/Preset/interfaces/Preset.interface";
import { PresetError } from "@containers/Preset/interfaces/PresetState.interface";
import { isAllowedOwnerOccupiedValues } from "@containers/PresetBuilder/util";

import * as actions from "./actions";

const initialState: PresetBuilderState = {
  ...PRESET_BUILDER_DEFAULT_STATE,
  preset: {
    ...PRESET_RECORD_DEFAULT_VALUES,
  },
  errors: {
    ...PRESET_DEFAULT_ERRORS,
  },
};

type Actions = ActionType<typeof actions>;

const reducer = createReducer<PresetBuilderState, Actions>(initialState)
  .handleAction(actions.getBuyBoxData.success, (state, action) =>
    produce(state, (nextState) => {
      if (!action.payload) {
        nextState.buyBoxMetrics = DEFAULT_BUY_BOX_METRICS;
      } else {
        nextState.buyBoxMetrics = action.payload;
      }
    }),
  )
  .handleAction(actions.filterChange.success, (state, action) =>
    produce(state, (nextState) => {
      const name = action.payload.name;
      let zip: PresetBuilderLocationFilters["zips"][0] | undefined;

      switch (name) {
        case "product_type":
          nextState.filterState.states = [];
          nextState.filterState.counties = [];
          nextState.filterState.zips = [];
          nextState.buyBoxMetrics = {
            ...DEFAULT_BUY_BOX_METRICS,
          };
          nextState.preset.filters = {
            ...PRESET_FILTERS_DEFAULT_VALUES,
            product_type: action.payload.value as ProductType,
          };
          break;
        case "states":
          nextState.filterState.states = action.payload.value as State[];

          if (action.payload.counties) {
            nextState.filters.counties =
              action.payload?.counties.map((county) => ({
                label: county.name,
                value: county.code,
                state: county.state.abbreviation,
              })) ?? [];
          }

          break;
        case "counties":
          nextState.filterState.counties = action.payload.value as DetailedCounty[];
          nextState.filters.zips = [];

          if (nextState.filterState.counties.length === 0) {
            nextState.buyBoxMetrics = {
              ...DEFAULT_BUY_BOX_METRICS,
            };
          }

          if (action.payload.zipsResponse) {
            nextState.filters.zips = (action.payload.zipsResponse?.zips as ZipsExpanded[]).map((zip: ZipsExpanded) => ({
              value: zip.zip,
              label: zip.zip,
              ...zip,
            }));

            nextState.filterState.zips = nextState.filterState.zips.filter(
              (zip, index, self) =>
                nextState.filters.zips.some((el) => el.zip === zip.zip) &&
                self.findIndex((t) => t.zip === zip.zip) === index, // Remove duplicates during filtering
            );

            nextState.filterState.property_zips = nextState.filterState.property_zips.filter(
              (zip, index, self) =>
                nextState.filters.zips.some((el) => el.zip === zip.zip) &&
                self.findIndex((t) => t.zip === zip.zip) === index, // Remove duplicates during filtering
            );
          }
          break;
        case "zips":
          zip = nextState.filters.zips.find((filter) => filter.zip === action.payload.value);
          if (zip) {
            nextState.filterState.zips = [...nextState.filterState.zips, zip];
          }
          break;
        case "property_zips":
          zip = nextState.filters.zips.find((filter) => filter.zip === action.payload.value);
          if (zip) {
            nextState.filterState.property_zips = [...nextState.filterState.property_zips, zip];
          }
          break;
        case "property_type": {
          const value = action.payload.value as string | string[];

          if (Array.isArray(value)) {
            nextState.preset.filters.property_type = value;
          } else {
            nextState.preset.filters.property_type = [...(nextState.preset.filters.property_type as string[]), value];
          }
          break;
        }
        case "fileName":
          nextState.preset.fileName = action.payload.value as string;
          break;
        case "owner_occupied":
          if (isAllowedOwnerOccupiedValues(action.payload.value)) {
            nextState.preset.filters.owner_occupied = action.payload.value;
          } else {
            nextState.preset.filters.owner_occupied = null;
          }
          break;
        case "last_sale_date_min":
        case "last_sale_date_max":
          if (
            name === "last_sale_date_min" &&
            nextState.preset.filters.last_sale_date_max &&
            isDate(action.payload.value) &&
            new Date(action.payload.value) > new Date(nextState.preset.filters.last_sale_date_max)
          ) {
            nextState.preset.filters.last_sale_date_max = null;
          }

          nextState.preset.filters[
            name as keyof Pick<ESPreset["filters"], "last_sale_date_max" | "last_sale_date_min">
          ] = action.payload.value as string;
          break;
        default:
          if (action.payload.value === null) {
            nextState.preset.filters[name as keyof PresetNumberFilters] = null;
          } else if (isOfType(action.payload.value, STRING_TYPE)) {
            nextState.preset.filters[name as keyof PresetStringFilters] = action.payload.value;
          } else if (isOfType(action.payload.value, BOOLEAN_TYPE)) {
            nextState.preset.filters[name as keyof PresetBooleanFilters] = action.payload.value;
          } else if (isOfType(action.payload.value, NUMBER_TYPE)) {
            if (action.payload.value === 0) {
              nextState.preset.filters[name as keyof PresetNumberFilters] = null;
            } else {
              nextState.preset.filters[name as keyof PresetNumberFilters] = action.payload.value;
            }
          } else if (isArrayOfType(action.payload.value, STRING_TYPE)) {
            nextState.preset.filters[name as keyof PresetArrayFilters] = action.payload.value;
          }
      }
    }),
  )
  .handleAction(actions.getZips.success, (state, action) =>
    produce(state, (nextState) => {
      const { filters } = action.payload;
      nextState.filters = filters;
    }),
  )
  .handleAction(actions.removeTag.request, (state, action) =>
    produce(state, (draftState) => {
      const filterName = action.payload.stateName;
      const filterValues =
        filterName === "property_type" ? draftState.preset.filters[filterName] : draftState.filterState[filterName];
      if (filterName === "zips" || filterName === "property_zips") {
        draftState.filterState[filterName] = (filterValues as ZipsExpanded[]).filter(
          (item) => item.zip !== action.payload.value,
        );
      } else if (filterName === "states") {
        const value = action.payload.value as State;
        draftState.filterState.states = (filterValues as State[]).filter((item) => {
          return item.id !== value.id;
        });

        if (draftState.filterState.states.length === 0) {
          draftState.buyBoxMetrics = {
            ...DEFAULT_BUY_BOX_METRICS,
          };
        }

        draftState.filterState.counties = draftState.filterState.counties.filter((county) => {
          return county.state.abbreviation != value.abbreviation;
        });

        draftState.filters.counties = draftState.filters.counties.filter((county) => {
          return county.state != value.abbreviation;
        });

        draftState.filterState.zips = draftState.filterState.zips.filter((zip) => {
          return zip.state != value.abbreviation;
        });

        draftState.filterState.property_zips = draftState.filterState.property_zips.filter((zip) => {
          return zip.state != value.abbreviation;
        });

        draftState.filters.zips = draftState.filters.zips.filter((zip) => {
          return zip.state !== value.abbreviation;
        });
      } else if (filterName === "counties") {
        const value = action.payload.value as DetailedCounty;
        draftState.filterState.counties = (filterValues as DetailedCounty[]).filter((item) => item.code !== value.code);

        if (draftState.filterState.counties.length === 0) {
          draftState.buyBoxMetrics = {
            ...DEFAULT_BUY_BOX_METRICS,
          };
        }

        draftState.filterState.zips = draftState.filterState.zips.filter((zip) => {
          return zip.state != value.state.abbreviation;
        });

        draftState.filterState.property_zips = draftState.filterState.property_zips.filter((zip) => {
          return zip.state != value.state.abbreviation;
        });

        draftState.filters.zips = draftState.filters.zips.filter((zip) => {
          return zip.fips !== value.code;
        });
      } else if (filterName === "property_type") {
        draftState.preset.filters[filterName] = (filterValues as string[]).filter(
          (item) => item !== action.payload.value,
        );
      }
    }),
  )

  .handleAction(actions.createPreset.success, (state, action) =>
    produce(state, (nextState) => {
      const {
        created_at,
        created_by,
        updated_at,
        updated_by,
        esPreviewRecords,
        processings,
        estimated_records_count,
        investor_data_settings_id,
        states,
        fileName,
        counties,
        zips,
        status,
        ...preset
      } = action.payload;

      // eslint-disable-next-line no-debugger
      nextState.preset = {
        ...PRESET_BUILDER_DEFAULT_STATE,
        fileName,
        created_at,
        created_by,
        updated_at,
        updated_by,
        status,
        states,
        counties,
        zips,
        esPreviewRecords,
        estimated_records_count,
        processings,
        investor_data_settings_id,
        filters: {
          ...preset,
        },
      };
    }),
  )
  .handleAction(actions.getPreset.success, (state, action) =>
    produce(state, (nextState) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { filters, filterState, preset } = action.payload;

      const ESFilter = ES_PRESET_FILTER_VALUES.reduce((acc, filter) => {
        acc[filter] = preset[filter] ?? null;
        return acc;
      }, {} as Record<string, string | number | boolean | string[] | null>) as unknown as PresetFilters;

      nextState.filters = filters;
      nextState.filterState = filterState;

      nextState.preset = {
        ...PRESET_BUILDER_DEFAULT_STATE,
        fileName: preset.fileName,
        counties: preset.counties,
        created_at: preset.created_at,
        created_by: preset.created_by,
        states: preset.states,
        zips: preset.zips,
        esPreviewRecords: preset.esPreviewRecords,
        estimated_records_count: preset.estimated_records_count,
        status: preset.status,
        id: preset.id,
        investor_data_settings_id: preset.investor_data_settings_id,
        processings: preset.processings,
        filters: {
          ...ESFilter,
        },
      };
    }),
  )
  .handleAction(actions.getPresetList.success, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload && action.payload.length === 0) {
        nextState.presetList = null;
      } else {
        nextState.presetList = action.payload;
      }
    }),
  )
  .handleAction(actions.createPreset.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PresetError.createPresetError] = action.payload;
    }),
  )
  .handleAction(actions.updatePreset.success, (state, action) =>
    produce(state, (nextState) => {
      const {
        created_at,
        created_by,
        updated_at,
        updated_by,
        esPreviewRecords,
        processings,
        estimated_records_count,
        investor_data_settings_id,
        states,
        counties,
        zips,
        fileName,
        status,
        ...preset
      } = action.payload;

      // eslint-disable-next-line no-debugger
      nextState.preset = {
        ...PRESET_BUILDER_DEFAULT_STATE,
        created_at,
        created_by,
        updated_at,
        updated_by,
        status,
        states,
        counties,
        zips,
        fileName,
        esPreviewRecords,
        estimated_records_count,
        processings,
        investor_data_settings_id,
        filters: {
          ...preset,
        },
      };
    }),
  )
  .handleAction(actions.updatePreset.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PresetError.updatePresetError] = action.payload;
    }),
  )
  .handleAction(actions.deletePreset.success, (state) =>
    produce(state, () => {
      // nextState.preset = null;
    }),
  )
  .handleAction(actions.deletePreset.failure, (state) =>
    produce(state, () => {
      // nextState.errors[PresetError.deletePresetError] = action.payload;
    }),
  )
  .handleAction(actions.clearPreset, (state) =>
    produce(state, (nextState) => {
      nextState.preset = {
        ...PRESET_RECORD_DEFAULT_VALUES,
      };

      nextState.presetList = null;

      nextState.filters = {
        ...PRESET_BUILDER_DEFAULT_STATE.filters,
      };
      nextState.filterState = {
        ...PRESET_BUILDER_DEFAULT_STATE.filterState,
      };
      nextState.buyBoxMetrics = {
        ...PRESET_BUILDER_DEFAULT_STATE.buyBoxMetrics,
      };

      nextState.errors = {
        ...PRESET_DEFAULT_ERRORS,
      };
    }),
  )
  .handleAction(actions.clearPresetList, (state) =>
    produce(state, (nextState) => {
      nextState.presetList = null;
    }),
  );
export { reducer as PresetBuilderReducer };
