import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AutocompleteMultiSelect, Button, ErrorMessage, Input } from "@shared/components";
import { Formik, FormikProps } from "formik";
import { Subscription } from "@shared/models";
import { useDispatch } from "react-redux";
import { UpdateSubscriptionInterface } from "@containers/Account/interface/UpdateSubscriptionInterface";
import * as contactStore from "@containers/Contact/store";

import { getInitValues, handlers, prepareSubmitValue, SubscriptionFormShape, validationSchema } from "./formHelpers";
import "./index.scss";

interface DealFormProps {
  deal: Subscription;
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: Omit<UpdateSubscriptionInterface, "accountId">) => void;
}

export type SetFieldValueType = FormikProps<SubscriptionFormShape>["setFieldValue"];

const DealForm: FC<DealFormProps> = (props) => {
  const dispatch = useDispatch();
  const { deal, onChangeForm, onCloseForm, onSubmitForm } = props;

  const [additionalStateParams, setAdditionalStateParams] = useState<string>("");
  const [customPage, setCustomPage] = useState(1);

  const formValues = useMemo(() => getInitValues(deal), [deal]);
  const formikRef = useRef<FormikProps<SubscriptionFormShape>>(null);

  const removeStateHandler = useCallback(
    (id: string, values: SubscriptionFormShape, setFieldValue: SetFieldValueType) => {
      const idx = values.states.findIndex((state) => state.value === id);
      const newValues = values.states.filter((_x, i) => i !== idx);
      const availableCounties = handlers.counties.getAvailableCounties(newValues, values);
      const availableStates = handlers.states.getAvailableStates(newValues);
      setAdditionalStateParams(availableStates);
      setCustomPage(1);
      dispatch(contactStore.actions.clearCounties());
      setFieldValue("counties", availableCounties);
      setFieldValue("states", newValues);
    },
    [dispatch],
  );

  const removeCountyHandler = useCallback(
    (id: string, values: SubscriptionFormShape, setFieldValue: SetFieldValueType) => {
      const idx = values.counties.findIndex((county: { value: string }) => county.value === id);

      const newValues = values.counties.filter(
        (
          _c: {
            value: string;
          },
          i: number,
        ) => i !== idx,
      );

      setFieldValue("counties", newValues);
    },
    [],
  );

  const onChangeStateHandler = useCallback(
    (
      v: {
        label: string;
        value: string;
      }[],
      setFieldValue: (
        field: string,
        value:
          | {
              label: string;
              value: string;
            }[]
          | null,
      ) => void,
    ) => {
      const availableStates = handlers.states.getAvailableStates(v);
      setAdditionalStateParams(availableStates);
      setCustomPage(1);
      dispatch(contactStore.actions.clearCounties());
      setFieldValue("states", v);
    },
    [dispatch],
  );

  useEffect(() => {
    if (deal && formValues.states.length) {
      const availableStates = handlers.states.getAvailableStates(formValues.states);
      setAdditionalStateParams(availableStates);
    }
  }, [deal, formValues.states]);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitForm(prepareSubmitValue(values));
        setSubmitting(false);
      }}
      initialValues={formValues}
      validate={() => {
        onChangeForm();
      }}
      innerRef={formikRef}
    >
      {({ values, handleChange, setFieldValue, handleSubmit, errors, touched }) => (
        <div className="client-form">
          <form onSubmit={handleSubmit}>
            <div className="client-form-header">
              <div className="client-form-header-left">Edit Subscription</div>

              <div className="client-form-header-right">
                <Button type="submit" buttonStyle="accent-green">
                  Save
                </Button>
                <Button type="button" buttonStyle="form-cancel" onClick={onCloseForm}>
                  Cancel
                </Button>
              </div>
            </div>
            <div className="horizontal-divider"></div>
            <div className="client-form-content">
              <div>
                <Input
                  type="text"
                  name="name"
                  label="Deal name"
                  isRequiredField={true}
                  value={values.name || ""}
                  onChange={handleChange}
                  placeholder="Deal name"
                  disabled={true}
                />
              </div>
              <div className="state-select">
                <div className="multiselect-label">State</div>
                <AutocompleteMultiSelect
                  menuPlacement="bottom"
                  blurInputOnSelect={true}
                  getData={handlers.states.getData}
                  name="states"
                  onChange={(v) => onChangeStateHandler(v, setFieldValue)}
                  handleRemoveValue={(id) => removeStateHandler(id, values, setFieldValue)}
                  options={values.states}
                  selectData={handlers.states.selectData}
                  value={values.states}
                  placeholder="Select State"
                  prepareOptionFunction={handlers.states.prepareOptionFunction}
                  selectTotalCount={handlers.states.totalCount}
                />
                <ErrorMessage isTouched={!!touched["states"]} error={errors["states"]?.toString()} />
              </div>
              <div className="county-select">
                <div className="multiselect-label">County</div>
                <AutocompleteMultiSelect
                  menuPlacement="bottom"
                  menuPosition="fixed"
                  blurInputOnSelect={true}
                  getData={handlers.counties.getData}
                  additionalParamValue={additionalStateParams}
                  customPage={customPage}
                  setCustomPage={setCustomPage}
                  name="counties"
                  onChange={(v) => {
                    setFieldValue("counties", v);
                  }}
                  handleRemoveValue={(id) => removeCountyHandler(id, values, setFieldValue)}
                  options={values.counties}
                  selectData={handlers.counties.selectData}
                  value={values.counties}
                  placeholder="Select County"
                  prepareOptionFunction={handlers.counties.prepareOptionFunction}
                  selectTotalCount={handlers.counties.totalCount}
                  isDisabled={values.states.length < 1}
                />
                <ErrorMessage isTouched={!!touched["counties"]} error={errors["counties"]?.toString()} />
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default DealForm;
