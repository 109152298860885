import React, { useCallback, useEffect, useState } from "react";
import { Button, Icon } from "@shared/components";
import { TabBarNav } from "@containers/SkipTracing/components/SkipTracingContainer/SkipTracingTabBar/components";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { NameOfChildRoutes } from "@shared/constants";

import "./index.scss";

export interface TabBar {
  className?: string;
  children: {
    props: {
      label: string;
      isAccess?: boolean;
    };
  }[];
  onChangeActiveTabHandler?: (label: string, number: number) => void;
  isLoading?: boolean;
  activeTab?: number;
}

const TabBar: React.FC<TabBar> = (props) => {
  const { children, className, onChangeActiveTabHandler, isLoading } = props;
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTab(props.activeTab ? props.activeTab : 0);
  }, [props.activeTab]);

  const allLabelsList = children?.map(({ props }) => props.label);

  const onChangeActiveTab = (label: string) => {
    const activeChildren = children?.filter(({ props }) => (props.isAccess === undefined ? true : props.isAccess));
    const activeChildrenLabelsList = activeChildren?.map(({ props }) => props.label);
    if (allLabelsList.indexOf(label) !== -1 && activeChildrenLabelsList.indexOf(label) !== -1) {
      setActiveTab(allLabelsList.indexOf(label));
    }

    if (onChangeActiveTabHandler) {
      onChangeActiveTabHandler(label, allLabelsList.indexOf(label));
    }
  };

  const onBack = useCallback(() => {
    navigate(`${NameOfChildRoutes.SKIP_TRACING.ROOT}`);
  }, [navigate]);

  const classes = classNames("tab-bar", className);

  return (
    <div className={classes}>
      <div className="tab-bar-nav">
        <div className="tab-bar-back-btn">
          <Button onClick={onBack}>
            <Icon type="chevron-left" />
            Back
          </Button>
        </div>
        <div className="tab-nav-horizontal-divider">
          <div />
        </div>
        <div className="tab-bar-title">Skip Tracing</div>
        <div className="tab-bar-nav-tabs">
          {allLabelsList.map((label: string, key: number) => (
            <TabBarNav
              label={label}
              labelNumber={key + 1}
              key={label}
              className={key === activeTab ? "active-tab" : ""}
              onChangeActiveTab={onChangeActiveTab}
            />
          ))}
        </div>
      </div>
      <div className="tab-container">{!isLoading ? (children[activeTab] as React.ReactNode) : null}</div>
    </div>
  );
};

export default TabBar;
