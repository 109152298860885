import React, { FC, PropsWithChildren } from "react";
import classnames from "classnames";

import "./index.scss";

export type ContentProps = PropsWithChildren<{
  className?: string;
}>;

export const Content: FC<ContentProps> = ({ children, className }) => {
  return <div className={classnames("page-content", className)}>{children}</div>;
};
