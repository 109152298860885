import { County, State, User } from "@shared/models";
import { BaseFilter, ResponseError } from "@shared/interfaces";

export enum ClientError {
  createClientError = "createClientError",
  updateClientError = "updateClientError",
  deleteClientError = "deleteClientError",
  activateClientError = "activateClientError",
  getClientListError = "getClientListError",
  getClientError = "getClientError",
  getStatesError = "getStatesError",
  getCountiesError = "getCountiesError",
}

export interface ClientStateType {
  selectedClient: User | null;
  clientList: { list: User[]; total: number } | null;
  filter: BaseFilter;
  states: State[];
  statesTotal: number;
  statesFilter: BaseFilter;
  counties: County[];
  countiesTotal: number;
  countiesFilter: BaseFilter;

  errors: {
    [key in ClientError]: ResponseError | null;
  };
}
