import React, { ReactNode, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Option } from "@shared/interfaces";
import { actions as sharedActions } from "@shared/store";

import { useCache } from "./CacheProvider";

type StateOption = Option<number>;

export interface StateOptionsProviderProps {
  children: ReactNode;
}

export const StateOptionsProvider = ({ children }: StateOptionsProviderProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sharedActions.getStates.request());
  }, [dispatch]);

  return <>{children}</>;
};

export const useStateOptions = (): StateOption[] => {
  const cache = useCache();

  return useMemo(() => {
    return Object.values(cache.stateOptions).sort((a, b) => a.label.localeCompare(b.label));
  }, [cache.stateOptions]);
};
