import { Divider, Input } from "rsuite";
import { Button, Icon } from "@shared/components";
import React, { Fragment, useMemo, useState } from "react";
import { propertiesFiltersOptions } from "@containers/RecordManagement/containers/FilterPropertiesContainer/properties-filters-options";
import "./index.scss";
import { PropertyFilterOption } from "@shared/interfaces/RecordManagement";

interface Props {
  usedFilters: Record<string, boolean>;
  onAdd: (i: PropertyFilterOption) => void;
}

export const AddPropertiesFilters = ({ usedFilters, onAdd }: Props) => {
  const [isAdding, setIsAdding] = useState(false);
  const [search, setSearch] = useState("");

  const toggleAdding = () => {
    setIsAdding((s) => !s);
    setSearch("");
  };

  const filteredList = useMemo(() => {
    const s = search.toLowerCase().trim();

    return propertiesFiltersOptions
      .filter(
        (i) =>
          i.children.filter((j) => !usedFilters[j.value]).length &&
          (i.label.toLowerCase().includes(s) || i.children.some((j) => j.label.toLowerCase().includes(s))),
      )
      .map((i) => ({
        ...i,
        children: (i.label.toLowerCase().includes(s)
          ? i.children
          : i.children.filter((j) => j.label.toLowerCase().includes(s))
        ).filter((j) => !usedFilters[j.value]),
      }));
  }, [search, usedFilters]);

  if (!isAdding) {
    return (
      <>
        <Divider />
        <Button buttonStyle="blue-bordered" className="add-filter" onClick={toggleAdding}>
          <Icon type="plus" />
          Add Filter
        </Button>
      </>
    );
  }

  return (
    <>
      <Divider />
      <div className="search-wrapper">
        <div className="search">
          <Input placeholder="Search for Filters" value={search} onChange={setSearch} />
          <Button onClick={toggleAdding}>
            <Icon type="close" />
          </Button>
        </div>

        <div className="list">
          {filteredList.map((i) => (
            <Fragment key={i.label}>
              <p>{i.label}</p>
              {i.children.map((j) => (
                <span key={j.label} onClick={() => onAdd(j)}>
                  {j.label}
                  <span>Add</span>
                </span>
              ))}
              <Divider />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
