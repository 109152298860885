import { Account, DetailedCounty, State, User } from "@shared/models";
import { getCountiesFromAccount, getCountiesFromUser } from "@shared/utils";

/**
 * Extracts states and counties from a user object.
 *
 * @param {Account} account - The account object containing counties.
 *
 * @returns {{states: State[], counties: DetailedCounty[]}} - The extracted states and counties.
 */
export const extractStatesAndCountiesFromUser = (user: User) => {
  const statesMap = new Map();
  const counties: DetailedCounty[] = getCountiesFromUser(user);

  if (!counties) return { states: [], counties: [] };

  counties.forEach((county) => {
    if (!statesMap.has(county.state.id)) {
      statesMap.set(county.state.id, {
        id: county.state.id,
        name: county.state.name,
        abbreviation: county.state.abbreviation,
      });
    }
  });

  // Convert statesMap to array
  const states: State[] = Array.from(statesMap.values());

  counties.sort((a, b) => a.name.localeCompare(b.name));
  states.sort((a, b) => a.name.localeCompare(b.name));

  return { states, counties };
};

export const extractStatesAndCounties = (account: Account) => {
  const statesMap = new Map();
  const counties: DetailedCounty[] = getCountiesFromAccount(account);

  if (!counties) return { states: [], counties: [] };

  counties.forEach((county) => {
    if (!statesMap.has(county.state.id)) {
      statesMap.set(county.state.id, {
        id: county.state.id,
        name: county.state.name,
        abbreviation: county.state.abbreviation,
      });
    }
  });

  // Convert statesMap to array
  const states: State[] = Array.from(statesMap.values());

  counties.sort((a, b) => a.name.localeCompare(b.name));
  states.sort((a, b) => a.name.localeCompare(b.name));

  return { states, counties };
};
