import { InvestorDataSettings } from "@shared/models/InvestorDataSettings";

export enum INVESTOR_FILE_STATUS {
  PROCESSING = "processing",
  ACTIVE = "active",
  INACTIVE = "inactive",
  ERROR = "error",
  WAITING_FOR_PROCESSING = "waiting_for_processing",
}

export interface InvestorFileShort {
  id: number;
  name: string;
  records_count?: number | null;
  property_list_files?: { property_list_id: number }[];
  last_downloaded_at: Date | null;
  last_downloaded_by: string | null;
}

export interface InvestorFileSendingInfo {
  id: number;
  status: INVESTOR_FILE_STATUS;
  last_sent_at: Date | null;
  last_sent_by: string | null;
}

export interface InvestorFileFilter {
  id: number;
  name: string;
}

export interface InvestorFileESFilter {
  id: number;
  name: string;
  estimated_records_count: number;
}

export interface InvestorFile {
  id: number;
  filter_id: number;
  es_filter_id: number;
  file_id: number;
  status: INVESTOR_FILE_STATUS;
  created_at: Date;
  last_sent_at: Date | null;
  last_sent_by: string | null;
  last_unsend_at: Date | null;
  last_unsend_by: string | null;
  investor_file?: InvestorFileShort | null;
  investor_data_settings?: InvestorDataSettings;
  filter?: InvestorFileFilter;
  es_filter?: InvestorFileESFilter;
}

export interface InvestorFileProcessingShort {
  id: number;
  status: INVESTOR_FILE_STATUS;
}

export interface CreateInvestorFilePayload {
  name: string;
  account_id: number;
  url: string;
  sendEmail?: boolean;
}
