import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { MarketMapFilters, MarketMapState, SliderRangeTuple, ViewType } from "@shared/interfaces";
import {
  DEFAULT_BUY_BOX_FILTERS,
  DEFAULT_BUY_BOX_METRICS,
  DEFAULT_COUNTY_METRICS,
  DEFAULT_ZIPS_METRICS,
} from "@shared/constants";
import { County, State } from "@shared/models";
import DEFAULT_NEIGHBORHOOD_METRICS from "@shared/constants/defaultNeighborhoodMetrics";
import { Neighborhood } from "@shared/models/Neighborhood";

import * as actions from "./actions";

const DEFAULT_MARKET_MAP_STATE: Omit<MarketMapState, "filters"> = {
  viewType: "Overview",
  state: null,
  county: null,
  neighborhood: null,
  countyGeoData: null,
  zipCode: "",
  metrics: {
    county: {
      ...DEFAULT_COUNTY_METRICS,
    },
    zip: {
      ...DEFAULT_ZIPS_METRICS,
    },
    neighborhood: {
      ...DEFAULT_NEIGHBORHOOD_METRICS,
    },
  },
  buyBoxFilters: DEFAULT_BUY_BOX_FILTERS,
  buyBoxDefaultValues: DEFAULT_BUY_BOX_FILTERS,
  buyBoxMetrics: DEFAULT_BUY_BOX_METRICS,
  zipsCSVIsLoading: false,
};

const DEFAULT_FILTERS: MarketMapFilters = {
  states: [],
  counties: [],
  zips: [],
};
const initialState: MarketMapState = {
  ...DEFAULT_MARKET_MAP_STATE,
  filters: DEFAULT_FILTERS,
};

const reducer = createReducer<typeof initialState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getMetrics.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.metrics.county = action.payload.metrics.county;
      nextState.buyBoxFilters = action.payload.defaultBuyBoxFilters;
      nextState.buyBoxDefaultValues = action.payload.defaultBuyBoxFilters;
      if (action.payload.metrics.zip) {
        nextState.metrics.zip = action.payload.metrics.zip;
      }
      if (action.payload.metrics.neighborhood) {
        nextState.metrics.neighborhood = action.payload.metrics.neighborhood;
      }
    }),
  )
  .handleAction(actions.getMetrics.failure, (state) =>
    produce(state, (nextState) => {
      nextState.metrics = initialState.metrics;
    }),
  )
  .handleAction(actions.filterChange.success, (state, action) =>
    produce(state, (nextState) => {
      const name = action.payload.name;

      switch (name) {
        case "state":
          nextState.state = action.payload.value as State;
          nextState.county = null;
          nextState.zipCode = "";
          nextState.neighborhood = null;

          break;
        case "county":
          nextState.county = action.payload.value as County;
          nextState.zipCode = "";
          nextState.neighborhood = null;

          break;
        case "zipCode":
          nextState.zipCode = action.payload.value as string;
          break;
        case "neighborhood":
          nextState.neighborhood = action.payload.value as Neighborhood;
          break;
        case "viewType":
          nextState.viewType = action.payload.value as ViewType;
          break;
        case "area_building":
          nextState.buyBoxFilters.area_building = action.payload.value as SliderRangeTuple;
          break;
        case "year_built":
          nextState.buyBoxFilters.year_built = action.payload.value as SliderRangeTuple;
          break;
        case "property_value":
          nextState.buyBoxFilters.property_value = action.payload.value as SliderRangeTuple;
          break;
        case "bath_count":
          nextState.buyBoxFilters.bath_count = action.payload.value as SliderRangeTuple;
          break;
        case "bedrooms_count":
          nextState.buyBoxFilters.bedrooms_count = action.payload.value as SliderRangeTuple;
          break;
        default:
          break;
      }
    }),
  )
  .handleAction(actions.getBuyBoxData.success, (state, action) =>
    produce(state, (nextState) => {
      if (!action.payload) {
        nextState.buyBoxMetrics = DEFAULT_BUY_BOX_METRICS;
      } else {
        nextState.buyBoxMetrics = action.payload;
      }
    }),
  )
  .handleAction(actions.resetBuyBoxFilters.success, (state) => ({
    ...state,
    buyBoxFilters: state.buyBoxDefaultValues,
  }))
  .handleAction(actions.startDownloadingZipsCSV, (state) => ({
    ...state,
    zipsCSVIsLoading: true,
  }))
  .handleAction(actions.stopDownloadingZipsCSV, (state) => ({
    ...state,
    zipsCSVIsLoading: false,
  }))
  .handleAction(actions.resetMarketMapState.success, () => ({
    ...initialState,
  }))
  .handleAction(actions.clearCountyGeoData, (state) =>
    produce(state, (nextState) => {
      nextState.countyGeoData = null;
    }),
  )
  .handleAction(actions.getCountyGeoData.success, (state, actions) =>
    produce(state, (nextState) => {
      if (nextState.countyGeoData && nextState.countyGeoData.code !== actions.payload.code) {
        nextState.countyGeoData.neighborhoodsGeo = [];
      }

      nextState.countyGeoData = {
        ...nextState.countyGeoData,
        code: actions.payload.code,
        geometry: actions.payload.geometry,
      };
    }),
  )
  .handleAction(actions.getCountyNeighborhoodsGeoData.success, (state, actions) =>
    produce(state, (nextState) => {
      if (!nextState.countyGeoData || nextState.countyGeoData.code !== actions.payload.code) {
        nextState.countyGeoData = {
          ...nextState.countyGeoData,
          code: actions.payload.code,
          geometry: [],
          neighborhoodsGeo: actions.payload.neighborhoodsGeo,
        };
      } else {
        nextState.countyGeoData.neighborhoodsGeo = actions.payload.neighborhoodsGeo;
      }
    }),
  )
  .handleAction(actions.getCountyNeighborhoodMetrics.success, (state, actions) =>
    produce(state, (nextState) => {
      nextState.metrics.neighborhood = actions.payload;
    }),
  )
  .handleAction(actions.getInitData.success, (state, actions) =>
    produce(state, (nextState) => {
      nextState.state = actions.payload.state;
      nextState.county = actions.payload.county;
    }),
  );
export { reducer as MarketMapReducer };
