import React from "react";

export const Phone = () => (
  <div className="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16663 4.16667C3.94561 4.16667 3.73365 4.25446 3.57737 4.41074C3.42613 4.56198 3.33903 4.76537 3.33357 4.97864C3.52523 8.01525 4.81795 10.8781 6.96991 13.0301C9.12186 15.182 11.9847 16.4747 15.0213 16.6664C15.2346 16.6609 15.438 16.5738 15.5892 16.4226C15.7455 16.2663 15.8333 16.0543 15.8333 15.8333V13.0642L12.8494 11.8706L11.9645 13.3454C11.7414 13.7173 11.2703 13.8559 10.8814 13.6641C8.90724 12.6905 7.30951 11.0927 6.33591 9.1186C6.14408 8.72963 6.28266 8.25856 6.65455 8.03542L8.12932 7.15056L6.93577 4.16667H4.16663ZM2.39886 3.23223C2.8677 2.76339 3.50358 2.5 4.16663 2.5H7.49996C7.84071 2.5 8.14714 2.70746 8.27369 3.02384L9.94036 7.19051C10.0923 7.57027 9.9461 8.00414 9.59537 8.21458L8.20037 9.05158C8.88693 10.1713 9.8287 11.113 10.9484 11.7996L11.7854 10.4046C11.9958 10.0539 12.4297 9.9077 12.8095 10.0596L16.9761 11.7263C17.2925 11.8528 17.5 12.1592 17.5 12.5V15.8333C17.5 16.4964 17.2366 17.1323 16.7677 17.6011C16.2989 18.0699 15.663 18.3333 15 18.3333C14.9831 18.3333 14.9662 18.3328 14.9494 18.3318C11.4956 18.1219 8.23809 16.6553 5.79139 14.2086C3.3447 11.7619 1.87805 8.50432 1.66816 5.05055C1.66714 5.03372 1.66663 5.01686 1.66663 5C1.66663 4.33696 1.93002 3.70107 2.39886 3.23223Z"
        fill="currentColor"
      />
    </svg>
  </div>
);
