import { BaseFilter, PaginatedResponse, ResponseError } from "@shared/interfaces";
import { ClaritasVisitor } from "@shared/models";

export enum ClaritasError {
  getClaritasVisitorsError = "getClaritasVisitorsError",
  getClaritasStatisticsError = "getClaritasStatisticsError",
}

export interface ClaritasStatisticsDataPoint {
  range_start: string;
  range_end: string;
  users_count: number;
}

export interface ClaritasStateType {
  visitors: PaginatedResponse<ClaritasVisitor> | null;
  statistics: ClaritasStatisticsDataPoint[] | null;
  filter: BaseFilter;
  errors: {
    [key in ClaritasError]: ResponseError | null;
  };
}
