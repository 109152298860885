import React, { FC } from "react";
import { Input } from "rsuite";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { filterChange } from "@containers/PresetBuilder/store/actions";
import { InputComponentProps, RangeFields } from "@shared/interfaces/PresetBuilder.interface";

import { selectors as presetBuilderSelectors } from "../../../store";

const TypeBlock: FC<InputComponentProps> = (props) => {
  const { stateAccessor } = props;
  const dispatch = useDispatch();
  const presetStore = useSelector(presetBuilderSelectors.getPresetBuilder());
  const minState = `${stateAccessor}_min` as RangeFields;
  const maxState = `${stateAccessor}_max` as RangeFields;

  const onMinChange = (value: string) => {
    if (value === "") {
      dispatch(filterChange.request({ name: minState, value: null }));
      return;
    }
    const formattedValue = value.replace(/^0+/, "") || "0";
    if (/^\d+$/.test(formattedValue)) {
      dispatch(filterChange.request({ name: minState, value: +formattedValue }));
    }
  };

  const onMaxChange = (value: string) => {
    if (value === "") {
      dispatch(filterChange.request({ name: maxState, value: null }));
      return;
    }
    const formattedValue = value.replace(/^0+/, "") || "0";
    if (/^\d+$/.test(formattedValue)) {
      dispatch(filterChange.request({ name: maxState, value: +formattedValue }));
    }
  };

  const onBlur = () => {
    // Making sure that max value is > min value
    const min = presetStore.preset.filters[minState] as number;
    const max = presetStore.preset.filters[maxState] as number;

    if (min && max && max <= min) {
      dispatch(filterChange.request({ name: maxState, value: props.maxValue ? Math.min(min, props.maxValue) : min }));
    }

    if (max && props.maxValue && max > props.maxValue) {
      dispatch(filterChange.request({ name: maxState, value: props.maxValue }));
    }

    if (min && props.minValue && props.maxValue) {
      if (min < props.minValue) {
        dispatch(
          filterChange.request({
            name: minState,
            value: props.minValue,
          }),
        );
      } else {
        dispatch(
          filterChange.request({
            name: minState,
            value: props.maxValue ? Math.min(props.maxValue, min) : min,
          }),
        );
      }
    }
  };

  return (
    <div className="type-block">
      <div className="type-container">
        <Input
          className="type-field min"
          type="number"
          min={props.minValue ?? 0}
          max={props.maxValue}
          onChange={onMinChange}
          onBlur={onBlur}
          value={presetStore.preset.filters[minState] ? String(presetStore.preset.filters[minState]) : ""}
        />
        <span className="input-label">min</span>
      </div>
      <span className="hyphen">-</span>
      <div className="type-container">
        <Input
          className="type-field max"
          type="number"
          min={Math.max(props.minValue ?? 0, presetStore.preset.filters[minState] || 0)}
          max={props.maxValue}
          onChange={onMaxChange}
          onBlur={onBlur}
          value={presetStore.preset.filters[maxState] ? String(presetStore.preset.filters[maxState]) : ""}
        />
        <span className="input-label">max</span>
      </div>
    </div>
  );
};
export default TypeBlock;
