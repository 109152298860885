import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { PresetError, PresetStateType } from "@containers/Preset/interfaces/PresetState.interface";

import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: PresetStateType = {
  preset: null,
  presetList: null,
  presetFields: [],
  errors: {
    [PresetError.createPresetError]: null,
    [PresetError.updatePresetError]: null,
    [PresetError.deletePresetError]: null,
    [PresetError.getPresetError]: null,
    [PresetError.getPresetListError]: null,
    [PresetError.getPresetFieldsError]: null,
  },
};

const reducer = createReducer<PresetStateType, Action>(initialState)
  .handleAction(actions.getPresetList.success, (state, action) =>
    produce(state, (nextState) => {
      if (nextState.presetList) {
        nextState.presetList.list = [...nextState.presetList.list, ...action.payload];
      } else {
        nextState.presetList = { list: action.payload };
      }
    }),
  )
  .handleAction(actions.getPresetList.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PresetError.getPresetListError] = action.payload;
    }),
  )
  .handleAction(actions.getPreset.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.preset = action.payload;
    }),
  )
  .handleAction(actions.getPreset.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PresetError.getPresetError] = action.payload;
    }),
  )

  .handleAction(actions.clearPresetList, (state) =>
    produce(state, (nextState) => {
      nextState.presetList = null;
    }),
  )

  .handleAction(actions.clearPreset, (state) =>
    produce(state, (nextState) => {
      nextState.preset = null;
    }),
  )

  .handleAction(actions.createPreset.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.preset = action.payload;
    }),
  )
  .handleAction(actions.createPreset.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PresetError.createPresetError] = action.payload;
    }),
  )
  .handleAction(actions.updatePreset.success, (state, action) =>
    produce(state, (nextState) => {
      const updatedPreset = action.payload;
      const presetList = nextState.presetList;

      if (presetList) {
        const foundPresetIndex = presetList?.list.findIndex((p) => updatedPreset.id === p.id);
        presetList.list[foundPresetIndex] = updatedPreset;

        presetList.list = [...presetList.list];
      }

      if (state.preset && state.preset.id === updatedPreset.id) {
        nextState.preset = updatedPreset;
      }
    }),
  )
  .handleAction(actions.updatePreset.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PresetError.updatePresetError] = action.payload;
    }),
  )
  .handleAction(actions.deletePreset.success, (state) =>
    produce(state, (nextState) => {
      nextState.preset = null;
    }),
  )
  .handleAction(actions.deletePreset.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PresetError.deletePresetError] = action.payload;
    }),
  )

  .handleAction(actions.getPresetFields.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.presetFields = action.payload;
    }),
  )
  .handleAction(actions.getPresetFields.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PresetError.getPresetFieldsError] = action.payload;
    }),
  )

  .handleAction(actions.clearErrors, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        action.payload.forEach((err) => {
          nextState.errors[err] = null;
        });
      } else {
        nextState.errors = initialState.errors;
      }
    }),
  );

export { reducer as PresetReducer };
