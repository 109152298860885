import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "rsuite";
import { Button, Icon } from "@shared/components";
import { selectors as sharedSelectors } from "@shared/store";
import { selectors as AuthSelectors, actions as AuthActions } from "@containers/Auth/store";
import "./ImpersonationInfo.scss";

export const ImpersonationInfo: FC = () => {
  const isImpersonated = useSelector(AuthSelectors.impersonated());
  const user = useSelector(sharedSelectors.getUserDetails());
  const dispatch = useDispatch();

  const handleImpersonationExit = () => {
    dispatch(AuthActions.impersonationExit.request());
  };

  if (!isImpersonated || !user) return null;

  return (
    <div className="impersonation-info">
      <Message type="info" header="Important!">
        <span>
          You are logged in as&nbsp;
          <b>
            {user.first_name} {user.last_name}
          </b>
          &nbsp;user. Don&apos;t forget to log out after.
        </span>
        <Button buttonStyle="royal-blue" onClick={handleImpersonationExit}>
          <Icon type="logout-white" />
          Exit
        </Button>
      </Message>
    </div>
  );
};
