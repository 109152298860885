import { Options } from "ol/source/Google";
import { OlMap } from "@shared/components/MapContainer/OLMap";
import LayerTile from "ol/layer/Tile";
import { Google } from "ol/source";
import { defaults as defaultInteractions } from "ol/interaction";
import MapContainer, { MapType } from "@shared/components/MapContainer/MapContainer";
import { NeighborhoodCursorHover } from "@containers/MarketMap/components/MarketMapMap/interactions/NeighborhoodCursorHover";
import React, { FC } from "react";

import config from "../../../../config";

const googleMapKey = config.googleMapKey;

const getInstance = (options: Omit<Options, "key">, target?: string) => {
  return new OlMap({
    interactions: defaultInteractions().extend([new NeighborhoodCursorHover()]),
    target: target ? target : undefined,
    layers: [
      new LayerTile({
        source: new Google({
          key: googleMapKey,
          ...options,
        }),
      }),
    ],
  });
};

export type GoogleMapType = MapTypesEnum;

export enum MapTypesEnum {
  roadmap = "roadmap",
  satellite = "satellite",
  terrain = "terrain",
}

export const mapTypes = Object.values(MapTypesEnum) as Array<GoogleMapType>;

export const getMap = (mapType: MapTypesEnum): FC<Omit<MapType, "mapInstance">> => {
  const instance = (target?: string) =>
    getInstance(
      {
        mapType: mapType,
        layerTypes: ["layerRoadmap"],
      },
      target,
    );

  return (props) => <MapContainer {...props} getMapInstance={instance} />;
};
