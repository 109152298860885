import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Textarea } from "@shared/components";
import { Divider } from "rsuite";
import { getUSFormattedDateLong } from "@shared/utils";
import { actions as AccountActions, selectors as AccountSelectors } from "@containers/Account/store";
import "./index.scss";

interface Props {
  type: "owner" | "property";
  entity: string;
}

const PropertyNotes = ({ type, entity }: Props) => {
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const account = useSelector(AccountSelectors.getActiveAccount());
  const notes = useSelector(AccountSelectors.getActiveAccountNotes(type, entity));

  useEffect(() => {
    if (account?.id && !notes?.length) {
      dispatch(
        AccountActions.getAccountNotes.request({
          id: account.id,
          type,
          entity,
        }),
      );
    }
  }, [dispatch, notes?.length, account?.id, type, entity]);

  const saveNote = () => {
    if (account?.id) {
      dispatch(
        AccountActions.createAccountNote.request({
          id: account.id,
          type,
          entity,
          text: text.trim(),
          callback: () => setText(""),
        }),
      );
    }
  };

  return (
    <div className="notes">
      <h3>Notes</h3>

      <Textarea
        rows={5}
        placeholder="Leave your notes here"
        value={text}
        onChange={(v) => setText(v.currentTarget.value)}
      />

      <Button buttonStyle="accent-green" disabled={!text.trim().length} onClick={saveNote}>
        Save Note
      </Button>

      {!!notes?.length && (
        <>
          <Divider />

          <div className="list">
            {notes?.map((n) => (
              <div className="note" key={n.id}>
                <span>
                  Note from {getUSFormattedDateLong(n.created_at)} by {n.user.first_name} {n.user.last_name}
                </span>
                <p>{n.text}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PropertyNotes;
