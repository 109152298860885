import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as AccountActions, selectors as AccountSelectors } from "@containers/Account/store";
import "./index.scss";
import { AccountAttempts } from "@shared/models";

import { PropertyAttemptItem } from "./ProppertyAttemptItem";
import { Attempts, PropertyAttemptType } from "./PropertyAttempts.types";

interface Props {
  type: "owner" | "property";
  entity: string;
}

const PropertyAttempts = ({ type, entity }: Props) => {
  const [attempts, setAttempts] = useState<Attempts>({
    [PropertyAttemptType.Call]: 0,
    [PropertyAttemptType.Mail]: 0,
    [PropertyAttemptType.Message]: 0,
  });
  const hasRendered = useRef(false);
  const dispatch = useDispatch();
  const account = useSelector(AccountSelectors.getActiveAccount());

  const updateCounter = (key: PropertyAttemptType, dir: number) => {
    setAttempts((s) => ({
      ...s,
      [key]: dir === -1 && s[key] === 0 ? 0 : s[key] + dir,
    }));
  };

  useEffect(() => {
    if (account?.id) {
      dispatch(
        AccountActions.getAccountAttempts.request({
          id: account.id,
          type,
          entity,
          callback: (r?: AccountAttempts) => {
            if (r) {
              setAttempts((s) => ({ ...s, ...r }));
            }

            setTimeout(() => (hasRendered.current = true));
          },
        }),
      );
    }
  }, [dispatch, account?.id, type, entity]);

  useEffect(() => {
    if (hasRendered.current && account?.id) {
      dispatch(
        AccountActions.saveAccountAttempts.request({
          ...attempts,
          id: account.id,
          type,
          entity,
          hideLoader: true,
        }),
      );
      return;
    }
  }, [dispatch, attempts, type, entity, account?.id, hasRendered]);

  return (
    <div className="attempts">
      <h3>Reach out Attempts</h3>

      <div className="items">
        <PropertyAttemptItem type={PropertyAttemptType.Call} attempts={attempts} updateCounter={updateCounter} />
        <PropertyAttemptItem type={PropertyAttemptType.Mail} attempts={attempts} updateCounter={updateCounter} />
        <PropertyAttemptItem type={PropertyAttemptType.Message} attempts={attempts} updateCounter={updateCounter} />
      </div>
    </div>
  );
};

export default PropertyAttempts;
