import { createAction, createAsyncAction } from "typesafe-actions";
import { AccountsFilter, PaginatedResponse, ResponseError, WithCallback, WithOptionalLoader } from "@shared/interfaces";
import {
  AccountError,
  CheckAccountShape,
  GetInvoicesShape,
  PayInvoiceShape,
  UpdateAccountInterface,
  UpdateSubscriptionInterface,
  CreateAccountInterface,
  AccountPaymentShape,
  LinkAccountPropertyListToPropertiesFromFileInterface,
  LinkAccountPropertyListToPropertyInterface,
} from "@containers/Account/interface";
import { GoToBillingPortalShape, GoToCheckoutShape } from "@containers/Account/interface/AccountActions";
import { Invoice } from "@shared/models/Invoice";
import {
  Account,
  AccountAttempts,
  AccountPropertyList,
  AccountNote,
  SaveAccountAttempts,
  Feature,
  Subscription,
  GetAccountEntity,
  PaymentMethodExistence,
} from "@shared/models";

import { AccountActionTypes } from "./constants";

export const checkPaymentMethodExistence = createAsyncAction(
  AccountActionTypes.CHECK_PAYMENT_METHOD_EXISTENCE,
  AccountActionTypes.CHECK_PAYMENT_METHOD_EXISTENCE_SUCCESS,
  AccountActionTypes.CHECK_PAYMENT_METHOD_EXISTENCE_FAILURE,
)<WithOptionalLoader<{ id: number }>, PaymentMethodExistence, ResponseError>();

export const goToCheckout = createAction(AccountActionTypes.GO_TO_CHECKOUT)<GoToCheckoutShape>();

export const goToBillingPortal = createAction(AccountActionTypes.GO_TO_BILLING_PORTAL)<GoToBillingPortalShape>();

export const getInvoices = createAsyncAction(
  AccountActionTypes.GET_INVOICES,
  AccountActionTypes.GET_INVOICES_SUCCESS,
  AccountActionTypes.GET_INVOICES_FAILURE,
)<GetInvoicesShape, Invoice[] | null, ResponseError>();

export const payInvoice = createAsyncAction(
  AccountActionTypes.PAY_INVOICE,
  AccountActionTypes.PAY_INVOICE_SUCCESS,
  AccountActionTypes.PAY_INVOICE_FAILURE,
)<PayInvoiceShape, number | null, ResponseError>();

export const getAccounts = createAsyncAction(
  AccountActionTypes.GET_ACCOUNTS,
  AccountActionTypes.GET_ACCOUNTS_SUCCESS,
  AccountActionTypes.GET_ACCOUNTS_FAILURE,
)<AccountsFilter, PaginatedResponse<Account>, ResponseError>();

export const getAccountsList = createAsyncAction(
  AccountActionTypes.GET_ACCOUNTS_LIST,
  AccountActionTypes.GET_ACCOUNTS_LIST_SUCCESS,
  AccountActionTypes.GET_ACCOUNTS_LIST_FAILURE,
)<AccountsFilter, PaginatedResponse<Account>, ResponseError>();

export const getAccount = createAsyncAction(
  AccountActionTypes.GET_ACCOUNT,
  AccountActionTypes.GET_ACCOUNT_SUCCESS,
  AccountActionTypes.GET_ACCOUNT_FAILURE,
)<{ id: number }, Account, ResponseError>();

export const getAccountPropertyLists = createAsyncAction(
  AccountActionTypes.GET_ACCOUNT_PROPERTY_LISTS,
  AccountActionTypes.GET_ACCOUNT_PROPERTY_LISTS_SUCCESS,
  AccountActionTypes.GET_ACCOUNT_PROPERTY_LISTS_FAILURE,
)<{ id: number }, AccountPropertyList[], ResponseError>();

export const createAccountPropertyList = createAsyncAction(
  AccountActionTypes.CREATE_ACCOUNT_PROPERTY_LIST,
  AccountActionTypes.CREATE_ACCOUNT_PROPERTY_LIST_SUCCESS,
  AccountActionTypes.CREATE_ACCOUNT_PROPERTY_LIST_FAILURE,
)<{ id: number; name: string }, AccountPropertyList, ResponseError>();

export const getAccountNotes = createAsyncAction(
  AccountActionTypes.GET_ACCOUNT_NOTES,
  AccountActionTypes.GET_ACCOUNT_NOTES_SUCCESS,
  AccountActionTypes.GET_ACCOUNT_NOTES_FAILURE,
)<GetAccountEntity, { payload: GetAccountEntity; res: AccountNote[] }, ResponseError>();

export const createAccountNote = createAsyncAction(
  AccountActionTypes.CREATE_ACCOUNT_NOTE,
  AccountActionTypes.CREATE_ACCOUNT_NOTE_SUCCESS,
  AccountActionTypes.CREATE_ACCOUNT_NOTE_FAILURE,
)<WithCallback<GetAccountEntity & { text: string }>, { payload: GetAccountEntity; res: AccountNote }, ResponseError>();

export const getAccountAttempts = createAsyncAction(
  AccountActionTypes.GET_ACCOUNT_ATTEMPTS,
  AccountActionTypes.GET_ACCOUNT_ATTEMPTS_SUCCESS,
  AccountActionTypes.GET_ACCOUNT_ATTEMPTS_FAILURE,
)<
  WithCallback<GetAccountEntity, AccountAttempts>,
  { payload: GetAccountEntity; res: AccountAttempts },
  ResponseError
>();

export const saveAccountAttempts = createAsyncAction(
  AccountActionTypes.SAVE_ACCOUNT_ATTEMPTS,
  AccountActionTypes.SAVE_ACCOUNT_ATTEMPTS_SUCCESS,
  AccountActionTypes.SAVE_ACCOUNT_ATTEMPTS_FAILURE,
)<
  WithCallback<SaveAccountAttempts, AccountAttempts>,
  { payload: GetAccountEntity; res: AccountAttempts },
  ResponseError
>();

export const linkAccountPropertyListToPropertiesFromFile = createAsyncAction(
  AccountActionTypes.LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE,
  AccountActionTypes.LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE_SUCCESS,
  AccountActionTypes.LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTIES_FROM_FILE_FAILURE,
)<WithCallback<LinkAccountPropertyListToPropertiesFromFileInterface>, undefined, ResponseError>();

export const linkAccountPropertyListToProperty = createAsyncAction(
  AccountActionTypes.LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTY,
  AccountActionTypes.LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTY_SUCCESS,
  AccountActionTypes.LINK_ACCOUNT_PROPERTY_LIST_TO_PROPERTY_FAILURE,
)<WithCallback<LinkAccountPropertyListToPropertyInterface>, undefined, ResponseError>();

export const createAccount = createAsyncAction(
  AccountActionTypes.CREATE_ACCOUNT,
  AccountActionTypes.CREATE_ACCOUNT_SUCCESS,
  AccountActionTypes.CREATE_ACCOUNT_FAILURE,
)<WithCallback<CreateAccountInterface>, Account, ResponseError>();

export const checkAccount = createAsyncAction(
  AccountActionTypes.CHECK_ACCOUNT,
  AccountActionTypes.CHECK_ACCOUNT_SUCCESS,
  AccountActionTypes.CHECK_ACCOUNT_FAILURE,
)<WithCallback<CheckAccountShape>, { filledChargebeeId: string; isChargebeeIdValid: boolean }, ResponseError>();

export const updateAccount = createAsyncAction(
  AccountActionTypes.UPDATE_ACCOUNT,
  AccountActionTypes.UPDATE_ACCOUNT_SUCCESS,
  AccountActionTypes.UPDATE_ACCOUNT_FAILURE,
)<WithCallback<UpdateAccountInterface>, Account, ResponseError>();

export const updateSelfAccount = createAsyncAction(
  AccountActionTypes.UPDATE_SELF_ACCOUNT,
  AccountActionTypes.UPDATE_SELF_ACCOUNT_SUCCESS,
  AccountActionTypes.UPDATE_SELF_ACCOUNT_FAILURE,
)<WithCallback<UpdateAccountInterface>, Account, ResponseError>();

export const getSubscription = createAsyncAction(
  AccountActionTypes.GET_SUBSCRIPTION,
  AccountActionTypes.GET_SUBSCRIPTION_SUCCESS,
  AccountActionTypes.GET_SUBSCRIPTION_FAILURE,
)<number, Subscription, ResponseError>();

export const updateSubscription = createAsyncAction(
  AccountActionTypes.UPDATE_SUBSCRIPTION,
  AccountActionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
  AccountActionTypes.UPDATE_SUBSCRIPTION_FAILURE,
)<WithCallback<UpdateSubscriptionInterface>, Subscription, ResponseError>();

export const setActiveAccount = createAsyncAction(
  AccountActionTypes.SET_ACTIVE_ACCOUNT,
  AccountActionTypes.SET_ACTIVE_ACCOUNT_SUCCESS,
  AccountActionTypes.SET_ACTIVE_ACCOUNT_FAILURE,
)<number, Account, ResponseError>();

export const accountPayment = createAsyncAction(
  AccountActionTypes.CREATE_ACCOUNT_PAYMENT,
  AccountActionTypes.CREATE_ACCOUNT_PAYMENT_SUCCESS,
  AccountActionTypes.CREATE_ACCOUNT_PAYMENT_FAILURE,
)<AccountPaymentShape, { id: number; status: string }, ResponseError>();

export const getAccountPaymentStatus = createAsyncAction(
  AccountActionTypes.GET_ACCOUNT_PAYMENT_STATUS,
  AccountActionTypes.GET_ACCOUNT_PAYMENT_STATUS_SUCCESS,
  AccountActionTypes.GET_ACCOUNT_PAYMENT_STATUS_FAILURE,
)<{ accountId: number; invoiceId: number }, { id: number; status: string }, ResponseError>();

export const activateAccount = createAction(AccountActionTypes.ACTIVATE_ACCOUNT)<number>();
export const deactivateAccount = createAction(AccountActionTypes.DEACTIVATE_ACCOUNT)<number>();

export const setAccountsListFilter = createAction(
  AccountActionTypes.SET_ACCOUNTS_LIST_FILTER,
)<Partial<AccountsFilter> | null>();

export const clearAccountsList = createAction(AccountActionTypes.CLEAR_ACCOUNTS_LIST)();
export const clearAccount = createAction(AccountActionTypes.CLEAR_ACCOUNT)();
export const clearAccountPropertyList = createAction(AccountActionTypes.CLEAR_ACCOUNT_PROPERTY_LIST)();
export const clearAllAccountPropertyLists = createAction(AccountActionTypes.CLEAR_ALL_ACCOUNT_PROPERTY_LISTS)();

export const clearErrors = createAction(AccountActionTypes.CLEAR_ERRORS)<AccountError[] | void>();

export const clearAccountCheck = createAction(AccountActionTypes.CLEAR_CHECK_ACCOUNT)();

export const clearAccountPayment = createAction(AccountActionTypes.CLEAR_ACCOUNT_PAYMENT)();

export const setFeatures = createAction(AccountActionTypes.SET_ENABLED_FEATURES)<Feature[]>();
