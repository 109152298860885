import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { PropertyStateType } from "@shared/interfaces/RecordManagement";

import * as actions from "./actions";

export const initialState: PropertyStateType = {
  propertyTotal: 0,
  propertyList: null,
  ownerTotal: 0,
  ownerList: null,
  property: null,
  ownerDetails: null,
  ownerPropertiesTotal: 0,
  ownerProperties: [],
  error: null,
};

export const reducer = createReducer<typeof initialState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getPropertyList.request, (state) => {
    return produce(state, (nextState) => {
      nextState.error = null;
    });
  })
  .handleAction(actions.getPropertyList.success, (state, action) => {
    return produce(state, (nextState) => {
      nextState.propertyTotal = action.payload?.total;
      nextState.propertyList = action.payload?.rows || [];
    });
  })
  .handleAction(actions.getPropertyList.failure, (state, action) => {
    return produce(state, (nextState) => {
      nextState.error = action.payload;
    });
  })
  .handleAction(actions.clearPropertyList, (state) => {
    return produce(state, (nextState) => {
      nextState.propertyTotal = initialState.propertyTotal;
      nextState.propertyList = initialState.propertyList;
    });
  })
  .handleAction(actions.getProperty.request, (state) => {
    return produce(state, (nextState) => {
      nextState.property = null;
      nextState.error = null;
    });
  })
  .handleAction(actions.getProperty.success, (state, action) => {
    return produce(state, (nextState) => {
      nextState.property = action.payload;
    });
  })
  .handleAction(actions.getProperty.failure, (state, action) => {
    return produce(state, (nextState) => {
      nextState.error = action.payload;
    });
  })

  .handleAction(actions.linkProperty, (state, action) => {
    return produce(state, (nextState) => {
      if (nextState?.property?.lists) {
        const unique = [...nextState.property.lists, ...action.payload].reduce(
          (map, item) => map.set(item.id, item),
          new Map(),
        );
        nextState.property.lists = Array.from(unique.values());
      }
    });
  })
  .handleAction(actions.unlinkProperty.success, (state, action) => {
    return produce(state, (nextState) => {
      if (nextState?.property?.lists) {
        nextState.property.lists = nextState.property.lists.filter((list) => list.id !== action.payload.listId);
      }
    });
  })

  .handleAction(actions.getOwnerList.request, (state) => {
    return produce(state, (nextState) => {
      nextState.error = null;
    });
  })
  .handleAction(actions.getOwnerList.success, (state, action) => {
    return produce(state, (nextState) => {
      nextState.ownerTotal = action.payload?.total;
      nextState.ownerList = action.payload?.rows || [];
    });
  })
  .handleAction(actions.getOwnerList.failure, (state, action) => {
    return produce(state, (nextState) => {
      nextState.error = action.payload;
    });
  })

  .handleAction(actions.getOwnerDetails.request, (state) => {
    return produce(state, (nextState) => {
      nextState.ownerDetails = null;
      nextState.error = null;
    });
  })
  .handleAction(actions.getOwnerDetails.success, (state, action) => {
    return produce(state, (nextState) => {
      nextState.ownerDetails = action.payload;
    });
  })
  .handleAction(actions.getOwnerDetails.failure, (state, action) => {
    return produce(state, (nextState) => {
      nextState.error = action.payload;
    });
  })
  .handleAction(actions.getOwnerProperties.request, (state) => {
    return produce(state, (nextState) => {
      nextState.error = null;
    });
  })
  .handleAction(actions.getOwnerProperties.success, (state, action) => {
    return produce(state, (nextState) => {
      nextState.ownerPropertiesTotal = action.payload?.total;
      nextState.ownerProperties = action.payload?.rows;
    });
  })
  .handleAction(actions.getOwnerProperties.failure, (state, action) => {
    return produce(state, (nextState) => {
      nextState.error = action.payload;
    });
  });
