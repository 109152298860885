import { call, put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload, ResponseError } from "@shared/interfaces";
import {
  RequestOwnerListPayload,
  SuccessOwnerListPayload,
  RequestPropertyListPayload,
  SuccessPropertyListPayload,
  RequestPropertyPayload,
  SuccessPropertyPayload,
  SuccessOwnerDetailsPayload,
  SuccessOwnerPropertiesPayload,
  RequestOwnerPropertiesPayload,
  RequestOwnerDetailsPayload,
  RequestUnlinkPropertyPayload,
} from "@shared/interfaces/RecordManagement";
import * as sharedActions from "@shared/store/actions";
import { showHttpErrorNotification } from "@shared/utils";

import {
  getPropertyList,
  getProperty,
  unlinkProperty,
  getOwnerList,
  getOwnerDetails,
  getOwnerProperties,
} from "./actions";
import {
  fetchProperty,
  fetchOwnerList,
  fetchOwnerDetails,
  fetchOwnerProperties,
  fetchPropertyList,
  fetchUnlinkProperty,
} from "../api";

function* getPropertyListSaga(action: ActionWithPayload<RequestPropertyListPayload>) {
  try {
    yield put(sharedActions.startLoading());

    const response: SuccessPropertyListPayload = yield call(fetchPropertyList, action.payload);

    yield put(getPropertyList.success(response));
  } catch (error) {
    yield put(getPropertyList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* getPropertySaga(action: ActionWithPayload<RequestPropertyPayload>) {
  try {
    yield put(sharedActions.startLoading());

    const response: SuccessPropertyPayload = yield call(fetchProperty, action.payload);

    yield put(getProperty.success(response));
  } catch (error) {
    yield put(getProperty.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* getOwnerListSaga(action: ActionWithPayload<RequestOwnerListPayload>) {
  try {
    yield put(sharedActions.startLoading());

    const response: SuccessOwnerListPayload = yield call(fetchOwnerList, action.payload);

    yield put(getOwnerList.success(response));
  } catch (error) {
    yield put(getOwnerList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* getOwnerDetailsSaga(action: ActionWithPayload<RequestOwnerDetailsPayload>) {
  try {
    yield put(sharedActions.startLoading());

    const response: SuccessOwnerDetailsPayload = yield call(fetchOwnerDetails, action.payload);

    yield put(getOwnerDetails.success(response));
  } catch (error) {
    yield put(getOwnerDetails.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* getOwnerPropertiesSaga(action: ActionWithPayload<RequestOwnerPropertiesPayload>) {
  try {
    yield put(sharedActions.startLoading());

    const response: SuccessOwnerPropertiesPayload = yield call(fetchOwnerProperties, action.payload);

    yield put(getOwnerProperties.success(response));
  } catch (error) {
    yield put(getProperty.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* unlinkPropertySaga(action: ActionWithPayload<RequestUnlinkPropertyPayload>) {
  try {
    yield put(sharedActions.startLoading());
    yield call(fetchUnlinkProperty, action.payload);
    yield put(unlinkProperty.success(action.payload));
  } catch (error) {
    yield put(unlinkProperty.failure(error as Error));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

function* saga() {
  yield takeLatest(getProperty.request, getPropertySaga);
  yield takeLatest(getOwnerList.request, getOwnerListSaga);
  yield takeLatest(getOwnerDetails.request, getOwnerDetailsSaga);
  yield takeLatest(getOwnerProperties.request, getOwnerPropertiesSaga);
  yield takeLatest(getPropertyList.request, getPropertyListSaga);
  yield takeLatest(unlinkProperty.request, unlinkPropertySaga);
}

export default saga;
