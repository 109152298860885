import React, { FC } from "react";
import { InputPicker } from "@shared/components";
import { selectors as sharedSelectors } from "@shared/store";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectors as presetBuilderSelectors } from "@containers/PresetBuilder/store";
import { filterChange, removeTag } from "@containers/PresetBuilder/store/actions";
import { ESPreset, SelectInputComponent } from "@shared/interfaces/PresetBuilder.interface";
import { InputPickerProps } from "rsuite";
import { TaggedInputPicker } from "@containers/PresetBuilder/components/TaggedInputPicker";
import { isAllowedSelectBlockField, isAllowedTaggedInputField } from "@containers/PresetBuilder/util";
import { TaggedInputPickerProps } from "@containers/PresetBuilder/components/TaggedInputPicker/TaggedInputPicker";

const SelectBlock: FC<SelectInputComponent> = (props) => {
  const { data, stateAccessor, selectAll } = props;

  const dispatch = useDispatch();
  const presetStore = useSelector(presetBuilderSelectors.getPresetBuilder());
  const propertyTypes = useSelector(sharedSelectors.getPropertyTypes());

  if (stateAccessor && !isAllowedSelectBlockField(stateAccessor)) return <></>;

  const onChange: InputPickerProps["onChange"] = (e) => {
    stateAccessor && dispatch(filterChange.request({ name: stateAccessor, value: e as string }));
  };

  const onClean: InputPickerProps["onClean"] = () =>
    stateAccessor && dispatch(filterChange.request({ name: stateAccessor, value: "" }));

  if (props.tagged && stateAccessor && isAllowedTaggedInputField(stateAccessor)) {
    const props: TaggedInputPickerProps = {
      tags: presetStore.preset.filters.property_type.map((value) => ({
        name: propertyTypes?.find((i) => i.code === value)?.description ?? "",
        value,
      })),
      values: presetStore.preset.filters.property_type,
      disabledValues: presetStore.preset.filters.property_type,
      data: data,
      selectAll,
      onChange: (value: string) => dispatch(filterChange.request({ name: stateAccessor, value })),
      onTagRemove: (tag) => {
        dispatch(removeTag.request({ stateName: stateAccessor, value: tag }));
      },
    };

    return <TaggedInputPicker {...props} />;
  }

  return (
    <div className={`select-pickers`}>
      <InputPicker
        name={stateAccessor}
        searchable={true}
        cleanable={props.cleanable ?? false}
        selectAll={selectAll}
        onClean={onClean}
        locale={{ noResultsText: "No matches" }}
        className={"select-picker"}
        placeholder={props.label ?? "Select State"}
        useContainer={true}
        disableContainerStyles={true}
        value={`${presetStore.preset.filters[stateAccessor as keyof ESPreset["filters"]]}`}
        data={data || []}
        onChange={onChange}
      />
    </div>
  );
};
export default SelectBlock;
