import React, { FC } from "react";
import { Button } from "@shared/components";

interface NewListButtonProps {
  onClickHandler: () => void;
}

const NewListButton: FC<NewListButtonProps> = (props) => {
  const { onClickHandler } = props;
  return (
    <Button buttonStyle="accent-green" onClick={onClickHandler}>
      + New List
    </Button>
  );
};

export default NewListButton;
