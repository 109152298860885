import { API, METHODS } from "@shared/constants";
import {
  RequestPropertyPayload,
  RequestPropertyListPayload,
  RequestUnlinkPropertyPayload,
  RequestOwnerPropertiesPayload,
  RequestOwnerDetailsPayload,
  RequestOwnerListPayload,
} from "@shared/interfaces/RecordManagement";
import { request } from "@shared/utils";

export const fetchPropertyList = ({ accountId, ...params }: RequestPropertyListPayload) => {
  return request(METHODS.GET, API.RECORD_MANAGEMENT.GET_PROPERTY_LIST({ accountId }))({ params });
};

export const fetchProperty = async ({ accountId, propertyId }: RequestPropertyPayload) => {
  return request(METHODS.GET, API.RECORD_MANAGEMENT.GET_PROPERTY({ accountId, propertyId }))();
};

export const fetchUnlinkProperty = async ({ accountId, listId, propertyId }: RequestUnlinkPropertyPayload) => {
  return request(
    METHODS.DELETE,
    API.RECORD_MANAGEMENT.UNLINK_PROPERTY({ accountId }),
  )({ data: { listId, propertyId } });
};

export const fetchOwnerList = ({ accountId, from, size, filters, search }: RequestOwnerListPayload) => {
  return request(
    METHODS.GET,
    API.RECORD_MANAGEMENT.GET_OWNER_LIST({ accountId }),
  )({ params: { from, size, search, filters } });
};

export const fetchOwnerDetails = ({ accountId, ownerName }: RequestOwnerDetailsPayload) => {
  return request(METHODS.GET, API.RECORD_MANAGEMENT.GET_OWNER_DETAILS({ accountId, ownerName }))();
};

export const fetchOwnerProperties = ({ accountId, ownerName, from, size }: RequestOwnerPropertiesPayload) => {
  return request(
    METHODS.GET,
    API.RECORD_MANAGEMENT.GET_OWNER_PROPERTIES({ accountId, ownerName }),
  )({ params: { from, size } });
};

export default {
  fetchPropertyList,
  fetchProperty,
  fetchUnlinkProperty,
  fetchOwnerList,
  fetchOwnerDetails,
  fetchOwnerProperties,
};
