import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";
import { PresetError } from "@containers/Preset/interfaces/PresetState.interface";

const selectPreset = (state: AppState) => state.preset;

export const getPresetList = () => createSelector(selectPreset, (state) => state.presetList);
export const getPreset = () => createSelector(selectPreset, (state) => state.preset);
export const getPresetFields = () => createSelector(selectPreset, (state) => state.presetFields);
export const selectError = (errorType: PresetError) => createSelector(selectPreset, (state) => state.errors[errorType]);
