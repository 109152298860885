import React, { FC, HTMLAttributes, PropsWithChildren } from "react";

import "./index.scss";

interface ContentCardProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  style?: React.CSSProperties;
}

const Card: FC<PropsWithChildren<ContentCardProps>> = (props) => {
  const { children, className = "", ...otherProps } = props;

  return (
    <div className={`content-card ${className}`} {...otherProps}>
      {children}
    </div>
  );
};

export default Card;
