import { Preset, PresetField } from "@shared/models";
import { ResponseError } from "@shared/interfaces";

export enum PresetError {
  createPresetError = "createPresetError",
  updatePresetError = "updatePresetError",
  deletePresetError = "deletePresetError",
  getPresetError = "getPresetError",
  getPresetListError = "getPresetListError",
  getPresetFieldsError = "getPresetFieldsError",
}

export interface PresetStateType {
  preset: Preset | null;
  presetList: { list: Preset[] } | null;
  presetFields: PresetField[];

  errors: {
    [key in PresetError]: ResponseError | null;
  };
}
