import React from "react";
import { StylesConfig } from "react-select";
import classnames from "classnames";
import { FieldItemType, Option } from "@shared/interfaces";
import { ErrorMessage, InputPicker } from "@shared/components";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface InputPickerFieldItem extends RenderField {
  type: FieldItemType.INPUT_PICKER;
  label?: string;
  options: { label: string; value: string }[];
  disabled?: boolean;
  placeholder?: string;
  styles?: StylesConfig;
  helpText?: string;
  isSearchable?: boolean;
  relatedFields?: Record<string, number | string[]>;
  isMulti?: boolean;
  isClearable?: boolean;
}

export const GenerateInputPicker: GenerateRenderField<InputPickerFieldItem> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values },
    wrapperClass,
    disabled,
    label,
    name,
    options,
    placeholder,
  } = props;

  const handleChange = (option: Option) => {
    setFieldValue(name, option);
  };

  return (
    <div
      className={classnames("render-input-picker", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}
    >
      {label && <label className={classnames({ disabled })}>{label}</label>}
      <div className={classnames("input-picker", { disabled })}>
        <InputPicker
          disabled={disabled || !options.length}
          name={name}
          searchable
          className="select-picker"
          placeholder={placeholder || "Start typing here"}
          useContainer
          cleanable={false}
          disableContainerStyles
          value={values[name]}
          data={options.map((o) => ({ value: o.value?.toString(), label: o.label }))}
          onChange={handleChange}
        />
      </div>
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
