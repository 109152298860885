import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { BaseFilter, ORDER_COLUMN_TYPE, ORDER_TYPE } from "@shared/interfaces";
import { ClientError, ClientStateType } from "@containers/Contact/interfaces";

import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultFilter: BaseFilter = {
  page: 1,
  limit: 50,
  search: null,
  order: ORDER_COLUMN_TYPE.FIRST_NAME,
  direction: ORDER_TYPE.ASC,
};
const defaultStatesFilter: BaseFilter = {
  page: 1,
  limit: 10,
  search: null,
  order: ORDER_COLUMN_TYPE.NAME,
  direction: ORDER_TYPE.ASC,
};
const defaultCountiesFilter: BaseFilter = {
  page: 1,
  limit: 10,
  search: null,
  order: ORDER_COLUMN_TYPE.NAME,
  direction: ORDER_TYPE.ASC,
};

export const initialState: ClientStateType = {
  selectedClient: null,
  clientList: null,
  filter: { ...defaultFilter },
  states: [],
  statesTotal: 0,
  statesFilter: defaultStatesFilter,
  counties: [],
  countiesTotal: 0,
  countiesFilter: defaultCountiesFilter,

  errors: {
    [ClientError.createClientError]: null,
    [ClientError.deleteClientError]: null,
    [ClientError.activateClientError]: null,
    [ClientError.updateClientError]: null,
    [ClientError.getClientListError]: null,
    [ClientError.getClientError]: null,
    [ClientError.getStatesError]: null,
    [ClientError.getCountiesError]: null,
  },
};

const reducer = createReducer<ClientStateType, Action>(initialState)
  .handleAction(actions.getClientList.success, (state, action) =>
    produce(state, (nextState) => {
      const { items, total, clear } = action.payload;
      if (nextState.clientList) {
        nextState.clientList.list = !clear ? [...nextState.clientList.list, ...items] : [...items];
        nextState.clientList.total = total;
      } else {
        nextState.clientList = { list: items, total };
      }
    }),
  )
  .handleAction(actions.getClientList.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClientError.getClientListError] = action.payload;
    }),
  )
  .handleAction(actions.getStates.success, (state, action) =>
    produce(state, (nextState) => {
      const { items, total, clear } = action.payload;
      nextState.states = !clear ? [...nextState.states, ...items] : [...items];
      nextState.statesTotal = total;
    }),
  )
  .handleAction(actions.getStates.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClientError.getStatesError] = action.payload;
    }),
  )
  .handleAction(actions.getCounties.success, (state, action) =>
    produce(state, (nextState) => {
      const { items, total, clear } = action.payload;
      nextState.counties = !clear ? [...nextState.counties, ...items] : [...items];
      nextState.countiesTotal = total;
    }),
  )
  .handleAction(actions.getCounties.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClientError.getCountiesError] = action.payload;
    }),
  )
  .handleAction(actions.getClient.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.selectedClient = action.payload;
    }),
  )
  .handleAction(actions.getClient.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClientError.getClientError] = action.payload;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.setStatesFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.statesFilter = action.payload || { ...defaultStatesFilter };
    }),
  )
  .handleAction(actions.setCountiesFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.countiesFilter = action.payload || { ...defaultCountiesFilter };
    }),
  )
  .handleAction(actions.clearClientList, (state) =>
    produce(state, (nextState) => {
      nextState.clientList = null;
    }),
  )
  .handleAction(actions.clearStates, (state) =>
    produce(state, (nextState) => {
      nextState.states = [];
      nextState.statesTotal = 0;
    }),
  )
  .handleAction(actions.clearCounties, (state) =>
    produce(state, (nextState) => {
      nextState.counties = [];
      nextState.countiesTotal = 0;
    }),
  )
  .handleAction(actions.clearClient, (state) =>
    produce(state, (nextState) => {
      nextState.selectedClient = null;
    }),
  )
  .handleAction(actions.createClient.success, (state, action) =>
    produce(state, (nextState) => {
      if (nextState.clientList) {
        nextState.clientList.list = [action.payload, ...nextState.clientList.list];
      }
    }),
  )
  .handleAction(actions.createClient.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClientError.createClientError] = action.payload;
    }),
  )
  .handleAction(actions.updateClient.success, (state, action) =>
    produce(state, (nextState) => {
      const updatedClient = action.payload;
      const clientList = nextState.clientList?.list;
      if (clientList) {
        const foundClientIndex = clientList.findIndex((p) => updatedClient.id === p.id);
        clientList[foundClientIndex] = updatedClient;

        if (nextState.clientList) {
          nextState.clientList.list = [...clientList];
        }
      }
    }),
  )
  .handleAction(actions.updateClient.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClientError.updateClientError] = action.payload;
    }),
  )
  .handleAction(actions.deleteClient.success, (state, action) =>
    produce(state, (nextState) => {
      const deletedUser = action.payload;
      if (state.selectedClient) {
        nextState.selectedClient = deletedUser;
      }

      const clientList = nextState.clientList?.list;
      if (clientList) {
        const foundClientIndex = clientList.findIndex((c) => deletedUser.id === c.id);
        clientList[foundClientIndex] = deletedUser;
        if (nextState.clientList) {
          nextState.clientList.list = [...clientList];
        }
      }
    }),
  )
  .handleAction(actions.deleteClient.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClientError.deleteClientError] = action.payload;
    }),
  )
  .handleAction(actions.activateClient.success, (state, action) =>
    produce(state, (nextState) => {
      const activatedUser = action.payload;
      if (state.selectedClient) {
        nextState.selectedClient = activatedUser;
      }

      const clientList = nextState.clientList?.list;

      if (clientList) {
        const foundClientIndex = clientList.findIndex((c) => activatedUser.id === c.id);
        clientList[foundClientIndex] = activatedUser;
        if (nextState.clientList) {
          nextState.clientList.list = [...clientList];
        }
      }
    }),
  )
  .handleAction(actions.activateClient.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[ClientError.activateClientError] = action.payload;
    }),
  )
  .handleAction(actions.clearErrors, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        action.payload.forEach((err) => {
          nextState.errors[err] = null;
        });
      } else {
        nextState.errors = initialState.errors;
      }
    }),
  );

export { reducer as ClientReducer };
