import React, { FC } from "react";
import "./index.scss";
import { isBefore } from "rsuite/cjs/utils/dateUtils";
import { useSelector } from "react-redux";
import { getPresetBuilder } from "@containers/PresetBuilder/store/selectors";

import Picker from "./Picker";

const DateBlock: FC = () => {
  const presetState = useSelector(getPresetBuilder());
  const lastSaleMinDate = presetState.preset.filters.last_sale_date_min;

  const maxDisabledDate = (date: Date | undefined) => {
    if (!lastSaleMinDate) return false;
    return !!date && isBefore(date, new Date(lastSaleMinDate));
  };

  return (
    <div className={"lastSaleDate"}>
      <Picker accessor={"last_sale_date_min"} placeHolder={"From"} />
      <Picker accessor={"last_sale_date_max"} disabledDate={maxDisabledDate} placeHolder={"To"} />
    </div>
  );
};

export default DateBlock;
