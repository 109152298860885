import { InvestorDataSettings } from "@shared/models/InvestorDataSettings";
import { DetailedCounty, User } from "@shared/models/User";
import { ZipCode } from "@shared/models/ZipCode";
import { HubspotPropertiesHistory } from "@shared/models/HubspotPropertiesHistory";

export enum StripeSubscriptionStatus {
  ACTIVE = "active",
  CANCELED = "canceled",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  PAST_DUE = "past_due",
  TRIALING = "trialing",
  UNPAID = "unpaid",
}

export enum AccountFilterStatus {
  active = "active",
  inactive = "inactive",
  all = "all",
}

export interface Subscription {
  id: number;
  status: StripeSubscriptionStatus;
  subscription_properties: {
    id: number;
    subscription_id: number;
    subscription_name: string;
    subscription_description: string | null;
    deal_name: string | null;
  } | null;
  stripe_id: string;
  chargebee_subscription_id: string;
  counties: DetailedCounty[];
  zip_codes: ZipCode[];
  account?: Partial<Account>;
}

export interface Account {
  id: number;
  name: string;
  credits: number;
  is_active: boolean;
  claritas_id: string | null;
  claritas_nickname: string | null;
  stripe_customer_id: string;
  chargebee_customer_id: string;
  created_at: Date;
  updated_at: Date | null;
  created_by: string;
  updated_by: string | null;
  investor_data_settings: InvestorDataSettings | null;
  subscriptions?: Subscription[];
  hubspot_company_id?: number | null;
  state_id: number | null;
  county_id: number | null;
  buy_box?: string | null;
  users?: User[];
  zip_codes?: ZipCode[];
  hubspot_properties_history?: HubspotPropertiesHistory[];
}

export interface PaymentMethodExistence {
  exists: boolean | null;
  cardExists: boolean | null;
}

export interface AccountPropertyList {
  id: number;
  name: string;
  account_id: number;
  counties_total: number;
  properties_total: number;
  created_at: Date | null;
}

export interface AccountNote {
  id: number;
  text: string;
  user: User;
  account_id: number;
  created_at: Date;
}

export interface GetAccountEntity {
  id: number;
  type: string;
  entity: string;
}

export interface SaveAccountAttempts {
  id: number;
  call: number;
  mail: number;
  message: number;
  type: string;
  entity: string;
  hideLoader?: boolean;
}

export interface AccountAttempts {
  id: number;
  call: number;
  mail: number;
  message: number;
  property_id: number;
  account_id: number;
  created_at: Date;
}
