import React, { FC, useState } from "react";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { PropertyHistory, Ticket } from "@shared/models/Ticket";
import { ErrorMessage, Textarea } from "@shared/components";
import { ButtonPrimary } from "@shared/components/Button/ButtonEnhanced";
import { useUpdateTicket } from "@containers/Tickets/hooks/useUpdateTicket";
import { getTicketById } from "@containers/Tickets/store/selectors";
import { useSelector } from "react-redux";
import { ReactComponent as IconEdit } from "@assets/files/icons/general/edit.svg";
import { Overlay } from "@shared/components/Page";

import "./index.scss";

export type TicketNotesContainerProps = {
  onClose: () => void;
  data?: {
    ticket: Ticket;
  };
};

const validationSchema = Yup.object({
  notes: Yup.string().trim().required("Notes is required").max(1000, "Notes must be less than 1000 characters"),
});

export const TicketNotesContainer: FC<TicketNotesContainerProps> = ({ data, onClose }) => {
  const handleUpdateTicket = useUpdateTicket();

  const [isEditing, setIsEditing] = useState(false);

  const ticket = useSelector(getTicketById(data?.ticket.id as number));

  const history = ticket?.propertyHistory?.reduce<PropertyHistory | null>((reduction, current) => {
    if (current.name === "notes" && (!reduction || new Date(current.timestamp) > new Date(reduction.timestamp))) {
      return current;
    }

    return reduction;
  }, null);

  return (
    <Formik
      initialValues={{ notes: "" }}
      validationSchema={validationSchema}
      onSubmit={({ notes }, { resetForm }) => {
        if (ticket) {
          handleUpdateTicket({ id: ticket.id, notes });
        }
        setIsEditing(false);
        resetForm();
      }}
    >
      {({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => {
        const handleEditNote = () => {
          setIsEditing(true);
          setFieldValue("notes", ticket?.notes);
        };
        return (
          <form onSubmit={handleSubmit}>
            <Overlay className="ticket-notes">
              <Overlay.Header>
                <Overlay.Title>Notes</Overlay.Title>
                <Overlay.Close onClick={onClose}>Close</Overlay.Close>
              </Overlay.Header>
              <Overlay.Content className="ticket-notes-content">
                {ticket?.notes && (
                  <div className="ticket-notes-item">
                    {history && (
                      <div className="ticket-notes-item-title">
                        {moment(history.timestamp).format("MM/DD/YY [at] h:mm A")} {history.created_by}
                        {!isEditing && (
                          <button onClick={handleEditNote}>
                            <IconEdit width={"16px"} height={"16px"} stroke={"#1e57c7"} /> Edit
                          </button>
                        )}
                      </div>
                    )}
                    <div className="ticket-notes-item-content">{ticket?.notes}</div>
                  </div>
                )}
                <div className="ticket-notes-add-new">
                  <div className="ticket-notes-item-title"> {isEditing && "Edit note"}</div>
                  <Textarea rows={isEditing ? 16 : 4} value={values.notes} name="notes" onChange={handleChange} />

                  <div className="ticket-notes-add-new-action">
                    <ErrorMessage isTouched={touched.notes} error={errors.notes} />
                    <ButtonPrimary type="submit">{isEditing ? "Save" : "Add Note"}</ButtonPrimary>
                  </div>
                </div>
              </Overlay.Content>
            </Overlay>
          </form>
        );
      }}
    </Formik>
  );
};
