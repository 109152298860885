import React from "react";
import classnames from "classnames";

import ButtonComponent, { ButtonProps } from "./Button";

import "./index.scss";

const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <ButtonComponent className={classnames("button-component", className)} {...props}>
      {children}
    </ButtonComponent>
  );
};

export const ButtonPrimary: React.FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <Button className={classnames("button-primary", className)} {...props}>
      {children}
    </Button>
  );
};

export const ButtonSecondary: React.FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <Button className={classnames("button-secondary", className)} {...props}>
      {children}
    </Button>
  );
};

export const ButtonIcon: React.FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <Button className={classnames("button-icon", className)} {...props}>
      {children}
    </Button>
  );
};
