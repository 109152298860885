import { PropertyFilterType } from "@shared/interfaces/RecordManagement";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors as sharedSelectors, actions as sharedActions } from "@shared/store";
import {
  allSelectors as recordManagementSelectors,
  allActions as recordManagementActions,
} from "@containers/RecordManagement/store";
import { actions as accountActions, selectors as AccountSelectors } from "@containers/Account/store";
import { DISTRESSORS } from "@containers/PresetBuilder/containers/PresetBuilderContainer/constant";
import { mapDropDownDataToInputPickerData } from "@shared/utils";

interface Props<T> {
  field: T;
  type?: PropertyFilterType;
}

const distressors = mapDropDownDataToInputPickerData(DISTRESSORS);

const ownershipVacancyStatus = [
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useListOptions<T>({ field, type }: Props<T>): any[] {
  const dispatch = useDispatch();
  const account = useSelector(AccountSelectors.getActiveAccount());
  const accountId = account?.id as number;

  const data = {
    owners: useSelector(recordManagementSelectors.selectOwnerList()),
    lists: useSelector(AccountSelectors.getActiveAccountPropertyLists()),
    state: useSelector(sharedSelectors.getStates()),
    county: useSelector(sharedSelectors.getCounties()),
    zipCodes: useSelector(sharedSelectors.getZips())?.map((i) => ({ id: i.zip_code, name: i.zip_code })),
    propertyType: useSelector(sharedSelectors.getPropertyTypes())?.map((i) => ({ id: i.code, name: i.description })),
    distressors,
    ownershipVacancyStatus,
  };

  useEffect(() => {
    if (type === PropertyFilterType.TAGS || type === PropertyFilterType.SELECT) {
      if (!data.owners && field === "owners")
        dispatch(recordManagementActions.getOwnerList.request({ accountId, from: 0, size: 10000 }));
      if (!data.state && field === "state") dispatch(sharedActions.getStates.request());
      if (!data.propertyType && field === "propertyType") dispatch(sharedActions.getPropertyTypes.request());
      if (!data.county && field === "county") dispatch(sharedActions.getCountiesByState.request(undefined));
      if (!data.zipCodes && field === "zipCodes") dispatch(sharedActions.getZips.request(undefined));
      if (!data.lists && field === "lists") dispatch(accountActions.getAccountPropertyLists.request({ id: accountId }));
    }
  }, [dispatch, data.owners, data.lists, data.state, data.county, data.zipCodes, data.propertyType, field, type]); // eslint-disable-line react-hooks/exhaustive-deps

  return data[field as keyof typeof data] || [];
}
