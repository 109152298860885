import React, { FC, useEffect, useState } from "react";
import { PayPerLead } from "@shared/models/PayPerLead";
import { Thumb } from "@containers/PayPerLead/components/Button/Thumb";
import { Tooltip } from "@shared/components";
import { useQualifyLeads } from "@containers/PayPerLead/hooks";

export interface LeadActionsProps {
  lead: PayPerLead;
}

export const LeadActions: FC<LeadActionsProps> = ({ lead }) => {
  const [isQualified, setQualified] = useState<boolean | null>(lead.is_qualified);
  const handleQualifyLead = useQualifyLeads();

  const qualify = (e?: React.MouseEvent<Element, MouseEvent>) => {
    e?.stopPropagation();

    const nextState = isQualified === true ? null : true;

    setQualified(nextState);

    handleQualifyLead({
      leadId: [lead.id],
      is_qualified: nextState,
      onError: () => setQualified(isQualified),
    });
  };

  const disqualify = (e?: React.MouseEvent<Element, MouseEvent>) => {
    e?.stopPropagation();

    const nextState = isQualified === false ? null : false;

    setQualified(nextState);

    handleQualifyLead({
      leadId: [lead.id],
      is_qualified: nextState,
      onError: () => setQualified(isQualified),
    });
  };

  useEffect(() => {
    setQualified(lead.is_qualified);
  }, [lead.is_qualified]);

  const classes: string[] = ["lead-action-buttons"];

  if (isQualified !== null || lead.is_qualified !== null) {
    classes.push("visible");
  }

  return (
    <>
      <div className={classes.join(" ")}>
        <Tooltip content="Mark as Quality Seller Lead">
          <Thumb direction="up" onClick={qualify} active={isQualified === true} />
        </Tooltip>
        <Tooltip content="Mark as Not Quality">
          <Thumb direction="down" onClick={disqualify} active={isQualified === false} />
        </Tooltip>
      </div>
    </>
  );
};
