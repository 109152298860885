import "./index.scss";
import { Button } from "@shared/components";
import React, { useMemo, useState } from "react";
import { PropertyFilterOption } from "@shared/interfaces/RecordManagement";
import {
  AddPropertiesFilters,
  PropertiesFiltersState,
  RecentlyUsedFilters,
} from "@containers/RecordManagement/containers/FilterPropertiesContainer/components";
import recentlyUsedPropertiesFiltersStorage from "@shared/utils/recentlyUsedPropertiesFiltersStorage";

interface Props {
  onClose: () => void;
  data?: {
    filters: PropertyFilterOption[];
  };
  callback?: <T>(e?: T) => void;
}

export const FilterPropertiesContainer = ({ data, onClose, callback }: Props) => {
  const [state, setState] = useState(data?.filters || []);
  const [recentlyUsed, setRecentlyUsed] = useState(recentlyUsedPropertiesFiltersStorage.get());

  const usedFilters = useMemo(() => state.reduce((acc, i) => ({ ...acc, [i.value]: true }), {}), [state]);

  const handleAdd = (item: PropertyFilterOption) => {
    setState((s) => s.concat(item));

    // add to recently used
    const newRecentlyUsed = [item, ...recentlyUsed.filter((i) => i.value !== item.value)];

    setRecentlyUsed(newRecentlyUsed);
    recentlyUsedPropertiesFiltersStorage.set(newRecentlyUsed);
  };

  const apply = () => {
    onClose();
    callback?.(state);
  };

  return (
    <div className="filter-properties">
      <div className="header">
        <h3>Filter Records</h3>

        {!!state?.length && (
          <Button type="button" buttonStyle="form-cancel" onClick={() => setState([])}>
            Clear All
          </Button>
        )}
      </div>

      <div className="content">
        <PropertiesFiltersState state={state} onChange={setState} />
        <AddPropertiesFilters usedFilters={usedFilters} onAdd={handleAdd} />
        <RecentlyUsedFilters usedFilters={usedFilters} recentlyUsed={recentlyUsed} onAdd={handleAdd} />
      </div>
      <div className="footer">
        <Button type="button" buttonStyle="form-cancel" onClick={onClose}>
          Cancel
        </Button>

        <Button type="button" buttonStyle="accent-green" onClick={apply}>
          Apply
        </Button>
      </div>
    </div>
  );
};
