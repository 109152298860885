import { Property } from "@shared/interfaces/RecordManagement";
import { County } from "@shared/models/County";
import { State } from "@shared/models/State";
import { Account } from "@shared/models/Account";

export enum PayPerLeadResolution {
  approved = "approved",
  rejected = "rejected",
}

export interface PayPerLeadDispute {
  id: number;
  lead_id: number;
  message: string;
  created_at: Date;
  created_by?: string | null;
  resolved_date?: Date | string | null;
  resolved_by?: string | null;
  resolution: PayPerLeadResolution;
  is_active: boolean;
  lead: Partial<PayPerLead>;
}

export interface PayPerLead {
  id: number;
  name: string;
  address: string;
  long_address: string;
  phone: string;
  email: string;
  account_id: number;
  account?: Account;
  subscription_id: number;
  city: string;
  state_id: number;
  state?: State;
  county_id: number;
  county: County;
  price: number;
  is_active: boolean;
  property_type: string;
  occupied: string;
  listed_by_realtor: boolean;
  sell_timeline: string;
  sell_reason: string;
  est_value: number;
  created_at: Date;
  updated_at: Date;
  dispute?: PayPerLeadDispute;
  is_qualified: boolean | null;
}

export interface PayPerLeadWithDetail extends PayPerLead {
  detail: Property | null;
}
