import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import { Pagination as PaginationInterface } from "@shared/interfaces";
import { Pagination } from "@shared/components/Pagination";
import { MIN_PAGE_SIZE } from "@shared/constants";

type PaginationDispatcher = Dispatch<SetStateAction<PaginationInterface>>;

export interface PaginationFacadeProps extends PaginationInterface {
  totalData: number;
  onPaginationChange: PaginationDispatcher;
}

export const PaginationFacade: FC<PaginationFacadeProps> = ({
  currentPage = 1,
  resultsPerPage = MIN_PAGE_SIZE,
  totalData = 0,
  onPaginationChange,
}) => {
  const totalPages = useMemo(() => Math.ceil(totalData / resultsPerPage), [totalData, resultsPerPage]);

  const handlePageChange = (page: number) => {
    onPaginationChange((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  if (totalData <= MIN_PAGE_SIZE) return null;

  return (
    <Pagination
      pagination={{ currentPage, resultsPerPage }}
      totalData={totalData}
      totalPages={totalPages}
      setPagination={onPaginationChange}
      handlePagination={handlePageChange}
    />
  );
};
