import React from "react";
import Lottie from "lottie-react";

import processingAnimationJSON from "./processing.animation.json";

import "./index.scss";

export const StatusProcessing: React.FC = () => {
  return (
    <div className="progress-status-processing">
      <div className="progress-status-processing-animation">
        <Lottie animationData={processingAnimationJSON} loop={true} />
      </div>
      <div className="progress-status-processing-caption">
        <p>Processing...</p>
        <p>This might take up to a minute.</p>
      </div>
    </div>
  );
};
