import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { BaseFilter, CountiesFilter } from "@shared/interfaces";
import { CreateClientDto, UpdateClientDto } from "@containers/Contact/interfaces";

export default {
  getClient: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.GET, API.USER.GET_USER(String(id)))();
  },

  getClientsList: (payload: BaseFilter) => request(METHODS.GET, API.USER.GET_USER_LIST)({ params: payload }),

  createClient: (payload: CreateClientDto) => request(METHODS.POST, API.USER.CREATE_USER)(payload),

  updateClient: (payload: UpdateClientDto & { id: number }) => {
    const { id, ...rest } = payload;
    return request(METHODS.PATCH, API.USER.UPDATE_USER(String(id)))(rest);
  },
  deleteClient: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.DELETE, API.USER.DELETE_USER(String(id)))();
  },
  activateClient: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.PUT, API.USER.ACTIVATE_USER(String(id)))();
  },
  getStates: (payload: BaseFilter) => request(METHODS.GET, API.STATE.GET_STATES_LIST)({ params: payload }),
  getCounties: (payload: CountiesFilter) => request(METHODS.GET, API.COUNTY.GET_COUNTIES_LIST)({ params: payload }),
  changeClientAccountStatus: (payload: { user_id: number; account_id: number; is_active: boolean }) => {
    const { user_id, account_id, is_active } = payload;
    return request(METHODS.PUT, API.USER.CHANGE_USER_STATUS(String(user_id)))({ account_id, is_active });
  },
};
