import { useCallback, useRef, DependencyList } from "react";
import isEqual from "lodash/isEqual";

function useDeepCompareMemoize<T>(value: T): T {
  const ref = useRef<T>();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current as T;
}

// @see https://github.com/sandiiarov/use-deep-compare/blob/master/src/useDeepCompareCallback.ts
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDeepCompareCallback = (callback: (...args: any[]) => void, dependencies: DependencyList) => {
  const memoizedDependencies = useDeepCompareMemoize(dependencies);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callback, memoizedDependencies);
};

export default useDeepCompareCallback;
