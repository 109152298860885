import React, { FC } from "react";
import { DatePicker } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { filterChange } from "@containers/PresetBuilder/store/actions";
import "./index.scss";
import { getPresetBuilder } from "@containers/PresetBuilder/store/selectors";
import { DateRangeFields } from "@shared/interfaces/PresetBuilder.interface";
import { ModifiedDatePickerProps } from "@shared/components/Field/DatePicker/DatePicker";

import { useDatePickerContainer } from "./ContainerProvider";

const Picker: FC<{ accessor: DateRangeFields; placeHolder?: string } & ModifiedDatePickerProps> = ({
  accessor,
  placeHolder = new Date().toLocaleDateString(),
  ...props
}) => {
  const dispatch = useDispatch();
  const presetBuilder = useSelector(getPresetBuilder());

  const onChange = (e: Date | null) =>
    dispatch(filterChange.request({ name: accessor, value: e?.toLocaleDateString("en-EN") as string }));
  const clean = () => dispatch(filterChange.request({ name: accessor, value: null }));
  const container = useDatePickerContainer();

  return (
    <DatePicker
      oneTap
      className="date-picker"
      placeholder={placeHolder}
      format={"MM/dd/yyyy"}
      onChange={onChange}
      onClean={clean}
      container={container}
      value={presetBuilder.preset.filters[accessor] ? new Date(presetBuilder.preset.filters[accessor] as string) : null}
      {...props}
    />
  );
};

export default Picker;
