export enum DataDeliveryActionTypes {
  GET_HUBSPOT_HISTORY_LIST = "@@DATA_DELIVERY/GET_HUBSPOT_HISTORY_LIST",
  GET_ACCOUNT_HUBSPOT_HISTORY_SUCCESS = "@@DATA_DELIVERY/GET_ACCOUNT_HUBSPOT_HISTORY_SUCCESS",
  GET_ACCOUNT_HUBSPOT_HISTORY_FAILURE = "@@DATA_DELIVERY/GET_ACCOUNT_HUBSPOT_HISTORY_FAILURE",

  UPDATE_HUBSPOT_HISTORY_ITEM_STATUS = "@@DATA_DELIVERY/UPDATE_HUBSPOT_HISTORY_ITEM_STATUS",
  UPDATE_HUBSPOT_HISTORY_ITEM_STATUS_SUCCESS = "@@DATA_DELIVERY/UPDATE_HUBSPOT_HISTORY_ITEM_STATUS_SUCCESS",
  UPDATE_HUBSPOT_HISTORY_ITEM_STATUS_FAILURE = "@@DATA_DELIVERY/UPDATE_HUBSPOT_HISTORY_ITEM_STATUS_FAILURE",

  SET_HUBSPOT_HISTORY_FILTER = "@@DATA_DELIVERY/SET_HUBSPOT_HISTORY_FILTER",

  CLEAR_HUBSPOT_HISTORY_LIST = "@@DATA_DELIVERY/CLEAR_HUBSPOT_HISTORY_LIST",

  CLEAR_ERRORS = "@@DATA_DELIVERY/CLEAR_ERRORS",
}
