import { SubscriptionCountyItem } from "@containers/PayPerLead/interface";

export enum StateType {
  STATE = "state",
  OUTLYING_AREA = "outlying_area",
}

export interface State {
  id: number;
  name: string;
  code: string;
  abbreviation: string;
  type: StateType;
  counties?: SubscriptionCountyItem[];
}
