import React, { useCallback } from "react";
import classnames from "classnames";
import { TableItemType } from "@shared/components/Table/interfaces";

import { TableProperty } from "../interface";
import "./index.scss";

export interface RowProps<T> {
  item: TableItemType<T>;
  properties: TableProperty<T>[];
  onClick?: () => void;
}

function getItemCellContent<T>(item: TableItemType<T>, prop: TableProperty<T>) {
  return prop.get ? prop.get(item) : item[prop.key];
}

function Row<T>(props: RowProps<T>) {
  const { properties, item, onClick } = props;

  const localCellClick = useCallback(
    (property: TableProperty<T>, event: React.MouseEvent) => {
      if (!property.cellClick) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      property.cellClick(item);
    },
    [item],
  );

  return (
    <div className="table-row" onClick={onClick}>
      {properties.map((prop) => {
        if (prop.appearClickBlock && item.rowType) {
          return null;
        }

        if (prop.appearClickBlock) {
          return (
            <div
              key={`${prop.key.toString()}-select`}
              className="table-row-cell-clickblock appear-click-block"
              onClick={(e) => localCellClick(prop, e)}
            >
              {prop.appearClickBlock(item)}
            </div>
          );
        }

        return (
          <div
            key={prop.key.toString()}
            className={classnames(
              "table-row-cell",
              prop?.getCellClassName ? prop?.getCellClassName(item) : prop.cellClassName,
            )}
            onClick={prop.cellWholeClick ? (e) => localCellClick(prop, e) : undefined}
          >
            <>
              {getItemCellContent<T>(item, prop)}
              {prop.cellClickBlock && (
                <div className="table-row-cell-clickblock" onClick={(e) => localCellClick(prop, e)}>
                  {prop.cellClickBlock}
                </div>
              )}
            </>
          </div>
        );
      })}
    </div>
  );
}

export default Row;
