import React, { FC, useCallback, useMemo } from "react";
import { FieldArray, FieldArrayRenderProps, Form, FormikProps } from "formik";
import { FieldItemType, RenderTypes } from "@shared/interfaces";
import { FieldItem, RenderFieldType, Handlers } from "@shared/components";

import {
  GeneratePasswordField,
  GenerateCheckbox,
  GenerateTextField,
  GenerateSelect,
  GenerateBlock,
  GenerateSelectSearch,
  GenerateAutocompleteInput,
  GenerateTextarea,
  GenerateMultiSelect,
  GenerateNumberField,
  GenerateFileButton,
  GenerateOptionsSelector,
  GenerateTimepicker,
  GenerateDatepicker,
  GenerateInputPicker,
} from "./components";

// eslint-disable-next-line
const FieldsMap = new Map<FieldItemType, FC<any>>([
  [FieldItemType.TEXT, GenerateTextField],
  [FieldItemType.NUMBER, GenerateNumberField],
  [FieldItemType.TEXTAREA, GenerateTextarea],
  [FieldItemType.PASSWORD, GeneratePasswordField],
  [FieldItemType.CHECKBOX, GenerateCheckbox],
  [FieldItemType.INPUT_PICKER, GenerateInputPicker],
  [FieldItemType.SELECT, GenerateSelect],
  [FieldItemType.MULTI_SELECT, GenerateMultiSelect],
  [FieldItemType.BLOCK, GenerateBlock],
  [FieldItemType.AUTOCOMPLETE_SELECT, GenerateSelectSearch],
  [FieldItemType.AUTOCOMPLETE_INPUT, GenerateAutocompleteInput],
  [FieldItemType.OPTIONS_SELECTOR, GenerateOptionsSelector],
  [FieldItemType.TIMEPICKER, GenerateTimepicker],
  [FieldItemType.DATEPICKER, GenerateDatepicker],
  [FieldItemType.FILE_BUTTON, GenerateFileButton],
]);

// eslint-disable-next-line
export type AnyFormikProps = FormikProps<any>;
type onChangeFieldType = (
  field: string,
  // eslint-disable-next-line
  value: any,
  // eslint-disable-next-line
  values: any,
  // eslint-disable-next-line
  setFieldValue?: (field: string, value: any) => void,
) => void;

interface FormGeneratorProps<T> extends Handlers {
  className?: string;
  fields: FieldItem[];
  formikProps: FormikProps<T>;
  children?: React.ReactNode;
  FieldsWrapper?: React.ElementType;
  firstChildren?: React.ReactNode;
  onChangeField?: onChangeFieldType;
}

const renderFields = <T,>(options: {
  field: RenderFieldType;
  index: number;
  formikProps: FormikProps<T>;
  formikFieldArrayHelpers?: FieldArrayRenderProps;
  handlers?: Handlers;
  onChangeField?: onChangeFieldType;
}) => {
  const { field, index, formikProps, formikFieldArrayHelpers, handlers, onChangeField } = options;
  switch (field.renderType) {
    case RenderTypes.FIELD_LIST_LINE: {
      return (
        <div className={`line ${field.wrapperClass}`} key={field.name}>
          {field.fields &&
            field.fields.map((f, i) => {
              const input = renderField({
                field: f,
                index,
                formikProps,
                formikFieldArrayHelpers,
                handlers,
                onChangeField,
              });
              return (
                <div className={`block ${f.wrapperClass}`} key={`line${f.name}${i}`}>
                  {input}
                </div>
              );
            })}
        </div>
      );
    }
    default:
      return null;
  }
};

const renderField = <T,>(options: {
  field: FieldItem;
  index: number;
  formikProps: FormikProps<T>;
  formikFieldArrayHelpers?: FieldArrayRenderProps;
  handlers?: Handlers;
  onChangeField?: onChangeFieldType;
}) => {
  const { field, index, formikProps, formikFieldArrayHelpers, handlers, onChangeField } = options;
  if (field.type === FieldItemType.RENDER) {
    return renderFields({ field, index, formikProps, handlers, onChangeField });
  }

  const FieldComponent = FieldsMap.get(field.type);

  if (!FieldComponent) {
    return null;
  }
  return (
    <FieldComponent
      key={`${field.name}${index}`}
      onChangeField={onChangeField}
      {...field}
      formikProps={formikProps}
      handlers={handlers}
      formikFieldArrayHelpers={formikFieldArrayHelpers}
    />
  );
};

const renderFieldsList = <T,>(options: {
  fieldItem: FieldItem;
  formikProps: AnyFormikProps;
  handlers?: Handlers;
  onChangeField?: onChangeFieldType;
}) => {
  const { fieldItem, formikProps, handlers, onChangeField } = options;

  const { name } = fieldItem;

  if (!formikProps.values[name]) {
    return null;
  }

  return (
    <FieldArray
      key={name}
      name={name}
      render={(formikFieldArrayHelpers) =>
        // eslint-disable-next-line
        formikProps.values[name].map((value: any, index: number) =>
          renderField<T>({
            field: { ...fieldItem, name: `${name}.${index}`, index },
            index,
            formikProps,
            formikFieldArrayHelpers,
            handlers,
            onChangeField,
          }),
        )
      }
    />
  );
};

const FormGenerator = <T,>(props: FormGeneratorProps<T>) => {
  const { formikProps, className, fields, children, FieldsWrapper, firstChildren, handlers, onChangeField } = props;
  const generateFields = useCallback(
    (field: FieldItem, index: number) => {
      if (field.isFieldsList) {
        return renderFieldsList<T>({ fieldItem: field, formikProps, handlers, onChangeField });
      } else {
        return renderField<T>({ field, index, formikProps, handlers, onChangeField });
      }
    },

    [formikProps, handlers, onChangeField],
  );
  const mappedFields = useMemo(() => {
    return fields.map((field, index) => generateFields(field, index));
  }, [fields, generateFields]);

  return (
    <Form onSubmit={formikProps.handleSubmit} className={className}>
      {firstChildren}
      {FieldsWrapper ? <FieldsWrapper>{mappedFields}</FieldsWrapper> : mappedFields}
      {children}
    </Form>
  );
};

export default FormGenerator;
