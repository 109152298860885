import { useEffect, useState, RefObject } from "react";
import { TypeAttributes } from "rsuite/cjs/@types/common";

type UsePlacement = (props: {
  ref: RefObject<HTMLDivElement>;
  dropDownHeight?: number;
}) => TypeAttributes.Placement | undefined;

export const usePlacement: UsePlacement = ({ ref, dropDownHeight }) => {
  const [placement, setPlacement] = useState<TypeAttributes.Placement>();

  useEffect(() => {
    const updatePlacement = () => {
      if (ref.current && dropDownHeight) {
        const threshold = dropDownHeight;
        const dropdownRect = ref.current.getBoundingClientRect();
        const spaceAtBottom = window.innerHeight - dropdownRect.bottom;

        setPlacement(spaceAtBottom > threshold ? "bottom" : "top");
      }
    };

    updatePlacement();

    window.addEventListener("resize", updatePlacement);
    // // Observe for layout changes
    const observer = new MutationObserver(updatePlacement);
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => {
      window.removeEventListener("resize", updatePlacement);
      observer.disconnect();
    };
  }, [placement, ref, dropDownHeight]);

  return placement;
};
