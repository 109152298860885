export enum CountyType {
  COUNTY = "county",
  MUNICIPIO = "municipio",
  MUNICIPALITY = "municipality",
  CITY = "city",
  PARISH = "parish",
  BOROUGH = "borough",
  CITY_BOROUGH = "city_borough",
  CENSUS_AREA = "census_area",
  ISLAND = "island",
  DISTRICT = "district",
}

export interface County {
  id: number;
  state_id: number;
  name: string;
  code: string;
  type: CountyType;
}
