import * as Yup from "yup";
import { actions as clientActions, selectors as clientSelectors } from "@containers/Contact/store";
import { County, State, Subscription } from "@shared/models";
import { ORDER_COLUMN_TYPE, ORDER_TYPE } from "@shared/interfaces";
import { getUniqueArray } from "@shared/utils";
import { FORM_ERROR_MESSAGES } from "@shared/constants";
import { UpdateSubscriptionInterface } from "@containers/Account/interface/UpdateSubscriptionInterface";

export interface SubscriptionFormShape {
  id: number;
  name: string;
  states: { label: string; value: string }[];
  counties: { label: string; value: string; state_id: number }[];
}

const optionValidationSchema = Yup.object().shape({
  value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const validationSchema = Yup.object().shape({
  states: Yup.array().of(optionValidationSchema),
  counties: Yup.array()
    .of(optionValidationSchema)
    .when("states", {
      is: (states: []) => !!states.length,
      then: Yup.array().of(optionValidationSchema).min(1, "Please select at least one County"),
    }),
});

export const getStatesInitValues = (deal: Subscription) => {
  const states = deal.counties.map((county) => ({
    label: county.state.name,
    value: String(county.state.id),
  }));
  return getUniqueArray(states, "value");
};

export const getCountiesInitValues = (deal: Subscription) => {
  return deal.counties.map((county) => ({
    label: county.name,
    value: String(county.id),
    state_id: county.state.id,
  }));
};

export const getInitValues = (deal: Subscription): SubscriptionFormShape => ({
  id: deal.id,
  name: deal.subscription_properties?.deal_name || "",
  states: getStatesInitValues(deal),
  counties: getCountiesInitValues(deal),
});

export const handlers = {
  states: {
    selectData: clientSelectors.getStates,
    loadingAction: clientActions.getStates,
    getOptions: clientSelectors.getStates,
    totalCount: clientSelectors.getStatesTotal,
    getAvailableStates: (states: { label: string; value: string }[]) => {
      return states.map((s) => Number(s.value)).join(",");
    },
    prepareOptionFunction: (state: State) => {
      return { label: state.name, value: String(state.id) };
    },
    getData: (search?: string, page?: number) => {
      return clientActions.getStates.request({
        page: page || 1,
        limit: 10,
        search: search || "",
        order: ORDER_COLUMN_TYPE.NAME,
        direction: ORDER_TYPE.ASC,
      });
    },
  },
  counties: {
    selectData: clientSelectors.getCounties,
    loadingAction: clientActions.getCounties,
    getAvailableCounties: (
      selectedStates: { label: string; value: string }[],
      values: SubscriptionFormShape,
    ): { label: string; value: string; state_id: number }[] => {
      const availableStates = selectedStates.map((s) => Number(s.value));
      return values.counties.filter((county) => availableStates.includes(county.state_id));
    },
    totalCount: clientSelectors.getCountiesTotal,
    prepareOptionFunction: (county: County) => {
      return { label: county.name, value: String(county.id), state_id: county.state_id };
    },
    getData: (search?: string, page?: number, additionalParam?: string) => {
      return clientActions.getCounties.request({
        page: page || 1,
        limit: 10,
        search: search || "",
        state_id: additionalParam?.split(",").map((v) => Number(v)),
        order: ORDER_COLUMN_TYPE.NAME,
        direction: ORDER_TYPE.ASC,
      });
    },
  },
};

export const prepareSubmitValue = (value: SubscriptionFormShape): Omit<UpdateSubscriptionInterface, "accountId"> => ({
  id: value.id,
  counties: value.counties.map((county) => Number(county.value)),
});
