import React, { FC } from "react";
import { Button, ButtonStyle } from "@shared/components";

import "./index.scss";

interface ModalConfirmProps {
  heading?: string;
  content?: string;
  onClose: () => void;
  onSuccess: () => void;
  cancelBtnText?: string;
  successBtnText?: string;
  successBtnStyle?: ButtonStyle;
}

const ModalConfirm: FC<ModalConfirmProps> = (props) => {
  const { heading, content, onClose, onSuccess, cancelBtnText, successBtnText, successBtnStyle } = props;

  return (
    <div className="modal-content-wrapper">
      <div className="modal-heading">{heading}</div>
      <div className="modal-content">{content}</div>
      <div className="modal-actions">
        <div className="modal-actions-btn-block">
          <Button buttonStyle="clear" onClick={onClose}>
            {cancelBtnText}
          </Button>
          <Button type="submit" buttonStyle={successBtnStyle || "clear"} onClick={onSuccess}>
            {successBtnText}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ModalConfirm;
