import * as Yup from "yup";
import codes from "country-calling-code";
import { CreateClientDto } from "@containers/Contact/interfaces";
import { Account, User } from "@shared/models";
import { getUniqueArray } from "@shared/utils";
import { FORM_ERROR_MESSAGES } from "@shared/constants";

export interface ClientFormShape {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  phone_country_code: string;
  sms_on_lead_creation: boolean;
  email_on_lead_creation: boolean;
  account: { label: string; value: string } | null;
  account_users?: { account_id: number; sms_on_lead_creation: boolean; email_on_lead_creation: boolean }[];
  states: { label: string; value: string }[];
}

const optionValidationSchema = Yup.object().shape({
  value: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  label: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(35, `First name ${FORM_ERROR_MESSAGES.LONG}`)
    .required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),
  last_name: Yup.string()
    .max(35, `Last name ${FORM_ERROR_MESSAGES.LONG}`)
    .required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),
  email: Yup.string()
    .email(FORM_ERROR_MESSAGES.EMAIL)
    .max(150, `Email ${FORM_ERROR_MESSAGES.LONG}`)
    .required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),
  email_on_lead_creation: Yup.boolean(),
  sms_on_lead_creation: Yup.boolean(),
  phone_number: Yup.string()
    .matches(/\(\d{3}\)\s\d{3}-\d{4}/, FORM_ERROR_MESSAGES.PHONE_NUMBER)
    .when("sms_on_lead_creation", {
      is: true,
      then: Yup.string().required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),
    }),
  states: Yup.array().of(optionValidationSchema),
  counties: Yup.array()
    .of(optionValidationSchema)
    .when("states", {
      is: (states: []) => !!states.length,
      then: Yup.array().of(optionValidationSchema).min(1, "Please select at least one County"),
    }),
  claritas_id: Yup.string().max(100, `Flikid ${FORM_ERROR_MESSAGES.LONG}`).nullable(),
  claritas_nickname: Yup.string().max(100, `Flikid Nickname ${FORM_ERROR_MESSAGES.LONG}`).nullable(),
  account: Yup.object()
    .shape({
      value: Yup.string().nullable(),
      label: Yup.string().nullable(),
    })
    .nullable(),
});

export const getStatesInitValues = (client: User | null) => {
  if (!client || !client.counties) return [];

  const states = client.counties.map((clientCounty) => ({
    label: clientCounty.state.name,
    value: String(clientCounty.state.id),
  }));
  return getUniqueArray(states, "value");
};

export const getCountiesInitValues = (client: User | null) => {
  if (!client || !client.counties) return [];

  return client.counties.map((clientCounty) => ({
    label: clientCounty.name,
    value: String(clientCounty.id),
    state_id: clientCounty.state.id,
  }));
};

export const getInitValues = (client: User | null, contact: Account | undefined) => {
  const acc = client?.accounts?.length ? client.accounts[0] : contact;
  const accUser = client?.account_users?.find((i) => i.account_id === acc?.id);

  return {
    first_name: client?.first_name || "",
    last_name: client?.last_name || "",
    email: client?.email || "",
    phone_number: client?.phone_number?.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "($1) $2-$3") || "",
    phone_country_code: client?.phone_country_code || codes.find((c) => c.isoCode2 === "US")?.countryCodes?.[0] || "",
    sms_on_lead_creation: !!accUser?.sms_on_lead_creation,
    email_on_lead_creation: !!accUser?.email_on_lead_creation,
    states: getStatesInitValues(client),
    account: client?.accounts?.length
      ? { label: client.accounts[0].name, value: String(client.accounts[0].id) }
      : contact
      ? {
          label: contact.name,
          value: String(contact.id),
        }
      : null,
  };
};

export const prepareSubmitValue = (value: ClientFormShape, client: User | null): Partial<CreateClientDto> => ({
  first_name: value.first_name,
  last_name: value.last_name,
  phone_number: value.phone_number?.replace(/[^\d]/g, ""),
  phone_country_code: value.phone_country_code,
  sms_on_lead_creation: !!value?.sms_on_lead_creation,
  email_on_lead_creation: !!value.email_on_lead_creation,
  ...(!client ? { email: value.email } : {}),
  account_id: value.account?.value ? Number(value.account.value) : null,
});
