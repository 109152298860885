import React, { FC } from "react";
import { InputPicker } from "@shared/components";
import { Tag, TagGroup } from "rsuite";
import "./index.scss";
import { DropDown, Tag as TagInterface } from "@shared/interfaces";

export type TaggedInputPickerProps = {
  title?: string;
  data: DropDown[];
  tags: TagInterface[];
  disabledValues?: string[];
  selectAll?: boolean;
  values: string[];
  onChange: (value: string) => void;
  onTagRemove: (tag: TagInterface["value"]) => void;
};

const TaggedInputPicker: FC<TaggedInputPickerProps> = (props) => {
  const { title, data, onChange, onTagRemove, tags, values, disabledValues, selectAll } = props;

  return (
    <div className="select-line">
      <div className="form-select">
        {title && <h5 className="input-title">{title}</h5>}
        <InputPicker
          cleanable={false}
          searchable={true}
          selectAll={selectAll}
          name="product-type"
          locale={{ noResultsText: "No matches" }}
          useContainer={true}
          disableContainerStyles={true}
          className="select-picker"
          data={data}
          disabledItemValues={disabledValues ?? []}
          placeholder="Select"
          onChange={onChange}
          value={values}
        />
      </div>
      <TagGroup>
        {tags.map((tag, idx) => (
          <Tag key={idx} closable className="preset-tags" onClose={() => onTagRemove(tag.value)}>
            {tag.name}
          </Tag>
        ))}
      </TagGroup>
    </div>
  );
};

export default TaggedInputPicker;
