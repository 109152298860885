import React, { useState } from "react";
import { Input, SelectPicker } from "rsuite";
import { FilterValue, PropertyFilterType, PropertyFilterTypeValue } from "@shared/interfaces/RecordManagement";
import { Option } from "@shared/interfaces";
import "./index.scss";
import { Button, InputPicker } from "@shared/components";
import { useListOptions } from "@containers/RecordManagement/components/FilterInputGroup/useListOptions.hook";
import CustomDatePicker from "@shared/components/Field/DatePicker/DatePicker";
import moment from "moment/moment";

type Props<T> = {
  label: string;
  field: T;
  fromOptions?: Option<string>[];
  toOptions?: Option<string>[];
  onChange?: (field: T, subField?: string) => (v: FilterValue) => void;
  onRemove?: () => void;
} & PropertyFilterTypeValue;

export function FilterInputGroup<T>({
  label,
  field,
  type,
  fromOptions,
  toOptions,
  filter,
  onChange,
  onRemove,
}: Props<T>) {
  const options = useListOptions({ field, type });
  const [search, setSearch] = useState("");

  return (
    <div className="input-group">
      <div className="input-header">
        <label>{label}</label>
        {!!onRemove && (
          <Button buttonStyle="form-cancel" onClick={onRemove}>
            Remove
          </Button>
        )}
      </div>

      {type === PropertyFilterType.MIN_MAX && (
        <div className="inline">
          <div className="textfield">
            <span>min</span>
            <Input type="number" min={0} name="min" value={filter?.min || ""} onChange={onChange?.(field, "min")} />
          </div>
          -
          <div className="textfield">
            <span>max</span>
            <Input type="number" min={0} name="max" value={filter?.max || ""} onChange={onChange?.(field, "max")} />
          </div>
        </div>
      )}

      {fromOptions && toOptions && type === PropertyFilterType.FROM_TO_PREDEFINED && (
        <div className="inline">
          <SelectPicker
            data={fromOptions}
            value={filter?.from}
            searchable={false}
            placeholder="From"
            onChange={onChange?.(field, "from")}
          />
          -
          <SelectPicker
            data={toOptions}
            value={filter?.to}
            searchable={false}
            placeholder="To"
            onChange={onChange?.(field, "to")}
          />
        </div>
      )}

      {type === PropertyFilterType.FROM_TO && (
        <div className="inline">
          <CustomDatePicker
            value={filter?.from ? moment(filter?.from) : null}
            onChange={(v) => onChange?.(field, "from")(v ? v.format("MM/DD/YYYY") : v)}
            label="from"
          />
          -
          <CustomDatePicker
            value={filter?.to ? moment(filter?.to) : null}
            onChange={(v) => onChange?.(field, "to")(v ? v.format("MM/DD/YYYY") : v)}
            label="to"
          />
        </div>
      )}

      {type === PropertyFilterType.TAGS && (
        <InputPicker
          searchable
          cleanable={false}
          name="list"
          useContainer
          disableContainerStyles
          tagged
          locale={{ noResultsText: "No matches" }}
          className="select-picker"
          onSearch={(s) => setSearch(s.trim().toLowerCase())}
          data={(search ? options.filter((s) => (s.name || s.fullName || s)?.toLowerCase().includes(search)) : options)
            .slice(0, 30)
            .map((i) => ({ value: i.id || i.fullName, label: i.name || i.fullName }))}
          disabledItemValues={filter}
          placeholder="Select"
          onChange={(v) => onChange?.(field)((filter || []).concat(v))}
          onClose={() => setSearch("")}
          value={filter}
          onTagRemove={(idx) => onChange?.(field)(filter?.filter((i, index) => index !== idx) || [])}
        />
      )}

      {type === PropertyFilterType.TAGS_FREE && (
        <InputPicker
          creatable
          cleanable={false}
          name="list"
          useContainer
          disableContainerStyles
          tagged
          locale={{ noResultsText: "Type to add new items", createOption: "Add" }}
          className="select-picker"
          data={[]}
          placeholder="Type to add new items"
          onChange={(v) => onChange?.(field)((filter || []).concat(v))}
          value={filter}
          onTagRemove={(idx) => onChange?.(field)(filter?.filter((i, index) => index !== idx) || [])}
        />
      )}
    </div>
  );
}
