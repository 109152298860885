import { Contacts } from "@shared/interfaces";
import { InvestorFileProcessingShort } from "@shared/models/InvestorFile";

export enum FilterConditionOperator {
  EQ = "eq",
  NOT_EQ = "notEq",
  LT = "lt",
  LTE = "lte",
  GT = "gt",
  GTE = "gte",
}

export enum QUERY_COMBINATOR {
  AND = "and",
  OR = "or",
}

export enum PRESET_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
  TESTING = "testing",
}

export interface PresetCondition {
  id?: number;
  property: string;
  operator: FilterConditionOperator;
  value: string | number | null;
}

export interface PresetConditionSet {
  id?: number;
  parent_id: number | null;
  conditions: PresetCondition[];
  conditionSets: [];
  operator: QUERY_COMBINATOR;
}

export interface FilterPreviewRecord {
  id: number;
  filter_id: number;
  dataflik_id: number;
  county: string | null;
  parcel_number: string | null;
  property_address: string | null;
  property_unit_prefix: string | null;
  property_unit: string | null;
  property_city: string | null;
  property_state: string | null;
  property_zip: string | null;
  year_built: number | null;
  property_sqft: number | null;
  bath_count: number | null;
  partial_bath_count: number | null;
  bedroom_count: number | null;
  room_count: number | null;
  story_count: number | null;
  unit_count: number | null;
  owner_occupied: string | null;
  property_use_group: string | null;
  neighborhood_l1: string | null;
  neighborhood_l2: string | null;
  neighborhood_l3: string | null;
  property_value: string | null;
  last_sale_date: string | null;
  last_sale_price: string | null;
  full_name: string | null;
  first_name: string | null;
  last_name: string | null;
  mailing_address: string | null;
  mailing_address_unit_prefix: string | null;
  mailing_address_unit: string | null;
  mailing_address_city: string | null;
  mailing_address_state: string | null;
  mailing_address_zip: string | null;
  property_type: string | null;
  exterior_type: string | null;
  structure_style: string | null;
  foreclosure: string | null;
  case_number: string | null;
  original_loan_amount: string | null;
  foreclosure_recording_date: string | null;
  auction_date: string | null;
  auction_time: string | null;
  auction_address: string | null;
  lender_name: string | null;
  recorded_auction_opening_bid: string | null;
  contacts: Contacts | null;
  combined_statistical_area: string | null;
  cbsa_name: string | null;
  msa_name: string | null;
  census_tract: number | null;
  property_zip4: string | null;
  legal_description: string | null;
  utilities_sewage_usage: string | null;
  utilities_water_source: string | null;
  foundation: string | null;
  construction: string | null;
  interior_structure: string | null;
  roof_material: string | null;
  model_score: number | null;
  date_added: string | null;
  first_name2: string | null;
  last_name2: string | null;
  mailing_address_zip4: string | null;
  full_name2: string | null;
}

export interface Preset {
  id: number;
  investor_data_settings_id: number;
  monthly_records_count: number | null;
  name: string;
  description: string | null;
  status: PRESET_STATUS;
  dnc_included: boolean;
  conditionSet: PresetConditionSet | null;
  previewRecords: FilterPreviewRecord[];
  estimated_records_count: number | null;
  processings?: InvestorFileProcessingShort[];
  sql_query: string | null;
}
