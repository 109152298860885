const IMPERSONATION_KEY = "impersonation";

interface ImpersonationData {
  impersonator: string;
  exitLocation?: string;
}

class ImpersonationStorage {
  isImpersonated(): boolean {
    return !!this.get();
  }

  set(item: ImpersonationData) {
    localStorage.setItem(IMPERSONATION_KEY, JSON.stringify(item));
  }

  get(): ImpersonationData | undefined {
    try {
      const data = localStorage.getItem(IMPERSONATION_KEY);

      if (data) {
        return JSON.parse(data);
      } else {
        return undefined;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return undefined;
    }
  }

  clear() {
    localStorage.removeItem(IMPERSONATION_KEY);
  }
}

export default new ImpersonationStorage();
