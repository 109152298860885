import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { SharedStateType, LoadingSections, OverlayComponent } from "../interfaces";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: SharedStateType = {
  user: null,
  loadingCount: 0,
  notification: null,
  modal: undefined,
  progressScreen: undefined,
  overlay: undefined,
  navigateURL: null,
  location: null,
  loadingSections: {},
  loadingTypes: [],
  propertyTypes: null,
  backgroundUploadWidgetVisibilityStatus: false,
  aws: {
    s3: {
      upload: {
        url: null,
        name: null,
        error: null,
      },
      multipleUploads: [],
    },
  },
  counties: null,
  states: null,
  zips: null,
  neighborhoods: null,
};

const reducer = createReducer<SharedStateType, Action>(initialState)
  .handleAction(actions.startLoading, (state) =>
    produce(state, (nextState) => {
      nextState.loadingCount++;
    }),
  )
  .handleAction(actions.stopLoading, (state) =>
    produce(state, (nextState) => {
      nextState.loadingCount = Math.max(state.loadingCount - 1, 0);
    }),
  )

  .handleAction(actions.showModal, (state, action) =>
    produce(state, (nexState) => {
      nexState.modal = action.payload;
    }),
  )
  .handleAction(actions.hideModal, (state) =>
    produce(state, (nexState) => {
      nexState.modal = undefined;
    }),
  )
  .handleAction(actions.updateOverlay, (state, action) =>
    produce(state, (nexState) => {
      if (state.overlay) {
        nexState.overlay = { ...nexState.overlay, ...action.payload } as OverlayComponent;
      }
    }),
  )
  .handleAction(actions.hideOverlay, (state) =>
    produce(state, (nexState) => {
      nexState.overlay = undefined;
    }),
  )
  .handleAction(actions.showOverlay, (state, action) =>
    produce(state, (nexState) => {
      nexState.overlay = action.payload;
    }),
  )
  .handleAction(actions.showProgressScreen, (state, action) =>
    produce(state, (nexState) => {
      nexState.progressScreen = { ...nexState.progressScreen, ...action.payload };
    }),
  )
  .handleAction(actions.hideProgressScreen, (state) =>
    produce(state, (nexState) => {
      nexState.progressScreen = undefined;
    }),
  )
  .handleAction(actions.showNotification, (state, action) =>
    produce(state, (nexState) => {
      nexState.notification = action.payload;
    }),
  )
  .handleAction(actions.navigate, (state, action) =>
    produce(state, (nexState) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nexState.navigateURL = ["number", "string"].includes(typeof action.payload)
        ? { to: action.payload }
        : action.payload;
    }),
  )
  .handleAction(actions.addLoadingType, (state, action) =>
    produce(state, (nextState) => {
      nextState.loadingTypes = [...state.loadingTypes, action.payload];
    }),
  )
  .handleAction(actions.removeLoadingType, (state, action) =>
    produce(state, (nextState) => {
      nextState.loadingTypes = state.loadingTypes.filter(({ name }) => name !== action.payload);
      nextState.loadingSections = {};
    }),
  )
  .handleAction(actions.addLoadingSection, (state, action) =>
    produce(state, (nextState) => {
      const loadingSections: LoadingSections = { ...state.loadingSections };

      if (!loadingSections[action.payload.loadingSection]) {
        loadingSections[action.payload.loadingSection] = [];
      }

      loadingSections[action.payload.loadingSection] = [
        ...Array.from(new Set([...loadingSections[action.payload.loadingSection], action.payload.requestName])),
      ];

      nextState.loadingSections = loadingSections;
    }),
  )
  .handleAction(actions.removeLoadingSection, (state, action) =>
    produce(state, (nextState) => {
      const loadingSections = { ...state.loadingSections };

      if (loadingSections[action.payload.loadingSection]) {
        loadingSections[action.payload.loadingSection] = loadingSections[action.payload.loadingSection].filter(
          (loadingRequest) => loadingRequest !== action.payload.requestName,
        );

        if (!loadingSections[action.payload.loadingSection].length) {
          delete loadingSections[action.payload.loadingSection];
        }

        nextState.loadingSections = loadingSections;
      }
    }),
  )
  .handleAction(actions.uploadToS3.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.aws.s3.upload.url = action.payload.url;
      nextState.aws.s3.upload.name = action.payload.name;
      nextState.aws.s3.upload.error = null;
    }),
  )
  .handleAction(actions.uploadToS3.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.aws.s3.upload.error = action.payload;
      nextState.aws.s3.upload.url = null;
      nextState.aws.s3.upload.name = null;
    }),
  )
  .handleAction(actions.getUserDetails.success, (state, action) =>
    produce(state, (nexState) => {
      nexState.user = action.payload;
    }),
  )
  .handleAction(actions.clearUploadedFile, (state) =>
    produce(state, (nextState) => {
      nextState.aws = initialState.aws;
    }),
  )
  .handleAction(actions.showBackgroundFilesUploadWidget, (state) =>
    produce(state, (nextState) => {
      nextState.backgroundUploadWidgetVisibilityStatus = true;
    }),
  )
  .handleAction(actions.hideBackgroundFilesUploadWidget, (state) =>
    produce(state, (nextState) => {
      nextState.backgroundUploadWidgetVisibilityStatus = false;
    }),
  )
  .handleAction(actions.clearMultipleUploads, (state) =>
    produce(state, (nextState) => {
      nextState.aws.s3.multipleUploads = [];
    }),
  )
  .handleAction(actions.uploadToS3Multiple.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.aws.s3.multipleUploads = action.payload;
    }),
  )
  .handleAction(actions.uploadToS3MultipleItem.success, (state, action) =>
    produce(state, (nextState) => {
      const { index, data } = action.payload;
      if (nextState.aws.s3.multipleUploads[index]) {
        nextState.aws.s3.multipleUploads[index] = { ...nextState.aws.s3.multipleUploads[index], ...data };
      }
    }),
  )
  .handleAction(actions.uploadToS3MultipleItem.failure, (state, action) =>
    produce(state, (nextState) => {
      const { index, error } = action.payload;
      if (nextState.aws.s3.multipleUploads[index]) {
        nextState.aws.s3.multipleUploads[index].error = error;
      }
    }),
  )
  .handleAction(actions.uploadToS3MultipleItem.cancel, (state, action) =>
    produce(state, (nextState) => {
      if (nextState.aws.s3.multipleUploads[action.payload]) {
        nextState.aws.s3.multipleUploads[action.payload].uploadObj.abort();
        nextState.aws.s3.multipleUploads[action.payload].progress = null;
      }
    }),
  )
  .handleAction(actions.getStates.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.states = action.payload;
    }),
  )
  .handleAction(actions.getPropertyTypes.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.propertyTypes = action.payload;
    }),
  )
  .handleAction(actions.getCountiesByState.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.counties = action.payload;
    }),
  )
  .handleAction(actions.getZips.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.zips = action.payload;
    }),
  )
  .handleAction(actions.getNeighborhoods.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.neighborhoods = action.payload;
    }),
  );

export { reducer as SharedReducer };
