import React from "react";
import { Button, Icon } from "@shared/components";
import { Parallelograms } from "@containers/SkipTracing/components";

import "./index.scss";

interface WizardProps {
  steps: string[];
  activeStep: number;
  onBack: () => void;
  sideBarTitle: string;
  title: string;
  subTitle: React.ReactNode;
  infoText: React.ReactNode;
}

const Wizard: React.FC<React.PropsWithChildren<WizardProps>> = ({
  steps,
  activeStep,
  onBack,
  sideBarTitle,
  title,
  subTitle,
  infoText,
  children,
}) => {
  return (
    <div className="wizard-container">
      <div className="wizard-sidebar">
        <div className="wizard-sidebar-header">
          <Button onClick={onBack}>
            <Icon type="chevron-left" />
            Back
          </Button>
        </div>
        <div className="wizard-sidebar-menu-container">
          <div className="wizard-sidebar-menu">
            <div className="wizard-sidebar-menu-title">{sideBarTitle}</div>
            <div className="wizard-sidebar-menu-items">
              {steps.map((step, i) => (
                <div key={i} className={`wizard-sidebar-menu-item ${activeStep === i ? "active" : ""}`}>
                  <span className="sequence-number">{i + 1}.</span>
                  <span>{step}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="wizard-content-container">
        <Parallelograms />
        <div className="wizard-content">
          <div className="wizard-content-header">
            <div className="wizard-content-header-title">{title}</div>
            <div className="wizard-content-header-subtitle">{subTitle}</div>
          </div>
          <div className="wizard-content-main">{children}</div>
          <div className="wizard-content-info">{infoText}</div>
        </div>
      </div>
    </div>
  );
};

export default Wizard;
