import { Button, Icon } from "@shared/components";
import React from "react";

import { Attempts, PropertyAttemptType } from "./PropertyAttempts.types";

const LABELS: Record<PropertyAttemptType, string> = {
  [PropertyAttemptType.Call]: "Call",
  [PropertyAttemptType.Mail]: "Mail",
  [PropertyAttemptType.Message]: "Message",
};

interface Props {
  type: PropertyAttemptType;
  attempts: Attempts;
  updateCounter: (key: PropertyAttemptType, dir: number) => void;
}

export const PropertyAttemptItem = ({ type, attempts, updateCounter }: Props) => {
  return (
    <div className="item">
      <Icon type={type} /> {LABELS[type]}
      <div className="counter">
        <Button onClick={() => updateCounter(type, -1)}>
          <Icon type="minus-grey" />
        </Button>
        {attempts[type]}
        <Button onClick={() => updateCounter(type, 1)}>
          <Icon type="plus-grey" />
        </Button>
      </div>
    </div>
  );
};
