import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ErrorMessage, InputPicker } from "@shared/components";
import { actions as AccountActions, selectors as AccountSelectors } from "@containers/Account/store";
import { allActions as RecordManagementActions } from "@containers/RecordManagement/store";
import { Option } from "@shared/interfaces";
import * as sharedActions from "@shared/store/actions";
import "./index.scss";
import { ProgressScreenStatus } from "@containers/ProgressScreen";
import classnames from "classnames";

import { store } from "../../../index";

interface Props {
  heading?: string;
  data?: {
    id?: number;
    fileId?: number;
    recordsCount?: number | null;
    propertyId?: number;
    onFinished?: () => void;
  };
  onClose: () => void;
}

const INPUT_MAX_LENGTH = 50;

const sortLists = (lists: Option<number>[]) => {
  return lists.sort((a, b) => (a.label as string).localeCompare(b.label as string));
};

const ModalCreatePropertyList: FC<Props> = (props) => {
  const { onClose, data } = props;

  const hasRendered = useRef(false);
  const dispatch = useDispatch();
  const account = useSelector(AccountSelectors.getActiveAccount());
  const accountLists = useSelector(AccountSelectors.getActiveAccountPropertyLists());
  const accountList = useSelector(AccountSelectors.getActiveAccountPropertyList());

  const [lists, setLists] = useState<Option<number>[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [search, setSearch] = useState<string>("");
  const hasError = search.length > INPUT_MAX_LENGTH;

  const handleAdd = () => {
    if (account?.id) {
      dispatch(sharedActions.hideModal());

      if (data?.id && data?.fileId) {
        // process monthly file
        dispatch(
          sharedActions.showProgressScreen({
            title: "Processing Your File",
            subtitle:
              "Please wait for us to take a quick look to your file and mapping so we can generate the best matching results.",
            status: ProgressScreenStatus.Processing,
            totalSteps: 2,
          }),
        );

        dispatch(
          AccountActions.linkAccountPropertyListToPropertiesFromFile.request({
            id: account.id,
            investorFileId: data.id,
            listIds: selected,
            fileId: data.fileId,
            callback: () => {
              dispatch(
                sharedActions.showProgressScreen({
                  status: ProgressScreenStatus.Completed,
                  currentStep: 2,
                }),
              );

              data?.onFinished?.();
            },
          }),
        );
      } else if (data?.propertyId) {
        // process one property
        dispatch(
          AccountActions.linkAccountPropertyListToProperty.request({
            id: account.id,
            listIds: selected,
            propertyId: data.propertyId,
            callback: () => {
              store.dispatch(
                sharedActions.showNotification({
                  message: "Property has been added to the selected lists!",
                  appearance: "success",
                }),
              );

              store.dispatch(
                RecordManagementActions.linkProperty(
                  selected.map((id) => ({
                    id,
                    name: lists.find((i) => i.value === id)?.label as string,
                    account_id: account.id,
                  })),
                ),
              );
            },
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (account?.id) {
      if (accountLists?.length) {
        if (!lists.length) {
          setLists(sortLists(accountLists.map((i) => ({ label: i.name, value: i.id }))));
        }

        // select newly added list
        if (accountList && !lists.find((i) => i.value === accountList.id)) {
          setLists((s) => sortLists(s.concat({ label: accountList.name, value: accountList.id })));
          setSelected((s) => [...s, accountList.id]);

          dispatch(AccountActions.clearAccountPropertyList());
        }
      } else if (!hasRendered.current) {
        hasRendered.current = true;
        dispatch(AccountActions.getAccountPropertyLists.request({ id: account.id }));
      }
    }
    return () => {
      dispatch(AccountActions.clearAllAccountPropertyLists());
    };
  }, [dispatch, setLists, account?.id, accountLists, accountList, lists]);

  return (
    <div className="create-property-list-modal-content-wrapper">
      <div className="create-property-list-modal-heading">Add to List</div>
      <div className="create-property-list-modal-content">
        {data?.id ? (
          <>Add {data?.recordsCount} selected properties to the list.</>
        ) : (
          <>Choose the lists you want to add the selected property to.</>
        )}
      </div>
      <div
        className={classnames("create-property-list-modal-select", {
          "create-property-list-modal-select-error": hasError,
        })}
      >
        <ErrorMessage isTouched={hasError} error={`List name must be under ${INPUT_MAX_LENGTH} chars`} />
        <InputPicker
          creatable
          searchable
          cleanable={false}
          name="list"
          useContainer={true}
          disableContainerStyles={true}
          locale={{ noResultsText: "No matches" }}
          className="select-picker"
          data={lists}
          disabledItemValues={selected}
          placeholder="Enter list name"
          onSearch={(query) => setSearch(query)}
          onExit={() => setSearch("")}
          onChange={(v) => {
            // skip creation (comes as string)
            if (typeof v === "number") {
              setSelected((s) => [...s, v]);
            }
          }}
          renderMenuItem={(label, item) => {
            if (selected.includes(item.value as number)) {
              return false;
            }

            item.label = item.label?.toString().trim().slice(0, INPUT_MAX_LENGTH);

            if (!item.create) return label;

            if (!(item.label as string).length || lists.some((l) => l.label === item.label)) {
              return false;
            }

            if (hasError) {
              return false;
            }

            return (
              <div className="create-list-item">
                <span>{item.label}</span> <a>Create List</a>
              </div>
            );
          }}
          onCreate={(v) => {
            return (
              !hasError &&
              account?.id &&
              dispatch(
                AccountActions.createAccountPropertyList.request({
                  id: account.id,
                  name: v.slice(0, INPUT_MAX_LENGTH),
                }),
              )
            );
          }}
          value={selected}
          tagged
          onTagRemove={(idx) => setSelected((s) => s.filter((i, index) => index !== idx))}
        />
      </div>
      <div className="create-property-list-modal-actions">
        <div className="create-property-list-modal-actions-btn-block">
          <Button className="create-property-list-modal-close-btn" buttonStyle="blue-bordered" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="create-property-list-modal-success-btn"
            type="submit"
            buttonStyle="accent-green"
            disabled={!selected.length}
            onClick={handleAdd}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ModalCreatePropertyList;
