import React from "react";
import type { FC, ReactNode } from "react";
import { Navigate, useLocation, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authentificated } from "@containers/Auth/store/selectors";
import { setRedirectTo } from "@containers/Auth/store/actions";

import { NameOfChildRoutes, NameOfRoutes, NamesOfParentRoutes } from "../constants";

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const isAuthenticated = useSelector(authentificated());
  const location = useLocation();
  const dispatch = useDispatch();

  const fileDownloadRouteMatch = useMatch(`${NameOfRoutes.FILE}${NameOfChildRoutes.FILE.DOWNLOAD}`);

  if (!isAuthenticated) {
    dispatch(setRedirectTo(fileDownloadRouteMatch ? `${location.pathname}${location.search}` : null));
    return <Navigate to={{ pathname: NamesOfParentRoutes.AUTH, search: location.search }} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
