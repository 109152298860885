import React, { useEffect, useState } from "react";
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { ErrorMessage } from "@shared/components";

import "./styles.scss";

const getFriendlySize = (bytes: number) => {
  const measures = ["B", "KB", "MB", "GB"];
  let i = 0;
  let value = bytes;

  while (value >= 1024 && i <= 3) {
    value = value / 1024;
    i += 1;
  }
  return `${Math.round(value)} ${measures[i]}`;
};

type Props = DropzoneOptions & {
  className?: string;
  error?: string;
  openDialog?: boolean;
  onError?: (fileRejections: FileRejection[]) => void;
};

const UploadDropzone: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  error,
  openDialog,
  onError,
  ...rest
}) => {
  const [internalError, setInternalError] = useState("");
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    if (fileRejections.length > 0) {
      if (onError) {
        onError(fileRejections);
      } else {
        const firstError = fileRejections[0].errors[0];
        setInternalError(
          firstError.code === "file-too-large" && rest.maxSize
            ? `File is larger than ${getFriendlySize(rest.maxSize)}`
            : firstError.message,
        );
      }
    } else {
      setInternalError("");
      if (rest.onDrop) {
        rest.onDrop(acceptedFiles, fileRejections, event);
      }
    }
  };
  const { getRootProps, getInputProps, isDragActive, open, isFileDialogActive } = useDropzone({ ...rest, onDrop });

  useEffect(() => {
    if (openDialog && !isFileDialogActive) {
      open();
    }
  }, [isFileDialogActive, open, openDialog]);

  return (
    <div className={`upload-dropzone ${className || ""} ${isDragActive ? "active" : ""}`}>
      <div {...getRootProps()} className="presentation">
        <input {...getInputProps()} />
        {children}
      </div>
      <ErrorMessage isTouched={!!(internalError || error)} error={internalError || error} />
    </div>
  );
};

export default UploadDropzone;
