import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { SkipTracingFilter } from "@shared/interfaces";
import { CreateSkipTracingDto, ProcessSkipTracingDto, UpdateSkipTracingDto } from "@containers/SkipTracing/interfaces";

export default {
  getSkipTracing: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.GET, API.SKIP_TRACING.GET_SKIP_TRACING(String(id)))();
  },

  getSkipTracingList: (payload: SkipTracingFilter) =>
    request(METHODS.GET, API.SKIP_TRACING.GET_SKIP_TRACING_LIST)({ params: payload }),

  createSkipTracing: (payload: CreateSkipTracingDto) =>
    request(METHODS.POST, API.SKIP_TRACING.CREATE_SKIP_TRACING)(payload),

  updateSkipTracing: (payload: UpdateSkipTracingDto & { id: number }) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.SKIP_TRACING.UPDATE_SKIP_TRACING(String(id)))(rest);
  },
  deleteSkipTracing: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.DELETE, API.SKIP_TRACING.DELETE_SKIP_TRACING(String(id)))();
  },
  testSkipTracing: (payload: { id: number }) => {
    const { id } = payload;
    return request(METHODS.POST, API.SKIP_TRACING.TEST_SKIP_TRACING(String(id)))();
  },
  processSkipTracing: ({ id, ...rest }: ProcessSkipTracingDto) =>
    request(METHODS.POST, API.SKIP_TRACING.PROCESS_SKIP_TRACING(id))(rest),
  payForSkipTracing: (id: number, coupon?: string) =>
    request(METHODS.POST, API.SKIP_TRACING.PAY_FOR_SKIP_TRACING(id))({ coupon }),
  getPriceRange: (id: number) => request(METHODS.GET, API.SKIP_TRACING.GET_PRICE_RANGE(id))(),
  checkStripeCoupon: (payload: { id: number; coupon: string }) => {
    const { id, coupon } = payload;
    return request(METHODS.GET, API.SKIP_TRACING.CHECK_STRIPE_COUPON(id, coupon))();
  },
};
