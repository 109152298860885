import { Icon } from "@shared/components";
import React, { FC, PropsWithChildren, useRef, useState } from "react";

import "./index.scss";

export type AccordionProps = {
  title: string;
  defaultExpanded?: boolean;
};
let timeoutID: NodeJS.Timeout | null = null;

const Accordion: FC<PropsWithChildren<AccordionProps>> = (props) => {
  const { title, children, defaultExpanded = true } = props;

  const [isOpen, setIsOpen] = useState<boolean>(defaultExpanded);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const openAccordion = () => {
    setIsOpen((prev) => !prev);
    timeoutID && clearTimeout(timeoutID);
    if (isOpen)
      timeoutID = setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.classList.toggle("visible");
        }
      }, 100);
  };

  return (
    <div className={`accordion-container ${isOpen && "open"} visible`} ref={containerRef}>
      <div className="accordion-head" onClick={openAccordion}>
        <h4 className="accordion-title">{title}</h4>
        <Icon type="chevron-bottom-gray" className={`accordion-chevron`} />
      </div>
      <div className={`accordion-content`} ref={contentRef}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
