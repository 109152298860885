import "./index.scss";
import { Button } from "@shared/components";
import React, { useMemo, useState } from "react";
import { Divider } from "rsuite";
import { OwnersFilters, PropertyFilterType } from "@shared/interfaces/RecordManagement";
import { getUSFormattedDateShortYear } from "@shared/utils";
import moment from "moment";
import { Option } from "@shared/interfaces";
import { FilterInputGroup } from "@containers/RecordManagement/components";
import { ownersFiltersLabels } from "@containers/RecordManagement/containers/FilterOwnersContainer/owners-filters-labels";

interface Props {
  onClose: () => void;
  data?: {
    filters: OwnersFilters | null;
  };
  callback?: <T>(e?: T) => void;
}

export const FilterOwnersContainer = ({ data, onClose, callback }: Props) => {
  const [state, setState] = useState(data?.filters);

  const clearAll = () => setState(null);

  const handleChange = (field: keyof OwnersFilters, subField?: string) => (val: string | number | number[] | null) => {
    setState((s) => ({ ...s, [field]: subField ? { ...s?.[field], [subField]: val } : {} }));
  };

  const { monthsFrom, monthsTo } = useMemo(() => {
    const months = [...Array(100)].map((_, v) => moment().subtract(v, "month"));

    return months.reduce(
      (acc, i) => {
        const label = getUSFormattedDateShortYear(i.toDate(), "", true);

        acc.monthsFrom.push({
          value: i.startOf("month").format("MM-DD-YYYY HH:mm:ss"),
          label,
        });
        acc.monthsTo.push({
          value: i.endOf("month").format("MM-DD-YYYY HH:mm:ss"),
          label,
        });

        return acc;
      },
      { monthsFrom: [], monthsTo: [] } as { monthsFrom: Option<string>[]; monthsTo: Option<string>[] },
    );
  }, []);

  const apply = () => {
    onClose();
    callback?.(state);
  };

  return (
    <div className="filter-owners">
      <div className="header">
        <h3>Filter Owners</h3>

        <Button type="button" buttonStyle="form-cancel" onClick={clearAll}>
          Clear All
        </Button>
      </div>

      <div className="content">
        <Divider style={{ marginTop: 0 }} />
        <div className="form-group">
          <h4>Property</h4>

          <FilterInputGroup
            label={ownersFiltersLabels.propertyQty}
            field="propertyQty"
            type={PropertyFilterType.MIN_MAX}
            filter={state?.propertyQty}
            onChange={handleChange}
          />
        </div>

        <Divider />
        <div className="form-group">
          <h4>Owner</h4>

          <FilterInputGroup
            label={ownersFiltersLabels.phoneQty}
            field="phoneQty"
            type={PropertyFilterType.MIN_MAX}
            filter={state?.phoneQty}
            onChange={handleChange}
          />
          <FilterInputGroup
            label={ownersFiltersLabels.emailQty}
            field="emailQty"
            type={PropertyFilterType.MIN_MAX}
            filter={state?.emailQty}
            onChange={handleChange}
          />

          <FilterInputGroup
            label={ownersFiltersLabels.lastUpdateDate}
            field="lastUpdateDate"
            filter={state?.lastUpdateDate}
            type={PropertyFilterType.FROM_TO_PREDEFINED}
            fromOptions={monthsFrom}
            toOptions={monthsTo}
            onChange={handleChange}
          />
        </div>

        <Divider />
        <div className="form-group">
          <h4>Marketing</h4>

          <FilterInputGroup
            label={ownersFiltersLabels.mailAttemptsQty}
            field="mailAttemptsQty"
            type={PropertyFilterType.MIN_MAX}
            filter={state?.mailAttemptsQty}
            onChange={handleChange}
          />
          <FilterInputGroup
            label={ownersFiltersLabels.callAttemptsQty}
            field="callAttemptsQty"
            type={PropertyFilterType.MIN_MAX}
            filter={state?.callAttemptsQty}
            onChange={handleChange}
          />
          <FilterInputGroup
            label={ownersFiltersLabels.smsAttemptsQty}
            field="smsAttemptsQty"
            type={PropertyFilterType.MIN_MAX}
            filter={state?.smsAttemptsQty}
            onChange={handleChange}
          />
        </div>

        <Divider style={{ marginBottom: 0 }} />
      </div>
      <div className="footer">
        <Button type="button" buttonStyle="form-cancel" onClick={onClose}>
          Cancel
        </Button>

        <Button type="button" buttonStyle="accent-green" onClick={apply}>
          Apply
        </Button>
      </div>
    </div>
  );
};
