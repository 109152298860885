import { Feature } from "@shared/models";
import { useSelector } from "react-redux";
import { getFeatures } from "@containers/Account/store/selectors";

export const useFeatureEnabledCheck = (feature?: Feature) => {
  const accountFeatures: Feature[] = useSelector(getFeatures());

  if (!feature) {
    return true;
  }

  return accountFeatures.includes(feature);
};
