import config from "../../../../config";

export const getLabelText = (value: string): string => {
  const label = Number(value);

  return ["uat", "production"].includes(config.env) ? getForProduction(label) : getForDevelopment(label);
};

function getForProduction(label: number) {
  switch (label) {
    case 112889742:
    case 135818507:
      return "New";
    case 125156511:
    case 135818509:
      return "Customer Replied";
    case 203344050:
    case 203285429:
      return "Data Team Update Completed";
    case 203416369:
    case 137879629:
      return "Escalated to Data Team";
    case 127820974:
      return "Pause or Cancel Request";
    case 128339018:
      return "Escalated to CSM";
    case 116942890:
    case 135818511:
      return "Waiting on Customer";
    case 112889745:
    case 135818510:
      return "Resolved";
    case 112889744:
      return "Unresolved";
    case 140396903:
      return "Sent CSM Meeting Link";
    case 212916850:
      return "Meeting Booked";
    default:
      return `${label}`;
  }
}

function getForDevelopment(label: number) {
  switch (label) {
    case 1:
    case 205891875:
      return "New";
    case 2:
    case 205891876:
      return "Waiting on contact";
    case 3:
    case 205891877:
      return "Waiting on us";
    case 4:
    case 205891878:
      return "Closed";
    case 205960595:
    case 205983582:
      return "Escalated to Data Team";
    case 205960596:
    case 205983583:
      return "Data Team Update Completed";
    default:
      return `${label}`;
  }
}
