import PointerInteraction from "ol/interaction/Pointer";
import { MapBrowserEvent } from "ol";
import { Neighborhood } from "@shared/components/MapContainer/OLMap/features/Neighborhood";

export class NeighborhoodCursorHover extends PointerInteraction {
  constructor() {
    super({
      handleMoveEvent: (event) => this.onMouseMove(event),
    });
  }

  onMouseMove(event: MapBrowserEvent<UIEvent>) {
    if (event.dragging) return;

    const targetElement = event.map.getTargetElement();
    const features = event.map.getFeaturesAtPixel(event.pixel).filter((f) => f instanceof Neighborhood);

    if (features.length > 0) {
      targetElement.style.cursor = "pointer";
    } else {
      targetElement.style.cursor = "";
    }
  }
}
