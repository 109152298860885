import React, { FC } from "react";

import "./index.scss";

interface SkipTracingProcessingTitleBlockProps {
  title: string;
  subtitle?: string;
}

const SkipTracingProcessingTitleBlock: FC<SkipTracingProcessingTitleBlockProps> = (props) => {
  const { title, subtitle } = props;
  return (
    <div className="file-mapping-title-block-wrapper">
      <div className="title">{title}</div>
      {subtitle && <div className="subtitle">{subtitle}</div>}
    </div>
  );
};

export default SkipTracingProcessingTitleBlock;
