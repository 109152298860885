import { INVESTOR_FILE_STATUS, SKIP_TRACING_STATUS } from "@shared/models";

export enum ORDER_TYPE {
  ASC = "asc",
  DESC = "desc",
  NONE = "",
}

export enum ORDER_COLUMN_TYPE {
  CREATED_AT = "created_at",
  FIRST_NAME = "first_name",
  NAME = "name",
  CRITERIA_V2 = "criteria_v2",
  MODEL_SCORE = "model_score",
  RESOLVED_DATE = "resolved_date",
}

export interface BaseFilter {
  page: number;
  limit: number;
  search?: string | null;
  order?: ORDER_COLUMN_TYPE;
  direction?: ORDER_TYPE;
}

export interface SkipTracingFilter extends BaseFilter {
  account_id: number | null;
  status?: SKIP_TRACING_STATUS;
}

export interface InvestorFileFilter extends BaseFilter {
  account_id: number[] | null;
  status?: INVESTOR_FILE_STATUS;
}

export interface CountiesFilter extends BaseFilter {
  state_id?: number[];
}

export interface AccountsFilter extends BaseFilter {
  only_hubspot_companies?: boolean;
  status?: string;
}

export interface HubspotPropertiesHistoryFilter extends BaseFilter {
  account_id?: number | null;
}

export interface PayPerLeadFilter extends BaseFilter {
  stateId?: number;
  countyId?: number;
  monthOffset?: number;
}

export interface PayPerLeadStatisticsFilter {
  account_id?: number;
  state_id?: number;
  county_id?: number;
  status?: string;
  month?: string;
}

export interface GetRolesFilter {
  is_app_admin: boolean;
}

export interface CodeWithId<T = null> {
  code: string;
  id?: number | string;
  payload?: T;
}

export interface PayloadWithId<T = null> {
  id: number | string;
  payload: T;
}

export interface PayloadWithCode<T = null> {
  code: string;
  payload?: T;
}

export interface IdWithOptionalCode<T = null> {
  id: number | string;
  code?: string | null;
  payload: T;
}

export interface MinMax {
  min?: number;
  max?: number;
}

export interface FromTo {
  from?: string | null;
  to?: string | null;
}
