import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { UpdateTicketRequest } from "../interface";
import { actions } from "../store";

export const useUpdateTicket = () => {
  const dispatch = useDispatch();

  return useCallback(
    (params: UpdateTicketRequest) => {
      dispatch(actions.updateTicket.request(params));
    },
    [dispatch],
  );
};
