import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { ORDER_COLUMN_TYPE, ORDER_TYPE, SkipTracingFilter } from "@shared/interfaces";
import { SkipTracingError, SkipTracingStateType } from "@containers/SkipTracing/interfaces";

import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultFilter: SkipTracingFilter = {
  account_id: null,
  page: 1,
  limit: 50,
  search: null,
  order: ORDER_COLUMN_TYPE.CREATED_AT,
  direction: ORDER_TYPE.DESC,
};

export const initialState: SkipTracingStateType = {
  skipTracing: null,
  skipTracingList: [],
  skipTracingTotal: 0,
  filter: { ...defaultFilter },
  paymentProcessed: null,
  priceRange: null,
  coupon: null,
  errors: {
    [SkipTracingError.createSkipTracingError]: null,
    [SkipTracingError.getSkipTracingError]: null,
    [SkipTracingError.getSkipTracingListError]: null,
    [SkipTracingError.deleteSkipTracingError]: null,
    [SkipTracingError.updateSkipTracingError]: null,
    [SkipTracingError.processSkipTracingError]: null,
    [SkipTracingError.payForSkipTracingError]: null,
    [SkipTracingError.getPriceRangeError]: null,
    [SkipTracingError.checkStripeCouponError]: null,
  },
};

const reducer = createReducer<SkipTracingStateType, Action>(initialState)
  .handleAction(actions.getSkipTracingList.success, (state, action) =>
    produce(state, (nextState) => {
      const { items, total, clear } = action.payload;
      nextState.skipTracingList = !clear ? [...nextState.skipTracingList, ...items] : [...items];
      nextState.skipTracingTotal = total;
    }),
  )
  .handleAction(actions.getSkipTracingList.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[SkipTracingError.getSkipTracingListError] = action.payload;
    }),
  )
  .handleAction(actions.getSkipTracing.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.skipTracing = action.payload;
    }),
  )
  .handleAction(actions.getSkipTracing.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[SkipTracingError.getSkipTracingError] = action.payload;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )

  .handleAction(actions.clearSkipTracingList, (state) =>
    produce(state, (nextState) => {
      nextState.skipTracingList = [];
      nextState.skipTracingTotal = 0;
    }),
  )

  .handleAction(actions.clearSkipTracing, (state) =>
    produce(state, (nextState) => {
      nextState.skipTracing = null;
    }),
  )

  .handleAction(actions.createSkipTracing.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.skipTracing = action.payload;
    }),
  )
  .handleAction(actions.createSkipTracing.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[SkipTracingError.createSkipTracingError] = action.payload;
    }),
  )
  .handleAction(actions.updateSkipTracing.success, (state, action) =>
    produce(state, (nextState) => {
      const updatedSkipTracing = action.payload;
      const skipTracingList = nextState.skipTracingList;

      const foundPatientIndex = skipTracingList.findIndex((p) => updatedSkipTracing.id === p.id);
      skipTracingList[foundPatientIndex] = updatedSkipTracing;

      nextState.skipTracingList = [...skipTracingList];

      if (state.skipTracing && state.skipTracing.id === updatedSkipTracing.id) {
        nextState.skipTracing = updatedSkipTracing;
      }
    }),
  )
  .handleAction(actions.updateSkipTracing.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[SkipTracingError.updateSkipTracingError] = action.payload;
    }),
  )
  .handleAction(actions.deleteSkipTracing.success, (state) =>
    produce(state, (nextState) => {
      nextState.skipTracing = null;
    }),
  )
  .handleAction(actions.deleteSkipTracing.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[SkipTracingError.deleteSkipTracingError] = action.payload;
    }),
  )
  .handleAction(actions.processSkipTracing.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.skipTracing = action.payload;
      nextState.errors[SkipTracingError.processSkipTracingError] = null;
    }),
  )
  .handleAction(actions.processSkipTracing.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[SkipTracingError.processSkipTracingError] = action.payload;
    }),
  )
  .handleAction(actions.payForSkipTracing.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.paymentProcessed = action.payload;
      nextState.errors[SkipTracingError.payForSkipTracingError] = null;
    }),
  )
  .handleAction(actions.payForSkipTracing.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[SkipTracingError.payForSkipTracingError] = action.payload;
    }),
  )
  .handleAction(actions.getPriceRange.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.priceRange = action.payload;
      nextState.errors[SkipTracingError.getPriceRangeError] = null;
    }),
  )
  .handleAction(actions.getPriceRange.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[SkipTracingError.getPriceRangeError] = action.payload;
    }),
  )
  .handleAction(actions.checkStripeCoupon.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.coupon = action.payload;
      nextState.errors[SkipTracingError.checkStripeCouponError] = null;
    }),
  )
  .handleAction(actions.checkStripeCoupon.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[SkipTracingError.checkStripeCouponError] = action.payload;
    }),
  )
  .handleAction(actions.clearStripeCoupon, (state) =>
    produce(state, (nextState) => {
      nextState.coupon = null;
    }),
  )
  .handleAction(actions.clearErrors, (state, action) =>
    produce(state, (nextState) => {
      if (action.payload) {
        action.payload.forEach((err) => {
          nextState.errors[err] = null;
        });
      } else {
        nextState.errors = initialState.errors;
      }
    }),
  );

export { reducer as SkipTracingReducer };
