export enum InvestorFileActionTypes {
  GET_INVESTOR_FILE = "@@INVESTOR_FILE/GET_INVESTOR_FILE",
  GET_INVESTOR_FILE_SUCCESS = "@@INVESTOR_FILE/GET_INVESTOR_FILE_SUCCESS",
  GET_INVESTOR_FILE_FAILURE = "@@INVESTOR_FILE/GET_INVESTOR_FILE_FAILURE",

  GET_INVESTOR_FILE_LIST = "@@INVESTOR_FILE/GET_INVESTOR_FILE_LIST",
  GET_INVESTOR_FILE_LIST_SUCCESS = "@@INVESTOR_FILE/GET_INVESTOR_FILE_LIST_SUCCESS",
  GET_INVESTOR_FILE_LIST_FAILURE = "@@INVESTOR_FILE/GET_INVESTOR_FILE_LIST_FAILURE",

  PROCESS_INVESTOR_FILE = "@@INVESTOR_FILE/PROCESS_INVESTOR_FILE",
  PROCESS_INVESTOR_FILE_SUCCESS = "@@INVESTOR_FILE/PROCESS_INVESTOR_FILE_SUCCESS",
  PROCESS_INVESTOR_FILE_FAILURE = "@@INVESTOR_FILE/PROCESS_INVESTOR_FILE_FAILURE",

  SEND_INVESTOR_FILE = "@@INVESTOR_FILE/SEND_INVESTOR_FILE",
  SEND_INVESTOR_FILE_SUCCESS = "@@INVESTOR_FILE/SEND_INVESTOR_FILE_SUCCESS",
  SEND_INVESTOR_FILE_FAILURE = "@@INVESTOR_FILE/SEND_INVESTOR_FILE_FAILURE",

  UNSEND_INVESTOR_FILE = "@@INVESTOR_FILE/UNSEND_INVESTOR_FILE",
  UNSEND_INVESTOR_FILE_SUCCESS = "@@INVESTOR_FILE/UNSEND_INVESTOR_FILE_SUCCESS",
  UNSEND_INVESTOR_FILE_FAILURE = "@@INVESTOR_FILE/UNSEND_INVESTOR_FILE_FAILURE",

  CREATE_INVESTOR_FILE = "@@INVESTOR_FILE/CREATE_INVESTOR_FILE",
  CREATE_INVESTOR_FILE_SUCCESS = "@@INVESTOR_FILE/CREATE_INVESTOR_FILE_SUCCESS",
  CREATE_INVESTOR_FILE_FAILURE = "@@INVESTOR_FILE/CREATE_INVESTOR_FILE_FAILURE",

  UPDATE_INVESTOR_FILE = "@@INVESTOR_FILE/UPDATE_INVESTOR_FILE",
  UPDATE_INVESTOR_FILE_SUCCESS = "@@INVESTOR_FILE/UPDATE_INVESTOR_FILE_SUCCESS",
  UPDATE_INVESTOR_FILE_FAILURE = "@@INVESTOR_FILE/UPDATE_INVESTOR_FILE_FAILURE",

  SEND_INVESTOR_FILE_EMAIL = "@@INVESTOR_FILE/SEND_INVESTOR_FILE_EMAIL",
  SEND_INVESTOR_FILE_EMAIL_SUCCESS = "@@INVESTOR_FILE/SEND_INVESTOR_FILE_EMAIL_SUCCESS",
  SEND_INVESTOR_FILE_EMAIL_FAILURE = "@@INVESTOR_FILE/SEND_INVESTOR_FILE_EMAIL_FAILURE",

  SEND_INVESTOR_FILES_EMAIL = "@@INVESTOR_FILE/SEND_INVESTOR_FILEs_EMAIL",
  SEND_INVESTOR_FILES_EMAIL_SUCCESS = "@@INVESTOR_FILE/SEND_INVESTOR_FILEs_EMAIL_SUCCESS",
  SEND_INVESTOR_FILES_EMAIL_FAILURE = "@@INVESTOR_FILE/SEND_INVESTOR_FILEs_EMAIL_FAILURE",

  CLEAR_INVESTOR_FILE_LIST = "@@INVESTOR_FILE/CLEAR_INVESTOR_FILE_LIST",
  CLEAR_INVESTOR_FILE = "@@INVESTOR_FILE/CLEAR_INVESTOR_FILE",
  SET_FILTER = "@@INVESTOR_FILE/SET_FILTER",

  CLEAR_ERRORS = "@@INVESTOR_FILE/CLEAR_ERRORS",
}
