import { HubspotPropertiesHistoryFilter, ResponseError } from "@shared/interfaces";
import { HubspotPropertiesHistory } from "@shared/models";

export enum DataDeliveryError {
  getHubspotHistoryListError = "getHubspotHistoryListError",
  updateHubspotHistoryItemProcessingStatusError = "updateHubspotHistoryItemProcessingStatusError",
}

export interface DataDeliveryStateType {
  hubspotPropertiesHistory: HubspotPropertiesHistory[];
  hubspotPropertiesHistoryTotal: number;
  hubspotPropertiesHistoryFilter: HubspotPropertiesHistoryFilter;
  errors: {
    [key in DataDeliveryError]: ResponseError | null;
  };
}
