import React, { FC } from "react";
import moment from "moment";
import { Ticket } from "@shared/models/Ticket";
import { Overlay } from "@shared/components/Page";

import { getLabelText } from "./labels";

import "./index.scss";

export type TicketHistoryContainerProps = {
  onClose: () => void;
  data?: {
    ticket: Ticket;
  };
};

const ACTIONS: { [key: string]: string } = {
  notes: "added a note",
  stage: "updated the stage",
};

export const TicketHistoryContainer: FC<TicketHistoryContainerProps> = ({ data, onClose }) => {
  const propertyHistory = data?.ticket?.propertyHistory;

  if (!propertyHistory?.length) return null;

  const history = [...propertyHistory].sort((a, b) => {
    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
  });

  return (
    <Overlay className="ticket-history">
      <Overlay.Header className="ticket-history-header">
        <Overlay.Title className="ticket-history-header-title">History</Overlay.Title>
        <Overlay.Close className="ticket-history-header-action" onClick={onClose}>
          Close
        </Overlay.Close>
      </Overlay.Header>
      <Overlay.Content className="ticket-history-content">
        {history.map(({ name, value, timestamp, created_by }) => {
          const action = ACTIONS[name] ?? null;

          return (
            <div key={`${created_by}-${timestamp}`} className="ticket-history-item">
              <div className="ticket-history-item-title">
                {moment(timestamp).format("MM/DD/YY [at] h:mm A")} {created_by} <span>{action}</span>
              </div>
              <div className="ticket-history-item-value">{getLabelText(value)}</div>
            </div>
          );
        })}
      </Overlay.Content>
    </Overlay>
  );
};
