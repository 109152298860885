import { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { Notification } from "@shared/interfaces";

export default function useNotification(notification: Notification | null) {
  const { addToast } = useToasts();

  useEffect(() => {
    if (notification) {
      addToast(notification.message, {
        appearance: notification.appearance,
        autoDismiss: true,
      });
    }
  }, [addToast, notification]);
}
