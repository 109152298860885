import React, { useEffect, useState } from "react";
import classNames from "classnames";

import MainSidebar, { MainSidebarProps } from "./components/MainSidebar/MainSidebar";

import "./index.scss";

type SidebarProps = Omit<MainSidebarProps, "collapsed" | "setCollapsed">;

const Sidebar = (props: SidebarProps) => {
  const { items, onLogoutClick, user } = props;

  const [collapsed, setCollapsed] = useState<boolean>(() => {
    const collapsed = localStorage.getItem("sidebarCollapsed");
    return collapsed === "true";
  });

  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", collapsed.toString());
  }, [collapsed]);

  const classes = classNames("sidebar-container", collapsed ? "collapsed" : "");

  return (
    <div className={classes}>
      <MainSidebar
        items={items}
        onLogoutClick={onLogoutClick}
        user={user}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
    </div>
  );
};

export default Sidebar;
