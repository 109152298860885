import * as Yup from "yup";

import { FORM_ERROR_MESSAGES, IS_EXIST_DIGIT, IS_EXIST_LOWERCASE, IS_EXIST_UPPERCASE } from "../constants";

export const passwordValidator = Yup.string()
  .required(FORM_ERROR_MESSAGES.REQUIRED)
  .trim()
  .min(6, `Password ${FORM_ERROR_MESSAGES.SHORT}`)
  .matches(IS_EXIST_DIGIT, FORM_ERROR_MESSAGES.DIGIT)
  .matches(IS_EXIST_LOWERCASE, FORM_ERROR_MESSAGES.LOWERCASE)
  .matches(IS_EXIST_UPPERCASE, FORM_ERROR_MESSAGES.UPPERCASE);

export const stringValidator = Yup.string().trim(FORM_ERROR_MESSAGES.TRIM).strict(true);
