import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";
import { InvestorFileError } from "@containers/InvestorFile/interfaces";

const selectInvestorFile = (state: AppState) => state.investorFile;

export const getInvestorFileList = () => createSelector(selectInvestorFile, (state) => state.investorFileList);
export const getInvestorFile = () => createSelector(selectInvestorFile, (state) => state.investorFile);
export const getFilter = () => createSelector(selectInvestorFile, (state) => state.filter);

export const selectError = (errorType: InvestorFileError) =>
  createSelector(selectInvestorFile, (state) => state.errors[errorType]);
