import React, { FC } from "react";
import classNames from "classnames";
import "./index.scss";

export type TabsType = {
  tabs: string[];
  currentSelected: string;
  handleChange: (name: string) => () => void;
};

const Tabs: FC<TabsType> = (props) => {
  const { tabs, currentSelected, handleChange } = props;

  return (
    <>
      <div
        className="tabs-wrapper"
        style={{
          gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
        }}
      >
        {tabs.map((tab) => (
          <div key={tab} onClick={handleChange(tab)} className="menu-item">
            <p className={classNames({ selected: currentSelected === tab })}>{tab}</p>
            <p className="width">{tab}</p>
          </div>
        ))}
      </div>

      <div className="underline">
        <div
          className="slider"
          style={{
            width: `calc(100% / ${tabs.length}`,
            transform: `translate(${100 * tabs.indexOf(currentSelected)}%, 0%)`,
          }}
        />
      </div>
    </>
  );
};

export default Tabs;
