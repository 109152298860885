import LayerTile from "ol/layer/Tile";
import SourceOSM from "ol/source/OSM";
import * as proj from "ol/proj";
import View from "ol/View";
import { OlMap } from "@shared/components/MapContainer/OLMap/";

export const getMapLayer = () =>
  new LayerTile({
    source: new SourceOSM({}),
  });

export type Coordinate = { lat: number; lon: number };

export const getPoint = (coordinates: Coordinate) => proj.fromLonLat([coordinates.lon, coordinates.lat]);

export const getMap = (target?: string) =>
  new OlMap({
    layers: [getMapLayer()],
    target: target ? target : undefined,
  });

export const getView = (zoom?: number, center?: Coordinate) =>
  new View({
    center: center ? getPoint(center) : getPoint({ lat: 0, lon: 0 }),
    zoom: zoom ? zoom : 0,
  });
