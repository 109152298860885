import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces";

export const selectRecordManagement = (state: AppState) => state.recordManagement;

export const selectProperty = () => createSelector(selectRecordManagement, (state) => state.property);
export const selectPropertyList = () => createSelector(selectRecordManagement, (state) => state.propertyList);
export const selectPropertyTotal = () => createSelector(selectRecordManagement, (state) => state.propertyTotal);
export const selectOwnerList = () => createSelector(selectRecordManagement, (state) => state.ownerList);
export const selectOwnerDetails = () => createSelector(selectRecordManagement, (state) => state.ownerDetails);
export const selectOwnerProperties = () => createSelector(selectRecordManagement, (state) => state.ownerProperties);
export const selectOwnerPropertiesTotal = () =>
  createSelector(selectRecordManagement, (state) => state.ownerPropertiesTotal);
export const selectOwnerTotal = () => createSelector(selectRecordManagement, (state) => state.ownerTotal);
export const selectPropertyError = () => createSelector(selectRecordManagement, (state) => state.error);
