import React, { FC, PropsWithChildren, ReactNode, useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import throttle from "lodash/throttle";

import "./Tooltip.scss";

export interface TooltipProps extends PropsWithChildren {
  content: ReactNode;
  position?: "top" | "bottom" | "bottom-end";
}

export const Tooltip: FC<TooltipProps> = ({ content, children, position = "bottom" }) => {
  const [tooltipPortal, setTooltipPortal] = useState<React.ReactNode>();
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = (evt: React.MouseEvent) => {
    const { x, y, height, width } = evt.currentTarget.getBoundingClientRect();

    let top, left;
    let transform = "";

    switch (position) {
      case "top":
        top = y - height - 10 + window.scrollY;
        left = x + width / 2;
        transform = "translateX(-50%)";
        break;
      case "bottom":
        top = y + height + 10 + window.scrollY;
        left = x + width / 2;
        transform = "translateX(-50%)";
        break;
      case "bottom-end":
        top = y + height + 10 + window.scrollY;
        left = x + width;
        transform = "translateX(-100%)";
        break;
    }

    const style = { top: `${top}px`, left: `${left}px`, transform };

    setTooltipPortal(
      <div className="tooltip-content" style={style}>
        {content}
      </div>,
    );
  };

  const handleMouseLeave = () => {
    setTooltipPortal(null);
  };

  useEffect(() => {
    const handleMouseMove = throttle((evt: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(evt.target as Node)) {
        handleMouseLeave();
      }
    }, 100);

    document.addEventListener("mousemove", handleMouseMove);

    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <>
      <div className="tooltip" ref={tooltipRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {tooltipPortal && ReactDOM.createPortal(tooltipPortal, document.body)}
    </>
  );
};
