import React, { FC } from "react";

import "./index.scss";

const InProgressBadge: FC = () => {
  return (
    <div className="status-badge">
      <div>In Progress</div>
    </div>
  );
};

export default InProgressBadge;
