import { Fill, Stroke, Style } from "ol/style";
import { AbstractShape } from "@shared/components/MapContainer/OLMap/features/AbstractShape";

export class Neighborhood extends AbstractShape {
  private selected = false;
  private readonly borderColor = "#008f72";
  private readonly borderWidth = 3;
  private readonly fillColor = "rgba(0,143,114,0.15)";
  private readonly fillColorHighlighted = "rgba(0,143,114,0.30)";

  constructor(public readonly id: string, public readonly name: string, geometry: string, isSelected = false) {
    super(geometry);
    this.setSelected(isSelected);
  }

  setSelected(isSelected: boolean) {
    this.selected = isSelected;
    this.setStyle(
      new Style({
        fill: new Fill({
          color: isSelected ? this.fillColorHighlighted : this.fillColor,
        }),
        stroke: new Stroke({
          width: this.borderWidth,
          color: this.borderColor,
        }),
      }),
    );
  }

  isSelected() {
    return this.selected;
  }
}
