import React, { FC } from "react";
import { Button, Icon } from "@shared/components";
import { Account } from "@shared/models";
import CompanyItem from "@shared/components/Sidebar/components/MainSidebar/CompanyItem";

type ExpandedContainerType = {
  isExpanded: boolean;
  isCompanySwitcherEnabled?: boolean;
  collapsed: boolean;
  activeAccount: Account | null;
  accounts?: Account[];
  onLogoutClick: () => void;
  handleSelectAccount: (id: number) => void;
};

const ExpandedContainer: FC<ExpandedContainerType> = (props) => {
  const {
    isExpanded,
    collapsed,
    isCompanySwitcherEnabled = false,
    accounts,
    activeAccount,
    handleSelectAccount,
    onLogoutClick,
  } = props;

  if (!isExpanded) {
    return null;
  }

  if (collapsed) {
    return (
      <>
        <div className="user-horizontal-divider" />
        <div className="logout">
          <Button buttonStyle="clear" onClick={onLogoutClick}>
            <Icon type="logout" />
          </Button>
        </div>
      </>
    );
  }
  return (
    <>
      {isCompanySwitcherEnabled && (
        <>
          <div className="user-horizontal-divider" />
          <div className="company-wrapper">
            <div className="header">Company</div>
            {accounts?.map((account) => (
              <CompanyItem
                key={account.id}
                account={account}
                isActive={account.id === activeAccount?.id}
                handleSelectAccount={() => handleSelectAccount(account.id)}
              />
            ))}
          </div>
        </>
      )}

      <div className="user-horizontal-divider" />

      <div className="logout">
        <Button buttonStyle="clear" onClick={onLogoutClick}>
          Log Out
          <Icon type="logout" />
        </Button>
      </div>
    </>
  );
};

export default ExpandedContainer;
