import React from "react";

import { StatusBar, StatusBarProps } from "./StatusBar";
import { Header, HeaderProps } from "./Header";
import { Title, TitleProps } from "./Title";
import { Subtitle, SubtitleProps } from "./Subtitle";
import { GoBackButton } from "./GoBackButton";
import { Content, ContentProps } from "./Content";
import { StatusProcessing } from "./StatusProcessing";
import { StatusComplete } from "./StatusComplete";

import "./index.scss";

export type ProgressProps = {
  children: React.ReactNode;
};

export type ProgressComponents = {
  StatusBar: React.FC<StatusBarProps>;
  Header: React.FC<HeaderProps>;
  Title: React.FC<TitleProps>;
  Subtitle: React.FC<SubtitleProps>;
  Content: React.FC<ContentProps>;
  StatusProcessing: React.FC;
  StatusComplete: React.FC;
  GoBackButton: React.FC<React.HTMLProps<HTMLButtonElement>>;
};

export const Progress: React.FC<React.PropsWithChildren> & ProgressComponents = ({ children }) => (
  <div className="progress">{children}</div>
);

Progress.StatusBar = StatusBar;
Progress.Header = Header;
Progress.Title = Title;
Progress.Subtitle = Subtitle;
Progress.GoBackButton = GoBackButton;
Progress.Content = Content;
Progress.StatusProcessing = StatusProcessing;
Progress.StatusComplete = StatusComplete;

export default Progress;
