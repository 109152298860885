import { createAction, createAsyncAction } from "typesafe-actions";
import { ResponseError, WithCallback, WithOptionalLoader } from "@shared/interfaces";
import { PresetActionTypes } from "@containers/Preset/store/constants";
import { Preset, PresetField } from "@shared/models";
import { PresetError } from "@containers/Preset/interfaces/PresetState.interface";
import { CreatePresetDto } from "@containers/Preset/interfaces/Preset.interface";

export const getPresetList = createAsyncAction(
  PresetActionTypes.GET_PRESET_LIST,
  PresetActionTypes.GET_PRESET_LIST_SUCCESS,
  PresetActionTypes.GET_PRESET_LIST_FAILURE,
)<{ investorSettingsId: number }, Preset[], ResponseError>();

export const getPreset = createAsyncAction(
  PresetActionTypes.GET_PRESET,
  PresetActionTypes.GET_PRESET_SUCCESS,
  PresetActionTypes.GET_PRESET_FAILURE,
)<WithOptionalLoader<{ investorSettingsId: number; filterId: number; callback?: () => void }>, Preset, ResponseError>();

export const updatePreset = createAsyncAction(
  PresetActionTypes.UPDATE_PRESET,
  PresetActionTypes.UPDATE_PRESET_SUCCESS,
  PresetActionTypes.UPDATE_PRESET_FAILURE,
)<WithCallback<Partial<Preset> & { investorSettingsId: number; filterId: number }>, Preset, ResponseError>();

export const createPreset = createAsyncAction(
  PresetActionTypes.CREATE_PRESET,
  PresetActionTypes.CREATE_PRESET_SUCCESS,
  PresetActionTypes.CREATE_PRESET_FAILURE,
)<CreatePresetDto & { investorSettingsId: number; callback?: (presetId?: string) => void }, Preset, ResponseError>();

export const deletePreset = createAsyncAction(
  PresetActionTypes.DELETE_PRESET,
  PresetActionTypes.DELETE_PRESET_SUCCESS,
  PresetActionTypes.DELETE_PRESET_FAILURE,
)<{ investorSettingsId: number; filterId: number }, undefined, ResponseError>();

export const getPresetFields = createAsyncAction(
  PresetActionTypes.GET_PRESET_FIELDS,
  PresetActionTypes.GET_PRESET_FIELDS_SUCCESS,
  PresetActionTypes.GET_PRESET_FIELDS_FAILURE,
)<undefined, PresetField[], ResponseError>();

export const clearPresetList = createAction(PresetActionTypes.CLEAR_PRESET_LIST)();
export const clearPreset = createAction(PresetActionTypes.CLEAR_PRESET)();

export const clearErrors = createAction(PresetActionTypes.CLEAR_ERRORS)<PresetError[] | void>();
