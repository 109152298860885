import { Permission } from "./Permission";

export enum RoleName {
  super_admin = "super_admin",
  admin = "admin",
  user = "user",
}

export interface Role {
  id: number;
  name: RoleName;
  description: string;
  permissions: Permission[];

  created_at: Date;
  updated_at: Date;
  created_by: string;
  updated_by: string;
}
