import { call, put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload, ResponseError } from "@shared/interfaces";
import * as sharedActions from "@shared/store/actions";
import { showHttpErrorNotification } from "@shared/utils";

import { getTicketList, updateTicket } from "./actions";
import api from "../api";
import { GetTicketListRequest, GetTicketListSuccess, UpdateTicketRequest, UpdateTicketSuccess } from "../interface";

export function* getTicketListSaga({ payload }: ActionWithPayload<GetTicketListRequest>) {
  try {
    yield put(sharedActions.startLoading());
    const response: GetTicketListSuccess = yield call(api.getTicketList, payload);
    yield put(getTicketList.success(response));
  } catch (error) {
    yield put(getTicketList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

export function* updateTicketSaga({ payload }: ActionWithPayload<UpdateTicketRequest>) {
  try {
    yield put(sharedActions.startLoading());
    const response: UpdateTicketSuccess = yield call(api.updateTicket, payload);
    yield put(updateTicket.success(response));
  } catch (error) {
    yield put(getTicketList.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(sharedActions.stopLoading());
  }
}

export function* saga() {
  yield takeLatest(getTicketList.request, getTicketListSaga);
  yield takeLatest(updateTicket.request, updateTicketSaga);
}
