import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authentificated } from "@containers/Auth/store/selectors";
import { NamesOfParentRoutes } from "@shared/constants";
import { checkPermissions } from "@shared/utils/ACL";
import { selectors as sharedSelectors } from "@shared/store";

interface PermissionGuardProps {
  children?: ReactNode;
  permissions?: string[];
}

const PermissionGuard: React.FC<PermissionGuardProps> = ({ permissions, children }) => {
  const isAuthenticated = useSelector(authentificated());

  const user = useSelector(sharedSelectors.getUserDetails());

  const hasPermission = Array.isArray(permissions) ? checkPermissions(permissions, user || undefined) : isAuthenticated;

  if (!hasPermission) {
    return <Navigate to={`${NamesOfParentRoutes.DASHBOARD}`} />;
  }

  return <>{children}</>;
};

export default PermissionGuard;
