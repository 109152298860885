import { Dispatch, SetStateAction, useState } from "react";
import { MIN_PAGE_SIZE } from "@shared/constants";
import { Pagination } from "@shared/interfaces";

export const usePagination = ({ currentPage = 1, resultsPerPage = MIN_PAGE_SIZE } = {}) => {
  const [state, setState] = useState({ currentPage, resultsPerPage });

  const onPageStateChange: Dispatch<SetStateAction<Pagination>> = (action) => {
    setState((prevState) => {
      const nextState = typeof action === "function" ? action(prevState) : action;

      return nextState;
    });
  };

  const onPageChangeAndScroll: Dispatch<SetStateAction<Pagination>> = (action) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    onPageStateChange(action);
  };

  return { pagination: state, onPageChange: onPageStateChange, onPageChangeAndScroll };
};
