import React from "react";
import moment from "moment/moment";
import { TableProperty } from "@shared/components";
import { PayPerLeadWithDetail } from "@shared/models/PayPerLead";
import { County } from "@shared/models";
import { LeadActions } from "@containers/PayPerLead/components/PayPerLeadDashboardContainer/LeadActions/LeadActions";
import { SelectAllLeadsCheckbox, SelectLeadCheckbox } from "@containers/PayPerLead/components/SelectLeadCheckbox";

export const getLeadsTableProperties = ({
  counties,
}: {
  counties: County[] | null;
}): TableProperty<PayPerLeadWithDetail>[] => [
  {
    title: <SelectAllLeadsCheckbox />,
    key: "id",
    cellClassName: "lead-id",
    headerClassName: "lead-id",
    get: (item) => <SelectLeadCheckbox lead={item} />,
  },
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Address",
    key: "address",
    cellClassName: "lead-address",
    get: (i) => (
      <a>
        {[i.long_address || i.address, i.city || i.county?.name, i.state?.abbreviation, i.detail?.property_zip]
          .filter(Boolean)
          .join(", ")}
      </a>
    ),
  },
  {
    title: "County",
    key: "county_id",
    get: (item) => counties?.find((c) => c.id === item.county_id)?.name,
  },
  {
    title: "Est. Value",
    key: "est_value",
    get: (item) =>
      item?.detail?.property_value
        ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 }).format(
            item.detail?.property_value as number,
          )
        : "N/A",
  },
  {
    title: "Inv. Score",
    key: "detail",
    get: (item) => (item?.detail?.investor_score ? item.detail?.investor_score : "N/A"),
  },
  {
    title: "Generated",
    key: "created_at",
    get: (item) => moment(item.created_at).format("ll"),
  },
  {
    title: "",
    key: "is_qualified",
    cellClassName: "lead-status",
    headerClassName: "lead-status",
    get: (item) => <LeadActions lead={item} />,
  },
];
