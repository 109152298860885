export enum SKIP_TRACING_PERMISSION {
  READ_SKIP_TRACING = "read_skip_tracing",
  READ_SKIP_TRACINGS = "read_skip_tracings",
  CREATE_SKIP_TRACING = "create_skip_tracing",
  UPDATE_SKIP_TRACING = "update_skip_tracing",
  DELETE_SKIP_TRACING = "delete_skip_tracing",
  TEST_SKIP_TRACING = "test_skip_tracing",
  PROCESS_SKIP_TRACING = "process_skip_tracing",
  PAY_SKIP_TRACING = "pay_skip_tracing",
}

export enum FILE_PERMISSION {
  GET_FILE_SIGNED_URL = "get_file_signed_url",
  CREATE_FILE = "create_file",
  UPDATE_FILE_MAPPING = "update_file_mapping",
}

export enum ACCOUNT_PERMISSION {
  GET_CHECKOUT_URL = "get_account_checkout_url",
  GET_BILLING_PORTAL_URL = "get_account_billing_portal_url",
  GET_PAYMENT_METHOD = "get_account_payment_method",
}

export enum USER_PERMISSION {
  READ_USER = "read_user",
  READ_ALL_USERS = "read_users",
  CREATE_USER = "create_user",
  UPDATE_USER = "update_user",
  DELETE_USER = "delete_user",
  ACTIVATE_USER = "activate_user",
  IMPERSONATE = "impersonate",
}

export enum INVESTOR_PERMISSION {
  READ = "read_investor_settings",
  READ_ALL = "read_all_investor_settings",
  CREATE = "create_investor_settings",
  UPDATE = "update_investor_settings",
  DELETE = "delete_investor_settings",
  READ_FILTER = "read_filter",
  READ_FILTERS = "read_filters",
  CREATE_FILTER = "create_filter",
  UPDATE_FILTER = "update_filter",
  DELETE_FILTER = "delete_filter",
  READ_INVESTOR_FILE = "read_investor_file",
  DELETE_INVESTOR_FILE = "delete_investor_file",
  READ_INVESTOR_FILES = "read_investor_files",
  GENERATE_FILE = "generate_file",
  SEND_FILE = "send_file",
  READ_FILTER_FIELDS = "read_filter_fields",
  READ_HUBSPOT_PROPERTIES_HISTORY = "read_hubspot_properties_history",
  CHANGE_HUBSPOT_HISTORY_STATUS = "change_hubspot_history_status",
}

export enum LEAD_PERMISSION {
  // User
  READ_LEAD = "read_lead",
  READ_LEADS = "read_leads",
  PURCHASE_LEAD = "purchase_leads",
  READ_LEAD_DISPUTE = "read_lead_dispute",
  CREATE_LEAD_DISPUTE = "create_lead_dispute",
  // Admin
  READ_ALL_LEAD = "read_all_lead",
  READ_ALL_LEAD_DISPUTE = "read_all_lead_dispute",
  RESOLVE_LEAD_DISPUTE = "resolve_lead_dispute",
}

export enum TICKET_PERMISSION {
  READ_TICKETS = "read_tickets",
  UPDATE_TICKET = "update_ticket",
}
