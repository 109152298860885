import React, { FC, useState } from "react";
import { Button, Textarea } from "@shared/components";
import { useDispatch, useSelector } from "react-redux";
import { actions as PayPerLeadActions, selectors as PayPerLeadSelectors } from "@containers/PayPerLead/store";
import { getActiveAccount } from "@containers/Account/store/selectors";

import "./index.scss";

interface ModalPurchaseLeadProps {
  heading?: string;
  content?: string;
  onClose: () => void;
}

const MAX_LENGTH = 250;

const ModalDisputeLead: FC<ModalPurchaseLeadProps> = ({ heading, content, onClose }) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");

  const lead = useSelector(PayPerLeadSelectors.getLead());
  const account = useSelector(getActiveAccount());

  const dispute = () => {
    if (account?.id && lead?.id) {
      dispatch(
        PayPerLeadActions.disputeLead.request({
          message,
          lead_id: lead?.id,
        }),
      );
    }

    onClose();
  };

  return (
    <div className="pay-per-lead-dispute-modal">
      <div className="pay-per-lead-dispute-modal-header">{heading}</div>
      <div className="pay-per-lead-dispute-modal-content">{content}</div>

      <Textarea
        value={message}
        onChange={(e) => setMessage((e.target as HTMLTextAreaElement).value?.slice(0, 250))}
        placeholder="Tell us why this lead doesn't fit your request (Optional)"
      />

      <div className="pay-per-lead-dispute-buttons">
        <div className="count">
          {message.length}/{MAX_LENGTH}
        </div>

        <Button buttonStyle="blue-bordered" onClick={onClose}>
          Cancel
        </Button>

        <Button buttonStyle="accent-green" onClick={dispute} disabled={message.length > 250}>
          Dispute
        </Button>
      </div>
    </div>
  );
};
export default ModalDisputeLead;
