import React from "react";
import { MenuItem } from "@shared/interfaces";

import SidebarItem from "../SidebarItem/SidebarItem";

import "./index.scss";

interface SidebarItemsProps {
  items: MenuItem[];
  className: string | undefined;
}

const SidebarItems = (props: SidebarItemsProps) => {
  const { items, className } = props;
  return (
    <>
      {items.map((item) => (
        <SidebarItem key={item.key ?? item.name} item={item} className={className} />
      ))}
    </>
  );
};

export default SidebarItems;
