import { SkipTracing } from "@shared/models/SkipTracing";
import { ResponseError, SkipTracingFilter } from "@shared/interfaces";

export enum SkipTracingError {
  createSkipTracingError = "createSkipTracingError",
  updateSkipTracingError = "updateSkipTracingError",
  deleteSkipTracingError = "deleteSkipTracingError",
  getSkipTracingError = "getSkipTracingError",
  getSkipTracingListError = "getSkipTracingListError",
  processSkipTracingError = "processSkipTracingError",
  payForSkipTracingError = "payForSkipTracingError",
  getPriceRangeError = "getPriceRangeError",
  checkStripeCouponError = "checkStripeCouponError",
}

export interface SkipTracingPriceRange {
  id: number;
  from: number;
  to: number;
  reservedPrice: number;
}

export interface SkipTracingStripeCoupon {
  valid: boolean;
  amount_off: number | null;
  percent_off?: number | null;
}

export interface SkipTracingStateType {
  skipTracing: SkipTracing | null;
  skipTracingList: SkipTracing[];
  skipTracingTotal: number;
  filter: SkipTracingFilter;
  paymentProcessed: boolean | null;
  priceRange: SkipTracingPriceRange | null;
  coupon: SkipTracingStripeCoupon | null;
  errors: {
    [key in SkipTracingError]: ResponseError | null;
  };
}
