import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { ORDER_COLUMN_TYPE, ORDER_TYPE } from "@shared/interfaces";
import {
  PayPerLeadStateType,
  PayPerLeadsError,
  PayPerLeadSubscription,
  SubscriptionStateItem,
  SubscriptionCountyItem,
  PayPerLeadCacheType,
} from "@containers/PayPerLead/interface";
import { PayPerLeadDispute, PayPerLeadResolution } from "@shared/models/PayPerLead";
import { isDuplicate } from "@containers/PayPerLead/validation";

import * as actions from "./actions";

const defaultFilter = {
  limit: 25,
  page: 1,
  order: ORDER_COLUMN_TYPE.CREATED_AT,
  direction: ORDER_TYPE.DESC,
};

const defaultStatisticsFilter = {};

const initialGroupedByStateItem: Readonly<SubscriptionStateItem> = Object.freeze({
  all_counties: false,
  counties: [],
  force_total_per_county: false,
});

const initialDraftSubscription: PayPerLeadSubscription = {
  items: [initialGroupedByStateItem],
};

const initialCacheState: PayPerLeadCacheType = {
  leadPriceByState: {},
  leadPricesByCounty: {},
  stateOptions: {},
};

const initialState: PayPerLeadStateType = {
  lead: null,
  leads: null,
  selectedLeadIds: [],
  currentMonthLeads: null,
  filter: { ...defaultFilter, monthOffset: 0 },
  statisticsFilter: defaultStatisticsFilter,
  subscription: initialDraftSubscription,
  subscriptions: null,
  disputes: null,
  customers: null,
  cache: initialCacheState,
  statistics: null,
  purchaseInfo: null,
  errors: {
    [PayPerLeadsError.getPayPerLeadError]: null,
    [PayPerLeadsError.getPayPerLeadsError]: null,
    [PayPerLeadsError.getPayPerLeadsOrderError]: null,
    [PayPerLeadsError.getPayPerCurrentMonthLeadsError]: null,
    [PayPerLeadsError.disputePayPerLeadError]: null,
    [PayPerLeadsError.getPayPerLeadCustomersError]: null,
    [PayPerLeadsError.getPayPerLeadStatisticsError]: null,
  },
};

const reducer = createReducer<PayPerLeadStateType, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getLeadSubscriptions.success, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.subscriptions = payload.items;
      nextState.errors[PayPerLeadsError.getPayPerLeadsOrderError] = null;
    }),
  )
  .handleAction(actions.getLeadSubscriptions.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PayPerLeadsError.getPayPerLeadsOrderError] = action.payload;
    }),
  )
  .handleAction(actions.getLead.request, (state) =>
    produce(state, (nextState) => {
      nextState.lead = null;
      nextState.errors[PayPerLeadsError.getPayPerLeadError] = null;
    }),
  )
  .handleAction(actions.getLead.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.lead = action.payload;
      nextState.errors[PayPerLeadsError.getPayPerLeadError] = null;
    }),
  )
  .handleAction(actions.getLead.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PayPerLeadsError.getPayPerLeadError] = action.payload;
    }),
  )
  .handleAction(actions.selectLead, (state, action) =>
    produce(state, (nextState) => {
      nextState.selectedLeadIds = [...nextState.selectedLeadIds, action.payload];
    }),
  )
  .handleAction(actions.unselectLead, (state, action) =>
    produce(state, (nextState) => {
      nextState.selectedLeadIds = nextState.selectedLeadIds.filter((id) => id !== action.payload);
    }),
  )
  .handleAction(actions.selectAllLeads, (state) =>
    produce(state, (nextState) => {
      nextState.selectedLeadIds = nextState.leads?.items.map((lead) => lead.id) ?? [];
    }),
  )
  .handleAction(actions.unselectAllLeads, (state) =>
    produce(state, (nextState) => {
      nextState.selectedLeadIds = [];
    }),
  )
  .handleAction(actions.qualifyLeads.success, (state, { payload }) =>
    produce(state, (nextState) => {
      if (nextState.lead && payload.ids.includes(nextState.lead.id)) {
        nextState.lead.is_qualified = payload.is_qualified;
      }

      if (nextState.leads) {
        for (const lead of nextState.leads.items) {
          if (payload.ids.includes(lead.id)) {
            lead.is_qualified = payload.is_qualified;
          }
        }
      }
    }),
  )
  .handleAction(actions.disputeLead.success, (state, action) =>
    produce(state, (nextState) => {
      if (nextState.lead) {
        nextState.lead = {
          ...nextState.lead,
          dispute: action.payload || undefined,
        };
      }

      nextState.errors[PayPerLeadsError.disputePayPerLeadError] = null;
    }),
  )
  .handleAction(actions.disputeLead.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PayPerLeadsError.disputePayPerLeadError] = action.payload;
    }),
  )
  .handleAction(actions.saveSubscription.success, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.subscription = payload;
    }),
  )
  .handleAction(actions.addSubscription, (state, { payload }) =>
    produce(state, (nextState) => {
      if (nextState.subscriptions) {
        nextState.subscriptions = [...nextState.subscriptions, payload];
      } else {
        nextState.subscriptions = [payload];
      }
    }),
  )
  .handleAction(actions.getLeads.request, (state) =>
    produce(state, (nextState) => {
      nextState.leads = initialState.leads;
      nextState.errors[PayPerLeadsError.getPayPerLeadsError] = null;
    }),
  )
  .handleAction(actions.getLeads.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.leads = action.payload;
      nextState.errors[PayPerLeadsError.getPayPerLeadsError] = null;
    }),
  )
  .handleAction(actions.getLeads.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PayPerLeadsError.getPayPerLeadsError] = action.payload;
    }),
  )
  .handleAction(actions.getSubscriptionsWithCustomers.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.customers = action.payload;
      nextState.errors[PayPerLeadsError.getPayPerLeadCustomersError] = null;
    }),
  )
  .handleAction(actions.getSubscriptionsWithCustomers.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PayPerLeadsError.getPayPerLeadCustomersError] = action.payload;
    }),
  )
  .handleAction(actions.getCurrentMonthLeads.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.currentMonthLeads = action.payload;
      nextState.errors[PayPerLeadsError.getPayPerCurrentMonthLeadsError] = null;
    }),
  )
  .handleAction(actions.getCurrentMonthLeads.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PayPerLeadsError.getPayPerCurrentMonthLeadsError] = action.payload;
    }),
  )
  .handleAction(actions.fetchStateLeadPrice.success, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.cache.leadPriceByState[payload.stateId] = payload.price;
    }),
  )
  .handleAction(actions.fetchCountyPrice.success, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.cache.leadPricesByCounty[payload.countyId] = payload.price;
    }),
  )
  .handleAction(actions.fetchFilterOptions.success, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.filterOptions = payload;
    }),
  )
  .handleAction(actions.resetFilterOptions, (state) =>
    produce(state, (nextState) => {
      nextState.filterOptions = undefined;
    }),
  )
  .handleAction(actions.cacheStatesOptions, (state, action) =>
    produce(state, (nextState) => {
      for (const option of Object.values(action.payload)) {
        nextState.cache.stateOptions[option.value] ??= option;
      }
    }),
  )
  .handleAction(actions.clearCache, (state) =>
    produce(state, (nextState) => {
      nextState.cache = initialCacheState;
    }),
  )
  .handleAction(actions.fetchCountyOptions.success, (state, action) =>
    produce(state, (nextState) => {
      if (nextState.cache.stateOptions[action.payload.stateId]) {
        nextState.cache.stateOptions[action.payload.stateId].counties = action.payload.counties;
      }
    }),
  )
  .handleAction(actions.resetSubscription, (state) =>
    produce(state, (nextState) => {
      nextState.subscription = initialDraftSubscription;
    }),
  )
  .handleAction(actions.getSubscriptionDetails.success, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.subscription = payload;
    }),
  )
  .handleAction(actions.purchaseSubscription.success, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.subscription = payload;
    }),
  )
  .handleAction(actions.fetchPurchaseInfo.success, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.purchaseInfo = payload;
    }),
  )
  .handleAction(actions.addSubscriptionItem, (state) =>
    produce(state, (nextState) => {
      nextState.subscription.items = [...nextState.subscription.items, { ...initialGroupedByStateItem }];
    }),
  )
  .handleAction(actions.setSubscriptionTouched, (state) =>
    produce(state, (nextState) => {
      for (const item of nextState.subscription.items) {
        item.state_id_touched = true;
        item.counties_touched = true;

        if (item.all_counties || !item.force_total_per_county) {
          item.leads_total_touched = true;
        } else {
          for (const county of item.counties) {
            county.touched = true;
          }
        }
      }
    }),
  )
  .handleAction(actions.updateSubscriptionItem, (state, { payload }) =>
    produce(state, (nextValue) => {
      const itemIndex = state.subscription.items.findIndex((item) => item === payload.original);

      if (itemIndex >= 0) {
        const updated: SubscriptionStateItem = { ...payload.original, ...payload.update };
        if ("leads_total" in payload.update) {
          updated.leads_total_touched = true;
        }

        if ("state_id" in payload.update) {
          updated.counties_touched = false;
          updated.leads_total_touched = false;

          const otherSubscriptions = state.subscriptions?.filter((s) => s.id !== nextValue.subscription.id);
          updated.isDuplicate = isDuplicate(updated, otherSubscriptions);
        }

        nextValue.subscription.items[itemIndex] = updated;
        nextValue.subscription.price = undefined;
      }
    }),
  )
  .handleAction(actions.deleteSubscriptionItem, (state, { payload }) =>
    produce(state, (nextState) => {
      const index = state.subscription.items.findIndex((item) => item === payload);
      nextState.subscription.items.splice(index, 1);
      nextState.subscription.price = undefined;
    }),
  )
  .handleAction(actions.setStatisticsFilter, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.statisticsFilter = payload
        ? { ...nextState.statisticsFilter, ...payload }
        : { ...defaultStatisticsFilter };
    }),
  )
  .handleAction(actions.addSubscriptionCounty, (state, { payload }) =>
    produce(state, (nextState) => {
      const exists = !!payload.to.counties.find((c) => c.county_id === payload.countyId);
      if (exists) {
        return;
      }

      const stateItemIndex = state.subscription.items.findIndex((item) => item === payload.to);

      const newItem: SubscriptionCountyItem = {
        county_id: payload.countyId,
      };

      const nextStateItem: SubscriptionStateItem = nextState.subscription.items[stateItemIndex];
      nextStateItem.counties.push(newItem);
      nextStateItem.counties_touched = true;

      const otherSubscriptions = state.subscriptions?.filter((s) => s.id !== nextState.subscription.id);
      nextStateItem.isDuplicate = isDuplicate(nextStateItem, otherSubscriptions);

      nextState.subscription.price = undefined;
    }),
  )
  .handleAction(actions.updateSubscriptionCounty, (state, { payload }) =>
    produce(state, (next) => {
      const stateIndex = state.subscription.items.findIndex((stateItem) => stateItem === payload.from);
      const countyIndex = payload.from.counties.findIndex((countyItem) => countyItem === payload.county);

      if (countyIndex >= 0) {
        next.subscription.items[stateIndex].counties[countyIndex] = {
          ...payload.county,
          total_leads: payload.totalLeads,
          touched: true,
        };
        next.subscription.price = undefined;
      }
    }),
  )
  .handleAction(actions.deleteSubscriptionCounty, (state, { payload }) =>
    produce(state, (nextState) => {
      const subscriptionIndex = state.subscription.items.findIndex((s) => s === payload.from);

      if (subscriptionIndex >= 0) {
        const subscription = state.subscription.items[subscriptionIndex];
        const countyIndex = subscription.counties.findIndex((c) => c === payload.county);

        const nextStateItem: SubscriptionStateItem = nextState.subscription.items[subscriptionIndex];

        if (countyIndex >= 0) {
          nextStateItem.counties.splice(countyIndex, 1);
          nextStateItem.counties_touched = true;

          const otherSubscriptions = state.subscriptions?.filter((s) => s.id !== nextState.subscription.id);
          nextStateItem.isDuplicate = isDuplicate(nextStateItem, otherSubscriptions);

          nextState.subscription.price = undefined;

          if (nextStateItem.counties.length === 0) {
            nextStateItem.leads_total = undefined;
            nextStateItem.leads_total_touched = false;
          }
        }
      }
    }),
  )
  .handleAction(actions.getDisputes.request, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter.page = action.payload.page;
      nextState.filter.limit = action.payload.limit;
    }),
  )
  .handleAction(actions.getDisputes.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.disputes = action.payload;
    }),
  )
  .handleAction(actions.getDisputes.failure, (state) =>
    produce(state, (nextState) => {
      nextState.disputes = initialState.disputes;
    }),
  )
  .handleAction(actions.updateDispute.success, (state, action) =>
    produce(state, (nextState) => {
      const filteredItems = nextState.disputes?.items.filter((dispute) => dispute.id !== action.payload.disputeId);

      nextState.disputes = {
        total: nextState.disputes?.total ? nextState.disputes?.total - 1 : 0,
        items: filteredItems ?? [],
      };

      const dispute: Partial<PayPerLeadDispute> = {
        is_active: false,
        resolution: action.payload.is_approved ? PayPerLeadResolution.approved : PayPerLeadResolution.rejected,
        resolved_by: action.payload.resolved_by,
        resolved_date: action.payload.resolved_date,
      };

      if (nextState.lead?.dispute) {
        nextState.lead = {
          ...nextState.lead,
          dispute: {
            ...nextState.lead.dispute,
            ...dispute,
          },
        };
      }

      if (nextState.leads?.items) {
        nextState.leads.items = nextState.leads.items.map((l) => {
          if (l.dispute?.id !== action.payload.disputeId) return l;

          return {
            ...l,
            dispute: {
              ...l.dispute,
              ...dispute,
            },
          };
        });
      }
    }),
  )
  .handleAction(actions.getStatistics.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.statistics = action.payload;
      nextState.errors[PayPerLeadsError.getPayPerLeadStatisticsError] = null;
    }),
  )
  .handleAction(actions.getStatistics.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[PayPerLeadsError.getPayPerLeadStatisticsError] = action.payload;
    }),
  )
  .handleAction(actions.updateFilter, (state, { payload }) =>
    produce(state, (nextState) => {
      nextState.filter = { ...nextState.filter, ...payload };
    }),
  )
  .handleAction(actions.cleanup, (state) =>
    produce(state, () => {
      return initialState;
    }),
  );

export { reducer as PayPerLeadReducer };
