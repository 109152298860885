import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Button, ErrorMessage, Input } from "@shared/components";
import { Account } from "@shared/models";
import { useDispatch, useSelector } from "react-redux";
import * as authStore from "@containers/Auth/store";
import * as accountStore from "@containers/Account/store";
import "./index.scss";
import { AnyType } from "@shared/interfaces";
import { CreateAccountInterface } from "@containers/Account/interface/CreateAccount.interface";
import { getUserExistence } from "@containers/Auth/store/selectors";
import { getAccountChargebeeValidation } from "@containers/Account/store/selectors";
import { selectors as sharedSelectors } from "@shared/store";

import {
  CompanyFormShape,
  createValidationSchema,
  editValidationSchema,
  getInitValues,
  prepareSubmitValue,
} from "./formHelpers";

interface CompanyFormProps {
  account: Account | null;
  onDelete: () => void;
  onActivate: () => void;
  onChangeForm: () => void;
  onCloseForm: () => void;
  onSubmitForm: (payload: CreateAccountInterface) => void;
}

const CompanyForm: FC<CompanyFormProps> = (props) => {
  const dispatch = useDispatch();
  const { account, onChangeForm, onCloseForm, onDelete, onActivate, onSubmitForm } = props;

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isChargebeeIdValid, setIsChargebeeIdValid] = useState(false);
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [shouldBeSubmitted, setShouldBeSubmitted] = useState(false);
  const formikRef = useRef<FormikProps<CompanyFormShape>>(null);

  const userExistence = useSelector(getUserExistence());
  const accountChargebeeValid = useSelector(getAccountChargebeeValidation());
  const isLoading = useSelector(sharedSelectors.getLoading());

  const formValues = useMemo(() => getInitValues(account), [account]);

  const validationSchema = useMemo(() => {
    if (account) {
      return editValidationSchema;
    }
    return createValidationSchema;
  }, [account]);

  const isDisabled = account?.is_active === false;

  const handleCheckUserEmail = useCallback(
    (email: string) => {
      dispatch(authStore.actions.checkUser.request({ email, noRedirect: true }));
    },
    [dispatch],
  );

  const handleCheckChargebeeId = useCallback(
    (chargebeeId: string) => {
      dispatch(accountStore.actions.checkAccount.request({ chargebeeId }));
    },
    [dispatch],
  );

  const handleChangeValue = useCallback(
    (handleChange: (e: React.ChangeEvent<AnyType>) => void) => (e: React.ChangeEvent<AnyType>) => {
      handleChange(e);
      setTouched((prevState) => ({ ...prevState, [e.target.name]: true }));
      switch (e.target.name) {
        case "chargebeeId": {
          setIsChargebeeIdValid(false);
          break;
        }
        case "email": {
          setIsEmailValid(false);
          break;
        }
      }
    },
    [setTouched],
  );

  const handleSubmit = useCallback(
    (values: CompanyFormShape, helpers?: FormikHelpers<CompanyFormShape>) => {
      if (isLoading) {
        return;
      }

      const shouldValidateEmail = !isEmailValid && !!values.email && touched.email === true;
      const shouldValidateChargebeeId =
        !isChargebeeIdValid &&
        !!values.chargebeeId &&
        values.chargebeeId !== account?.chargebee_customer_id &&
        touched.chargebeeId === true;

      if (shouldValidateEmail || shouldValidateChargebeeId) {
        setShouldBeSubmitted(true);

        if (shouldValidateEmail) {
          handleCheckUserEmail(values.email as string);
        }

        if (shouldValidateChargebeeId) {
          handleCheckChargebeeId(values.chargebeeId as string);
        }

        return;
      }
      setShouldBeSubmitted(false);
      onSubmitForm(prepareSubmitValue(values));
      if (helpers?.setSubmitting) {
        helpers.setSubmitting(false);
      }
    },
    [
      isLoading,
      touched,
      isEmailValid,
      isChargebeeIdValid,
      account?.chargebee_customer_id,
      onSubmitForm,
      handleCheckUserEmail,
      handleCheckChargebeeId,
    ],
  );

  useEffect(() => {
    if (typeof accountChargebeeValid === "boolean" && touched.chargebeeId) {
      if (!accountChargebeeValid) {
        setIsChargebeeIdValid(false);
        formikRef.current?.setFieldError("chargebeeId", "Chargebee id is invalid or already taken");
      } else {
        setIsChargebeeIdValid(true);
      }

      dispatch(accountStore.actions.clearAccountCheck());
    }
  }, [accountChargebeeValid, userExistence, dispatch, setShouldBeSubmitted, touched.chargebeeId]);

  useEffect(() => {
    if (typeof userExistence === "boolean" && touched.email) {
      if (userExistence) {
        setIsEmailValid(false);
        formikRef.current?.setFieldError("email", "User already exists");
      } else {
        setIsEmailValid(true);
      }

      dispatch(authStore.actions.clearUserExistence());
    }
  }, [userExistence, dispatch, setShouldBeSubmitted, touched.email]);

  useEffect(() => {
    if (
      shouldBeSubmitted &&
      formikRef.current &&
      (isEmailValid || !touched.email) &&
      (isChargebeeIdValid || !touched.chargebeeId)
    ) {
      handleSubmit(formikRef.current.values);
    }
  }, [isEmailValid, isChargebeeIdValid, touched.email, touched.chargebeeId, shouldBeSubmitted, handleSubmit]);

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      initialValues={formValues}
      validate={async () => {
        onChangeForm();
      }}
      innerRef={formikRef}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => {
        return (
          <div className="company-form">
            <form onSubmit={handleSubmit}>
              <div className="client-form-header">
                <div className="client-form-header-left">{account ? "Edit Company" : "New Company"}</div>

                <div className="client-form-header-right">
                  {!isDisabled ? (
                    <Button type="submit" buttonStyle="accent-green">
                      Save
                    </Button>
                  ) : null}
                  <Button type="button" buttonStyle="form-cancel" onClick={onCloseForm}>
                    Cancel
                  </Button>
                </div>
              </div>
              <div className="horizontal-divider" />
              <div className="main-form-wrapper">
                <div>
                  <Input
                    type="text"
                    name="name"
                    label="Company Name"
                    isRequiredField={true}
                    value={values.name}
                    onChange={handleChange}
                    placeholder="Company Name"
                    disabled={isDisabled}
                  />
                  <ErrorMessage isTouched={!!touched["name"]} error={errors["name"]} />
                </div>

                <div>
                  <Input
                    type="text"
                    name="chargebeeId"
                    label="Chargebee ID"
                    value={values.chargebeeId}
                    onChange={handleChangeValue(handleChange)}
                    placeholder="Chargebee ID"
                    disabled={isDisabled}
                  />
                  <ErrorMessage isTouched={!!touched["chargebeeId"]} error={errors["chargebeeId"]} />
                </div>
                {account ? (
                  <div>
                    <Input
                      type="text"
                      name="stripeId"
                      label="Stripe ID"
                      value={values.stripeId}
                      onChange={handleChangeValue(handleChange)}
                      placeholder="Stripe ID"
                      disabled={isDisabled}
                    />
                    <ErrorMessage isTouched={!!touched["stripeId"]} error={errors["stripeId"]} />
                  </div>
                ) : null}

                <div className="gap-wrapper">
                  <div>
                    <Input
                      type="text"
                      name="flickId"
                      label="Flikid"
                      value={values.flickId}
                      onChange={handleChange}
                      placeholder="Flikid"
                      disabled={isDisabled}
                    />
                    <ErrorMessage
                      className="error-message-width"
                      isTouched={!!touched.flickId}
                      error={errors.flickId}
                    />
                  </div>

                  <div>
                    <Input
                      type="text"
                      name="flickName"
                      label="Flikid Nickname"
                      value={values.flickName}
                      onChange={handleChange}
                      placeholder="Flikid Nickname"
                      disabled={isDisabled}
                    />
                    <ErrorMessage
                      className="error-message-width"
                      isTouched={!!touched.flickName}
                      error={errors.flickName}
                    />
                  </div>
                </div>
              </div>

              {!account ? (
                <>
                  <div className="horizontal-divider" />

                  <p className="heading">Contact info</p>

                  <div>
                    <div className="gap-wrapper">
                      <div>
                        <Input
                          type="text"
                          name="firstName"
                          label="First Name"
                          isRequiredField={true}
                          value={values.firstName}
                          onChange={handleChange}
                          placeholder="First Name"
                        />
                        <ErrorMessage
                          className="error-message-width"
                          isTouched={!!touched["firstName"]}
                          error={errors["firstName"]}
                        />
                      </div>
                      <div>
                        <Input
                          type="text"
                          name="lastName"
                          label="Last Name"
                          isRequiredField={true}
                          value={values.lastName}
                          onChange={handleChange}
                          placeholder="Last Name"
                        />
                        <ErrorMessage
                          className="error-message-width"
                          isTouched={!!touched["lastName"]}
                          error={errors["lastName"]}
                        />
                      </div>
                    </div>

                    <div>
                      <Input
                        type="text"
                        name="email"
                        label="Email"
                        isRequiredField={true}
                        value={values.email}
                        onChange={handleChangeValue(handleChange)}
                        placeholder="Email"
                      />
                      <ErrorMessage isTouched={!!touched["email"]} error={errors["email"]} />
                    </div>
                  </div>
                </>
              ) : (
                <div className="status-container">
                  <div className={`status-cell ${account?.is_active ? "active" : ""}`}>
                    <span>{account?.is_active === true ? "Active" : "Inactive"}</span>
                  </div>

                  <div>
                    {account?.is_active ? (
                      <Button type="button" buttonStyle="clear-red" onClick={onDelete}>
                        Deactivate Company
                      </Button>
                    ) : (
                      <Button type="button" buttonStyle="get-output" onClick={onActivate}>
                        Activate Company
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default CompanyForm;
