import { NeighborhoodMetrics } from "@shared/interfaces/NeighborhoodMetrics.interface";

const DEFAULT_NEIGHBORHOOD_METRICS: NeighborhoodMetrics = {
  "1939_or_older_year_built_percentage": 0,
  "1940_to_1969_year_built_percentage": 0,
  "1970_to_1999_year_built_percentage": 0,
  "1_bedroom_percentage": 0,
  "2000_or_newer_year_built_percentage": 0,
  "2_bedroom_percentage": 0,
  "3_bedroom_percentage": 0,
  "4_bedroom_percentage": 0,
  "5_or_more_bedroom_percentage": 0,
  apartments_percentage: 0,
  condos_percentage: 0,
  fips: "",
  gross_rental_yield: 0,
  id: "",
  median_property_rental_value: 0,
  median_property_rental_value_nation_percent_rank: 0,
  median_property_rental_value_state_percent_rank: 0,
  median_property_value: 0,
  median_property_value_nation_percent_rank: 0,
  median_property_value_state_percent_rank: 0,
  median_tax_billed_amount: 0,
  mobile_homes_percentage: 0,
  multi_family_percentage: 0,
  name: "",
  no_bedroom_percentage: 0,
  num_transactions: 0,
  off_market_competitive_score: 0,
  other_property_type_percentage: 0,
  owners_percentage: 0,
  property_value_boundary_1: 0,
  property_value_boundary_1_percentage: 0,
  property_value_boundary_2: 0,
  property_value_boundary_2_percentage: 0,
  property_value_boundary_3: 0,
  property_value_boundary_3_percentage: 0,
  property_value_boundary_4: 0,
  property_value_boundary_4_percentage: 0,
  property_value_boundary_5: 0,
  property_value_boundary_5_percentage: 0,
  property_value_boundary_6: 0,
  property_value_boundary_6_percentage: 0,
  property_value_boundary_7: 0,
  property_value_boundary_7_percentage: 0,
  property_value_boundary_8_percentage: 0,
  renters_percentage: 0,
  single_family_percentage: 0,
  state: "",
  tax_effective_rate: 0,
  townhouses_percentage: 0,
  vacant_lands_percentage: 0,
};

export default DEFAULT_NEIGHBORHOOD_METRICS;
