import { useLocation } from "react-router";
import { useMemo } from "react";

export default function useQuery() {
  const location = useLocation();

  return useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
}
