import React, { FC } from "react";
import { Checkbox as _Checkbox } from "rsuite";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPresetBuilder } from "@containers/PresetBuilder/store/selectors";
import { ValueType } from "rsuite/Checkbox";
import { CheckBox } from "@shared/interfaces/PresetBuilder.interface";
import { filterChange } from "@containers/PresetBuilder/store/actions";

const Checkbox: FC<CheckBox> = ({ stateAccessor, label }) => {
  const dispatch = useDispatch();
  const state = useSelector(getPresetBuilder());

  if (!stateAccessor) return null;

  const onChange = (value: ValueType | undefined, checked: boolean) => {
    dispatch(filterChange.request({ name: stateAccessor, value: checked }));
  };

  const value = state.preset.filters[stateAccessor];

  return (
    <div className="checkbox-container">
      <_Checkbox name={stateAccessor} checked={value} onChange={onChange} />
      <span className="checkbox-label">{label}</span>
    </div>
  );
};

export default Checkbox;
