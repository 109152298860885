import React, { useCallback } from "react";
import { InputPicker as _InputPicker, Tag, TagGroup } from "rsuite";
import { InputPickerProps } from "rsuite/InputPicker";
import classnames from "classnames";
import "./index.scss";
import { usePlacement } from "@shared/components/SelectPickerInput/hooks/usePlacement";

interface InputPickerInterface extends InputPickerProps {
  label?: string;
  dropdownHeight?: number;
  disableContainerStyles?: boolean;
  useContainer?: boolean;
  selectAll?: boolean;
  tagged?: boolean;
  onTagRemove?: (index: number) => void;
}

const SELECT_ALL_ID = "SELECT_ALL";

const InputPicker = (props: InputPickerInterface) => {
  // TODO rewrite this code
  const { placement: propsPlacement, disableContainerStyles, useContainer, selectAll, label, ...rest } = props;
  const [data, setData] = React.useState(rest.data);
  const [idToLabel, setIdToLabel] = React.useState<Map<number, string>>();

  const ref = React.useRef<HTMLDivElement>(null);

  const [hasRendered, setState] = React.useState<boolean | null>(null);

  const placement = usePlacement({
    ref,
    dropDownHeight: props.dropdownHeight,
  });

  const onChange = useCallback(
    (id: unknown, e: React.SyntheticEvent) => {
      if (id === SELECT_ALL_ID) {
        rest.onChange?.(rest.value?.length === rest.data?.length ? [] : rest.data.map((i) => i.value), e);

        return;
      }

      rest.onChange?.(id, e);
    },
    [rest],
  );

  const _className = classnames(
    "select-picker-input",
    propsPlacement === "topStart" ? "transform-button" : "",
    disableContainerStyles ? "reset-styles" : "",
  );

  React.useEffect(() => {
    if (selectAll) {
      setData([{ label: "All", value: SELECT_ALL_ID }, ...rest.data]);
    } else {
      // eslint-disable-next-line
      setData([...new Map(rest.data.map((i) => [i.value || i, i])).values()]);
    }

    setIdToLabel(new Map(rest.data.map((i) => [i.value as number, `${i.label}`])));
  }, [setData, setIdToLabel, selectAll, rest.data]);

  React.useEffect(() => {
    setState(true);
  }, [hasRendered]);

  return (
    <div className={_className} ref={ref}>
      {label && <label>{label}</label>}

      <_InputPicker
        {...rest}
        data={data}
        onChange={onChange}
        placement={placement}
        container={useContainer && ref && ref.current ? () => ref.current as HTMLDivElement : undefined}
      />

      {Boolean(props.tagged) && Array.isArray(props.value) && (
        <TagGroup>
          {props.value.map((tag, idx) => (
            <Tag key={idx} closable onClose={() => props.onTagRemove?.(idx)}>
              {idToLabel?.get(tag) || tag}
            </Tag>
          ))}
        </TagGroup>
      )}
    </div>
  );
};

export default InputPicker;
