import { ESPreset, InputBlocks, PresetFilters } from "@shared/interfaces/PresetBuilder.interface";
import { CheckboxBlock, DateBlock, SelectBlock, TypeBlock } from "@containers/PresetBuilder/components/InputBlock";
import { DEFAULT_BUY_BOX_METRICS } from "@shared/constants/index";
import { PresetError } from "@containers/Preset/interfaces/PresetState.interface";

export const REALTOR_PRODUCT_TYPE = "REALTOR";
export const INVESTOR_PRODUCT_TYPE = "INVESTOR";

export type ProductType = typeof REALTOR_PRODUCT_TYPE | typeof INVESTOR_PRODUCT_TYPE;

export const PRESET_INPUT_BLOCKS: Record<string, InputBlocks> = {
  select: SelectBlock,
  input: TypeBlock,
  date: DateBlock,
  checkboxes: CheckboxBlock,
};

export const STRING_TYPE = "string";
export const BOOLEAN_TYPE = true;
export const NUMBER_TYPE = 1;

export const PRESET_FILTERS_DEFAULT_VALUES: PresetFilters = {
  product_type: INVESTOR_PRODUCT_TYPE,

  expended_criteria: false,
  mailing_list: false,

  name: "",
  monthly_records_count: null,

  property_type: [],
  property_type_not_defined: true,
  property_type_option: "include",

  property_value_min: null,
  property_value_max: null,
  property_value_not_defined: true,

  bedroom_count_min: null,
  bedroom_count_max: null,
  bedroom_count_not_defined: true,

  bath_count_min: null,
  bath_count_max: null,
  bath_count_not_defined: true,

  property_sqft_min: null,
  property_sqft_max: null,
  property_sqft_not_defined: true,

  year_built_min: null,
  year_built_max: null,
  year_built_not_defined: true,

  area_lot_sf_min: null,
  area_lot_sf_max: null,
  area_lot_sf_not_defined: true,

  last_sale_date_max: null,
  last_sale_date_min: null,
  last_sale_date_not_defined: true,

  last_sale_price_min: null,
  last_sale_price_max: null,
  last_sale_price_not_defined: true,

  auctions: true,
  liens: true,
  zombie: true,
  absentee_bad_credit: true,
  senior_owner: true,
  bankruptcy: true,
  pre_foreclosure: true,
  short_term_loan: true,
  absentee_vacant: true,
  senior_owner_two: true,
  free_clear: true,
  pre_probate: true,
  criminal_felony: true,
  owner_occupied_low_income: true,
  owner_eviction: true,
  high_equity: true,
  vacant: true,
  absentee_low_income: true,
  owner_occupied_low_credit: true,

  // Ownership / Vacancy Status
  owner_occupied: null,

  property_zips_option: "include",
  property_zips: [],
};

export const PRESET_RECORD_DEFAULT_VALUES: ESPreset = {
  filters: {
    ...PRESET_FILTERS_DEFAULT_VALUES,
  },
  states: [],
  counties: [],
  zips: [],
  fileName: null,
  processings: [],
  esPreviewRecords: [],
  status: null,
  estimated_records_count: null,
  investor_data_settings_id: null,
  created_at: null,
  created_by: null,
  updated_at: null,
  updated_by: null,
};

export const PRESET_DEFAULT_ERRORS = {
  [PresetError.createPresetError]: null,
  [PresetError.updatePresetError]: null,
  [PresetError.deletePresetError]: null,
  [PresetError.getPresetError]: null,
  [PresetError.getPresetListError]: null,
  [PresetError.getPresetFieldsError]: null,
};

export const PRESET_BUILDER_DEFAULT_STATE = {
  filterState: {
    states: [],
    counties: [],
    zips: [],
    property_zips: [],
  },

  filters: {
    states: [],
    counties: [],
    zips: [],
  },

  presetList: null,

  buyBoxMetrics: {
    ...DEFAULT_BUY_BOX_METRICS,
  },
};

export const MAILING_LIST_FIELDS = [
  "Mailing Address County",
  "Mailing Address",
  "Mailing Address Unit Prefix",
  "Mailing Address Unit",
  "Mailing Address City",
  "Mailing Address State",
  "Mailing Address Zip",
  "Mailing Address Zip4",
];

export const ES_PRESET_FILTER_VALUES: (keyof PresetFilters)[] = [
  "product_type",
  "expended_criteria",
  "mailing_list",
  "name",
  "monthly_records_count",
  "property_type",
  "property_type_not_defined",
  "property_type_option",
  "property_value_min",
  "property_value_max",
  "property_value_not_defined",
  "bedroom_count_min",
  "bedroom_count_max",
  "bedroom_count_not_defined",
  "bath_count_min",
  "bath_count_max",
  "bath_count_not_defined",
  "property_sqft_min",
  "property_sqft_max",
  "property_sqft_not_defined",
  "year_built_min",
  "year_built_max",
  "year_built_not_defined",
  "area_lot_sf_min",
  "area_lot_sf_max",
  "area_lot_sf_not_defined",
  "last_sale_date_min",
  "last_sale_date_max",
  "last_sale_date_not_defined",
  "last_sale_price_min",
  "last_sale_price_max",
  "last_sale_price_not_defined",
  "auctions",
  "liens",
  "zombie",
  "absentee_bad_credit",
  "senior_owner",
  "senior_owner_two",
  "bankruptcy",
  "pre_foreclosure",
  "short_term_loan",
  "absentee_vacant",
  "free_clear",
  "pre_probate",
  "criminal_felony",
  "owner_occupied_low_income",
  "owner_eviction",
  "high_equity",
  "vacant",
  "absentee_low_income",
  "owner_occupied_low_credit",
  "owner_occupied",
  "property_zips",
  "property_zips_option",
];
