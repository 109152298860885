export enum PayPerLeadActionTypes {
  GET_LEAD = "@@PAY_PER_LEAD/GET_LEAD",
  GET_LEAD_SUCCESS = "@@PAY_PER_LEAD/GET_LEAD_SUCCESS",
  GET_LEAD_FAILURE = "@@PAY_PER_LEAD/GET_LEAD_FAILURE",

  DISPUTE_LEAD = "@@PAY_PER_LEAD/DISPUTE_LEAD",
  DISPUTE_LEAD_SUCCESS = "@@PAY_PER_LEAD/DISPUTE_LEAD_SUCCESS",
  DISPUTE_LEAD_FAILURE = "@@PAY_PER_LEAD/DISPUTE_LEAD_FAILURE",

  GET_LEADS = "@@PAY_PER_LEAD/GET_LEADS",
  GET_LEADS_SUCCESS = "@@PAY_PER_LEAD/GET_LEADS_SUCCESS",
  GET_LEADS_FAILURE = "@@PAY_PER_LEAD/GET_LEADS_FAILURE",

  GET_STATISTICS = "@@PAY_PER_LEAD/GET_STATISTICS",
  GET_STATISTICS_SUCCESS = "@@PAY_PER_LEAD/GET_STATISTICS_SUCCESS",
  GET_STATISTICS_FAILURE = "@@PAY_PER_LEAD/GET_STATISTICS_FAILURE",

  SELECT_LEAD = "@@PAY_PER_LEAD/SELECT_LEAD",
  UNSELECT_LEAD = "@@PAY_PER_LEAD/UNSELECT_LEAD",
  SELECT_ALL_LEADS = "@@PAY_PER_LEAD/SELECT_ALL_LEADS",
  UNSELECT_ALL_LEADS = "@@PAY_PER_LEAD/UNSELECT_ALL_LEADS",

  QUALIFY_LEADS = "@@PAY_PER_LEAD/QUALIFY_LEADS",
  QUALIFY_LEADS_SUCCESS = "@@PAY_PER_LEAD/QUALIFY_LEADS_SUCCESS",
  QUALIFY_LEADS_FAILURE = "@@PAY_PER_LEAD/QUALIFY_LEADS_FAILURE",

  DOWNLOAD_LEADS_CSV = "@@PAY_PER_LEAD/DOWNLOAD_LEADS_CSV",
  DOWNLOAD_LEADS_CSV_SUCCESS = "@@PAY_PER_LEAD/DOWNLOAD_LEADS_CSV_SUCCESS",
  DOWNLOAD_LEADS_CSV_FAILURE = "@@PAY_PER_LEAD/DOWNLOAD_LEADS_CSV_FAILURE",

  GET_CURRENT_MONTH_LEADS = "@@PAY_PER_LEAD/GET_CURRENT_MONTH_LEADS",
  GET_CURRENT_MONTH_SUCCESS = "@@PAY_PER_LEAD/GET_CURRENT_MONTH_SUCCESS",
  GET_CURRENT_MONTH_FAILURE = "@@PAY_PER_LEAD/GET_CURRENT_MONTH_FAILURE",

  CACHE_STATE_OPTIONS = "@@PAY_PER_LEAD/CACHE_STATE_OPTIONS",

  FETCH_COUNTY_OPTIONS = "@@PAY_PER_LEAD/FETCH_COUNTY_OPTIONS",
  FETCH_COUNTY_OPTIONS_SUCCESS = "@@PAY_PER_LEAD/FETCH_COUNTY_OPTIONS_SUCCESS",
  FETCH_COUNTY_OPTIONS_FAILURE = "@@PAY_PER_LEAD/FETCH_COUNTY_OPTIONS_FAILURE",

  FETCH_COUNTY_PRICE = "@@PAY_PER_LEAD/FETCH_COUNTY_PRICES",
  FETCH_COUNTY_PRICE_SUCCESS = "@@PAY_PER_LEAD/FETCH_COUNTY_PRICES_SUCCESS",
  FETCH_COUNTY_PRICE_FAILURE = "@@PAY_PER_LEAD/FETCH_COUNTY_PRICES_FAILURE",

  FETCH_STATE_LEAD_PRICE = "@@PAY_PER_LEAD/FETCH_STATE_LEAD_PRICE",
  FETCH_STATE_LEAD_PRICE_SUCCESS = "@@PAY_PER_LEAD/FETCH_STATE_LEAD_PRICE_SUCCESS",
  FETCH_STATE_LEAD_PRICE_FAILURE = "@@PAY_PER_LEAD/FETCH_STATE_LEAD_PRICE_FAILURE",

  FETCH_FILTER_OPTIONS = "@@PAY_PER_LEAD/FETCH_FILTER_OPTIONS",
  FETCH_FILTER_OPTIONS_SUCCESS = "@@PAY_PER_LEAD/FETCH_FILTER_OPTIONS_SUCCESS",
  FETCH_FILTER_OPTIONS_FAILURE = "@@PAY_PER_LEAD/FETCH_FILTER_OPTIONS_FAILURE",
  RESET_FILTER_OPTIONS = "@@PAY_PER_LEAD/RESET_FILTER_OPTIONS",

  CLEAR_CACHE = "@@PAY_PER_LEAD/RESET_CACHE",

  GET_SUBSCRIPTIONS = "@@PAY_PER_LEAD/GET_SUBSCRIPTIONS",
  GET_SUBSCRIPTIONS_SUCCESS = "@@PAY_PER_LEAD/GET_SUBSCRIPTIONS_SUCCESS",
  GET_SUBSCRIPTIONS_FAILURE = "@@PAY_PER_LEAD/GET_SUBSCRIPTIONS_FAILURE",

  GET_SUBSCRIPTION_DETAILS = "@@PAY_PER_LEAD/GET_SUBSCRIPTION_DETAILS",
  GET_SUBSCRIPTION_DETAILS_SUCCESS = "@@PAY_PER_LEAD/GET_SUBSCRIPTION_DETAILS_SUCCESS",
  GET_SUBSCRIPTION_DETAILS_FAILURE = "@@PAY_PER_LEAD/GET_SUBSCRIPTION_DETAILS_FAILURE",

  GET_CUSTOMERS = "@@PAY_PER_LEAD/GET_CUSTOMERS",
  GET_CUSTOMERS_SUCCESS = "@@PAY_PER_LEAD/GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILURE = "@@PAY_PER_LEAD/GET_CUSTOMERS_FAILURE",

  SET_SUBSCRIPTION_TOUCHED = "@@PAY_PER_LEAD/SET_SUBSCRIPTION_TOUCHED",

  SAVE_SUBSCRIPTION = "@@PAY_PER_LEAD/SAVE_SUBSCRIPTION",
  SAVE_SUBSCRIPTION_SUCCESS = "@@PAY_PER_LEAD/SAVE_SUBSCRIPTION_SUCCESS",
  SAVE_SUBSCRIPTION_FAILURE = "@@PAY_PER_LEAD/SAVE_SUBSCRIPTION_FAILURE",

  DEACTIVATE_SUBSCRIPTION = "@@PAY_PER_LEAD/DEACTIVATE_SUBSCRIPTION",
  DEACTIVATE_SUBSCRIPTION_SUCCESS = "@@PAY_PER_LEAD/DEACTIVATE_SUBSCRIPTION_SUCCESS",
  DEACTIVATE_SUBSCRIPTION_FAILURE = "@@PAY_PER_LEAD/DEACTIVATE_SUBSCRIPTION_FAILURE",

  PURCHASE_SUBSCRIPTION = "@@PAY_PER_LEAD/PURCHASE_SUBSCRIPTION",
  PURCHASE_SUBSCRIPTION_SUCCESS = "@@PAY_PER_LEAD/PURCHASE_SUBSCRIPTION_SUCCESS",
  PURCHASE_SUBSCRIPTION_FAILURE = "@@PAY_PER_LEAD/PURCHASE_SUBSCRIPTION_FAILURE",

  PURCHASE_SUBSCRIPTIONS = "@@PAY_PER_LEAD/PURCHASE_SUBSCRIPTIONS",
  PURCHASE_SUBSCRIPTIONS_SUCCESS = "@@PAY_PER_LEAD/PURCHASE_SUBSCRIPTIONS_SUCCESS",
  PURCHASE_SUBSCRIPTIONS_FAILURE = "@@PAY_PER_LEAD/PURCHASE_SUBSCRIPTIONS_FAILURE",

  AWAIT_PURCHASE_PROCESSED = "@@PAY_PER_LEAD/AWAIT_PURCHASE_PROCESSED",
  AWAIT_PURCHASE_PROCESSED_SUCCESS = "@@PAY_PER_LEAD/AWAIT_PURCHASE_PROCESSED_SUCCESS",
  AWAIT_PURCHASE_PROCESSED_FAILURE = "@@PAY_PER_LEAD/AWAIT_PURCHASE_PROCESSED_FAILURE",

  FETCH_PURCHASE_INFO = "@@PAY_PER_LEAD/FETCH_PURCHASE_INFO",
  FETCH_PURCHASE_INFO_SUCCESS = "@@PAY_PER_LEAD/FETCH_PURCHASE_INFO_SUCCESS",
  FETCH_PURCHASE_INFO_FAILURE = "@@PAY_PER_LEAD/FETCH_PURCHASE_INFO_FAILURE",

  FETCH_TOTAL_PAYMENT_AMOUNT = "@@PAY_PER_LEAD/FETCH_TOTAL_PAYMENT_AMOUNT",
  FETCH_TOTAL_PAYMENT_AMOUNT_SUCCESS = "@@PAY_PER_LEAD/FETCH_TOTAL_PAYMENT_AMOUNT_SUCCESS",
  FETCH_TOTAL_PAYMENT_AMOUNT_FAILURE = "@@PAY_PER_LEAD/FETCH_TOTAL_PAYMENT_AMOUNT_FAILURE",

  ADD_SUBSCRIPTION = "@@PAY_PER_LEAD/ADD_SUBSCRIPTION",
  RESET_SUBSCRIPTION = "@@PAY_PER_LEAD/RESET_SUBSCRIPTION",
  ADD_SUBSCRIPTION_ITEM = "@@PAY_PER_LEAD/ADD_SUBSCRIPTION_ITEM",
  UPDATE_SUBSCRIPTION_ITEM = "@@PAY_PER_LEAD/UPDATE_SUBSCRIPTION_ITEM",
  DELETE_SUBSCRIPTION_ITEM = "@@PAY_PER_LEAD/DELETE_SUBSCRIPTION_ITEM",

  ADD_SUBSCRIPTION_COUNTY = "@@PAY_PER_LEAD/ADD_SUBSCRIPTION_COUNTY",
  UPDATE_SUBSCRIPTION_COUNTY = "@@PAY_PER_LEAD/UPDATE_SUBSCRIPTION_COUNTY",
  DELETE_SUBSCRIPTION_COUNTY = "@@PAY_PER_LEAD/DELETE_SUBSCRIPTION_COUNTY",

  GET_ADMIN_DISPUTES_REQUEST = "@@PAY_PER_LEAD/GET_ADMIN_DISPUTES_REQUEST",
  GET_ADMIN_DISPUTES_SUCCESS = "@@PAY_PER_LEAD/GET_ADMIN_DISPUTES_SUCCESS",
  GET_ADMIN_DISPUTES_FAILURE = "@@PAY_PER_LEAD/GET_ADMIN_DISPUTES_FAILURE",

  UPDATE_ADMIN_DISPUTE_REQUEST = "@@PAY_PER_LEAD/UPDATE_ADMIN_DISPUTE_REQUEST",
  UPDATE_ADMIN_DISPUTE_SUCCESS = "@@PAY_PER_LEAD/UPDATE_ADMIN_DISPUTE_SUCCESS",
  UPDATE_ADMIN_DISPUTE_FAILURE = "@@PAY_PER_LEAD/GET_ADMIN_DISPUTE_FAILURE",

  UPDATE_FILTER = "@@PAY_PER_LEAD/UPDATE_FILTER",

  CLEANUP = "@@PAY_PER_LEAD/CLEANUP",

  SET_STATISTICS_FILTER = "@@PAY_PER_LEAD/SET_STATISTICS_FILTER",
}

export const SUBSCRIPTION_ITEM_EXIST_ERROR = `You already have an active subscription for the selected state and county. Please review your current subscriptions. If you need to update your subscription, click the “Change” button on the subscription details page`;

export enum PurchaseLimit {
  min = 3000 * 100,
}

export enum Stage {
  Configure = "configure",
  Confirm = "confirm",
  Processing = "processing",
}

export interface PurchaseDialogConfig {
  heading: string;
  helpText: string;
  mainButtonText: string;
  backButtonText: string;
}

const confirmationConfig: PurchaseDialogConfig = {
  heading: "Launch Marketing Campaign",
  helpText: `Review the summary of the chosen leads and then proceed  with the purchase.`,
  mainButtonText: "Launch and Pay",
  backButtonText: "Back",
};

export const SubscriptionPurchaseDialog: Record<Stage, PurchaseDialogConfig> = {
  [Stage.Configure]: {
    heading: "Specify Your Leads",
    helpText: `Select the states and counties  you are interested in and choose the number of home seller leads you would like to receive monthly.`,
    mainButtonText: "Generate My Leads",
    backButtonText: "Skip",
  },
  [Stage.Confirm]: confirmationConfig,
  [Stage.Processing]: {
    ...confirmationConfig,
    helpText: `Payment is being processed`,
  },
};
