import { call, put, takeLatest, select, take, race } from "redux-saga/effects";
import { showHttpErrorNotification, tokenHandler, impersonationStorage } from "@shared/utils";
import { navigate, startLoading, stopLoading, getUserDetails, showNotification } from "@shared/store/actions";
import { ActionWithPayload, BaseResponse, ResponseError } from "@shared/interfaces";
import { NameOfChildRoutes, NameOfRoutes } from "@shared/constants";
import { User } from "@shared/models";
import { resetMarketMapState } from "@containers/MarketMap/store/actions";
import { SharedActionTypes } from "@shared/store/constants";

import {
  logout,
  login,
  forgotPassword,
  setPassword,
  activateAccount,
  registration,
  checkUser,
  setRedirectTo,
  resetPassword,
  impersonation,
  impersonationExit,
} from "./actions";
import api from "../api";
import {
  RestoreShape,
  LoginShape,
  ChangePasswordPayloadShape,
  ActivatePayloadShape,
  RegistrationPayloadShape,
  CheckUserShape,
  ImpersonationPayloadShape,
} from "../interface";
import { selectRedirectTo } from "./selectors";

function* loginUser(token: string, redirectUrl?: string) {
  tokenHandler.set(token);

  yield put(getUserDetails.request());
  const { error } = yield race({
    user: take(SharedActionTypes.GET_USER_DETAIL_SUCCESS),
    error: take(SharedActionTypes.GET_USER_DETAIL_FAILURE),
  });

  if (error) {
    yield put(login.failure(error));
  } else {
    yield put(setRedirectTo(redirectUrl || null));
    yield put(login.success());
  }
}

function* clearLocalStorage() {
  yield tokenHandler.removeAll();
}

function* logoutSaga() {
  yield clearLocalStorage();
  yield put(logout.success());
  yield put(getUserDetails.success(null));
  yield put(resetMarketMapState.request(null));
  yield put(navigate(NameOfRoutes.AUTH));
}

function* loginSaga({ payload }: ActionWithPayload<LoginShape>) {
  try {
    yield put(startLoading());
    yield clearLocalStorage();
    const { token }: { token: string } = yield call(api.login, payload);
    const redirectTo: string = yield select(selectRedirectTo());
    yield loginUser(token, redirectTo);
  } catch (error) {
    yield put(login.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* checkUserSaga({ payload }: ActionWithPayload<CheckUserShape & { noRedirect?: boolean }>) {
  try {
    const { noRedirect, ...rest } = payload;
    yield put(startLoading());
    const { email } = rest;
    const { isUserExist }: { isUserExist: boolean } = yield call(api.checkUser, rest);
    yield put(checkUser.success({ filledEmail: email, isUserExist }));

    if (!noRedirect) {
      yield put(navigate(isUserExist ? NameOfRoutes.AUTH_LOGIN : NameOfRoutes.AUTH_REGISTRATION));
    }
  } catch (error) {
    yield put(checkUser.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* forgotPasswordSaga({ payload }: ActionWithPayload<RestoreShape>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.forgotPassword, payload);
    yield put(forgotPassword.success(response));
    yield put(
      showNotification({
        message: "If the provided email exists in our system you will receive a reset password link on it",
        appearance: "success",
      }),
    );
  } catch (error) {
    yield put(forgotPassword.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* setPasswordSaga({ payload }: ActionWithPayload<ChangePasswordPayloadShape>) {
  try {
    yield put(startLoading());
    const { token }: { token: string } = yield call(api.setPassword, payload);

    yield loginUser(token, `${NameOfChildRoutes.SKIP_TRACING.ROOT}${NameOfChildRoutes.SKIP_TRACING.LIST}`);
  } catch (error) {
    yield put(setPassword.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* resetPasswordSaga({ payload }: ActionWithPayload<ChangePasswordPayloadShape>) {
  try {
    yield put(startLoading());
    const { token }: { token: string } = yield call(api.resetPassword, payload);
    yield loginUser(token, `${NameOfChildRoutes.SKIP_TRACING.ROOT}${NameOfChildRoutes.SKIP_TRACING.LIST}`);
  } catch (error) {
    yield put(resetPassword.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* activateSaga({ payload }: ActionWithPayload<ActivatePayloadShape>) {
  try {
    yield put(startLoading());
    const { token, user }: { token: string; user: Partial<User> } = yield call(api.activate, payload);

    if (user) {
      yield put(activateAccount.success(user));
    }

    if (token) {
      yield loginUser(token);
    }
  } catch (error) {
    yield put(navigate(NameOfRoutes.AUTH));
    yield put(activateAccount.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* registrationSaga({ payload }: ActionWithPayload<RegistrationPayloadShape>) {
  try {
    const { redirectUrl = `${NameOfChildRoutes.SKIP_TRACING.ROOT}${NameOfChildRoutes.SKIP_TRACING.CREATE}`, ...rest } =
      payload;
    yield put(startLoading());
    const { token }: { token: string } = yield call(api.registration, rest);
    yield loginUser(token, redirectUrl);
  } catch (error) {
    yield put(activateAccount.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* impersonationSaga({ payload }: ActionWithPayload<ImpersonationPayloadShape>) {
  try {
    yield put(startLoading());
    const { token }: { token: string } = yield call(api.impersonate, {
      user_id: payload.user_id,
    });
    const currentToken = tokenHandler.get() as string;

    impersonationStorage.set({ impersonator: currentToken, exitLocation: payload.returnLocation });

    yield loginUser(token);
    yield put(impersonation.success({ token }));
  } catch (error) {
    yield put(impersonation.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* impersonationExitSaga() {
  try {
    yield put(startLoading());
    const impersonation = impersonationStorage.get();
    if (!impersonation) {
      impersonationExit.failure(new Error("Not impersonated"));

      return;
    }

    yield loginUser(impersonation.impersonator);
    if (impersonation.exitLocation) {
      yield put(navigate(impersonation.exitLocation));
    }

    impersonationStorage.clear();

    yield put(impersonationExit.success());
  } catch (error) {
    yield put(impersonation.failure(error as Error));
    yield put(showHttpErrorNotification(error as ResponseError));
  } finally {
    yield put(stopLoading());
  }
}

function* authSagas() {
  yield takeLatest(logout.request, logoutSaga);
  yield takeLatest(login.request, loginSaga);
  yield takeLatest(checkUser.request, checkUserSaga);
  yield takeLatest(forgotPassword.request, forgotPasswordSaga);
  yield takeLatest(setPassword.request, setPasswordSaga);
  yield takeLatest(resetPassword.request, resetPasswordSaga);
  yield takeLatest(activateAccount.request, activateSaga);
  yield takeLatest(registration.request, registrationSaga);
  yield takeLatest(impersonation.request, impersonationSaga);
  yield takeLatest(impersonationExit.request, impersonationExitSaga);
}

export default authSagas;
