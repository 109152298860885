import React, { useCallback } from "react";
import { SaveFile } from "@shared/models";
import { getUSFormattedDate, getUSFormatedTime } from "@shared/utils";
import "./index.scss";
import { Icon } from "@shared/components";

export interface FileItemProps {
  file: SaveFile;
  onDelete?: (file: SaveFile) => void;
  permission?: string;

  isDisabled?: boolean;
}

const FileItem = (props: FileItemProps) => {
  const { file, onDelete, isDisabled = false } = props;

  const handleDelete = useCallback(() => {
    onDelete?.(file);
  }, [onDelete, file]);

  return (
    <div className="file-item">
      <div className="file-item-header">
        <div className="left-file-item-header">
          <a target="_blank" href={file.url} rel="noreferrer">
            {file.name}
          </a>
        </div>
        <div className="right-file-item-header">
          {file.creator && (
            <>
              <div className="file-user">{`${file.creator.first_name} ${file.creator.last_name}`}</div>
              <div className="file-devider">|</div>
            </>
          )}
          <div className="file-date">
            {`${getUSFormattedDate(file.created_at)} ${getUSFormatedTime(file.created_at)}`}
          </div>
        </div>

        {!isDisabled ? <Icon type="close" onClick={handleDelete} /> : null}
      </div>
    </div>
  );
};

export default FileItem;
