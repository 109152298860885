import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UploadDropzone } from "@shared/components/UploadDropzone";
import { clearUploadedFile, uploadToS3 } from "@shared/store/actions";
import { Button, Icon, InformationBlock } from "@shared/components";
import { FileType } from "@shared/models";
import { selectFile } from "@containers/File/store/selectors";
import { actions } from "@containers/SkipTracing/store";
import { getSkipTracing } from "@containers/SkipTracing/store/selectors";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { UploadFileTitleBlock } from "@containers/SkipTracing/components/UploadFileContainer/UploadFileComponent/components";
import { NameOfChildRoutes } from "@shared/constants";
import { getActiveAccount } from "@containers/Account/store/selectors";

import "./index.scss";

const UploadFileComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createdFile = useSelector(selectFile());
  const createdSkipTracing = useSelector(getSkipTracing());
  const account = useSelector(getActiveAccount());

  const { id: skipTracingId } = useParams();

  const onDropFile = useCallback(
    (acceptedFiles: File[]) => {
      const [file] = acceptedFiles;
      dispatch(
        uploadToS3.request({
          file,
          name: file.name,
          type: FileType.skipTracingInput,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(clearUploadedFile());
    };
  }, [dispatch]);

  useEffect(() => {
    if (createdFile && !createdSkipTracing && !skipTracingId && account) {
      dispatch(
        actions.createSkipTracing.request({
          input_file_id: createdFile.id,
          account_id: account.id,
          user_id: createdFile.user_id,
        }),
      );
    }
  }, [account, createdFile, createdSkipTracing, dispatch, skipTracingId]);

  useEffect(() => {
    if (createdSkipTracing?.id && !skipTracingId) {
      const { id } = createdSkipTracing;
      navigate(
        generatePath(`${NameOfChildRoutes.SKIP_TRACING.ROOT}${NameOfChildRoutes.SKIP_TRACING.DETAILS}?step=1`, {
          id: id.toString(),
        }),
      );
    }
  }, [createdSkipTracing, navigate, skipTracingId]);

  return (
    <div className="upload-file-component-wrapper">
      <div className="upload-wrapper">
        <UploadFileTitleBlock />
        <UploadDropzone
          onDrop={onDropFile}
          maxSize={5 * 1024 * 1024} // 5MB
          multiple={false}
          className="upload-own-file"
          accept={{ "text/html": [".csv", ".xls", ".xlsx"] }}
        >
          <div className="title-wrapper">
            <Icon type="upload-file" />
            <span className="title">Drag & Drop file here</span> <span className="subtitle">or</span>
            <Button className="browse-btn" buttonStyle="accent-green">
              Browse
            </Button>
            <span className="subtitle">Please note that the max file size is 5Mb</span>
          </div>
        </UploadDropzone>
        <InformationBlock text="Once you add the file you will need to map it and make sure that your data is correct. It should not take more that 5 min" />
        <div className="parallelogram-second"></div>
      </div>
    </div>
  );
};

export default UploadFileComponent;
