import React, { FC } from "react";
import { Form, Formik } from "formik";
import { ButtonPrimary, ButtonSecondary, Input, ErrorMessage } from "@shared/components";
import { Modal } from "@shared/components/Modal";

import { formValidationSchema } from "./formHelper";

import "./index.scss";

export type ModalNewPropertyListProps = {
  data?: { id: number; name: string };
  onClose: () => void;
  onSuccess: (values: { id: number; name: string }) => void;
};

export const ModalNewPropertyList: FC<ModalNewPropertyListProps> = (props) => {
  const { data, onClose, onSuccess } = props;

  const initialValues = { id: data?.id as number, name: "" };

  const handleSubmit = (values: { id: number; name: string }) => {
    onSuccess(values);
  };

  return (
    <Modal>
      <Formik
        validationSchema={formValidationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleSubmit }) => {
          const disabled = !values.name || !!errors["name"];

          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Section>
                <Modal.Title>New List</Modal.Title>
                <Modal.Content>
                  <Input name="id" type="hidden" value={values.id} />
                  <Input placeholder="Enter list name" name="name" maxLength={50} value={values.name} />
                  <ErrorMessage isTouched={!!touched["name"]} error={errors["name"]} />
                </Modal.Content>
                <Modal.Actions>
                  <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
                  <ButtonPrimary type="submit" disabled={disabled}>
                    Create
                  </ButtonPrimary>
                </Modal.Actions>
              </Modal.Section>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
