import { useSelector } from "react-redux";
import { selectors } from "@shared/store";
import { RoleName } from "@shared/models";

const useCheckRoles = (roles: RoleName[]) => {
  const user = useSelector(selectors.getUserDetails());

  return user?.roles?.some(({ name }) => roles.includes(name));
};

export default useCheckRoles;
