import { createSelector } from "reselect";

import { AppState, Option } from "../interfaces";

const selectShared = (state: AppState) => state.shared;

export const getLoading = () => createSelector(selectShared, (state) => state.loadingCount > 0);

export const getNotification = () => createSelector(selectShared, (state) => state.notification);

export const getModal = () => createSelector(selectShared, (state) => state.modal);

export const getProgressScreen = () => createSelector(selectShared, (state) => state.progressScreen);

export const getOverlay = () => createSelector(selectShared, (state) => state.overlay);

export const getNavigateURL = () => createSelector(selectShared, (state) => state.navigateURL);

export const getLoadingSection = (sectionName: string) =>
  createSelector(selectShared, (state) => state.loadingSections[sectionName]);

export const getAwsUploadedFile = () => createSelector(selectShared, (state) => state.aws.s3.upload);

export const getUserDetails = () => createSelector(selectShared, (state) => state.user);

export const getAwsUploadedFiles = () => createSelector(selectShared, (state) => state.aws.s3.multipleUploads);

export const getBackgroundUploadWidgetStatus = () =>
  createSelector(selectShared, (state) => state.backgroundUploadWidgetVisibilityStatus);

export const getCounties = () => createSelector(selectShared, (state) => state.counties);
export const getCountiesDropdownValues = () =>
  createSelector(
    selectShared,
    (state) => state.counties?.map<Option<string>>(({ id, name }) => ({ value: String(id), label: name })) ?? [],
  );

export const getStates = () => createSelector(selectShared, (state) => state.states);

export const getPropertyTypes = () => createSelector(selectShared, (state) => state.propertyTypes);

export const getSatesDropdownValues = () =>
  createSelector(
    selectShared,
    (state) => state.states?.map<Option<string>>(({ id, name }) => ({ value: String(id), label: name })) ?? [],
  );

export const getZips = () => createSelector(selectShared, (state) => state.zips);

export const getZipsDropdownValues = () =>
  createSelector(
    selectShared,
    (state) => state.zips?.map<Option<string>>(({ zip_code }) => ({ value: String(zip_code), label: zip_code })) ?? [],
  );

export const getNeighborhoods = () => createSelector(selectShared, (state) => state.neighborhoods);

export const getNeighborhoodsDropdownValues = () =>
  createSelector(
    selectShared,
    (state) => state.neighborhoods?.map<Option<string>>(({ id, name }) => ({ value: String(id), label: name })) ?? [],
  );
