import { createAction, createAsyncAction } from "typesafe-actions";
import { BuyBoxMetrics, GetZipsShape, ResponseError, WithCallback, WithOptionalLoader } from "@shared/interfaces";
import {
  ESPresetDto,
  ESPresetResponse,
  PresetBuilderState,
  PresetFilterChanged,
  PresetFilterShape,
  PresetListItem,
  RemoveTagPayload,
  ZipListSuccessPayloadPresets,
} from "@shared/interfaces/PresetBuilder.interface";
import { CreatePresetDto, PresetBuilderLocationFilters } from "@containers/Preset/interfaces/Preset.interface";

import { PRESET_BUILDER_ACTIONS } from "./constants";

export const getZips = createAsyncAction(
  PRESET_BUILDER_ACTIONS.GET_ZIPS,
  PRESET_BUILDER_ACTIONS.GET_ZIPS_SUCCESS,
  PRESET_BUILDER_ACTIONS.GET_ZIPS_FAILURE,
)<Omit<GetZipsShape, "account">, ZipListSuccessPayloadPresets, ResponseError>();

export const removeTag = createAsyncAction(
  PRESET_BUILDER_ACTIONS.REMOVE_TAG,
  PRESET_BUILDER_ACTIONS.REMOVE_TAG_SUCCESS,
  PRESET_BUILDER_ACTIONS.REMOVE_TAG_FAILURE,
)<RemoveTagPayload, void, ResponseError>();

export const getBuyBoxData = createAsyncAction(
  PRESET_BUILDER_ACTIONS.GET_BUY_BOX,
  PRESET_BUILDER_ACTIONS.GET_BUY_BOX_SUCCESS,
  PRESET_BUILDER_ACTIONS.GET_BUY_BOX_FAILURE,
)<ESPresetDto, BuyBoxMetrics, ResponseError>();

export const filterChange = createAsyncAction(
  PRESET_BUILDER_ACTIONS.FILTER_CHANGE,
  PRESET_BUILDER_ACTIONS.FILTER_CHANGE_SUCCESS,
  PRESET_BUILDER_ACTIONS.FILTER_CHANGE_FAILURE,
)<PresetFilterShape, PresetFilterChanged>();

export const updatePreset = createAsyncAction(
  PRESET_BUILDER_ACTIONS.UPDATE_PRESET,
  PRESET_BUILDER_ACTIONS.UPDATE_PRESET_SUCCESS,
  PRESET_BUILDER_ACTIONS.UPDATE_PRESET_FAILURE,
)<
  WithCallback<Partial<ESPresetDto> & { investorSettingsId: number; filterId: number }>,
  ESPresetResponse,
  ResponseError
>();

export const createPreset = createAsyncAction(
  PRESET_BUILDER_ACTIONS.CREATE_PRESET,
  PRESET_BUILDER_ACTIONS.CREATE_PRESET_SUCCESS,
  PRESET_BUILDER_ACTIONS.CREATE_PRESET_FAILURE,
)<
  CreatePresetDto & { investorSettingsId: number; callback?: (presetId?: string) => void },
  ESPresetResponse,
  ResponseError
>();

export const copyPreset = createAsyncAction(
  PRESET_BUILDER_ACTIONS.COPY_PRESET,
  PRESET_BUILDER_ACTIONS.COPY_PRESET_SUCCESS,
  PRESET_BUILDER_ACTIONS.COPY_PRESET_FAILURE,
)<
  { investorSettingsId: number; filterId: number; callback?: (copy: ESPresetResponse) => void },
  ESPresetResponse,
  ResponseError
>();

export const deletePreset = createAsyncAction(
  PRESET_BUILDER_ACTIONS.DELETE_PRESET,
  PRESET_BUILDER_ACTIONS.DELETE_PRESET_SUCCESS,
  PRESET_BUILDER_ACTIONS.DELETE_PRESET_FAILURE,
)<WithCallback<{ investorSettingsId: number; filterId: number }>, undefined, ResponseError>();

export const getPreset = createAsyncAction(
  PRESET_BUILDER_ACTIONS.GET_PRESET,
  PRESET_BUILDER_ACTIONS.GET_PRESET_SUCCESS,
  PRESET_BUILDER_ACTIONS.GET_PRESET_FAILURE,
)<
  WithOptionalLoader<{ investorSettingsId: number; filterId: number; callback?: () => void }>,
  {
    preset: ESPresetResponse;
    filters: PresetBuilderLocationFilters;
    filterState: PresetBuilderState["filterState"];
  },
  ResponseError
>();

export const getPresetList = createAsyncAction(
  PRESET_BUILDER_ACTIONS.GET_PRESET_LIST,
  PRESET_BUILDER_ACTIONS.GET_PRESET_LIST_SUCCESS,
  PRESET_BUILDER_ACTIONS.GET_PRESET_LIST_FAILURE,
)<{ investorSettingsId: number }, PresetListItem[], ResponseError>();

export const clearPreset = createAction(PRESET_BUILDER_ACTIONS.CLEAR_PRESET)();
export const clearPresetList = createAction(PRESET_BUILDER_ACTIONS.CLEAR_PRESET_LIST)();
