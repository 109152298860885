import React, { FC } from "react";
import { Icon } from "@shared/components";

import "./index.scss";

export interface TitleBlockProps {
  title?: string;
  subtitle?: string;
}

const TitleBlock: FC<TitleBlockProps> = (props) => {
  const { title, subtitle } = props;

  return (
    <div className="title-block-wrapper">
      <div className="title-block-icon">
        <Icon type="auth-screen" />
      </div>
      <div className="title">{title ? title : "Skip My List"}</div>
      <div className="subtitle">
        {subtitle
          ? subtitle
          : "The number 1 source of data nationwide for Real Estate Investors and Agents.\nLeverage the power of AI and predictive data to save time, money, and increase your ROI by finding off market properties and streamlining your marketing efforts."}
      </div>
    </div>
  );
};

export default TitleBlock;
