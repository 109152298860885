import * as Yup from "yup";
import { FORM_ERROR_MESSAGES } from "@shared/constants";

export const formValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, "The entered amount is too small")
    .max(50, "The entered amount is too big")
    .required(`This ${FORM_ERROR_MESSAGES.REQUIRED}`),
});
