import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces";

const selectAccountState = (state: AppState) => state.account;
export const selectPaymentMethodExistence = () =>
  createSelector(selectAccountState, (state) => state.paymentMethodExists);
export const selectCardPaymentMethodExistence = () =>
  createSelector(selectAccountState, (state) => state.cardPaymentMethodExists);
export const selectInvoices = () => createSelector(selectAccountState, (state) => state.invoices);
export const selectPaidInvoiceId = () => createSelector(selectAccountState, (state) => state.paidInvoiceId);

export const getSelectedAccount = () => createSelector(selectAccountState, (state) => state.selectedAccount);
export const getAccounts = () => createSelector(selectAccountState, (state) => state.accounts);
export const getAccountsTotal = () => createSelector(selectAccountState, (state) => state.accountsTotal);
export const getAccountsList = () => createSelector(selectAccountState, (state) => state.accountsList);
export const getAccountsListFilter = () => createSelector(selectAccountState, (state) => state.accountsListFilter);
export const getAccountsPayment = () => createSelector(selectAccountState, (state) => state.accountPayment);

export const getAccountChargebeeValidation = () =>
  createSelector(selectAccountState, (state) => state.accountCheck.isChargebeeIdValid);

export const getSelectedSubscription = () => createSelector(selectAccountState, (state) => state.selectedSubscription);
export const getFeatures = () => createSelector(selectAccountState, (state) => state.features);
export const getActiveAccount = () => createSelector(selectAccountState, (state) => state.activeAccount);
export const getActiveAccountPropertyLists = () =>
  createSelector(selectAccountState, (state) => state.accountPropertyLists);
export const getActiveAccountPropertyList = () =>
  createSelector(selectAccountState, (state) => state.accountPropertyList);
export const getActiveAccountNotes = (type: string, entity: string) =>
  createSelector(selectAccountState, (state) => state.accountNotes[type]?.[entity]);
export const getActiveAccountAttempts = (type: string, entity: string) =>
  createSelector(selectAccountState, (state) => state.accountAttempts[type]?.[entity]);
export const getPropertyListActive = (listId: number) =>
  createSelector(getActiveAccountPropertyLists(), (lists) => lists?.find(({ id }) => id === listId));
