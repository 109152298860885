import React, { FC } from "react";
import { Icon } from "@shared/components";
import { EmptyScreen } from "@shared/components/Page";
import { checkIfAdmin } from "@shared/utils/ACL";

export const NoFilteredLead: FC = () => {
  const isAdmin = checkIfAdmin();

  if (isAdmin) {
    return (
      <EmptyScreen>
        <EmptyScreen.Figure>
          <Icon type="empty-leads" />
        </EmptyScreen.Figure>
        <EmptyScreen.Title>No Leads</EmptyScreen.Title>
        <EmptyScreen.Subtitle>
          There are no leads that match the selected filters. Try to select other client, state, or county.
        </EmptyScreen.Subtitle>
      </EmptyScreen>
    );
  }

  return (
    <EmptyScreen>
      <EmptyScreen.Figure>
        <Icon type="empty-screen" />
      </EmptyScreen.Figure>
      <EmptyScreen.Title>Hang tight, leads are on the way!</EmptyScreen.Title>
      <EmptyScreen.Subtitle>
        It may take a few days for leads to begin populating. You will receive an email as soon as your lead flow
        begins!
      </EmptyScreen.Subtitle>
    </EmptyScreen>
  );
};
