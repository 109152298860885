import { UploadedFile } from "@shared/models/File";

export enum SKIP_TRACING_STATUS {
  INITIAL = "initial",
  TESTING = "testing",
  TESTED_VALID = "tested_valid",
  TESTED_INVALID = "tested_invalid",
  MAPPING_CONFIRMED = "mapping_confirmed",
  PROCESSING = "processing",
  FINISHED = "finished",
  ERROR = "error",
}

export interface SkipTracing {
  id: number;
  user_id: number;
  input_file_id?: number;
  output_file_id?: number;
  status: SKIP_TRACING_STATUS;
  price: number | null;
  records_resolved: number | null;
  input_file: UploadedFile;
  output_file: UploadedFile | null;
  input_records_count: number;
  dnc_included: boolean;
  created_at: Date;
  records_processed?: number;
  skip_tracing_queue_position?: number;
}
