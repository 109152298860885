import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";
import { ClientError } from "@containers/Contact/interfaces";

const selectClient = (state: AppState) => state.client;

export const getClientList = () => createSelector(selectClient, (state) => state.clientList);
export const getFilter = () => createSelector(selectClient, (state) => state.filter);

export const getStates = () => createSelector(selectClient, (state) => state.states);
export const getStatesTotal = () => createSelector(selectClient, (state) => state.statesTotal);
export const getStatesFilter = () => createSelector(selectClient, (state) => state.statesFilter);

export const getCounties = () => createSelector(selectClient, (state) => state.counties);
export const getCountiesTotal = () => createSelector(selectClient, (state) => state.countiesTotal);
export const getCountiesFilter = () => createSelector(selectClient, (state) => state.countiesFilter);

export const getSelectedClient = () => createSelector(selectClient, (state) => state.selectedClient);

export const selectError = (errorType: ClientError) => createSelector(selectClient, (state) => state.errors[errorType]);
