import { createAction, createAsyncAction } from "typesafe-actions";
import { SkipTracingActionTypes } from "@containers/SkipTracing/store/constants";
import { PaginatedResponse, ResponseError, SkipTracingFilter, WithOptionalLoader } from "@shared/interfaces";
import { SkipTracing } from "@shared/models/SkipTracing";
import {
  CreateSkipTracingDto,
  PayForSkipTracingDto,
  ProcessSkipTracingDto,
  SkipTracingError,
  SkipTracingPriceRange,
  SkipTracingStripeCoupon,
  UpdateSkipTracingDto,
} from "@containers/SkipTracing/interfaces";

export const getSkipTracingList = createAsyncAction(
  SkipTracingActionTypes.GET_SKIP_TRACING_LIST,
  SkipTracingActionTypes.GET_SKIP_TRACING_LIST_SUCCESS,
  SkipTracingActionTypes.GET_SKIP_TRACING_LIST_FAILURE,
)<SkipTracingFilter, PaginatedResponse<SkipTracing>, ResponseError>();

export const getSkipTracing = createAsyncAction(
  SkipTracingActionTypes.GET_SKIP_TRACING,
  SkipTracingActionTypes.GET_SKIP_TRACING_SUCCESS,
  SkipTracingActionTypes.GET_SKIP_TRACING_FAILURE,
)<WithOptionalLoader<{ id: number; callback?: () => void }>, SkipTracing, ResponseError>();

export const updateSkipTracing = createAsyncAction(
  SkipTracingActionTypes.UPDATE_SKIP_TRACING,
  SkipTracingActionTypes.UPDATE_SKIP_TRACING_SUCCESS,
  SkipTracingActionTypes.UPDATE_SKIP_TRACING_FAILURE,
)<UpdateSkipTracingDto & { id: number }, SkipTracing, ResponseError>();

export const createSkipTracing = createAsyncAction(
  SkipTracingActionTypes.CREATE_SKIP_TRACING,
  SkipTracingActionTypes.CREATE_SKIP_TRACING_SUCCESS,
  SkipTracingActionTypes.CREATE_SKIP_TRACING_FAILURE,
)<CreateSkipTracingDto, SkipTracing, ResponseError>();

export const deleteSkipTracing = createAsyncAction(
  SkipTracingActionTypes.DELETE_SKIP_TRACING,
  SkipTracingActionTypes.DELETE_SKIP_TRACING_SUCCESS,
  SkipTracingActionTypes.DELETE_SKIP_TRACING_FAILURE,
)<{ id: number }, undefined, ResponseError>();

export const testSkipTracing = createAsyncAction(
  SkipTracingActionTypes.TEST_SKIP_TRACING,
  SkipTracingActionTypes.TEST_SKIP_TRACING_SUCCESS,
  SkipTracingActionTypes.TEST_SKIP_TRACING_FAILURE,
)<{ id: number; callback?: () => void }, undefined, ResponseError>();

export const processSkipTracing = createAsyncAction(
  SkipTracingActionTypes.PROCESS_SKIP_TRACING,
  SkipTracingActionTypes.PROCESS_SKIP_TRACING_SUCCESS,
  SkipTracingActionTypes.PROCESS_SKIP_TRACING_FAILURE,
)<ProcessSkipTracingDto, SkipTracing, ResponseError>();

export const payForSkipTracing = createAsyncAction(
  SkipTracingActionTypes.PAY_FOR_SKIP_TRACING,
  SkipTracingActionTypes.PAY_FOR_SKIP_TRACING_SUCCESS,
  SkipTracingActionTypes.PAY_FOR_SKIP_TRACING_FAILURE,
)<PayForSkipTracingDto, boolean | null, ResponseError>();

export const getPriceRange = createAsyncAction(
  SkipTracingActionTypes.GET_PRICE_RANGE,
  SkipTracingActionTypes.GET_PRICE_RANGE_SUCCESS,
  SkipTracingActionTypes.GET_PRICE_RANGE_FAILURE,
)<number, SkipTracingPriceRange | null, ResponseError>();

export const checkStripeCoupon = createAsyncAction(
  SkipTracingActionTypes.CHECK_STRIPE_COUPON,
  SkipTracingActionTypes.CHECK_STRIPE_COUPON_SUCCESS,
  SkipTracingActionTypes.CHECK_STRIPE_COUPON_FAILURE,
)<{ id: number; coupon: string }, SkipTracingStripeCoupon, ResponseError>();

export const setFilter = createAction(SkipTracingActionTypes.SET_FILTER)<SkipTracingFilter | null>();
export const clearSkipTracingList = createAction(SkipTracingActionTypes.CLEAR_SKIP_TRACING_LIST)();
export const clearSkipTracing = createAction(SkipTracingActionTypes.CLEAR_SKIP_TRACING)();
export const clearStripeCoupon = createAction(SkipTracingActionTypes.CLEAR_STRIPE_COUPON)();

export const clearErrors = createAction(SkipTracingActionTypes.CLEAR_ERRORS)<SkipTracingError[] | void>();
