import React, { createContext, FC, ReactNode, useContext } from "react";

const DatePickerContainerContext = createContext<HTMLElement | undefined>(undefined);

export interface DatePickerContainerProviderProps {
  value: HTMLElement | undefined;
  children: ReactNode;
}

export const DatePickerContainerProvider: FC<DatePickerContainerProviderProps> = (props) => {
  const { value, children } = props;

  return <DatePickerContainerContext.Provider value={value}>{children}</DatePickerContainerContext.Provider>;
};

export const useDatePickerContainer = () => useContext(DatePickerContainerContext);
