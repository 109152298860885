export enum RECORD_MANAGEMENT_ACTIONS {
  GET_PROPERTY_REQUEST = "@@RECORD_MANAGEMENT/GET_PROPERTY_REQUEST",
  GET_PROPERTY_SUCCESS = "@@RECORD_MANAGEMENT/GET_PROPERTY_SUCCESS",
  GET_PROPERTY_FAILURE = "@@RECORD_MANAGEMENT/GET_PROPERTY_FAILURE",

  GET_PROPERTY_LIST_REQUEST = "@@RECORD_MANAGEMENT/GET_PROPERTY_LIST_REQUEST",
  GET_PROPERTY_LIST_SUCCESS = "@@RECORD_MANAGEMENT/GET_PROPERTY_LIST_SUCCESS",
  GET_PROPERTY_LIST_FAILURE = "@@RECORD_MANAGEMENT/GET_PROPERTY_LIST_FAILURE",
  CLEAR_PROPERTY_LIST = "@@RECORD_MANAGEMENT/CLEAR_PROPERTY_LIST",

  GET_LISTS_REQUEST = "@@RECORD_MANAGEMENT/GET_LISTS_REQUEST",
  GET_LISTS_SUCCESS = "@@RECORD_MANAGEMENT/GET_LISTS_SUCCESS",
  GET_LISTS_FAILURE = "@@RECORD_MANAGEMENT/GET_LISTS_FAILURE",

  LINK_PROPERTY = "@@RECORD_MANAGEMENT/LINK_PROPERTY",

  UNLINK_PROPERTY_REQUEST = "@@RECORD_MANAGEMENT/UNLINK_PROPERTY_REQUEST",
  UNLINK_PROPERTY_SUCCESS = "@@RECORD_MANAGEMENT/UNLINK_PROPERTY_SUCCESS",
  UNLINK_PROPERTY_FAILURE = "@@RECORD_MANAGEMENT/UNLINK_PROPERTY_FAILURE",

  GET_OWNER_LIST_REQUEST = "@@RECORD_MANAGEMENT/GET_OWNER_LIST_REQUEST",
  GET_OWNER_LIST_SUCCESS = "@@RECORD_MANAGEMENT/GET_OWNER_LIST_SUCCESS",
  GET_OWNER_LIST_FAILURE = "@@RECORD_MANAGEMENT/GET_OWNER_LIST_FAILURE",

  GET_OWNER_DETAILS_REQUEST = "@@RECORD_MANAGEMENT/GET_OWNER_DETAILS_REQUEST",
  GET_OWNER_DETAILS_SUCCESS = "@@RECORD_MANAGEMENT/GET_OWNER_DETAILS_SUCCESS",
  GET_OWNER_DETAILS_FAILURE = "@@RECORD_MANAGEMENT/GET_OWNER_DETAILS_FAILURE",

  GET_OWNER_PROPERTIES_REQUEST = "@@RECORD_MANAGEMENT/GET_OWNER_PROPERTIES_REQUEST",
  GET_OWNER_PROPERTIES_SUCCESS = "@@RECORD_MANAGEMENT/GET_OWNER_PROPERTIES_SUCCESS",
  GET_OWNER_PROPERTIES_FAILURE = "@@RECORD_MANAGEMENT/GET_OWNER_PROPERTIES_FAILURE",
}
