import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { BuyBoxPresetDto } from "@shared/interfaces/PresetBuilder.interface";
import {
  ExportNeighborhoodsToCSVQuery,
  ExportZipsToCSVQuery,
  GetBuyBoxDataQuery,
  GetNeighborhoodQuery,
  GetZipsQuery,
} from "@shared/interfaces";

export default {
  getCountyMetrics: (payload: { accountID: number; county: string }) => {
    const { accountID, county } = payload;
    return request(METHODS.GET, API.MARKET_MAP.GET_COUNTY_METRICS(accountID, county))();
  },
  getZipMetrics: (payload: { accountID: number; county: string; zip: string }) => {
    const { accountID, county, zip } = payload;
    return request(METHODS.GET, API.MARKET_MAP.GET_ZIP_METRICS(accountID, county, zip))();
  },

  getZips(payload: { accountID: number; county: string; zipsOnly?: boolean }) {
    const { accountID, county, zipsOnly = true } = payload;
    return request(METHODS.GET, API.MARKET_MAP.GET_ZIP_CODES(accountID, county, zipsOnly))();
  },

  getZipsPaginated(payload: GetZipsQuery) {
    const { accountID, from, size, state, fips, zip } = payload;
    return request(
      METHODS.GET,
      API.MARKET_MAP.GET_ZIPS_TABLE({
        accountID,
        from,
        fips,
        size: size,
        state: state,
        zip: zip,
      }),
    )();
  },
  getNeighborhoodsPaginated: ({ accountID, ...payload }: GetNeighborhoodQuery) =>
    request(METHODS.GET, API.MARKET_MAP.GET_NEIGHBORHOODS_TABLE(accountID))({ params: payload }),
  getBuyBoxData(payload: GetBuyBoxDataQuery) {
    return request(METHODS.GET, API.MARKET_MAP.GET_FILTERED_BUY_BOX_DATA(payload))();
  },
  getPresetBuyBoxData(payload: BuyBoxPresetDto) {
    return request(METHODS.GET, API.MARKET_MAP.GET_PRESET_BUY_BOX_DATA(payload))();
  },
  exportZipsToCSV(payload: ExportZipsToCSVQuery) {
    const { accountID, state, zip, fips } = payload;
    return request(
      METHODS.GET,
      API.MARKET_MAP.EXPORT_ZIPS_TO_CSV({
        accountID,
        fips,
        state,
        zip,
      }),
    )({ responseType: "blob" });
  },
  neighborhoodsZipsToCSV(payload: ExportNeighborhoodsToCSVQuery) {
    const { accountId, ...params } = payload;

    return request(
      METHODS.GET,
      API.MARKET_MAP.EXPORT_NEIGHBORHOODS_ZIPS_TO_CSV(accountId),
    )({ responseType: "blob", params });
  },
  getCountyGeoDataByCode(code: string) {
    return request(METHODS.GET, API.MARKET_MAP.GET_COUNTY_GEO_DATA(code))();
  },
  getCountyNeighborhoodsGeoDataByCountyCode(code: string) {
    return request(METHODS.GET, API.MARKET_MAP.GET_COUNTY_NEIGHBORHOODS_GEO_DATA(code))();
  },
  getCountyNeighborhoodMetricsByECId(ecId: string) {
    return request(METHODS.GET, API.MARKET_MAP.GET_COUNTY_NEIGHBORHOOD_METRICS_BY_ES_ID(ecId))();
  },
};
