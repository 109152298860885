import { BuyBoxFilters, CountyMetricsResponse, MarketMapState } from "@shared/interfaces";
import {
  BUY_BOX_ES_TO_UI_KEYS,
  BUY_BOX_FILTER_KEYS,
} from "@containers/MarketMap/components/BuyBoxSliderContainer/constants";
import { DEFAULT_BUY_BOX_FILTERS } from "@shared/constants";
import moment from "moment/moment";

export const getBuyBoxDefaultValues = (response: CountyMetricsResponse) => {
  const { countyMetrics } = response;
  const buyBoxDefaults = BUY_BOX_FILTER_KEYS.reduce<BuyBoxFilters>((acc, key) => {
    const metric = countyMetrics[key];
    const uiKey = BUY_BOX_ES_TO_UI_KEYS[key] as keyof BuyBoxFilters;
    if (uiKey && metric) {
      if (!acc[uiKey]) {
        return {
          ...acc,
          [uiKey]: key.includes("low") ? [metric] : [null, metric],
        };
      } else {
        return {
          ...acc,
          [uiKey]: key.includes("low") ? [metric, acc[uiKey][1]] : [acc[uiKey][0], metric],
        };
      }
    } else {
      return acc;
    }
  }, {} as BuyBoxFilters);

  return {
    ...DEFAULT_BUY_BOX_FILTERS,
    ...buyBoxDefaults,
  };
};

export const getFileName = (state: MarketMapState) => {
  let fileName = state.state?.name ?? "NO_STATE";
  const countyName = state.county?.name;
  const zipCode = state.zipCode;

  if (countyName) fileName += `_${countyName}`;
  if (zipCode) fileName += `_${zipCode}`;

  // timestamp using the format YYYY-MM-DD-HH-MM-SS
  const timestamp = moment().format("YYYY-MM-DD-HH-MM-SS");

  fileName += `_${timestamp}.csv`;

  return fileName;
};
