import React, { FC, HTMLProps, PropsWithChildren } from "react";
import classnames from "classnames";

import "./index.scss";

export type OverlayProps = PropsWithChildren<{ className?: string }>;

export type OverlayComponents = {
  Header: FC<OverlayProps>;
  Title: FC<OverlayProps>;
  Close: FC<HTMLProps<HTMLButtonElement>>;
  Content: FC<OverlayProps>;
};

const Header: OverlayComponents["Header"] = ({ children, className }) => {
  return <div className={classnames("page-overlay-header", className)}>{children}</div>;
};

const Title: FC<OverlayProps> = ({ children, className }) => {
  return <div className={classnames("page-overlay-title", className)}>{children}</div>;
};

const Close: FC<OverlayProps> = ({ className, ...props }) => {
  return <button className={classnames("page-overlay-close", className)} {...props} />;
};

const Content: FC<OverlayProps> = ({ children, className }) => {
  return <div className={classnames("page-overlay-content", className)}>{children}</div>;
};

export const Overlay: FC<OverlayProps> & OverlayComponents = ({ children, className }) => {
  return <div className={classnames("page-overlay", className)}>{children}</div>;
};

Overlay.Header = Header;

Overlay.Title = Title;

Overlay.Close = Close;

Overlay.Content = Content;

export default Overlay;
