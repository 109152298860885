export enum PRESET_BUILDER_ACTIONS {
  GET_PRESET = "@@PRESETS_BUILDER/GET_PRESET",
  GET_PRESET_SUCCESS = "@@PRESETS_BUILDER/GET_PRESET_SUCCESS",
  GET_PRESET_FAILURE = "@@PRESETS_BUILDER/GET_PRESET_BUILDER_DATA_FAILURE",

  GET_ZIPS = "@@PRESETS_BUILDER/ZIPS",
  GET_ZIPS_SUCCESS = "@@PRESETS_BUILDER/ZIPS_SUCCESS",
  GET_ZIPS_FAILURE = "@@PRESETS_BUILDER/ZIPS_FAILURE",

  REMOVE_TAG = "@@PRESETS_BUILDER/REMOVE",
  REMOVE_TAG_SUCCESS = "@@PRESETS_BUILDER/REMOVE_SUCCESS",
  REMOVE_TAG_FAILURE = "@@PRESETS_BUILDER/REMOVE_FAILURE",

  FILTER_CHANGE = "@@FILTER_CHANGE/INPUT",
  FILTER_CHANGE_SUCCESS = "@@FILTER_CHANGE/INPUT_SUCCESS",
  FILTER_CHANGE_FAILURE = "@@FILTER_CHANGE/INPUT_FAILURE",

  GET_BUY_BOX = "@@PRESET_ES/GET_BUY_BOX",
  GET_BUY_BOX_SUCCESS = "@@PRESET_ES/GET_BUY_BOX_SUCCESS",
  GET_BUY_BOX_FAILURE = "@@PRESET_ES/GET_BUY_BOX_BUILDER_DATA_FAILURE",

  CREATE_PRESET = "@@PRESET_ES/CREATE_PRESET",
  CREATE_PRESET_SUCCESS = "@@PRESET_ES/CREATE_PRESET_SUCCESS",
  CREATE_PRESET_FAILURE = "@@PRESET_ES/CREATE_PRESET_FAILURE",

  UPDATE_PRESET = "@@PRESET_ES/UPDATE_PRESET",
  UPDATE_PRESET_SUCCESS = "@@PRESET_ES/UPDATE_PRESET_SUCCESS",
  UPDATE_PRESET_FAILURE = "@@PRESET_ES/UPDATE_PRESET_FAILURE",

  COPY_PRESET = "@@PRESET_ES/COPY_PRESET",
  COPY_PRESET_SUCCESS = "@@PRESET_ES/COPY_PRESET_SUCCESS",
  COPY_PRESET_FAILURE = "@@PRESET_ES/COPY_PRESET_FAILURE",

  DELETE_PRESET = "@@PRESET_ES/DELETE_PRESET",
  DELETE_PRESET_SUCCESS = "@@PRESET/DELETE_PRESET_SUCCESS",
  DELETE_PRESET_FAILURE = "@@PRESET/DELETE_PRESET_FAILURE",

  GET_PRESET_LIST = "@@PRESETS_BUILDER/GET_PRESET_LIST",
  GET_PRESET_LIST_SUCCESS = "@@PRESETS_BUILDER/GET_PRESET_LIST_SUCCESS",
  GET_PRESET_LIST_FAILURE = "@@PRESETS_BUILDER/GET_PRESET_LIST_BUILDER_DATA_FAILURE",

  CLEAR_PRESET = "@@PRESETS_BUILDER/CLEAR_PRESET",

  CLEAR_PRESET_LIST = "@@PRESETS_BUILDER/CLEAR_PRESET_LIST",
}
