import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces";
import { getCounties, getStates } from "@shared/store/selectors";

import { PayPerLeadsError } from "../interface";

const selectPayPerLeadState = (state: AppState) => state.payPerLead;

export const getLeads = () => createSelector(selectPayPerLeadState, (state) => state.leads);
export const getLead = () => createSelector(selectPayPerLeadState, (state) => state.lead);
export const getLeadError = () =>
  createSelector(selectPayPerLeadState, (state) => state.errors[PayPerLeadsError.getPayPerLeadError]);

export const getCurrentMonthLeads = () => createSelector(selectPayPerLeadState, (state) => state.currentMonthLeads);
export const getSelectedLeadIds = () => createSelector(selectPayPerLeadState, (state) => state.selectedLeadIds);

export const getSubscription = () => createSelector(selectPayPerLeadState, (state) => state.subscription);
export const getSubscriptions = () => createSelector(selectPayPerLeadState, (state) => state.subscriptions);

export const subscriptionsSortedByIsActive = createSelector(selectPayPerLeadState, (state) => {
  const all = state.subscriptions ?? [];
  const active = all.filter((s) => s.is_active);
  const inactive = all.filter((s) => !s.is_active);

  return [...active, ...inactive];
});

export const allSubscriptionFieldsTouched = createSelector(selectPayPerLeadState, (state) => {
  return state.subscription.items.every((item) => {
    if (!item.state_id_touched) {
      return false;
    }

    if (item.all_counties || !item.force_total_per_county) {
      return item.leads_total_touched;
    } else {
      return item.counties.every((c) => c.touched);
    }
  });
});

export const getCache = () => createSelector(selectPayPerLeadState, (state) => state.cache);

export const getDisputes = () => createSelector(selectPayPerLeadState, (state) => state.disputes);
export const getSubscriptionsWithCustomers = () => createSelector(selectPayPerLeadState, (state) => state.customers);
export const getStatistics = () => createSelector(selectPayPerLeadState, (state) => state.statistics);

export const getFilter = () => createSelector(selectPayPerLeadState, (state) => state.filter);

export const getPurchaseInfo = () => createSelector(selectPayPerLeadState, (state) => state.purchaseInfo);

export const filterOptions = createSelector(selectPayPerLeadState, (state) => state.filterOptions);
export const getStatisticsFilter = () => createSelector(selectPayPerLeadState, (state) => state.statisticsFilter);

export const availableStates = createSelector([getStates(), filterOptions], (states, filterOptions) => {
  if (!filterOptions) {
    return null;
  }

  return states?.filter((state) => filterOptions.states.includes(state.id)) ?? null;
});

export const availableCounties = createSelector([getCounties(), filterOptions], (counties, filterOptions) => {
  if (!filterOptions) {
    return null;
  }

  return counties?.filter((county) => filterOptions.counties.includes(county.id)) ?? null;
});
