export const getQueueSubtitleText = (skipTracingQueuePosition: number) => {
  let subtitleText = "";
  switch (true) {
    case skipTracingQueuePosition === 1:
      subtitleText = "Your file is currently processing. Please check back in 15 minutes";
      break;
    case skipTracingQueuePosition === 2:
      subtitleText = "You are next in line. Please check back in 15 minutes";
      break;
    case skipTracingQueuePosition >= 3 && skipTracingQueuePosition <= 10:
      subtitleText = "Few people are ahead of you. Please check back in 30 minutes";
      break;
    case skipTracingQueuePosition > 10:
      subtitleText = "We are experiencing high demand. Please check back in 1 hour";
      break;
    default:
      break;
  }
  return subtitleText;
};
