import { Tag, TagGroup } from "rsuite";
import React from "react";
import { PropertyFilterOption, PropertyFilterType } from "@shared/interfaces/RecordManagement";
import "./index.scss";
import { useListOptions } from "@containers/RecordManagement/components/FilterInputGroup/useListOptions.hook";
import { NotAvailableText } from "@shared/constants";

interface Props {
  filter: PropertyFilterOption;
  onTagRemove?: (idx?: number) => void;
}

export const SelectedFiltersItem = ({ filter, onTagRemove }: Props) => {
  const options = useListOptions({ field: filter.value, type: filter.type });

  return (
    <>
      {(filter.type === PropertyFilterType.TAGS || filter.type === PropertyFilterType.TAGS_FREE) && (
        <TagGroup>
          {filter.filter?.map((tag, idx) => (
            <Tag key={idx} closable onClose={() => onTagRemove?.(idx)}>
              {options.find((i) => i.id === tag)?.name || tag}
            </Tag>
          ))}
        </TagGroup>
      )}

      {filter.type === PropertyFilterType.MIN_MAX && (
        <TagGroup>
          <Tag closable onClose={() => onTagRemove?.()}>
            {filter.filter?.min || NotAvailableText} - {filter.filter?.max || NotAvailableText}
          </Tag>
        </TagGroup>
      )}

      {filter.type === PropertyFilterType.FROM_TO && (
        <TagGroup>
          <Tag closable onClose={() => onTagRemove?.()}>
            {filter.filter?.from || NotAvailableText} - {filter.filter?.to || NotAvailableText}
          </Tag>
        </TagGroup>
      )}
    </>
  );
};
