import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { tokenHandler, impersonationStorage } from "@shared/utils";

import { AuthErrorType, AuthStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: AuthStateType = {
  authentificated: !!tokenHandler.get(),
  impersonated: impersonationStorage.isImpersonated(),
  activateUserInfo: null,
  filledEmail: null,
  redirectTo: null,
  userExistence: null,
  errors: {
    [AuthErrorType.loginError]: null,
    [AuthErrorType.checkUserError]: null,
  },
};

const reducer = createReducer<AuthStateType, Action>(initialState)
  .handleAction(actions.login.success, (state) =>
    produce(state, (nextState) => {
      nextState.authentificated = true;
      nextState.userExistence = null;
      nextState.errors[AuthErrorType.loginError] = null;
    }),
  )
  .handleAction(actions.login.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AuthErrorType.loginError] = action.payload;
    }),
  )
  .handleAction(actions.activateAccount.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.activateUserInfo = action.payload;
    }),
  )
  .handleAction(actions.logout.success, (state) =>
    produce(state, (nextState) => {
      nextState.authentificated = false;
      nextState.impersonated = false;
    }),
  )
  .handleAction(actions.checkUser.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.filledEmail = action.payload.filledEmail;
      nextState.userExistence = action.payload.isUserExist;
      nextState.errors[AuthErrorType.checkUserError] = null;
    }),
  )
  .handleAction(actions.checkUser.failure, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[AuthErrorType.checkUserError] = action.payload;
    }),
  )
  .handleAction(actions.impersonation.success, (state) =>
    produce(state, (nextState) => {
      nextState.impersonated = true;
    }),
  )
  .handleAction(actions.impersonationExit.success, (state) =>
    produce(state, (nextState) => {
      nextState.impersonated = impersonationStorage.isImpersonated();
    }),
  )
  .handleAction(actions.setRedirectTo, (state, action) =>
    produce(state, (nextState) => {
      nextState.redirectTo = action.payload;
    }),
  )
  .handleAction(actions.clearError, (state, action) =>
    produce(state, (nextState) => {
      nextState.errors[action.payload] = null;
    }),
  )
  .handleAction(actions.clearUserExistence, (state) =>
    produce(state, (nextState) => {
      nextState.userExistence = null;
    }),
  )
  .handleAction(actions.clearFilledEmail, (state) =>
    produce(state, (nextState) => {
      nextState.filledEmail = null;
    }),
  );

export { reducer as AuthReducer };
