import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { ESPresetDto } from "@shared/interfaces/PresetBuilder.interface";

export type WithFilterId = {
  filterId: number;
};

export type WithInvestorSettingsId = {
  investorSettingsId: number;
};

export default {
  getPreset: (payload: WithInvestorSettingsId & WithFilterId) => {
    const { investorSettingsId, filterId } = payload;
    return request(METHODS.GET, API.INVESTOR_SETTINGS.GET_ES_PRESET(String(investorSettingsId), String(filterId)))();
  },

  getPresetList: (payload: WithInvestorSettingsId) => {
    const { investorSettingsId } = payload;
    return request(METHODS.GET, API.INVESTOR_SETTINGS.GET_ES_PRESET_LIST(String(investorSettingsId)))();
  },

  createPreset: (payload: ESPresetDto & WithInvestorSettingsId) => {
    const { investorSettingsId, ...rest } = payload;
    return request(METHODS.POST, API.INVESTOR_SETTINGS.CREATE_ES_PRESET(String(investorSettingsId)))(rest);
  },
  updatePreset: (payload: ESPresetDto & WithInvestorSettingsId & WithFilterId) => {
    const { investorSettingsId, filterId, ...rest } = payload;
    return request(
      METHODS.PATCH,
      API.INVESTOR_SETTINGS.UPDATE_ES_PRESET(String(investorSettingsId), String(filterId)),
    )(rest);
  },
  copyPreset: (payload: WithInvestorSettingsId & WithFilterId) => {
    const { investorSettingsId, filterId } = payload;

    return request(METHODS.POST, API.INVESTOR_SETTINGS.COPY_ES_PRESET(String(investorSettingsId), String(filterId)));
  },
  deletePreset: (payload: { investorSettingsId: number; filterId: number }) => {
    const { investorSettingsId, filterId } = payload;
    return request(
      METHODS.DELETE,
      API.INVESTOR_SETTINGS.DELETE_ES_PRESET(String(investorSettingsId), String(filterId)),
    )();
  },

  getFilterFields: () => request(METHODS.GET, API.INVESTOR_SETTINGS.GET_FILTER_FIELDS)(),
};
